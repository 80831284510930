import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, TableFooter } from '@mui/material';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Check, Close, QuestionAnswer, TrainRounded } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../MessageListViewer';
import PerformaCancelSales from './PerformaCancelSales';
import CancelByJam from './CancelByJam';

class PerformaSalesByLabel extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null,
        resumeByKategori: [],
        years: [],
        selectedMonth: moment().format("M"),
        selectedYear: moment().format("YYYY"),
        dataPerforma: [],
        contactLeadsHourly: [],
        listAppProfiles: [],
        filterAppNumber: "",
        performaTotalCancel: 0,
        performaTotalPengurangLeads: 0,
        performaTotalLeads: 0,
        summaryWeekly: {},
        performaSalesByLabel: [],
        weeklyLeadsPercentage: [],
        summary: {
          leads: 0,
          paid: 0,
          unpaid: 0,
          pending: 0,
          cancel: 0,
          random: 0,
        }
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.handleRefresh();
          // this.getResumeWeekly(true);
        }
      );
    }

    handleRefresh = () => {
        this.loadAppProfile();
        this.getContactLeads();
    }

    handleChangeMonth = (event) => {
      this.setState({selectedMonth: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleChangeYear = (event) => {
      this.setState({selectedYear: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        console.log(this.state.appProfileId);
        // this.getContactLeads();
        this.handleRefresh();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
  };

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], contactLeadsHourly: [], performaTotalCancel: 0, performaTotalLeads: 0, performaTotalPengurangLeads: 0, additionalData: null, isLoading: true}, () => {
            axios
            .post(Api.getContactLeads(), params, Api.getRequestHeader())
            .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }
            
            var performaSalesByLabel = [];

            var summary = {
              leads: 0,
              paid: 0,
              unpaid: 0,
              pending: 0,
              cancel: 0,
              random: 0,
            };

            tmpContactLeads.forEach(lead => {
                var performaSalesByLabelItem = {
                    salesName: lead.salesName,
                    jumlahLeads: lead.leads.length,
                    paid: lead.leads.filter(itemLead => itemLead.label === Global.labelPaid()).length,
                    unpaid: lead.leads.filter(itemLead => itemLead.label === Global.labelUnpaid()).length,
                    pending: lead.leads.filter(itemLead => itemLead.label === Global.labelPending()).length,
                    cancel: lead.leads.filter(itemLead => itemLead.label === Global.labelCancel()).length,
                    random: lead.leads.filter(itemLead => itemLead.label === Global.labelRandomLeads()).length
                };

                performaSalesByLabel.push(performaSalesByLabelItem);
                summary.leads += performaSalesByLabelItem.jumlahLeads;
                summary.paid += performaSalesByLabelItem.paid;
                summary.unpaid += performaSalesByLabelItem.unpaid;
                summary.pending += performaSalesByLabelItem.pending;
                summary.cancel += performaSalesByLabelItem.cancel;
                summary.random += performaSalesByLabelItem.random;

            });

            this.setState({contactLeads: tmpContactLeads, performaSalesByLabel: performaSalesByLabel, summary: summary, isLoading: false, additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null}, () => {
                // console.log("CONTACTLEADS : ", this.state.contactLeads);
            });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      });
      
    };

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => this.handleRefresh());
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        
        return ( 
          <>
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-2" style={{padding: "5px"}}>
                            <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                          </div>
                          <div className="col-2">
                              <FormControl variant="outlined" style={{width: '100%'}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.appProfileId}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl>
                            </div>
                          <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                              
                              {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.handleRefresh}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                              
                            </div>
                        </div>

                        {/* Performa sales by label */}
                        <Divider style={{marginTop: "10px", marginBottom: "10px"}} />
                        <div className="row align-items-center">
                            <div className="col-6">
                              <h4>Performa Sales By Label</h4>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-1"></div>
                            <div className="col-2 text-center" style={{borderRadius: 10, padding: 5}}></div>
                        </div>
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                        <TableCell key={"head1"} align={"left"}>
                                            <strong>Sales</strong>
                                        </TableCell>
                                        <TableCell key={"head2"} align={"left"}>
                                            <strong>Jumlah Leads</strong>
                                        </TableCell>
                                        <TableCell key={"head3"} align={"center"}>
                                            <strong>Paid</strong>
                                        </TableCell>
                                        <TableCell key={"head4"} align={"center"}>
                                            <strong>Unpaid</strong>
                                        </TableCell>
                                        <TableCell key={"head5"} align={"center"}>
                                            <strong>Pending</strong>
                                        </TableCell>
                                        <TableCell key={"head6"} align={"center"}>
                                            <strong>Cancel</strong>
                                        </TableCell>
                                        <TableCell key={"head7"} align={"center"}>
                                            <strong>Random</strong>
                                        </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.performaSalesByLabel.map((row) => {
                                        return (
                                            <TableRow>
                                            <TableCell>{row.salesName}</TableCell>
                                            <TableCell align='center'>{row.jumlahLeads}</TableCell>
                                            <TableCell align='center'>{row.paid}</TableCell>
                                            <TableCell align='center'>{row.unpaid}</TableCell>
                                            <TableCell align='center'>{row.pending}</TableCell>
                                            <TableCell align='center'>{row.cancel}</TableCell>
                                            <TableCell align='center'>{row.random}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                        <TableRow>
                                            <TableCell><strong>Summary</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.leads}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.paid}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.unpaid}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.pending}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.cancel}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.random}</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        {/* Performa sales by label */}
                        
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>
            </Paper>
            <PerformaCancelSales startDate={this.state.startDate} endDate={this.state.endDate} isComponentMode={true}/>
            <CancelByJam startDate={this.state.startDate} endDate={this.state.endDate} isComponentMode={true} />
            </>
         );
    }
}
 
export default PerformaSalesByLabel;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});


function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }