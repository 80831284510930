import { Alert, Button, Container, Fab, Menu, MenuItem, Popover } from '@mui/material';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import momen from "moment";
import axios from 'axios';
import FollowUp123 from '.';
import { Campaign, ClearAll, Warning } from '@mui/icons-material';

class NotifyFuSalesOnline extends Component {
    state = { 
        anchorEl: null,
        isMenuOpen: false,
        appNumber: Global.getAppProfile().waNumber,
        showList: false
     } 

    handleClick = (event) => {
        console.log(event);
        this.setState({anchorEl: event.target, isMenuOpen: true});
    };
    handleClose = (messageText) => {
        this.setState({anchorEl: null, isMenuOpen: false});
        // this.props.onOpenCreateOrder(messageText);
    };

    getContactInfo = (waNumber) => {
        console.log("INIPROPS", this.props)
        let params = {
            waNumber: waNumber
        } 
        axios
        .post(Api.getContactInfo(), params, Api.getRequestHeader())
        .then((response) => {
            let responseData = response.data;
            if(responseData.success === true){
                let contact = this.props.generateItemConversation(responseData.data);
                this.props.handleConversationSelected(contact);
            }
        })
        .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
        });
    }

    toggleShowList = () => {
        this.setState({showList: !this.state.showList});
    }

    render() { 
        return (
            <>
            {
                this.props.mode === "fab" ? 
                (
                    // <Fab variant="extended" size="small" sx={{position: "fixed", bottom: 20, right: "5vw"}} color="error" onClick={this.toggleShowList}>
                    <Fab variant="extended" size="small" sx={{position: "fixed", bottom: 20, left: "20vw"}} color="error" onClick={this.toggleShowList}>
                        <Campaign />
                    </Fab>
                ) : 
                (
                        <div>
                            <Alert variant="filled" severity="warning" action={
                                // <Button variant='outlined' size="small" onClick={this.handleClick}>
                                <Button color='error' variant='contained' size="small" onClick={this.toggleShowList}>
                                    <ClearAll />&nbsp;
                                    Lihat Customer FU
                                </Button>
                            }>
                                {
                                    this.props.countListFuSalesOnline > 0 && "Anda punya data yang harus di followup segera, check data followup anda sekarang!"
                                }

                                {
                                    this.props.countListFuSalesOnline <= 0 && "Lihat customer yang perlu di Follow Up!"
                                }
                                
                            </Alert>
                        </div>
                )
            }

            {this.state.showList && <FollowUp123 onClose={this.toggleShowList} {...this.props} />}

            </>
        );
    }
}
 
export default NotifyFuSalesOnline;