import React, { Component } from 'react'
import axios from "axios";
import Api from "../../customlib/Api";
import {TextField, Autocomplete, Paper} from '@mui/material';

class SettingManager extends Component {
    state = { 
        teleManager: 0,
        salesManager: 0,
        teleManagerName: "",
        salesManagerName: "",
        users: [],
     }

    getAllUsers = () => {
        axios
        .post(Api.allUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            // console.log("SALESTELEUSER : ", response.data.data);
            this.setState({users: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    getContactManager = () => {
        axios
        .post(Api.getContactManager(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("CONTACTMANAGER : ", response.data.data);
            this.setState({
                salesManager: response.data.data.salesManagerId,
                salesManagerName: response.data.data.salesManagerName,
                teleManager: response.data.data.teleManagerId,
                teleManagerName: response.data.data.teleManagerName
            });
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    saveContactManager = () => {

        const params = {
            salesManagerId: this.state.salesManager,
            salesManagerName: this.state.salesManagerName,
            teleManagerId: this.state.teleManager,
            teleManagerName: this.state.teleManagerName
        };

        axios
        .post(Api.saveContactManager(), params, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            // this.setState({users: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleTeleManagerSelectionChange = (name) => {
        const salesData = [...this.state.users];
        let selected = salesData.filter((user) => user.name===name);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){
            this.setState({teleManager: selected[0].id, teleManagerName: selected[0].name});
        }
    }

    handleSalesManagerSelectionChange = (name) => {
        const salesData = [...this.state.users];
        let selected = salesData.filter((user) => user.name===name);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){
            this.setState({salesManager: selected[0].id, salesManagerName: selected[0].name});
        }

    }

    componentDidMount() {
        this.getContactManager();
        this.getAllUsers();
    }
    

    render() { 
        console.log("STATE : ", this.state);
        return ( 
            <Paper style={{padding: 20}}>
                <div className="container-fluid">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div className="panel-title">
                                {/* <h1>Setup Contact</h1> */}
                            </div>
                        </div>
                        <div className="panel-body">
                        <Autocomplete
                            id="free-solo-2-demo"
                            // disableClearable
                            freeSolo 
                            options={this.state.users.map((option) => {
                                // console.log("LABEL : ", option);
                                return String(option.name);
                            })}
                            onChange={(event, newValue) => {
                                // console.log("NEWVALUE : ", event);
                                this.handleTeleManagerSelectionChange(newValue);
                            }} 
                            value={this.state.teleManagerName}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tele manager"
                                margin="0"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                        />
                        <br />
                        <Autocomplete
                            id="free-solo-2-demo"
                            // disableClearable
                            freeSolo 
                            options={this.state.users.map((option) => {
                                // console.log("LABEL : ", option);
                                return String(option.name);
                            })}
                            onChange={(event, newValue) => {
                                // console.log("NEWVALUE : ", event);
                                this.handleSalesManagerSelectionChange(newValue);
                            }} 
                            value={this.state.salesManagerName}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Sales manager"
                                    margin="0"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                />
                                )}
                            />
                        </div>
                        <hr />
                        <div className="panel-footer text-right">
                            <button onClick={this.saveContactManager} className="btn btn-primary">Simpan</button>
                        </div>
                    </div>
                </div>
            </Paper>
         );
    }
}
 
export default SettingManager;