import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import moment from 'moment';

class HistoryContactLabel extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 0,
        rowsPerPage: 10,
        showAddForm: false,
        keyword: "",
        appProfileId: "",
        searchInField: "fromName"
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getMyCustomerToday = (replaceAll) => {

        var userData = Global.get();
        const params = {
            contactNumber: this.props.contactNumber
        }

        console.log("PARAMS", params, "replaceAll", replaceAll);

        axios
        .post(Api.historyContactLabel(), params, Api.getRequestHeader())
        .then((response) => {
            console.log("MyCustomerToday : ", response.data.data);
            var history = response.data.data;
            this.setState({listMycustomer: history});
        })
        .catch((error) => {
            console.error("There was an error!", userData.user.id, error);
        });
    };

    
    componentDidMount() {
        this.getMyCustomerToday();
    }

    columns = [
        { id: 'functionName', label: 'Function Name', minWidth: 150 },
        { id: 'callerFunctionName', label: 'Caller Function Name', minWidth: 100 },
        {
            id: 'byUserName',
            label: 'By User',
            minWidth: 170,
            align: 'left',
        },
        {
            id: 'labelBefore',
            label: 'Before',
            minWidth: 170,
            align: 'left'
        },
        {
            id: 'labelAfter',
            label: 'After',
            minWidth: 170,
            align: 'left',
        },
        {
            id: 'message',
            label: 'Pesan',
            minWidth: 100,
            align: 'left',
        },
        {
            id: 'created_at',
            label: 'Tanggal',
            minWidth: 100,
            align: 'left',
            format: (value) => Global.formatDateTimeToStrDateTime24(value)
        },
    ];

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          this.getMyCustomerToday(true);
        }
      };

    render() { 
        return ( 
            <div className="container-fluid">
                <h3>History Perubahan Label</h3>
                <Paper style={{padding: "10px"}}>
                    <div className="row">
                        <div className="col-1"><strong>Nomor</strong></div>
                        <div className="col-6">{this.props.contactNumber}</div>
                    </div>
                    <div className="row">
                        <div className="col-1"><strong>Nama</strong></div>
                        <div className="col-6">{this.props.contactName}</div>
                    </div>
                </Paper>
                <br />
                <Paper className="root">
                    
                    <TableContainer style={{maxHeight: 440}}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                            {this.columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.id}>
                                {
                                    this.columns.map((column) => {
                                        return <TableCell key={column.id} align={column.align}>
                                            {column.format ? column.format(row[column.id]) : row[column.id]}  
                                        </TableCell>
                                    })
                                }
                                </TableRow>
                            );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 75, 100]}
                        component="div"
                        count={this.state.listMycustomer.length + this.state.rowsPerPage}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    /> */}
                </Paper>
            </div>
        );
    }
}
 
export default HistoryContactLabel;