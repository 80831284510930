import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, TableFooter } from '@mui/material';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Check, Close, QuestionAnswer, TrainRounded } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../MessageListViewer';

class CancelByJam extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null,
        resumeByKategori: [],
        years: [],
        selectedMonth: moment().format("M"),
        selectedYear: moment().format("YYYY"),
        dataPerforma: [],
        contactLeadsHourly: [],
        listAppProfiles: [],
        filterAppNumber: "",
        performaTotalCancel: 0,
        performaTotalPengurangLeads: 0,
        performaTotalLeads: 0,
        summaryWeekly: {},
        performaSalesByLabel: [],
        weeklyLeadsPercentage: []
    }

    componentDidUpdate(prevProps, prevState){

      if(this.props.isComponentMode !== true){
        return;
      }

      if(prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate)
      this.setState(
        {startDate: this.props.startDate, endDate: this.props.endDate}, ()=>{
          this.handleRefresh();
        }
      );
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.handleRefresh();
          // this.getResumeWeekly(true);
        }
      );
    }

    handleRefresh = () => {
        this.loadAppProfile();
        this.getContactLeads();
    }

    handleChangeMonth = (event) => {
      this.setState({selectedMonth: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleChangeYear = (event) => {
      this.setState({selectedYear: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        console.log(this.state.appProfileId);
        // this.getContactLeads();
        this.handleRefresh();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
  };

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], contactLeadsHourly: [], performaTotalCancel: 0, performaTotalLeads: 0, performaTotalPengurangLeads: 0, additionalData: null, isLoading: true}, () => {
            axios
            .post(Api.getContactLeads(), params, Api.getRequestHeader())
            .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

            var performaSalesByLabel = [];

            var tmpContactLeadsHourly = [
                {
                    id: "24000300",
                    label: "24:00 - 03:00",//2400 - 2700 am
                    count: 0,
                    detail: []
                },
                {
                    id: "03010600",
                    label: "03:01 - 06:00",//2701 - 3000 am
                    count: 0,
                    detail: []
                },
                {
                    id: "06010900",
                    label: "06:01 - 09:00",//3001 - 3300 am
                    count: 0,
                    detail: []
                },
                {
                    id: "09011200",
                    label: "09:01 - 12:00",//3301 - 3600 am
                    count: 0,
                    detail: []
                },
                {
                    id: "12011600",
                    label: "12:01 - 16:00",//3601 - 4000 pm
                    count: 0,
                    detail: []
                },
                {
                    id: "16011800",
                    label: "16:01 - 18:00",//4001 - 4200 pm
                    count: 0,
                    detail: []
                },
                {
                    id: "18012100",
                    label: "18:01 - 21:00",//4201 - 4500 pm
                    count: 0,
                    detail: []
                },
                {
                    id: "21012359",
                    label: "21:01 - 23:59",//4501 - 4759 pm
                    count: 0,
                    detail: []
                },
            ]

            tmpContactLeads.forEach(lead => {
                
                lead.leads.forEach(contact => {

                    //Hourly
                    // var originalTime = Global.formatDateTimeToStrTime12(contact.createdAt, true);
                    var originalTime = Global.formatDateTimeToStrFormat(contact.created_at,"hh:mm A");
                    var timeAndAmPm = originalTime.split(" ");
                    var time = timeAndAmPm[0];
                    var amPm = timeAndAmPm[1];
                    var convertedTime = this.convertTime12to24(originalTime);

                    var timeValue = parseInt(convertedTime.replaceAll(":", ""));

                    console.log("TIMEVALUE", timeValue);

                    if(contact.firstLabel === Global.labelCancel()){
                        if(timeValue <= 2359){
                            timeValue += 2400;
                        }
                        
                        if(timeValue >= 2400 && timeValue <= 2700){
                            tmpContactLeadsHourly[0].count += 1;
                            tmpContactLeadsHourly[0].detail.push(contact);
                        }else if(timeValue >= 2701  && timeValue <= 3000){
                            tmpContactLeadsHourly[1].count += 1;
                            tmpContactLeadsHourly[1].detail.push(contact);
                        }else if(timeValue >= 3001 && timeValue <= 3300){
                            tmpContactLeadsHourly[2].count += 1;
                            tmpContactLeadsHourly[2].detail.push(contact);
                        }else if(timeValue >= 3301 && timeValue <= 3600){
                            tmpContactLeadsHourly[3].count += 1;
                            tmpContactLeadsHourly[3].detail.push(contact);
                        }else if(timeValue >= 3601 && timeValue <= 4000){
                            tmpContactLeadsHourly[4].count += 1;
                            tmpContactLeadsHourly[4].detail.push(contact);
                        }else if(timeValue >= 4001 && timeValue <= 4200){
                            tmpContactLeadsHourly[5].count += 1;
                            tmpContactLeadsHourly[5].detail.push(contact);
                        }else if(timeValue >= 4201 && timeValue <= 4500){
                            tmpContactLeadsHourly[6].count += 1;
                            tmpContactLeadsHourly[6].detail.push(contact);
                        }else if(timeValue >= 4501 && timeValue <= 4759){
                            tmpContactLeadsHourly[7].count += 1;
                            tmpContactLeadsHourly[7].detail.push(contact);
                        }
                    }
                    
                });

            });

            this.setState({contactLeads: tmpContactLeads, contactLeadsHourly: tmpContactLeadsHourly, isLoading: false, additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null}, () => {
                console.log("CONTACTLEADS : ", this.state.contactLeads);
            });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      });
      
    };

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah FU', minWidth: 100 },
        // { id: 'totalAdaResponLavender', label: 'Respon Lavender', minWidth: 100 },
        { id: 'totalAdaResponHsm', label: 'Respon HSM', minWidth: 100 },
        // { id: 'responRateLavender', label: 'Respon Rate Lavender', minWidth: 100 },
        { id: 'responRate', label: 'Respon Rate HSM', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => this.handleRefresh());
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        return ( 
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

            {
              this.state.currentConversation !== null ? (
                  <Dialog
                      open={this.state.currentConversation !== null}
                      // TransitionComponent={Transition}
                      // keepMounted
                      onClose={() => {this.setState({currentConversation: null})}}
                      aria-describedby=""
                  >
                      {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                      <DialogContent style={{height: "100%"}}>
                          <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} />
                      </DialogContent>
                      <DialogActions>
                      {/* <Button onClick={handleClose}>Disagree</Button>
                      <Button onClick={handleClose}>Agree</Button> */}
                      </DialogActions>
                  </Dialog>
              ) : (<></>)
            }

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                      {
                        this.props.isComponentMode !== true ? (<div className="row">
                        <div className="col-6"></div>
                        <div className="col-2" style={{padding: "5px"}}>
                          <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                        </div>
                        <div className="col-2">
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.appProfileId}
                                    onChange={this.handleAppTypeChanged}
                                    label="Aplikasi"
                                >
                                    {
                                    this.state.listAppProfiles.map(
                                        (profile) => {
                                        return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                        } 
                                    )
                                    }
                                </Select>
                            </FormControl>
                          </div>
                        <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                            
                            {
                              this.state.isLoading ? 
                              (
                                <>
                                  Sedang memuat
                                  <LinearProgress color="secondary" />
                                </>
                              ) : 
                              (
                                <div className="btn btn-primary" onClick={this.handleRefresh}>
                                  <Refresh /> Refresh
                                </div>
                              )
                            }
                            
                          </div>
                      </div>) : (<></>)
                      }
                        

                        <h4>Hourly</h4>
                        <small>Leads cancel berdasarkan periode jam</small>
                        <br /><br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                    {this.state.contactLeadsHourly.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell />
                                      {this.state.contactLeadsHourly.map((column) => (
                                          <TableCell
                                              key={column.id+"a"}
                                              align={column.align}
                                          >
                                          {column.count}
                                          </TableCell>
                                      ))}
                                  </TableRow>
                                </TableBody>
                                </Table>
                            </TableContainer>
                            
                        </Paper>
                        
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>
            </Paper>
         );
    }
}
 
export default CancelByJam;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});


  function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }