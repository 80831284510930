import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import Api from "../../customlib/Api";
import { FaUserCheck } from "react-icons/fa";
// import { Checkbox } from 'material-ui';


class SettingContactItem extends Component {
    state = { 
        selectedSales : {},//id = id, contactId, name
        isAssigned : false,
        isChecked : false
    }

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.props.salesData];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));
            newData.contactId = this.props.contactData.id;

            this.setState({selectedSales: newData},()=>{
                if(this.state.isChecked){
                    this.props.onChecked(newData);
                }
            });            

        }

    }

    handleAssignContactToSales = () => {
        console.log("PARAMASSIGN : ", {contactId: this.props.contactData.id, userId: this.state.selectedSales.id});
        axios
          .post(Api.assignContactToUser(), {contactId: this.props.contactData.id, userId: this.state.selectedSales.id}, Api.getRequestHeader())
          .then((response) => {
            // console.log("ASSIGNRESPONSE : ", response.data);
            let responseData = response.data;
            if(responseData.success){
                this.setState({isAssigned: true});
            }
            // this.setState({contacts: response.data.data});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    }

    handleCheckBoxChange = (event) => {

        if(event.target.checked){
            this.props.onChecked(this.state.selectedSales);
        }else{
            this.props.onUnchecked(this.state.selectedSales);
        }

        this.setState({isChecked: event.target.checked});
      }

    componentDidMount() {
        this.handleSelectionChanged(this.props.contactData.assignToUserName);
        console.log("FORCECHECK : ", this.props.forceCheck);
        // if(this.props.forceCheck){
        //     this.setState({isChecked: true});
        // }else{
        //     this.setState({isChecked: false});
        // }
    }
    


    render() { 
        // console.log("SELECTEDSALES : ", this.state);
        const { from, fromName, lastMessage, assignToUserName} = this.props.contactData;
        return ( 
            <div>
                <div className="row">
                    <div className="col-1">
                        <input type="checkbox" checked={this.state.isChecked} onChange={this.handleCheckBoxChange} />
                    </div>
                    <div className="col-2">
                        <p>
                            {fromName}
                        </p>
                    </div>
                    <div className="col-2">
                        <p>{from}</p>
                    </div>
                    <div className="col-3">
                        <p>{lastMessage}</p>
                    </div>
                    <div className="col-2">
                    <Autocomplete
                        id="free-solo-2-demo"
                        // disableClearable
                        freeSolo 
                        options={this.props.salesData.map((option) => {
                            // console.log("LABEL : ", option);
                            return String(option.name);
                        })}
                        onChange={(event, newValue) => {
                            // console.log("NEWVALUE : ", event);
                            this.handleSelectionChanged(newValue);
                          }} 
                        value={assignToUserName}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="User"
                            margin="0"
                            variant="outlined"
                            InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                        )}
                    />
                    </div>
                    <div className="col-2 text-center">
                        <span className="btn btn-primary" onClick={this.handleAssignContactToSales}>
                            Apply
                        </span>
                        &nbsp;
                        {
                            this.state.isAssigned ? 
                                <FaUserCheck className="text-danger" style={{fontSize: 25}}/> : 
                                <span></span>
                            }
                    </div>
                </div>
                <hr />
            </div>
         );
    }
}
 
export default SettingContactItem;