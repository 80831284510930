import { TextField, CircularProgress, Snackbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip as ToolTip, Dialog, DialogContent, DialogActions, IconButton, Collapse, Box, Typography, Tooltip, ToggleButtonGroup, ToggleButton } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Search, QuestionAnswer} from '@mui/icons-material';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MessageList from '../MessageList';


class DataPendingLeadsParcel extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        snackbarVisible: false,
        snackbarMessage: "",
        page: 0,
        rowsPerPage: 200,
        showAddForm: false,
        keyword: "",
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        // startDate: moment("2023-01-24").format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        startDateResume: moment(new Date()).format("YYYY-MM-DD"),
        endDateResume: moment(new Date()).format("YYYY-MM-DD"),
        isLoading: false,
        isLoadingResume: false,
        currentConversation: null,
        searchInField: "fromName",
        isFirstLoad: true,
        contactLeads: [],
        deliveryStartDate: moment(new Date()).format("YYYY-MM-DD"),
        useLeadsDate: true,
        useDeliveryDate: true,
        opsiFilter: "Semua",
        totalLeads: 0,
        totalClosing: 0
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        
        this.setState({ startDate:  formatStartDate, endDate: formatStartDate}, () => {
            console.log(formatStartDate);
            this.getContactLeads();
        });
    };

    handleDeliveryDateChange = (event) => {
        var deliveryDate = event.target.value.replace("/", "-");
        this.setState({ deliveryStartDate:  deliveryDate}, () => {
            console.log(deliveryDate);
        });
    };

    handleOpsiFilterChange = (event, value) => {
        let useLeadsDate = false;
        let useDeliveryDate = false;
        if(value === "Semua"){
            useLeadsDate = true;
            useDeliveryDate = true;
        }else if(value == "Tanggal Leads"){
            useLeadsDate = true;
            useDeliveryDate = false;
        }else if(value === "Tanggal Kirim"){
            useLeadsDate = false;
            useDeliveryDate = true;
        }

        this.setState({useLeadsDate: useLeadsDate, useDeliveryDate: useDeliveryDate, opsiFilter: value}, () => {
            this.getContactLeads();
        });

    }

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
            leadsStartDate: this.state.startDate,
            leadsEndDate: this.state.startDate,
            deliveryStartDate: this.state.deliveryStartDate,
            deliveryEndDate: this.state.deliveryStartDate,
            useLeadsDate: this.state.useLeadsDate,
            useDeliveryDate: this.state.useDeliveryDate,
            appProfileId: Global.getAppProfile()._id
        }

        this.setState({contactLeads: [], additionalData: null, isLoading: true}, () => {
          console.log("PARAMS : ", params);
        });

        axios
          .post(Api.getDataPendingLeadsParcel(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data.leads;
            var totalLeads = response.data.data.totalLeads;
            var totalClosing = response.data.data.totalClosing;


            if(userData.admin === false){
              if(Global.isTeleUser()){
                tmpContactLeads = tmpContactLeads.filter(element => {
                  if(element.leads.length > 0 && element.leads[0].isTeleCustomer === true && element.salesId === userData.user.id){
                    return true
                  }else{
                    return false;
                  }
                })
              }else if(Global.isSales()){
                tmpContactLeads = tmpContactLeads.filter(element => {
                  if(element.leads.length > 0 && element.leads[0].isTeleCustomer !== true){
                    return true
                  }else{
                    return false;
                  }
                })
              }
            }

            const arrayLabels = [
              Global.labelPaid(),
              Global.labelComplaintKeterlambatan(),
              Global.labelComplaintProdukTidakSesuai(),
              Global.labelComplaintLayuRusak(),
              Global.labelComplaintReport(),
              Global.labelReport(),
              Global.labelKonfirmasiPesanan(),
              Global.labelComplaintPesananTidakDibuatkan(),
              Global.labelLainLain()
            ];

            let flattenLeads = [];

            tmpContactLeads.forEach(lead => {
                lead.leadsCount = lead.leads.length;
                lead.labelUnpaid = 0;
                lead.labelPaid = 0;
                lead.labelDp = 0;
                lead.labelCancel = 0;
                lead.labelPending = 0;

                lead.leads.forEach(contact => {


                  if(arrayLabels.includes(contact.label)){
                    lead.labelPaid += 1;
                  }
    
                  if(contact.label === Global.labelUnpaid()){
                    lead.labelUnpaid += 1;
                  }
    
                  if(contact.label === Global.labelDp()){
                    lead.labelDp += 1;
                  }
    
                  if(contact.label === Global.labelCancel()){
                    lead.labelCancel += 1;
                  }

                  if(contact.label === Global.labelPending()){
                    lead.labelPending += 1;
                  }

                  const newData =  {
                    photo: "https://picsum.photos/seed/picsum/200",
                    fromName: `${contact.fromName}`,
                    text: contact.lastMessage,
                    fromNumber: contact.from,
                    contactId: contact.id,
                    toNumber: contact.to,
                    toName: contact.toName,
                    selected: true,
                    unreadCounter: contact.unreadCounter,
                    assignToUserName: contact.assignToUserName,
                    contactLabel: contact.label
                  };
                  contact.contactFormatted = newData;
                  flattenLeads.push(contact);
                });

            });

            // this.setState({contactLeads: tmpContactLeads, isLoading: false, additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null}, () => {
            this.setState({contactLeads: flattenLeads, totalLeads: totalLeads, totalClosing: totalClosing, isLoading: false, additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null}, () => {
              console.log("ADDITIONAL_DATA : ", this.state.additionalData);
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    getWaMessages = (contactData) => {
        console.log("CONTACTDATA : ", contactData);
        if(contactData.fromNumber === ""){
          return;
        }
    
        this.setState({isLoading: true}, ()=>{
          const params = {
            from: contactData.fromNumber,
          };
          console.log("PARAMSWAMESSAGE", params);
      
          axios
            .post(Api.messagesList(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_MESSAGES",response.data);
              
              const responseData = response.data;
                
              const clonedMyCust = [...this.state.listMycustomer];
              let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

              if(myCust.length > 0){
                  myCust[0].messages = responseData.data;
              }

              this.setState({listMycustomer: clonedMyCust});
              
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoading: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    handleShowSnackbar = () => {
        this.setState({snackbarVisible: true});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, errorMessage: ""});
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.getContactLeads()});
      }

    componentDidMount() {
        console.log(Global.get());
        this.getContactLeads();
    }

    columns = [
        { id: 'assignToUserName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Leads Pending', minWidth: 170 },
    ];

    handleCloseFormAddContact = () => {
        this.setState({showAddForm: false});
    }

    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    handleCloseEditAnalisa = () => {
        this.setState({showEditAnalisa: false, obEditContact: null, errorMessage: ""});
    }

    handleAnalisaCancelChanged = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.analisaCancel = event.target.value;
        this.setState({ obEditContact: editingContact });
    };

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.setState({page: 0}, () => {
                this.getContactLeads();
            });
        }
      };

    handleShowMessages = (conversation) => {
        this.setState({currentConversation: conversation});
    }

    getFieldClassName = (fieldName) => {
        let className = "badge";
        if(this.state.searchInField === fieldName){
          className += " badge-primary";
        }else{
          className += " badge-secondary";
        }
    
        return className;
      }
    
    handleFieldSelectionChanged = (fieldName) => {
        this.setState({searchInField: fieldName}, () => {
            this.getContactLeads();
        });
    }

    loadAppProfile = () => {
        const params = {
        };
        
        axios
          .post(
            Api.listAppProfile(),
            params,
            Api.getRequestHeaderNoAuth()
          )
          .then((response) => {
            console.log("App Profiles", response.data);
            
            let profiles = [];
            profiles.push({_id: "", waNumber: "", label: "---"});

            let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
            profilesFiltered.forEach(item => {
                profiles.push(item);
            });
            this.setState({listAppProfiles: profiles});
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, loginSuccess: false });
            console.error("There was an error!", error);
            // this.props.onLogin();
          }
        );
    };

    handleAppTypeChanged = (event) => {
        this.setState({filterAppNumber: event.target.value}, ()=>{
          console.log(this.state.filterAppNumber);
          this.getContactLeads();
        //   this.getResumeWeekly();
        });
      }

    render() { 
        return (
          <div className="containe-fluid" style={{padding: "20px"}}>
                {/* <div className="row">
                    <div className="col-11">
                        <h1>Leads Data Pending</h1>
                    </div>
                    <div className="col-1">
                    <Link to="/">
                        <div className="btn btn-danger">X</div>
                    </Link>
                    </div>
                </div>
                <hr /> */}
                {

                    
                    <div className="container-fluid">
                      <div className="row">
                        <div className={this.state.currentConversation !== null ? "col-8" : "col-12"}>

                        <div className="row"  style={{marginTop: 10, marginBottom: 0}}>
                            {/* <div className="col-4">
                                <TextField
                                    onChange={this.handleKeyword}
                                    label="Ketik untuk mencari nama/nomor wa/kategori"
                                    type="text"
                                    className="form-control"
                                    id="namacustomer"
                                    variant="outlined"
                                    onKeyDown={this.onKeyDownHandler}
                                    onBlur={() => {
                                        
                                    }}
                                />
                            </div> */}
                            
                            <div className="col-2 text-right" style={{borderRadius: 10}}>
                                {/* <MyDateRangePicker onDateChanged={this.handleDateChanged} /> */}
                                {/* <span style={{marginTop: 5,}}><strong>Leads dari :</strong> &nbsp;</span> */}
                                
                                <TextField
                                    id="dateStart"
                                    label="Tanggal Leads"
                                    type="date"
                                    defaultValue={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    size='small'
                                />
                                
                            </div>
                            <div className="col-2" style={{borderRadius: 10}}>
                                <TextField
                                    id="deliveryDateStart"
                                    label="Tanggal Kirim"
                                    type="date"
                                    defaultValue={this.state.deliveryStartDate}
                                    onChange={this.handleDeliveryDateChange}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    size="small"
                                />
                            </div>
                            <div className="col-6 text-center">
                                {/* <div style={{fontSize: "10px", marginBottom: "5px", textAlign: "center"}}>Opsi Filter</div> */}
                                <ToggleButtonGroup
                                    color="primary"
                                    value={this.state.opsiFilter}
                                    exclusive
                                    onChange={this.handleOpsiFilterChange}
                                    aria-label="Platform"
                                    size='small'
                                    >
                                    <ToggleButton value="Semua" style={{fontSize: "10px"}}>
                                      <Tooltip title="Tampilkan semua yang tanggal kirim belum expired">
                                        <span>Semua</span>
                                      </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="Tanggal Leads" style={{fontSize: "10px"}}>
                                      <Tooltip title="Tampilkan sesuai filter tanggal leads">
                                        <span>Tanggal Leads Saja</span>
                                      </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="Tanggal Kirim" style={{fontSize: "10px"}}>
                                      <Tooltip title="Tampilkan sesuai filter tanggal kirim">
                                        <span>Tanggal Kirim Saja</span>
                                      </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            {/* <div className='col-2'>
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.appTypeId}
                                    onChange={this.handleAppTypeChanged}
                                    label="Aplikasi"
                                >
                                    {
                                    this.state.listAppProfiles.map(
                                        (profile) => {
                                        return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                        } 
                                    )
                                    }
                                </Select>
                            </FormControl>
                                
                            </div> */}
                            <div className="col-2" >
                                {
                                    this.state.isLoading === true ? 
                                        (<div className='text-left'>
                                            <CircularProgress style={{marginTop: "15px"}} size={20} color="secondary" /> loading ...
                                        </div>) : 
                                        (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary btn-sm" style={{paddinga: 0}}><Search /></div>)
                                }
                                {/* <div onClick={() => {this.setState({showAddForm: true})}} className="btn btn-primary" style={{padding: 15}}><Add /></div> */}
                            </div>
                            
                        </div>
                        {/* <div className="container-fluid">
                            <small>Field Pencarian : </small>
                                <div className={this.getFieldClassName("fromName")} onClick={() => this.handleFieldSelectionChanged("fromName")} style={{cursor: "pointer"}}>Nama</div>&nbsp;
                                <div className={this.getFieldClassName("from")} onClick={() => this.handleFieldSelectionChanged("from")} style={{cursor: "pointer"}}>Nomor</div>&nbsp;
                                <div className={this.getFieldClassName("productCategory")} onClick={() => this.handleFieldSelectionChanged("productCategory")} style={{cursor: "pointer"}}>Kategori</div>&nbsp;
                                <div className={this.getFieldClassName("kategoriProblem")} onClick={() => this.handleFieldSelectionChanged("kategoriProblem")} style={{cursor: "pointer"}}>Keterangan Cancel</div>
                        </div> */}
                        <br />
                        <div style={{marginBottom: "10px"}}>
                          <div className="badge badge-pill badge-primary" style={{padding: "8px"}}>
                            Total Leads : {this.state.totalLeads + this.state.totalClosing}
                          </div>&nbsp;
                          <div className="badge badge-pill badge-primary" style={{padding: "8px"}}>
                            Total Closing : {this.state.totalClosing}
                          </div>&nbsp;
                          <div className="badge badge-pill badge-primary" style={{padding: "8px"}}>
                            Sisa Leads Sekarang : {this.state.totalLeads}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {/* <Paper className='root' style={{maxHeight: "80vh", overflow: "scroll"}}> */}
                            <Paper className='root'>
                              <TableContainer style={{marginBottom: "20px"}}>
                                  <Table aria-label="sticky table" size='small'>
                                  <TableHead>
                                      <TableRow>
                                        <TableCell><strong>No</strong></TableCell>
                                        <TableCell><strong>Sales</strong></TableCell>
                                        <TableCell><strong>Nama</strong></TableCell>
                                        <TableCell>Label</TableCell>
                                        <TableCell><strong>Nomor WhatsApp</strong></TableCell>
                                        <TableCell><strong>Tanggal lead</strong></TableCell>
                                        <TableCell><strong>Kategori Produk</strong></TableCell>
                                        <TableCell><strong>Kota</strong></TableCell>
                                        <TableCell><strong>Chat Terakhir</strong></TableCell>
                                        <TableCell><strong>Tanggal Kirim</strong></TableCell>
                                        {/* <TableCell>Tele ?</TableCell> */}
                                        <TableCell><strong>Action</strong></TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {
                                    // this.state.contactLeads.map((row, index) => {
                                    //   return (
                                        this.state.contactLeads.map((historyRow, index) => (
                                          <TableRow key={historyRow.id}>
                                            <TableCell style={{fontSize: "13px"}} align="left">{index+1}</TableCell>
                                            <TableCell style={{fontSize: "13px"}} align="left">{historyRow.assignToUserName}</TableCell>
                                            <TableCell style={{fontSize: "13px"}}>
                                              {/* {Global.getAppProfile().appVendor === "VONAGE" && Global.isSales() ? historyRow.fromName.slice(0, -5) + Array(5 + 1).join("x") : historyRow.fromName} */}
                                              {historyRow.fromName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              <div className={Global.getLabelClasses(historyRow.label)}>{historyRow.label}</div>
                                            </TableCell>
                                            <TableCell style={{fontSize: "12px"}}>{Global.maskPhoneNumber(historyRow.from)}</TableCell>
                                            <TableCell style={{fontSize: "13px"}} align="left">{Global.formatDateTimeToStrDateTime24(historyRow.createdAt)}</TableCell>
                                            <TableCell style={{fontSize: "13px"}}>{historyRow.productCategory}</TableCell>
                                            <TableCell style={{fontSize: "13px"}}>{historyRow.cityName}</TableCell>
                                            <TableCell style={{fontSize: "13px"}} align="left">
                                              {Global.formatDateTimeToStrDateTime24(historyRow.lastMessageReceivedAt)}
                                            </TableCell>
                                            <TableCell style={{fontSize: "13px"}} align="left">
                                              {moment(historyRow.deliveryDate).tz("utc").format("DD-MM-Y HH:mm:ss")}
                                            </TableCell>
                                            {/* <TableCell>
                                              {historyRow.isTeleCustomer === true ? "YA" : 'TIDAK'}
                                            </TableCell> */}
                                            <TableCell style={{fontSize: "13px"}}>
                                              {
                                                historyRow.isDeliveryDateExpired === true ? (<></>) : (
                                                  <Tooltip title="Kirim Pesan">
                                                      <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowMessages(historyRow.contactFormatted)}}  />
                                                  </Tooltip>
                                                )
                                              }
                                              
                                            </TableCell>
                                          </TableRow>
                                        ))
                                          // <Row handleShowMessages={this.handleShowMessages} key={row.salesId} row={row} />
                                    //   )
                                    // })
                                    }
                                  
                                      
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                          </Paper>
                          </div>
                        </div>

                        </div>
                        <div className="col-4" style={{maxHeight: "70vh", overflowY: "none", position: "static"}}>
                            {
                              this.state.currentConversation !== null ? (
                                <div className='text-right'>
                                  <div className="btn btn-block btn-danger" onClick={() => {this.handleShowMessages(null)}}>Close Chat</div>
                                </div>
                              ) : (<></>)
                            }
                            {
                              this.state.currentConversation !== null ? (
                                <Paper className='root' style={{overflowY: "none"}}>
                                  <MessageList
                                    onUpdateReadCounter = {()=>{}}
                                    onNewIncomingMessage={()=>{}}
                                    onUpdateConversations={()=>{}}
                                    onUpdateCsHelpState={()=>{}}
                                    currentConversation={this.state.currentConversation}
                                    onUpdateContactLabelState={()=>{}}
                                    onClearCurrentConversation = {()=>{}}
                                    onUpdateSlow={()=>{}}
                                    onUpdateJustRead={()=>{}}
                                    onUpdateUnfinishedChat={()=>{}}
                                    onResetUnreadCounter={()=>{}}
                                    conversationList={[]}
                                    logoutHandler={()=>{}}
                                    onSaveCustomerName={()=>{}}
                                  />
                                </Paper>
                                
                              ) : (<></>)
                            }
                          </div>
                      </div>

                        

                        
                        
                    </div>

                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />

                {
                    this.state.currentConversation === "hellooo" ? (
                        <Dialog
                            open={this.state.currentConversation !== null}
                            // TransitionComponent={Transition}
                            // keepMounted
                            onClose={() => {this.setState({currentConversation: null})}}
                            aria-describedby=""
                        >
                            {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                            <DialogContent>
                              <MessageList
                                onUpdateReadCounter = {()=>{}}
                                onNewIncomingMessage={()=>{}}
                                onUpdateConversations={()=>{}}
                                onUpdateCsHelpState={()=>{}}
                                currentConversation={this.state.currentConversation}
                                onUpdateContactLabelState={()=>{}}
                                onClearCurrentConversation = {()=>{}}
                                onUpdateSlow={()=>{}}
                                onUpdateJustRead={()=>{}}
                                onUpdateUnfinishedChat={()=>{}}
                                onResetUnreadCounter={()=>{}}
                                conversationList={[]}
                                logoutHandler={()=>{}}
                                onSaveCustomerName={()=>{}}
                              />
                            </DialogContent>
                            <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button>
                            <Button onClick={handleClose}>Agree</Button> */}
                            </DialogActions>
                        </Dialog>
                    ) : (<></>)
                }

            </div>
        );
    }
}
 
export default DataPendingLeadsParcel;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    // const classes = {root: {}};
  
    return (
      <React.Fragment>
        <TableRow className={classes.root} style={{fontSize: "10px"}}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.salesName}
          </TableCell>
          <TableCell align="left">{row.leadsCount} leads</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Leads Detail
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nama</TableCell>
                      {
                          Global.get().admin === true ? <TableCell>Nomor WhatsApp</TableCell> : <></>
                      }
                      {/* <TableCell>Label</TableCell> */}
                      <TableCell>Tanggal lead</TableCell>
                      <TableCell>Kategori Produk</TableCell>
                      <TableCell>Kota</TableCell>
                      <TableCell>Chat Terakhir</TableCell>
                      <TableCell>Tanggal Kirim</TableCell>
                      <TableCell>Tele ?</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row" style={{fontSize: "12px"}}>
                          {/* {Global.getAppProfile().appVendor === "VONAGE" && Global.isSales() ? historyRow.fromName.slice(0, -5) + Array(5 + 1).join("x") : historyRow.fromName} */}
                          {historyRow.fromName}
                        </TableCell>
                        {
                            Global.get().admin === true ? <TableCell style={{fontSize: "12px"}}>{historyRow.from}</TableCell> : <></>
                            // <TableCell style={{fontSize: "12px"}}>{historyRow.from}</TableCell>
                        }
                        {/* <TableCell component="th" scope="row">
                          <div className={Global.getLabelClasses(historyRow.label)}>{historyRow.label}</div>
                        </TableCell> */}
                        <TableCell style={{fontSize: "12px"}} align="left">{Global.formatDateTimeToStrDateTime24(historyRow.createdAt)}</TableCell>
                        <TableCell style={{fontSize: "12px"}}>{historyRow.productCategory}</TableCell>
                        <TableCell style={{fontSize: "12px"}}>{historyRow.cityName}</TableCell>
                        <TableCell style={{fontSize: "12px"}} align="left">
                          {Global.formatDateTimeToStrDateTime24(historyRow.lastMessageReceivedAt)}
                        </TableCell>
                        <TableCell style={{fontSize: "12px"}} align="left">
                          {moment(historyRow.deliveryDate).format("DD-MM-Y HH:mm:ss")}
                        </TableCell>
                        <TableCell>
                          {historyRow.isTeleCustomer === true ? "YA" : 'TIDAK'}
                        </TableCell>
                        <TableCell style={{fontSize: "12px"}}>
                          {
                            historyRow.isDeliveryDateExpired === true ? (<></>) : (
                              <Tooltip title="Kirim Pesan">
                                  <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {props.handleShowMessages(historyRow.contactFormatted)}}  />
                              </Tooltip>
                            )
                          }
                          
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }