import React from "react";
import "./Toolbar.css";
import { Paper } from "@mui/material";

export default function Toolbar(props) {
  const { title, leftItems, rightItems } = props;
  return (
    <Paper elevation={0} style={{margin: "0 2px 0 2px", borderRadius: "5px"}}>
      <div className="toolbar">
        <div onClick={props.onClickLeftItem} className="left-items">
          {leftItems}
        </div>
        {/* <div className="left-items">
          <Link to="/about">About</Link>
        </div> */}
        <h1 className="toolbar-title">{title}</h1>
        <div onClick={props.onClickRightItem} className="right-items">
          {rightItems}
        </div>
      </div>
      {/* {props.children} */}
    </Paper>
    
  );
}
