import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import {Paper, Tabs, Tab, Typography, Box, AppBar} from '@mui/material';
import SalesResponseTimeReportFirstTime from './SalesResponseTimeReportFirstTime';
import SalesResponseTimeReportAverage from './SalesResponseTimeReportAverage';
import TeleResponseTimeReportAverage from './TeleResponseTimeReportAverage';
import TeleFuEffectivityRate from './TeleFuEffectivityRate';
import DetailKedisiplinanFuTele from '../KedisiplinanFuTele/DetailKedisiplinanFuTele';
import ResumeKedisiplinanFuTele from '../KedisiplinanFuTele/ResumeKedisiplinanFuTele';
import TeleFuHsmMarketingEffectivityRate from './TeleFuHsmMarketingEffectivityRate';
import HsmTeleEffectivityRate from './HsmTeleEffectivityRate';
import CsResponseTimeReportAverage from './CsResponseTimeReportAverage';
import TeleDiChatCustomerResponseTimeReportAverage from './TeleDiChatCustomerResponseTimeReportAverage';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class SalesResponseTimeReport extends Component {

    state = { 
        value : 0,
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    // classes = useStyles();
    // const [value, setValue] = React.useState(0);

    componentDidMount() {
        this.setState({value: 0});
    }
    

    handleChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };

    render() { 
        return ( 
            <div className='container-fluid'>
            {/* <Paper> */}
                {/* <div className="container-fluid text-right bg-primary">
                    <Link to="/">
                        <div className="btn btn-primary">Close</div>
                    </Link>
                </div> */}
                {/* <div className="container text-center">
                  <div className="row">
                      <div className="col-11" style={{paddingLeft: "30px"}}><h1>Data Pending</h1></div>
                      <div className="col-1 text-right">
                          <Link to="/">
                              <div className="btn btn-danger">X</div>
                          </Link>
                      </div>
                  </div>
                </div> */}
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    centered
                >
                    <Tab key="tab0" label="By First Response" />
                    <Tab key="tab1" label="Average" />
                    <Tab key="tab2" label="Average Tele/Recycle" />
                    <Tab key="tab3" label="Average Tele Di Chat Customer" />
                    <Tab key="tab4" label="CS Average" />
                    <Tab key="tab5" label="Efektivitas FU Tele" />
                    <Tab key="tab6" label="Kedisiplinan Fu Tele" />
                    <Tab key="tab7" label="Efektivitas Fu Tele HSM Marketing" />
                    <Tab key="tab8" label="Efektivitas HSM Tele" />
                    <Tab key="tab9" label="Resume Kedisiplinan Fu Tele" />
                </Tabs>
            {/* </Paper> */}
            <TabPanel value={this.state.value} index={0}>
                <SalesResponseTimeReportFirstTime />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
                <SalesResponseTimeReportAverage />
            </TabPanel>            
            <TabPanel value={this.state.value} index={2}>
                <TeleResponseTimeReportAverage />
            </TabPanel>
            <TabPanel value={this.state.value} index={3}>
                <TeleDiChatCustomerResponseTimeReportAverage />
            </TabPanel>
            <TabPanel value={this.state.value} index={4}>
                <CsResponseTimeReportAverage />
            </TabPanel>            
            <TabPanel value={this.state.value} index={5}>
                <TeleFuEffectivityRate />
            </TabPanel>            
            <TabPanel value={this.state.value} index={6}>
                <DetailKedisiplinanFuTele />
            </TabPanel>            
            <TabPanel value={this.state.value} index={7}>
                <TeleFuHsmMarketingEffectivityRate />
            </TabPanel>            
            <TabPanel value={this.state.value} index={8}>
                <HsmTeleEffectivityRate />
            </TabPanel>            
            <TabPanel value={this.state.value} index={9}>
                <ResumeKedisiplinanFuTele />
            </TabPanel>            
            </div>
         );
    }
}
 
export default SalesResponseTimeReport;