import { Alert, Autocomplete, Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import moment from 'moment';
import axios from 'axios';

class FormSetLabelTele extends Component {
    state = { 
        selectedLabel: "",
        productCategory: "",
        selectedCity: "",
        selectedCityId: 0,
        deliveryDate: "",
        priceRange: "",
        kategoriKonfirmasi: "",
        errorMessage: ""
    } 

    componentDidMount() {
        const currentConversation = this.props.currentConversation;

        if(currentConversation){
            this.setState({
                productCategory: currentConversation.productCategory,
                selectedCity: currentConversation.cityName ?? "",
                selectedCityId: currentConversation.cityId ?? 0,
                deliveryDate: currentConversation.deliveryDate ?? "",
                priceRange: currentConversation.priceRange ?? "",
                kategoriKonfirmasi: currentConversation.kategoriKonfirmasi ?? "" 
            });
        }
    }

    handleSelectLabel = (label) => {
        this.setState({selectedLabel: label});
    }

    handleProductCategoryChange = (event) => {
        this.setState({productCategory: event.target.value});
    }

    handlePriceRangeChange = (event) => {
        this.setState({priceRange: event.target.value});
    }

    handleKategoriKonfirmasiChange = (event) => {
        this.setState({kategoriKonfirmasi: event.target.value});
    }

    handleCityOnChange = (newCity) => {
        let selected = this.props.cityList.filter((city) => city.name===newCity);
    
        if(selected.length > 0){
          this.setState({selectedCity: selected[0].name, selectedCityId: selected[0].id}, () => {
            console.log("SELECTED CITY", selected[0]);
          });
        }   
    }

    handleTanggalPengirimanChange = (event) => {
        console.log("DATE_ORI", event.target.value);
        var formatDeliveryDate = event.target.value.replace("/", "-");
        console.log(formatDeliveryDate);
        this.setState({ deliveryDate: formatDeliveryDate});
    }

    handleCancelSelectLabel = () => {
        this.setState({selectedLabel: ""});
    }

    handleSetContactLabel = (conversation, labelValue) => {
        
        if([Global.labelPending(), Global.labelCancel()].includes(this.selectedLabel)){
            if(this.state.productCategory === ""){
                this.setState({errorMessage: "Kategori Pproduk belum ditentukan"});
                return;
            }
            if(this.state.cityId <= 0){
                this.setState({errorMessage: "Kota belum ditentukan"});
                return;
            }
            if(this.state.deliveryDate === ""){
                this.setState({errorMessage: "Tanggal Pengiriman belum ditentukan"});
                return;
            }
        }

        if([Global.labelCancel()].includes(this.selectedLabel)){
            if(this.state.priceRange === ""){
                this.setState({errorMessage: "Range harga belum ditentukan"});
                return;
            }
        }

        if([Global.labelKonfirmasi()].includes(this.selectedLabel)){
            if(this.state.kategoriKonfirmasi === ""){
                this.setState({errorMessage: "Kategori konfirmasi belum ditentukan"});
                return;
            }
        }
    
        let params = {
            contactNumber: conversation.fromNumber,
            labelValue: this.state.selectedLabel,
            productCategory: this.state.productCategory,
            cityName: this.state.selectedCity,
            cityId: this.state.selectedCityId,
            deliveryDate: this.state.deliveryDate !== "" ? this.state.deliveryDate : this.props.currentConversation.deliveryDate,
            keterangan: this.props.currentConversation.keterangan,
            emailRegister: this.props.currentConversation.emailRegister,
            keteranganPending: this.props.currentConversation.keteranganPending,
            keteranganCancel: this.props.currentConversation.keteranganCancel,
            tipeLead: this.props.currentConversation.tipeLead, 
            permasalahan: this.props.currentConversation.permasalahan,
            alasanUmum: this.props.currentConversation.alasanUmum,
            alasanRinci: this.props.currentConversation.alasanRinci,
            priceRange: this.state.selectedLabel === Global.labelCancel() ? this.state.priceRange : this.props.currentConversation.priceRange,
            kategoriKonfirmasi: this.state.selectedLabel === Global.labelKonfirmasi() ? this.state.kategoriKonfirmasi : this.props.currentConversation.kategoriKonfirmasi,

        };
        
        console.log("PARAMSSETLABEL", params);
        axios
            .post(Api.setContactLabel(), params, Api.getRequestHeader())
            .then((response) => {
                this.props.onUpdateContactLabelState(response.data.data);
                this.props.onClose();
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message });
                console.error("There was an error!", error);
            });
    }

    render() { 
        return (
            <Container style={{border: "solid 0px"}}>
                {
                    this.state.selectedLabel === "" && 
                    <Grid container spacing={2}>
                        {/* {
                        [Global.labelPaid(), Global.labelUnpaid(), Global.labelPending(), Global.labelCancel(), Global.labelRandomLeads()].includes(this.props.currentConversation.contactLabel) &&  
                        <Grid item xs={3}>
                            <span onClick={() => this.handleToggleShowCancelCategory(Global.labelPaid())} className="btn btn-success btn-block">
                                {Global.labelPaid()}
                            </span>
                        </Grid>
                        }
                        
                        {
                        [Global.labelPaid(), Global.labelUnpaid(), Global.labelPending(), Global.labelCancel(), Global.labelRandomLeads()].includes(this.props.currentConversation.contactLabel) &&  
                            <Grid item xs={3}>
                            <span onClick={() => this.handleToggleShowCancelCategory(Global.labelDp())} className="btn btn-secondary btn-block">
                            {Global.labelDp()}
                            </span>
                            </Grid>
                        }
                        
                        {
                        [Global.labelPaid(), Global.labelUnpaid(), Global.labelPending(), Global.labelCancel(), Global.labelRandomLeads()].includes(this.props.currentConversation.contactLabel) && 
                        <Grid item xs={3}>
                            <span onClick={() => this.handleToggleShowCancelCategory(Global.labelUnpaid())} className="btn btn-primary btn-block">
                            {Global.labelUnpaid()}
                            </span>
                        </Grid>
                        } */}
                        
                        <Grid item xs={3}>
                        {/* <span onClick={() => this.handleSelectLabel(Global.labelPending())} className="btn btn-info btn-block"> */}
                        <span onClick={() => this.handleSelectLabel(Global.labelPending())} className="btn btn-outline-dark btn-block">
                            {Global.labelPending()}
                        </span>
                        </Grid>
                        {/* <Grid item xs={3}>
                        <span onClick={() => this.handleToggleShowCancelCategory(Global.labelRandomLeads())} className="btn btn-dark btn-block">
                            {Global.labelRandomLeads()}
                        </span>
                        </Grid> */}
                        <Grid item xs={3}>
                        {/* <span onClick={() => this.handleSelectLabel(Global.labelCancel())} className="btn btn-danger btn-block"> */}
                        <span onClick={() => this.handleSelectLabel(Global.labelCancel())} className="btn btn-outline-dark btn-block">
                            {Global.labelCancel()}
                        </span>
                        </Grid>
                        <Grid item xs={3}>
                        {/* <span onClick={() => this.handleSelectLabel(Global.labelKonfirmasi())} className="btn btn-danger btn-block"> */}
                        <span onClick={() => this.handleSelectLabel(Global.labelKonfirmasi())} className="btn btn-outline-dark btn-block">
                            {Global.labelKonfirmasi()}
                        </span>
                        </Grid>
                        <Grid item xs={3}>
                        {/* <span onClick={() => this.handleSetContactLabel(this.props.currentConversation, this.state.selectedLabel)} className="btn btn-danger btn-block"> */}
                        {/* <span onClick={() => this.handleSetContactLabel(this.props.currentConversation, this.state.selectedLabel)} className="btn btn-outline-dark btn-block"> */}
                        <span onClick={() => this.handleSelectLabel(Global.labelComplaint())} className="btn btn-outline-dark btn-block">
                            {Global.labelComplaint()}
                        </span>
                        </Grid>
                    </Grid>
                }
                
                <Container sx={{mt: "10px", padding: 0}}>
                {
                    this.state.selectedLabel !== "" && 
                        <Grid container>
                            <Grid item xs={11}>
                                <strong>Label : </strong>{this.state.selectedLabel}
                            </Grid>
                            <Grid item xs={1}>
                                <span onClick={this.handleCancelSelectLabel} className="btn btn-secondary btn-sm" style={{borderRadius: "1000px", padding: "5px"}}>
                                    X
                                </span>
                            </Grid>
                        </Grid>
                }

                {
                    this.state.errorMessage !== "" && 
                    <Alert sx={{mt: "5px", mb: "5px"}} severity="error">{this.state.errorMessage}</Alert>
                }

                {
                    [Global.labelPending(), Global.labelCancel()].includes(this.state.selectedLabel) && 
                    <Box>
                        <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                          <InputLabel id="demo-simple-select-outlined-label">Pilih Kategori Produk</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.productCategory}
                            onChange={this.handleProductCategoryChange}
                            label="Kategori Produk"
                          >
                            {
                              Global.getListProductCategories().map(
                                (cat) => {
                                  return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                } 
                              )
                            }
                          </Select>
                        </FormControl>
                        
                        <Grid container spacing={2} sx={{mt: "5px"}}>
                            <Grid item xs={8}>
                                <Autocomplete
                                    id="free-solo-2-demo"
                                    // disableClearable
                                    freeSolo 
                                    options={this.props.cityList.map((option) => {
                                        return String(option.name);
                                    })}
                                    onChange={(event, newValue) => {
                                        this.handleCityOnChange(newValue);
                                    }} 
                                    value={this.state.selectedCity}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pilih Kota"
                                        margin="0"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />
                                    )}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="dateStart"
                                    label="Tanggal Pengiriman"
                                    type="datetime-local"
                                    defaultValue={moment(this.props.currentConversation.deliveryDate).format("YYYY-MM-DDTHH:mm")}
                                    onChange={this.handleTanggalPengirimanChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                        {
                            this.state.selectedLabel === Global.labelCancel() && 
                            <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                                <InputLabel id="demo-simple-select-outlined-label">Range Harga</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.priceRange}
                                    onChange={this.handlePriceRangeChange}
                                    label="Range Harga"
                                >
                                    {
                                    Global.getPriceRange().map(
                                        (cat) => {
                                        return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                        } 
                                    )
                                    }
                                </Select>
                            </FormControl>
                        }
                    </Box>
                }

                {
                    this.state.selectedLabel === Global.labelKonfirmasi() && 
                    <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                        <InputLabel id="demo-simple-select-outlined-label">Kategori Konfirmasi</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.kategoriKonfirmasi}
                            onChange={this.handleKategoriKonfirmasiChange}
                            label="Kategori Konfirmasi"
                        >
                            {
                            Global.getKategoriKonfirmasi().map(
                                (cat) => {
                                return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                } 
                            )
                            }
                        </Select>
                    </FormControl>
                }

                {
                    this.state.selectedLabel === Global.labelComplaint() && 
                    <Box padding={"10px"} textAlign={"center"}>
                        Klik tombol simpan untuk set label <strong>{Global.labelComplaint()}</strong> ke customer ini
                    </Box>
                }
                    
                {
                    this.state.selectedLabel !== "" && 
                    <Button sx={{mt: "10px"}} fullWidth variant='contained' onClick={() => this.handleSetContactLabel(this.props.currentConversation, this.state.tmpLabel)} color='primary'>
                        Simpan
                    </Button>
                }
                </Container>
            </Container>
        );
    }
}
 
export default FormSetLabelTele;