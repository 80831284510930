import React, { Component } from 'react';
import "./CustomerServiceHelp.css";
import { Celebration, Close, CloseRounded, ContactSupport, LiveHelp, PartyMode, PhonePaused, Scoreboard as ScoreboardIcon, Support, ThumbUp } from '@mui/icons-material';
import Api from '../../customlib/Api';
import Global from '../../customlib/Global';
import axios from 'axios';
import CsContactListItem from './CsContactListItem';
import { CircularProgress, TextField, Tooltip, Paper, Divider, FormGroup, FormControlLabel, Switch, Tooltip as ToolTip, Badge, IconButton, Menu, MenuItem, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/styles';

class AntrianCustomerCs extends Component {
  state = {
    listMycustomer: [],
    page: 0,
    rowsPerPage: 30,
    keyword: "",
    useLimit: false,
    isLoading: true,
    searchMode: false,
    searchInAllContact: false,
    searchInField: "fromName",
    anchorElJustRead: null,
    openMenuJustRead: false,
  }

  constructor(){
    super();
    
    // Global.getSocket().on("helpRequestChanged", data => {
    //   console.log("helpRequestChanged : ", data);
    //   if(this.state.searchMode === false){
    //     this.updateCsHelpData(data);
    //     this.getCsHelpList();
    //     // this.props.onUpdateCsHelpState(data);
    //   }
    //   // this.props.onUpdateCsHelpState(data);
      
    // });

    Global.getSocket().on("helpRequestChanged", data => {
      console.log("helpRequestChanged : ", data);

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      if(this.state.searchMode === false){
        this.updateCsHelpData(data);
        this.getCsHelpList();
        // this.props.onUpdateCsHelpState(data);
      }
      
    });

    Global.getSocket().on("newMessageComing", data => {
      console.log("LOG__NEW_MESSAGE_COMING_LOCAL", data, );
      // console.log("NEW_MESSAGE_COMING_LOCAL",Global.getAppProfile().waNumber, data.to);
      // let isNewContact = data.isNewContact ?? false;

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      if(Global.getAppProfile().waNumber !== data.to){
        return;
      }

      if(data.contact.csHelpState !== "OPEN"){
        return;
      }

      console.log("LOG__CURRENT_CONV_CHECK", this.props.currentConversation);
      if(this.props.currentConversation !== undefined && data.from === this.props.currentConversation.fromNumber){
        this.getCsHelpList();
          // let lastMessage = "";
          // if(data.message.text !== undefined){
          //   lastMessage = data.message.text;
          // }else{
          //   if(data.message.caption !== undefined){
          //     lastMessage = data.message.caption;
          //   }else if(data.message.type === "STICKER"){
          //     lastMessage = "Stiker";
          //   }
          // }
          
          // this.setState({ mustSendGreeting: false }, () => this.scrollToMyRef());

          // this.props.onNewIncomingMessage(this.props.currentConversation, lastMessage);
          // this.props.onResetUnreadCounter(this.props.currentConversation.id, false);
      }else{
          // this.props.onUpdateReadCounter(data);
          this.getCsHelpList();
      }

      // var isMyContact = false;
      // try{
      //   isMyContact = this.props.conversationList.filter((contact) => contact.fromNumber===data.from);
      // }catch(err){

      // }

      // if(this.state.muteSound === true){
      //   return;
      // }

      // if(Global.get().admin === true){
      //   try{
      //     if(isNewContact){
      //       this.state.newContactAudio.play();
      //     }else{
      //       this.state.audio.play();
      //     }
          
      //   }catch(err){
      //     console.log("PLAYERROR", err);
      //   }
      // // }else if(data.assignToUserId === Global.get().user.id){
      // }else if(isMyContact.length > 0 || data.assignToUser === Global.get().user.id){
      //   try{
      //     if(isNewContact){
      //       this.state.newContactAudio.play();
      //     }else{
      //       this.state.audio.play();
      //     }
      //   }catch(err){
      //     console.log("PLAYERROR", err);
      //   }
      // }

    });

    
   
  }

  handleClosePopupMenuJustRead = () => {
    this.setState({openMenuJustRead: false, anchorElJustRead: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleJustReadMenuClicked = (event) => {
    this.setState({anchorElJustRead: event.currentTarget, openMenuJustRead: true}, () => {
      this.props.onUpdateJustRead();
    });
  };

  handleKeyword = (event) => {
    this.setState({ keyword: event.target.value });
  };

  onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      this.getCsHelpList();
    }
  };

  updateCsHelpData = (data) => {
    const csHelpList = [...this.state.listMycustomer];
    let contact = csHelpList.filter((conv) => conv.contactId===data.contactId);

    if(contact.length > 0){
      contact[0].csHelpState = data.helpState;
      if(data.csNote !== undefined){
        contact[0].csNote = data.csNote;
      }

      if(data.label !== undefined){
        contact[0].contactLabel = data.label;
      }

      this.setState({listMycustomer: csHelpList});
    }else{
      this.getCsHelpList();
    }

  }

  getCsHelpList = () => {

    // if(this.state.keyword.length <= 0){
    //   this.setState({listMycustomer: []});
    //   return;
    // }

    this.setState({isLoading: true}, () => {
      var userData = Global.get();
      const params = {
          userId: userData.user.id,
          appProfileId: Global.getAppProfile()._id
      }

      if(this.state.searchMode === true){
        params.query = this.state.keyword;
        params.searchInAllContact = this.state.searchInAllContact;
        params.searchInField = this.state.searchInField
      }

      console.log("PARAMS", params);

      axios
      .post(Api.csInQueueCustomers(), params, Api.getRequestHeader())
      .then((response) => {
          console.log("MyCustomerToday : ", response.data.data);
          var customers = response.data.data;
          var contacts = [];
          customers.forEach(customer => {
              let newData =  {
                photo: "https://picsum.photos/seed/picsum/200",
                fromName: `${customer.fromName}`,
                text: customer.lastMessage,
                fromNumber: customer.from,
                contactId: customer.id,
                toNumber: customer.to,
                toName: customer.toName,
                selected: false,
                unreadCounter: customer.unreadCounter,
                assignToUserName: customer.assignToUserName,
                csHelpState: customer.csHelpState,
                showButton: true,
                csNote: customer.csNote,
                lastWaCsLog: customer.lastWaCsLog,
                contactLabel: customer.label,
                firstContactAssignedToName: customer.firstContactAssignedToName,
                lastMessageTime: customer.lastMessageTime,
                csCreatedAt: customer.csCreatedAt
                };
              contacts.push(newData);
          });

          this.setState({listMycustomer: contacts, isLoading: false});
      })
      .catch((error) => {
        this.setState({isLoading: false});
        console.error("There was an error!", userData.user.id, error);
      });
    });

  };

  getFieldClassName = (fieldName) => {
    let className = "badge";
    if(this.state.searchInField === fieldName){
      className += " badge-primary";
    }else{
      className += " badge-secondary";
    }

    return className;
  }

  handleFieldSelectionChanged = (fieldName) => {
      this.setState({searchInField: fieldName}, () => {
        this.getCsHelpList();
      });
  }

  getUsersBySlugRole = () => {

    // if(this.state.keyword.length <= 0){
    //   this.setState({listMycustomer: []});
    //   return;
    // }

    this.setState({isLoading: true}, () => {
      var userData = Global.get();
      const params = {
          slugRole: "nightshift"
      }
      console.log("PARAMS", params);

      axios
      .post(Api.usersBySlugRole(), params, Api.getRequestHeader())
      .then((response) => {
          console.log("Users By Slug Role : ", response.data);
          // var customers = response.data.data;
          // var contacts = [];
          // customers.forEach(customer => {
          //     let newData =  {
          //       photo: "https://picsum.photos/seed/picsum/200",
          //       fromName: `${customer.fromName}`,
          //       text: customer.lastMessage,
          //       fromNumber: customer.from,
          //       contactId: customer.id,
          //       toNumber: customer.to,
          //       toName: customer.toName,
          //       selected: false,
          //       unreadCounter: customer.unreadCounter,
          //       assignToUserName: customer.assignToUserName,
          //       csHelpState: customer.csHelpState,
          //       showButton: true,
          //       csNote: customer.csNote,
          //       lastWaCsLog: customer.lastWaCsLog,
          //       contactLabel: customer.label,
          //       firstContactAssignedToName: customer.firstContactAssignedToName
          //       };
          //     contacts.push(newData);
          // });

          // this.setState({listMycustomer: contacts, isLoading: false});
      })
      .catch((error) => {
        this.setState({isLoading: false});
        console.error("There was an error!", userData.user.id, error);
      });
    });

  };

  handleUpdateNote = (conversation, note) => {
    const conversations = [...this.state.listMycustomer];
    let contact = conversations.filter((conv) => conv.contactId===conversation.contactId);

    if(contact.length > 0){
      console.log("CONTACTFOND : ", contact);
      contact[0].csNote = note;

      this.setState({isSavingNote: false, listMycustomer: conversations, isEditingNote: false});
    }
  }

  handleSwitchLeads = () => {
    let mode = false;
    if(this.state.searchMode === true){
      mode = false;
    }else{
      mode = true;
    }

    this.setState({searchMode: mode, keyword: ""}, () => {
      this.getCsHelpList();
    });

  }

  handleSwitchSearchContext = () => {
    let mode = false;
    if(this.state.searchInAllContact === true){
      mode = false;
    }else{
      mode = true;
    }

    this.setState({searchInAllContact: mode, keyword: this.state.keyword}, () => {
      this.getCsHelpList();
    });

  }

  componentDidMount() {
    this.getCsHelpList();
    this.getUsersBySlugRole();

    // setTimeout(() => {
    //   this.props.onUpdateJustRead()
    // }, 3000)
  }

  render() { 
    return ( 
      <>
        <Paper elevation={0} style={{marginTop: 0, marginLeft: 5, marginRight: 5, marginBottom: 10, zIndex: 0}}>
          <div style={{position: "static", zIndex: -10000}}>
          <div className="text-center">
            <FormControlLabel
              label= {this.state.searchMode === true ? "Search Mode (ON), Realtime Off" : "Search Mode (OFF), Realtime On"}
              control={
                <Switch
                  checked={this.state.searchMode}
                  onChange={this.handleSwitchLeads}
                  name="switchLeads"
                  // color="primary"
                  size='small'
                />
              }
            />
          </div> 
        </div>

          {
            this.state.searchMode === true ? 
            (
              <div className="conversation-search">
                <TextField
                  onChange={this.handleKeyword}
                  label="Cari nama / no wa / label"
                  type="text"
                  className="form-control"
                  id="searchCustomer"
                  variant="outlined"
                  onKeyDown={this.onKeyDownHandler}
                  onBlur={() => {
                      
                  }}
                  size='small'
                />
                {/* <br /> */}
                <div className="container-fluid">
                    <small>Field Pencarian : </small>
                    {/* <div> */}
                        <div className={this.getFieldClassName("fromName")} onClick={() => this.handleFieldSelectionChanged("fromName")} style={{cursor: "pointer"}}>Nama</div>&nbsp;
                        <div className={this.getFieldClassName("from")} onClick={() => this.handleFieldSelectionChanged("from")} style={{cursor: "pointer"}}>Nomor</div>&nbsp;
                        <div className={this.getFieldClassName("label")} onClick={() => this.handleFieldSelectionChanged("label")} style={{cursor: "pointer"}}>Label</div>
                    {/* </div> */}
                </div>
                {/* <br /> */}
                {
                  Global.get().admin && !Global.isCustomerService() ? 
                  (
                    <div className="text-right">
                      <FormControlLabel
                        label= "Cari di semua kontak"
                        control={
                          <Switch
                            checked={this.state.searchInAllContact}
                            onChange={this.handleSwitchSearchContext}
                            name="switchSearchContext"
                            color="primary"
                          />
                        }
                        
                      />
                    </div>
                  ) : 
                  (<></>) 
                }
                {/* <br />
                  */}
              </div>
            ) : 
            (
              <></>
            )
          }
          
          {/* <div className="conversation-search">
            <TextField
              onChange={this.handleKeyword}
              label="Cari nama / no wa / sales"
              type="text"
              className="form-control"
              id="namacustomer"
              variant="outlined"
              onKeyDown={this.onKeyDownHandler}
              onBlur={() => {
                  
              }}
          />
          <br />
          </div> */}

          {
            this.state.isLoading === true ? 
            (<LinearProgress />) : (<></>)
          }
          
        </Paper>
        {/* <div style={{overflowY: 'scroll', marginBottom: 10, maxHeight: 500, position: "static"}}> */}
        <div style={{marginBottom: 10, position: "static"}}>
          {
            this.state.listMycustomer.map((conversation) => {
              
              return (
                <CsContactListItem
                  onSelect={conversation.csHelpState === "OPEN" ? () => {this.props.showSnackBar('Klik tombol "START" untuk memulai conversation dengan ' + conversation.fromName)} : this.props.onSelect}
                  key={conversation.fromNumber}
                  data={conversation}
                  onUpdateNote={this.handleUpdateNote}
                  reloadCsHelpList={this.getCsHelpList}
                  onCustomerStarted={this.props.onCustomerStarted}
                  // onBtnEditNoteClick={this.handleToggleShowNoteInput}
                  // onUpdateHelpState={this.handleSetHelpState}
                  // isSavingNote={this.state.isSavingNote}
                  // onSaveNote={this.handleSaveNote}
                  // isEditingNote={this.state.isEditingNote}
                  // savingNoteContactId={this.state.savingNoteContactId}
                />
              )
                    
              })
          }
        </div>
        {/* <Dialog 
          open={true}>
            <DialogTitle>
              <div className="row">
                <div className="col-10" style={{paddingTop: "5px"}}>
                  Antrian Customer CS
                </div>
                <div className="col-2 text-right">
                  <IconButton><Close /></IconButton>
                </div>
              </div>
            </DialogTitle>
          <DialogContent>
            <div style={{marginBottom: 10, position: "static"}}>
            {
              this.state.listMycustomer.map((conversation) => {
                
                return (
                  <CsContactListItem
                    onSelect={conversation.csHelpState === "OPEN" ? () => {this.props.showSnackBar('Klik tombol "START" untuk memulai conversation dengan ' + conversation.fromName)} : this.props.onSelect}
                    key={conversation.fromNumber}
                    data={conversation}
                    onUpdateNote={this.handleUpdateNote}
                    reloadCsHelpList={this.getCsHelpList}
                    // onBtnEditNoteClick={this.handleToggleShowNoteInput}
                    // onUpdateHelpState={this.handleSetHelpState}
                    // isSavingNote={this.state.isSavingNote}
                    // onSaveNote={this.handleSaveNote}
                    // isEditingNote={this.state.isEditingNote}
                    // savingNoteContactId={this.state.savingNoteContactId}
                  />
                )
                      
                })
            }
          </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog> */}
      </>
    );
  }
}
 
export default AntrianCustomerCs;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));