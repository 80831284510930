import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, CircularProgress} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Search } from '@mui/icons-material';
import ExportInboundReferral from './ExportInboundReferral';
import DateRangePicker from '../../customlib/DateRangePicker';

class InboundByreferral extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        isTeleReport: true,
        isLoading: false
    }
    
    componentDidMount() {
        this.getContactLeads();
    }

    getContactLeads = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      var params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
        referralCode: "utm_medium=bio-IG"
      };

      this.setState({contactLeads: [], isLoading: true});

      console.log("PARAMS", params);

      axios
        .post(Api.inobundByReferral(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONTACTLEADS : ", response.data);
          console.log("USERDATA : ", "wkwkwk");
          
          var tmpContactLeads = response.data.data;

          this.setState({contactLeads: tmpContactLeads, isLoading: false});
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    columns = [
        { id: 'from', label: 'From', minWidth: 170 },
        { id: 'fromName', label: 'Name', minWidth: 100 },
        { id: 'created_at', label: 'Tanggal', minWidth: 100 }
    ];

    render() { 
        return ( 
          <Paper style={{padding:20}}>
                <div className="container-fluid">
                  <div className="panel panel-default">
                      <div className="panel-heading">
                          <div className="panel-title">
                              <h1>Inbound Referral Instagram</h1>
                          </div>
                      </div>
                      <div className="panel-body">
                          <div className="row align-items-center">
                              <div className="col-3">
                                  <strong>Total {this.state.contactLeads.length} Inbound</strong>
                              </div>
                              <div className="col-3">
                                <ExportInboundReferral data={this.state.contactLeads} />
                              </div>
                              <div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                              </div>
                              <div className="col-1">
                              {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                            </div>
                          </div>
                          <br />
                          <Paper className="root">
                              <TableContainer>
                                  <Table aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {this.columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contactLeads.map((row) => {
                                        return (
                                            <TableRow>
                                              <TableCell>{row.from}</TableCell>
                                              <TableCell>{row.fromName}</TableCell>
                                              <TableCell>{Global.formatDateTimeToStrDateTime24(row.created_at)}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                    </TableBody>
                                  </Table>
                              </TableContainer>
                          </Paper>
                      </div>
                      <div className="panel-footer"></div>
                  </div>
                </div>
              </Paper>
         );
    }
}
 
export default InboundByreferral;