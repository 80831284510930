import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import { 
    Paper, Grid, List, Card, CardHeader, ListItem, ListItemText, 
    ListItemIcon, Checkbox, Button, Divider 
} from '@mui/material';
import axios from 'axios';
import Api from '../../customlib/Api';
import './SetupChatUser.css';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  containerPadding: {
      padding: 20
  }
}));

class SetupChatUsers extends Component {
    state = { 
        checked: [],
        left: [0, 1, 2, 3],
        right: [4, 5, 6, 7]
    }

    // classes = useStyles();
    // const [checked, setChecked] = React.useState([]);
    // const [left, setLeft] = React.useState([0, 1, 2, 3]);
    // const [right, setRight] = React.useState([4, 5, 6, 7]);

    leftChecked = intersection(this.state.checked, this.state.left);
    rightChecked = intersection(this.state.checked, this.state.right);

    getLavengerUsers = () => {
        axios
        .post(Api.lavengerUsersList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("LAVENGERUSERS : ", response.data.data);
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleToggle = (value) => {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        
        this.setState({checked: newChecked});
        // setChecked(newChecked);  
    };

    numberOfChecked = (items) => intersection(this.state.checked, items).length;

    handleToggleAll = (items) => {
        if (this.numberOfChecked(items) === items.length) {
            this.setState({checked: not(this.state.checked, items)});
            // setChecked(not(this.state.checked, items));
        } else {
            this.setState({checked: union(this.state.checked, items)});
            // setChecked(union(this.state.checked, items));
        }
    };

    handleCheckedRight = () => {
        this.setState({
            right: this.state.right.concat(this.leftChecked),
            left: not(this.state.left, this.leftChecked),
            checked: not(this.state.checked, this.leftChecked)
        });
        // setRight(this.state.right.concat(leftChecked));
        // setLeft(not(this.state.left, leftChecked));
        // setChecked(not(this.state.checked, leftChecked));
    };

    handleCheckedLeft = () => {

        this.setState({
            left: this.state.left.concat(this.rightChecked),
            right: this.state.not(this.state.right, this.rightChecked),
            checked: not(this.state.checked, this.rightChecked)
        });

        // setLeft(left.concat(rightChecked));
        // setRight(not(this.state.right, rightChecked));
        // setChecked(not(this.state.checked, rightChecked));
    };

    customList = (title, items) => (
        <Card>
        <CardHeader
            className="cardHeader"
            avatar={
            <Checkbox
                onClick={() => this.handleToggleAll(items)}
                checked={this.numberOfChecked(items) === items.length && items.length !== 0}
                indeterminate={this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0}
                disabled={items.length === 0}
                inputProps={{ 'aria-label': 'all items selected' }}
            />
            }
            title={title}
            subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List className="list" dense component="div" role="list">
            {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
                <ListItem key={value} role="listitem" button onClick={() => this.handleToggle(value)}>
                <ListItemIcon>
                    <Checkbox
                    checked={this.state.checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`List item ${value + 1}`} />
                </ListItem>
            );
            })}
            <ListItem />
        </List>
        </Card>
    );

    componentDidMount() {
        this.getLavengerUsers();
    }

    render() { 
        return ( 
            <div className="container-fluida">
                <Paper className="containerPadding">
                    {/* <h4>
                    Halaman ini digunakan untuk menentukan user lavender yang diperbolehkan mengakses aplikasi chat
                    </h4> */}
                    <Grid container spacing={2} justify="center" alignItems="center" className="root">
                        <Grid item>{this.customList('Tidak Boleh Mengakses Chat', this.state.left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                className="button"
                                onClick={this.handleCheckedRight}
                                disabled={this.leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className="button"
                                onClick={this.handleCheckedLeft}
                                disabled={this.rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{this.customList('Boleh Mengakses Chat', this.state.right)}</Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}
 
export default SetupChatUsers;

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

// export default function SetupChatUsers() {
//     const classes = useStyles();
//     const [checked, setChecked] = React.useState([]);
//     const [left, setLeft] = React.useState([0, 1, 2, 3]);
//     const [right, setRight] = React.useState([4, 5, 6, 7]);

//     const leftChecked = intersection(checked, left);
//     const rightChecked = intersection(checked, right);

//     getLavengerUsers = () => {
//         axios
//         .post(Api.lavengerUsersList(), {}, Api.getRequestHeader())
//         .then((response) => {
//             console.log("LAVENGERUSERS : ", response.data.data);
//         })
//         .catch((error) => {
//             console.error("There was an error!", error);
//         });
//     };

//     const handleToggle = (value) => () => {
//         const currentIndex = checked.indexOf(value);
//         const newChecked = [...checked];

//         if (currentIndex === -1) {
//         newChecked.push(value);
//         } else {
//         newChecked.splice(currentIndex, 1);
//         }

//         setChecked(newChecked);  
//     };

//     const numberOfChecked = (items) => intersection(checked, items).length;

//     const handleToggleAll = (items) => () => {
//         if (numberOfChecked(items) === items.length) {
//         setChecked(not(checked, items));
//         } else {
//         setChecked(union(checked, items));
//         }
//     };

//     const handleCheckedRight = () => {
//         setRight(right.concat(leftChecked));
//         setLeft(not(left, leftChecked));
//         setChecked(not(checked, leftChecked));
//     };

//     const handleCheckedLeft = () => {
//         setLeft(left.concat(rightChecked));
//         setRight(not(right, rightChecked));
//         setChecked(not(checked, rightChecked));
//     };

//     const customList = (title, items) => (
//         <Card>
//         <CardHeader
//             className={classes.cardHeader}
//             avatar={
//             <Checkbox
//                 onClick={handleToggleAll(items)}
//                 checked={numberOfChecked(items) === items.length && items.length !== 0}
//                 indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
//                 disabled={items.length === 0}
//                 inputProps={{ 'aria-label': 'all items selected' }}
//             />
//             }
//             title={title}
//             subheader={`${numberOfChecked(items)}/${items.length} selected`}
//         />
//         <Divider />
//         <List className={classes.list} dense component="div" role="list">
//             {items.map((value) => {
//             const labelId = `transfer-list-all-item-${value}-label`;

//             return (
//                 <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
//                 <ListItemIcon>
//                     <Checkbox
//                     checked={checked.indexOf(value) !== -1}
//                     tabIndex={-1}
//                     disableRipple
//                     inputProps={{ 'aria-labelledby': labelId }}
//                     />
//                 </ListItemIcon>
//                 <ListItemText id={labelId} primary={`List item ${value + 1}`} />
//                 </ListItem>
//             );
//             })}
//             <ListItem />
//         </List>
//         </Card>
//     );

//   return (
//     <div className="container-fluida">
//         <Paper className={classes.containerPadding}>
//             {/* <h4>
//             Halaman ini digunakan untuk menentukan user lavender yang diperbolehkan mengakses aplikasi chat
//             </h4> */}
//             <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
//                 <Grid item>{customList('Tidak Boleh Mengakses Chat', left)}</Grid>
//                 <Grid item>
//                     <Grid container direction="column" alignItems="center">
//                     <Button
//                         variant="outlined"
//                         size="small"
//                         className={classes.button}
//                         onClick={handleCheckedRight}
//                         disabled={leftChecked.length === 0}
//                         aria-label="move selected right"
//                     >
//                         &gt;
//                     </Button>
//                     <Button
//                         variant="outlined"
//                         size="small"
//                         className={classes.button}
//                         onClick={handleCheckedLeft}
//                         disabled={rightChecked.length === 0}
//                         aria-label="move selected left"
//                     >
//                         &lt;
//                     </Button>
//                     </Grid>
//                 </Grid>
//                 <Grid item>{customList('Boleh Mengakses Chat', right)}</Grid>
//             </Grid>
//         </Paper>
//     </div>
//   );
// }
