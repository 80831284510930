import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, CircularProgress, ToggleButtonGroup, ToggleButton, TextField} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import ExportCheckPointReport from './ExportCheckPointReport';
import { ConnectWithoutContact, Lightbulb, Search } from '@mui/icons-material';

class LoginMonitoring extends Component {
    SHIFT_PAGI = "PAGI";
    SHIFT_SIANG = "SIANG";
    SHIFT_MALAM = "MALAM";
    state = { 
        loginLogs : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        appProfileId: "",
        dateRangeValue: [null,null],
        isLoading: false,
        shift: this.SHIFT_PAGI
    }
    
    componentDidMount() {
        this.setState(
          {appProfileId: Global.getAppProfile()._id}, ()=>{
            this.getLoginLogs();
          }
        );
    }

    getLoginLogs = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          appProfileId: this.state.appProfileId,
          shift: this.state.shift
          // token: "07d0b91e771752005d94ceb5c5efdc0a"
        }

        this.setState({loginLogs: [], isLoading: true}, () => {
            axios
            .post(Api.loginMonitoring(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("loginLogs : ", response.data);
                
                var tmpLogs = response.data.data.logs;

                let formatted = [];

                tmpLogs.forEach(log => {
                    let existedLog = formatted.find(item => log.lavenderUserId === item.userId);

                    if(existedLog){
                        existedLog.rows.push(log);
                        existedLog.lastLoginState = log.state;
                    }else{
                        formatted.push({
                            userId: log.lavenderUserId,
                            userName: log.name,
                            lastLoginState: log.state,
                            rows: [
                                log
                            ]
                        });
                    }

                });
                
                this.setState({loginLogs: formatted, isLoading: false});
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
        });

      };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getLoginLogs);
        // this.state.startDate = formatStartDate;
        // this.getloginLogs();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getLoginLogs);
        // this.getloginLogs();
        // console.log(this.state.email);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getLoginLogs);
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    handleChangeShift = (event, value) => {
      this.setState({shift: value}, () => {
        this.getLoginLogs();
      });
    }

    render() { 
        return ( 
            // <Paper style={{padding:20}}>
              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row align-items-center">
                            <div className="col-3">
                                <strong>Jumlah User : {this.state.loginLogs.length}</strong>
                            </div>
                            <div className="col-3">
                                <strong>Online : {this.state.loginLogs.filter(item => item.lastLoginState === "ONLINE").length}</strong>
                            {
                                // Global.get().admin === true ? <ExportCheckPointReport leadsReportData={this.state.loginLogs} /> : <></>
                            }
                            
                            </div>
                            <div className="col-3">
                              {/* Shift :&nbsp;
                              <ToggleButtonGroup
                                color="secondary"
                                value={this.state.shift}
                                exclusive
                                onChange={this.handleChangeShift}
                                aria-label="Shift"
                              >
                                <ToggleButton value="PAGI">Pagi</ToggleButton>
                                <ToggleButton value="SIANG">Siang</ToggleButton>
                                <ToggleButton value="MALAM">Malam</ToggleButton>
                              </ToggleButtonGroup> */}
                            </div>
                            <div className="col-2 text-right" style={{borderRadius: 10, padding: 5}}>
                            <TextField
                              id="tanggal"
                              label="Tanggal"
                              value={this.state.startDate}
                              onChange={(event) => {
                                console.log(event.target.value);
                                let selectedDate = event.target.value;
                                this.setState({startDate: selectedDate, endDate: selectedDate}, () => {
                                  this.getLoginLogs();
                                });
                              }}
                              type="date"
                              size='small'
                            />
                                {/* <MyDateRangePicker onDateChanged={this.handleDateChanged} /> */}
                            </div>
                            <div className="col-1">
                            {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 5}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getLoginLogs()}} className="btn btn-primary" style={{padding: 7}}><Search /></div>)
                                }
                            </div>
                        </div>
                        <br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20px" />
                                        <TableCell>User</TableCell>
                                        <TableCell>Login Count</TableCell>
                                        <TableCell>Last Login State</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loginLogs.map((row) => {
                                    return (
                                        <Row key={row.salesId} row={row} />
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="panel-footer">
                        
                    </div>
                </div>
              </div>
            // </Paper>
         );
    }
}
 
export default LoginMonitoring;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell width="20px">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.userName}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.rows.length}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.lastLoginState === "ONLINE" ? <span className='text-warning font-bold'><ConnectWithoutContact/> ONLINE</span> : <span className='text-secondary font-bold'><ConnectWithoutContact/> OFFLINE</span>}
          </TableCell> 
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Riwayat Login
                </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Login</strong></TableCell>
                      <TableCell><strong>Logout</strong></TableCell>
                      <TableCell><strong>Status</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.rows.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell align='left'>
                          {Global.formatDateTimeToStrDateTime24(historyRow.created_at)}
                        </TableCell>
                        <TableCell align="left">
                            {historyRow.logoutTime ? Global.formatDateTimeToStrDateTime24(historyRow.updated_at) : "--:--:--"}
                        </TableCell>
                        <TableCell align='left'>{historyRow.state}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }