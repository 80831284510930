import { FormControlLabel, FormGroup, Paper, Switch, TextField, Autocomplete } from '@mui/material';
import React, { Component } from 'react';
import axios from 'axios';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import {Save, Warning} from '@mui/icons-material';

class AddContact extends Component {
    state = { 
        listSales: [],
        selectedSales: {},//id, name, email
        namaCustomer: "",
        nomorWa: "",
        email: "",
        isTeleCustomer: false,
        isInputError: false,
        errorMessage: "",
        isLoading: false
    }

    getUserData = () => {
        return Global.get();
    }

    getSalesTeleUsers = () => {
        // let userData = Global.get();
        // console.log("AUTHDATA", userData);
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    addContact = () => {

        this.setState({isLoading: true}, () => {
            let userData = Global.get();
        
            const params = {
                contactNumber: this.state.nomorWa,
                contactName: this.state.namaCustomer,
                assignToUserId: userData.admin === true ? this.state.selectedSales.id : userData.user.id,
                assignToUserName: userData.admin === true ? this.state.selectedSales.name : userData.user.name,
                email: this.state.email,
                addedById: userData.user.id,
                addedByName: userData.user.name,
                appProfileId: Global.getAppProfile()._id,
                isTeleCustomer: this.state.isTeleCustomer
            };

            console.log(params);
            // return;
            axios
            .post(Api.addContact(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("RESPONSE ADD CONTACT: ", response.data);

                let responseData = response.data;

                if(responseData.success){
                    this.props.onCloseForm();
                }else{
                    this.setState({isInputError: true, errorMessage: responseData.message});
                }

                // this.setState({listSales: response.data.data});
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
        });
        
    };

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    validateInput = () => {
        if(this.state.namaCustomer === ""){
            this.setState({isInputError: true, errorMessage: "Nama pelanggan harus diisi"});
            return false;
        }

        if(this.state.nomorWa === ""){
            this.setState({isInputError: true, errorMessage: "Nomor WA harus diisi"});
            return false;
        }

        this.setState({isInputError: false, errorMessage: ""}, () => {});
        return true;
    }

    handleSubmitNomorKontak = () => {

        if(this.state.namaCustomer === ""){
            this.setState({isInputError: true, errorMessage: "Nama pelanggan harus diisi"});
            return;
        }

        if(this.state.nomorWa === ""){
            this.setState({isInputError: true, errorMessage: "Nomor WA harus diisi"});
            return;
        }

        this.setState({isInputError: false, errorMessage: ""}, () => {
            this.addContact();
        });
    }

    handleNamaCustomer = (event) => {
        this.setState({ namaCustomer: event.target.value });
    };

    handleNomorWa = (event) => {
        this.setState({ nomorWa: event.target.value });
    };

    handleEmail = (event) => {
        this.setState({ email: event.target.value });
    };

    handleIsTeleCustomerChange = () => {
        let isTeleCustomer = false;
        if(this.state.isTeleCustomer === true){
            isTeleCustomer = false;
        }else{
            isTeleCustomer = true;
        }

        this.setState({isTeleCustomer: isTeleCustomer});
    }

    componentDidMount() {
        this.getSalesTeleUsers();
        console.log(this.getUserData());
    }

    render() { 
        return ( 
            <div>
                <Paper>
                    <div className="container">
                        <div className="text-secondary">Masukkan informasi kontak pelanggan ke dalam isian berikut : </div>
                        <br />

                        <div
                            className={
                                this.state.isInputError === false
                                ? "d-none"
                                : "alert alert-danger"
                            }
                            role="alert"
                            >
                            <Warning />&nbsp;{this.state.errorMessage}
                        </div>

                        <form>
                            <div className="">
                                <div>
                                    <TextField
                                        onChange={this.handleNamaCustomer}
                                        label="Nama"
                                        type="text"
                                        className="form-control"
                                        id="namacustomer"
                                        variant="outlined"
                                        onBlur={() => {
                                            
                                        }}
                                    />
                                </div>
                                <br />
                                <div style={{marginTop: 10}}>
                                    {/* <FormGroup> */}
                                        <TextField
                                            onChange={this.handleNomorWa}
                                            label="Nomor WhatsApp"
                                            type="text"
                                            className="form-control"
                                            id="nomorwa"
                                            variant="outlined"
                                            onBlur={() => {
                                                
                                            }}
                                        />
                                    {/* </FormGroup> */}
                                </div>
                                {/* <br />
                                <div style={{marginTop: 10}}>
                                        <TextField
                                            onChange={this.handleEmail}
                                            label="Email"
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            variant="outlined"
                                            onBlur={() => {
                                                
                                            }}
                                        />
                                </div> */}
                                <br />
                                
                                {
                                    this.getUserData().admin === true ? 
                                    (
                                        <div style={{marginTop: 10}}>
                                            <Autocomplete
                                                id="free-solo-2-demo"
                                                // disableClearable
                                                freeSolo 
                                                options={this.state.listSales.map((option) => {
                                                    // console.log("LABEL : ", option);
                                                    return String(option.name);
                                                })}
                                                onChange={(event, newValue) => {
                                                    console.log("NEWVALUE : ", event);
                                                    this.handleSelectionChanged(newValue);
                                                }} 
                                                value={""}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Sales"
                                                    margin="0"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                                />
                                                )}
                                            />
                                        </div>
                                    ) : 
                                    (<></>)

                                }
                                
                                
                                <br />

                                <FormGroup variant="outlined">
                                  <FormControlLabel
                                    label= {this.state.isTeleCustomer === true ? "Customer Tele (Ya)" : "Customer Tele (Bukan)"}
                                    control={
                                      <Switch
                                        checked={this.state.isTeleCustomer === true}
                                        onChange={this.handleIsTeleCustomerChange}
                                        name="switchNightShift"
                                        // color="primary"
                                      />
                                    }
                                    
                                  />
                              </FormGroup> 
                                <div className="btn btn-primary btn-block" onClick={this.handleSubmitNomorKontak}>
                                    <Save />&nbsp;Simpan
                                </div>
                            </div>
                        </form>
                    </div>
                </Paper>
            </div>
         );
    }
}
 
export default AddContact;