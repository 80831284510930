import { TextField, CircularProgress, FormControl, InputLabel, Select, MenuItem, Snackbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip as ToolTip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import "./ContactsDashboard.css";
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Search, QuestionAnswer, Edit, CloudDownloadOutlined, TrainOutlined} from '@mui/icons-material';
import AddContact from './AddContact';
import ContactEditChangeSales from './ContactEditChangeSales';
import ExportConversation from './ExportConversation';
import ReactLoading from 'react-loading';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from 'chart.js';
// import { Bar } from 'react-chartjs-2';

class ContactsCancelDashboard extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 0,
        rowsPerPage: 40,
        showAddForm: false,
        keyword: "",
        showEditAnalisa: false,
        obEditContact: null,
        savingAnalisa: false,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        // startDate: "2021-04-01",
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        isLoading: false,
        isConfirmSaveDialogOpen: false, 
        currentSaveData: null
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleEditOnClick = (contactData) => {
        const myCustomers = [...this.state.listMycustomer];

        var findCustomer = myCustomers.filter(customer => customer.id === contactData.id);

        if(findCustomer.length > 0){
            findCustomer[0].isEditing = !findCustomer[0].isEditing;
            findCustomer[0].assignToUser = contactData.assignToUser;
            findCustomer[0].assignToUserName = contactData.assignToUserName;
        }

        this.setState({listMycustomer: myCustomers});

    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getMyCustomerToday);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getMyCustomerToday);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    getMyCustomerToday = (replaceAll) => {

        this.setState({isLoading: true}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }

            console.log("PARAMS", params, "replaceAll", replaceAll);

            axios
            // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
            .post(Api.contactCancelListPagedSearchable(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("MyCustomerToday : ", response.data.data);

                if(replaceAll === true){

                    var customers = response.data.data;
                    customers.forEach(customer => {
                        const newData =  {
                            photo: "https://picsum.photos/seed/picsum/200",
                            fromName: `${customer.fromName}`,
                            text: customer.lastMessage,
                            fromNumber: customer.from,
                            contactId: customer.id,
                            toNumber: customer.to,
                            toName: customer.toName,
                            selected: true,
                            unreadCounter: customer.unreadCounter,
                            assignToUserName: customer.assignToUserName,
                            contactLabel: customer.label
                        };
                        
                        customer.isEditing = false;
                        customer.contactFormatted = newData;
                    });

                    this.setState({listMycustomer: customers, isLoading: false});
                }else{
                    let myCustomers = [...this.state.listMycustomer];

                    response.data.data.forEach(customer => {
                        // console.log(element);
                        const newData =  {
                            photo: "https://picsum.photos/seed/picsum/200",
                            fromName: `${customer.fromName}`,
                            text: customer.lastMessage,
                            fromNumber: customer.from,
                            contactId: customer.id,
                            toNumber: customer.to,
                            toName: customer.toName,
                            selected: true,
                            unreadCounter: customer.unreadCounter,
                            assignToUserName: customer.assignToUserName,
                            contactLabel: customer.label
                        };
                        
                        customer.isEditing = false;
                        customer.contactFormatted = newData;
                        myCustomers.push(customer);
                    });

                    this.setState({listMycustomer: myCustomers, isLoading: false});
                }
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    getWaMessages = (contactData) => {
        console.log("CONTACTDATA : ", contactData);
        if(contactData.fromNumber === ""){
          return;
        }
    
        this.setState({isLoading: true}, ()=>{
          const params = {
            from: contactData.fromNumber,
          };
          console.log("PARAMSWAMESSAGE", params);
      
          axios
            .post(Api.messagesList(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_MESSAGES",response.data);
              
              const responseData = response.data;
                
              const clonedMyCust = [...this.state.listMycustomer];
              let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

              if(myCust.length > 0){
                  myCust[0].messages = responseData.data;
              }

              this.setState({listMycustomer: clonedMyCust});
              
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoading: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    handleShowSnackbar = () => {
        this.setState({snackbarVisible: true});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, errorMessage: ""});
    }

    handleEditAnalisa = (contact) => {
        this.setState({obEditContact: contact, showEditAnalisa: true});
    }

    saveAnalisa = () => {
        this.setState({savingAnalisa: TrainOutlined}, ()=>{
            const params = {
              contactId: this.state.obEditContact.id,
              why1: this.state.obEditContact.why1,
              why2: this.state.obEditContact.why2,
              why3: this.state.obEditContact.why3,
              why4: this.state.obEditContact.why4,
              why5: this.state.obEditContact.why5,
              saran: this.state.obEditContact.saran,
              kategoriProblem: this.state.obEditContact.kategoriProblem
            };
            console.log("PARAMSWAMESSAGE", params);
            // return;
            axios
              .post(Api.saveAnalisa(), params, Api.getRequestHeader())
              .then((response) => {
                console.log("YOUR_MESSAGES",response.data);
                
                const responseData = response.data;
                  
                if(responseData.success === true){
                    const myCustomers = [...this.state.listMycustomer];
                    var findCustomer = myCustomers.filter(customer => customer.id === responseData.data.id);

                    if(findCustomer.length > 0){
                        findCustomer[0].why1 = this.state.obEditContact.why1;
                        findCustomer[0].why2 = this.state.obEditContact.why2;
                        findCustomer[0].why3 = this.state.obEditContact.why3;
                        findCustomer[0].why4 = this.state.obEditContact.why4;
                        findCustomer[0].why5 = this.state.obEditContact.why5;
                        findCustomer[0].saran = this.state.obEditContact.saran;
                        findCustomer[0].kategoriProblem = this.state.obEditContact.kategoriProblem;
                    }

                    this.setState({listMycustomer: myCustomers, showEditAnalisa: false, savingAnalisa: false, obEditContact: null, snackbarMessage: responseData.message}, () => {
                        this.handleShowSnackbar();
                    });

                }else{
                    this.setState({ snackbarMessage: responseData.message, savingAnalisa: false }, () => {
                        this.handleShowSnackbar();
                    });
                }
                
              })
              .catch((error) => {
                this.setState({ snackbarMessage: error.message, savingAnalisa: false }, () => {
                    this.handleShowSnackbar();
                });
                console.error("There was an error!", error);
              });
          });
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.getMyCustomerToday(true)});
      }

    componentDidMount() {
        console.log(Global.get());
        this.getMyCustomerToday();
        this.getSalesTeleUsers();
    }

    columns = [
        { id: 'fromName', label: 'Nama', minWidth: 170 },
        { id: 'from', label: 'No Customer', minWidth: 200 },
        { id: 'productCategory', label: 'Kategori', minWidth: 200 },
        { id: 'deliveryDate', label: 'Tanggal Pengiriman', minWidth: 200 },
        // { id: 'why1', label: 'Why1', minWidth: 200 },
        // { id: 'why2', label: 'Why2', minWidth: 200 },
        // { id: 'why3', label: 'Why3', minWidth: 200 },
        // { id: 'why4', label: 'Why4', minWidth: 200 },
        // { id: 'why5', label: 'Why5', minWidth: 200 },
        // { id: 'saran', label: 'Saran', minWidth: 200 },
        { id: 'kategoriProblem', label: 'Keterangan Cancel', minWidth: 200 },
        // {
        //     id: 'population',
        //     label: 'Population',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'size',
        //     label: 'Size\u00a0(km\u00b2)',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'assignToUserName',
        //     label: 'Salesman',
        //     minWidth: 170,
        //     align: 'left',
        // },
        {
            id: 'action',
            label: 'Action',
            minWidth: 130,
            align: 'center',
        },
    ];

    handleChangePage = (event, newPage) => {
        console.log("NEWPAGE : ", newPage);

        var isNavigateToNewPage = newPage >= this.state.listMycustomer.length/this.state.rowsPerPage;

        this.setState({page: newPage}, () => {if(isNavigateToNewPage){this.getMyCustomerToday();}});
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    handleCloseFormAddContact = () => {
        this.setState({showAddForm: false});
    }

    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    handleCloseEditAnalisa = () => {
        this.setState({showEditAnalisa: false, obEditContact: null, errorMessage: ""});
    }

    handleWhy1Changed = (event) => {

        // const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));
        const editingContact = this.state.obEditContact;

        editingContact.why1 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy2Changed = (event) => {

        // const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));
        const editingContact = this.state.obEditContact;

        editingContact.why2 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy3Changed = (event) => {

        // const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));
        const editingContact = this.state.obEditContact;

        editingContact.why3 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy4Changed = (event) => {

        // const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));
        const editingContact = this.state.obEditContact;

        editingContact.why4 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy5Changed = (event) => {

        // const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));
        const editingContact = this.state.obEditContact;

        editingContact.why5 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    
    handleSaranChanged = (event) => {

        const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));

        editingContact.saran = event.target.value;
        this.setState({ obEditContact: editingContact });
    };

    handleKategoriProblemChanged = (event) => {
        const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));
        editingContact.kategoriProblem = event.target.value;
        // this.state.obEditContact = editingContact;
        this.setState({obEditContact: editingContact}, ()=>{
          console.log("ObEditContact",this.state.obEditContact);
        });
      }

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.setState({page: 0}, () => {
                this.getMyCustomerToday(true);
            });
        }
    };
    
    render() { 
        return ( 
            <div className="containe-fluid" style={{padding: "20px"}}>

                <div className="row">
                    <div className="col-10" style={{paddingLeft: "30px"}}><h1>{this.state.showAddForm ? "Tambah Kontak" : "Daftar Kontak Berlabel Cancel"}</h1></div>
                    <div className="col-2 text-right">
                        <div className="btn btn-danger" onClick={this.state.showAddForm ? this.handleCloseFormAddContact : this.props.onClose}>
                            {
                                this.state.showAddForm ? "Batalkan" : "X"
                            }
                        </div>
                    </div>
                </div>
                
                {

                    this.state.showAddForm ? 
                    (
                        <AddContact onCloseForm={() => {this.setState({showAddForm: false}, () => {this.getMyCustomerToday()})}} />
                    ) : 
                    
                    (
                        <div className="container-fluid">

                            <div className="row"  style={{marginTop: 10, marginBottom: 10}}>
                                <div className="col-5">
                                    <TextField
                                        onChange={this.handleKeyword}
                                        label="Ketik untuk mencari nama/nomor wa/kategori"
                                        type="text"
                                        className="form-control"
                                        id="namacustomer"
                                        variant="outlined"
                                        onKeyDown={this.onKeyDownHandler}
                                        onBlur={() => {
                                            
                                        }}
                                    />
                                </div>
                                
                                <div className="col-5text-right" style={{borderRadius: 10, padding: 5}}>
                                    <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                                  {/* <span style={{marginTop: 5,}}><strong>Leads dari :</strong> &nbsp;</span> */}
                                  
                                  {/* <TextField
                                      id="dateStart"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.startDate}
                                      onChange={this.handleStartDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  />
                                  &nbsp; <strong style={{marginTop: 5,}}>-</strong> &nbsp;
                                  <TextField
                                      id="dateEnd"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.endDate}
                                      onChange={this.handleEndDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  /> */}
                              </div>
                              <div className="col-1">
                                    {
                                        this.state.isLoading === true ? 
                                            (<CircularProgress size={30} color="secondary" />) : 
                                            (<div onClick={() => {this.getMyCustomerToday(true)}} className="btn btn-primary" style={{padding: 15}}><Search /></div>)
                                    }
                                    {/* <div onClick={() => {this.setState({showAddForm: true})}} className="btn btn-primary" style={{padding: 15}}><Add /></div> */}
                                </div>
                            </div>
                            <br />
                            {
                                this.state.showEditAnalisa === true ? 
                                (
                                    <Paper className="" style={{marginTop: "10px"}}>
                                        <div className="" style={{marginBottom: "20px", marginTop: "10px"}}>
                                            <div className="card-header bg-info">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                            <strong>Nama</strong>: {this.state.obEditContact.fromName}
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                        <strong>No WA</strong> : {this.state.obEditContact.from}
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                            <strong>Kategori</strong> : {this.state.obEditContact.productCategory}
                                                        </div>
                                                    </div>
                                                    <div className="col-1">
                                                    <button onClick={this.handleCloseEditAnalisa} type="button" class="close" aria-label="Close">
                                                        <span aria-hidden="true" className="text-white">&times;</span>
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                
                                                <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                                    <div className="col-12">
                                                        <FormControl variant="outlined" style={{width: '100%'}}>
                                                            <InputLabel id="demo-simple-select-outlined-label">Problem</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.obEditContact.kategoriProblem === undefined ? "" : this.state.obEditContact.kategoriProblem}
                                                                onChange={this.handleKategoriProblemChanged}
                                                                label="Kategori Problem"
                                                            >
                                                                {
                                                                Global.getListKategoriProblem().map(
                                                                    (cat) => {
                                                                    return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                                                    } 
                                                                )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                {/* <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                                    <div className="col-4 bg-white">
                                                        <TextField
                                                            onChange={this.handleWhy1Changed}
                                                            fullWidth
                                                            label="Why 1"
                                                            // type="text"
                                                            id="why1"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.why1 === undefined ? "" : this.state.obEditContact.why1}
                                                        />
                                                    </div>
                                                    <div className="col-4 bg-white">
                                                        <TextField
                                                            onChange={this.handleWhy2Changed}
                                                            fullWidth
                                                            label="Why 2"
                                                            // type="text"
                                                            id="why2"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.why2 === undefined ? "" : this.state.obEditContact.why2}
                                                        />
                                                    </div>
                                                    <div className="col-4 bg-white">
                                                        <TextField
                                                            onChange={this.handleWhy3Changed}
                                                            fullWidth
                                                            label="Why 3"
                                                            // type="text"
                                                            id="why3"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.why3 === undefined ? "" : this.state.obEditContact.why3}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 bg-white">
                                                        <TextField
                                                            onChange={this.handleWhy4Changed}
                                                            fullWidth
                                                            label="Why 4"
                                                            // type="text"
                                                            id="why4"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.why4 === undefined ? "" : this.state.obEditContact.why4}
                                                        />
                                                    </div>
                                                    <div className="col-6 bg-white">
                                                        <TextField
                                                            onChange={this.handleWhy5Changed}
                                                            fullWidth
                                                            label="Why 5"
                                                            // type="text"
                                                            id="why5"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.why5 === undefined ? "" : this.state.obEditContact.why5}
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                                <div className="row">
                                                    <div className="col-6">
                                                        <TextField
                                                            onChange={this.handleSaranChanged}
                                                            fullWidth
                                                            label="Saran"
                                                            // type="text"
                                                            id="txtsaran"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.saran === undefined ? "" : this.state.obEditContact.saran}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <FormControl variant="outlined" style={{width: '100%'}}>
                                                            <InputLabel id="demo-simple-select-outlined-label">Problem</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.obEditContact.kategoriProblem === undefined ? "" : this.state.obEditContact.kategoriProblem}
                                                                onChange={this.handleKategoriProblemChanged}
                                                                label="Kategori Problem"
                                                            >
                                                                {
                                                                Global.getListKategoriProblem().map(
                                                                    (cat) => {
                                                                    return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                                                    } 
                                                                )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div> */}
                                                {/* <div className="row">
                                                    <div className="col-12">
                                                        {
                                                            this.state.savingAnalisa ? 
                                                            (
                                                                <div className="alert alert-danger">
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <ReactLoading color="#FF0000" width="30px" height="30px" type="spin"/>
                                                                        </div>
                                                                        <div className="col-10">
                                                                            loading ...
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : 
                                                            (
                                                                <div onClick={this.saveAnalisa} className="btn btn-secondary btn-block">Simpan Analisa</div>
                                                            )
                                                        }
                                                    </div>
                                                </div> */}
                                                
                                            </div>
                                            <div className="card-footer bg-info">
                                                <div className="row">
                                                    <div className="col-9"></div>
                                                    <div className="col-3 text-right">
                                                        {
                                                            this.state.savingAnalisa ? 
                                                            (
                                                                <div className="alert alert-danger">
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <ReactLoading color="#FF0000" width="30px" height="30px" type="spin"/>
                                                                        </div>
                                                                        <div className="col-10">
                                                                            loading ...
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : 
                                                            (
                                                                <div onClick={this.saveAnalisa} className="btn btn-warning btn-block">Simpan Analisa</div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </Paper>
                                ) : 
                                (<></>)
                            }

                            <Paper className="root">
                                <TableContainer style={{maxHeight: 440}}>
                                    <Table aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {this.columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {this.columns.map((column) => {
                                                // const value = row[column.id];
                                                const value = Global.isSales() && column.id === "from" ? row[column.id].slice(0, -5) + Array(5 + 1).join("x") : row[column.id];
                                                var isEditing = row.isEditing;
                                                var messagesDownloaded = row.messages !== undefined;
                                                // return 
                                                // var colValue = "";

                                                if(column.id === "assignToUserName"){
                                                    return isEditing === true && column.id === "assignToUserName" ? 
                                                        (
                                                            <TableCell key={column.id} align={column.align}>
                                                                <ContactEditChangeSales onFinishEdit={this.handleEditOnClick} salesData={this.state.listSales} contactData={row} />
                                                            </TableCell>
                                                        ) : 
                                                        (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                            </TableCell>
                                                        )
                                                }
                                                
                                                if(column.id === "action"){
                                                    return messagesDownloaded === true && column.id === "action" ? 
                                                        (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {
                                                                    Global.get().admin === true ? 
                                                                    (
                                                                        <ToolTip title="Input analisa">
                                                                            <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditAnalisa(row);}}  />
                                                                        </ToolTip>
                                                                    ) : 
                                                                    (<></>)
                                                                }
                                                                &nbsp;
                                                                <ToolTip title="Buka Chat">
                                                                    <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.props.onSelect(row.contactFormatted);}}  />
                                                                </ToolTip>
                                                                &nbsp;
                                                                {
                                                                    Global.get().admin === true ? 
                                                                    (
                                                                        <ToolTip title="Download percakapan" key={row.from}>
                                                                            <ExportConversation contactData={row.contactFormatted} messagesData={row.messages}  />
                                                                        </ToolTip>
                                                                    ) : 
                                                                    (<></>)
                                                                }
                                                            </TableCell>
                                                        ) : 
                                                        (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {
                                                                    Global.get().admin === true ? 
                                                                    (
                                                                        <ToolTip title="Input analisa">
                                                                            <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditAnalisa(row);}}  />
                                                                        </ToolTip>
                                                                    ) : 
                                                                    (<></>)
                                                                }
                                                                &nbsp;
                                                                <ToolTip title="Buka Chat">
                                                                    <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.props.onSelect(row.contactFormatted);}}  />
                                                                </ToolTip>
                                                                &nbsp;
                                                                {
                                                                    Global.get().admin === true ? 
                                                                    (
                                                                        <ToolTip title="Download percakapan">
                                                                            <CloudDownloadOutlined style={{cursor: "pointer"}} className="text-success" onClick={() => {this.getWaMessages(row.contactFormatted);}}  />
                                                                        </ToolTip>
                                                                    ) : 
                                                                    (<></>)
                                                                }
                                                            </TableCell>
                                                        )
                                                }

                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                    </TableCell>
                                                )
                                                
                                            })}
                                            </TableRow>
                                        );
                                        })}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                    component="div"
                                    count={this.state.listMycustomer.length + this.state.rowsPerPage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    )

                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />
                
            </div>
        );
    }
}
 
export default ContactsCancelDashboard;

function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }