import React, { Component } from 'react';
import {Divider, Paper, Chip, Avatar, Card, CardContent, CardActions, Drawer} from "@mui/material";
import { Apps, Done, Check } from "@mui/icons-material";
import EditAppProfile from './EditAppProfile';
import Api from "../../customlib/Api";
import axios from "axios";
 
class AppProfile extends Component {
    state = { 
        bottom: false,
        appProfiles: [],
        selectedAppProfile: {}
    }

    toggleDrawer = (anchor, open) => (event) => {
        console.log("TOGGLE", anchor, open);
        this.setState({ ...this.state, [anchor]: open });
    };

    loadAppProfile = () => {
        axios
        .post(Api.listAppProfile(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log(response);
            this.setState({appProfiles: response.data.data});
        })
        .catch((error) => {
            // this.setState({isSaving: false, message: message, isError: true});
            console.error("There was an error!", error);
        });
    }

    componentDidMount(){
        this.loadAppProfile();
    }

    render() { 
        return ( 
            <div>

                <Paper>
                    <div className="container">
                        <div className="row">
                            <div className="col-11"></div>
                            <div className="col-1">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-9"></div>
                            <div className="col-3">
                                <div className="btn btn-primary btn-block" onClick={this.toggleDrawer('bottom', true)}>
                                    <Apps />&nbsp;
                                    Tambah Profile
                                </div>
                            </div>
                        </div>
                        <Divider style={{marginBottom: 10, marginTop: 10}} />
                        <div className="container">
                            <div className="row">

                                {
                                    this.state.appProfiles.map(profile => {
                                        return <div className="col-4" style={{marginTop: 10}}>
                                        <Card className="">
                                            <CardContent>
                                                <div style={{fontWeight: "bold", fontSize: 20}}>
                                                    {profile.label}
                                                </div>
                                                <br />
                                                <div>
                                                <Chip className="bg-primary text-white"
                                                    avatar={<Avatar>O</Avatar>}
                                                    label={profile.label}
                                                    clickable
                                                    deleteIcon={<Done />}
                                                />
                                                &nbsp;
                                                <Chip className="bg-success text-white"
                                                    avatar={<Avatar><Check /></Avatar>}
                                                    label={profile.enabled ? "Aplikasi ON" : "Aplikasi OFF"}
                                                    clickable
                                                    deleteIcon={<Done />}
                                                />
                                                </div>
                                            </CardContent>
                                            <CardActions>
                                                <div className="btn btn-warning btn-block" onClick={() => {
                                                    this.setState({bottom: true, selectedAppProfile: profile});
                                                }}>
                                                    Edit
                                                </div>
                                            </CardActions>
                                        </Card>
                                    </div>
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </Paper>

                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                        <Drawer 
                            anchor={anchor} 
                            open={this.state[anchor]} 
                            onClose={this.toggleDrawer('bottom',false)}
                        >
                            {/* <TemplateMessage onCloseDrawer={() => {this.setState({right: false});}} pushMessageHandler={this.handlePushMessage} messageData={this.handleGetMessageData} onClose={this.toggleDrawer('right',false)}/> */}
                            <EditAppProfile data={this.state.selectedAppProfile} onClose={this.toggleDrawer('bottom',false)} />
                        </Drawer>
                    </React.Fragment> 
                ))}
            </div>
         );
    }
}
 
export default AppProfile;