import { TextField, CircularProgress, FormControl, InputLabel, Select, MenuItem, Snackbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip as ToolTip, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete, LinearProgress, FormControlLabel, Checkbox, Switch, TableFooter } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Search, QuestionAnswer, Edit, ErrorOutline, ArrowUpward, ArrowDownward, SwapHoriz, SwapVert} from '@mui/icons-material';
import ReactLoading from 'react-loading';
import moment from "moment";
import MessageListViewer from '../MessageListViewer';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import DateRangePicker from '../../customlib/DateRangePicker';
import { FaEquals } from 'react-icons/fa';

// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from 'chart.js';
// import { Bar } from 'react-chartjs-2';

class DataCancelDashboard extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 0,
        rowsPerPage: 200,
        showAddForm: false,
        keyword: "",
        showEditAnalisa: false,
        obEditContact: null,
        savingAnalisa: false,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        startDateResume: moment(new Date()).format("YYYY-MM-DD"),
        endDateResume: moment(new Date()).format("YYYY-MM-DD"),
        isLoading: false,
        isLoadingResume: false,
        currentConversation: null,
        resumeWeekly: [],
        resumeByKategoriWeekly: [
            {id: 7, contacts: [], categories: []},
            {id: 14, contacts: [], categories: []},
            {id: 21, contacts: [], categories: []},
            {id: 28, contacts: [], categories: []},
            {id: 31, contacts: [], categories: []},
        ],
        searchInField: "fromName",
        totalResumeCategorized: 0,
        totalResumeUncategorized: 0,
        filterAppNumber: "",
        listAppProfiles: [],
        isFirstLoad: true,
        listCity: [],
        showEmptyAnalisaOnly: false,
        totalByWeeks: {
            week1: 0, 
            week2: 0, 
            week3: 0, 
            week4: 0, 
            // week5: 0, 
        },
        resumeByPermasalahan: [],
        stockWeekly: []
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleEditOnClick = (contactData) => {
        const myCustomers = [...this.state.listMycustomer];

        var findCustomer = myCustomers.filter(customer => customer.id === contactData.id);

        if(findCustomer.length > 0){
            findCustomer[0].isEditing = !findCustomer[0].isEditing;
            findCustomer[0].assignToUser = contactData.assignToUser;
            findCustomer[0].assignToUserName = contactData.assignToUserName;
        }

        this.setState({listMycustomer: myCustomers});

    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        
        this.setState({ startDate:  formatStartDate, endDate: formatStartDate}, () => {
            console.log(formatStartDate);
            this.getMyCustomerToday(true);
        });
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleDeliveryDateChange = (event) => {
        var deliveryDate = event.target.value.replace("/", "-");
        const editingContact = this.state.obEditContact;
        editingContact.deliveryDate = deliveryDate;
        this.setState({ obEditContact:  editingContact}, () => {
            console.log(deliveryDate);
        });
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleStartDateResumeChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDateResume:  formatStartDate, endDateResume: formatStartDate}, () => this.getResume());
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, () =>  this.getMyCustomerToday(true));
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    getMyCustomerToday = (replaceAll) => {
        let first = this.state.isFirstLoad;
        this.setState({isLoading: true, isLoadingResume: true}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                searchInField: this.state.searchInField,
                filterAppNumber: this.state.filterAppNumber,
                showEmptyAnalisaOnly: this.state.showEmptyAnalisaOnly
            }

            console.log("PARAMS", params, "replaceAll", replaceAll);

            axios
            // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
            .post(Api.listAnalisaDataCancel(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("MyCustomerToday : ", response.data.data);

                if(replaceAll === true){

                    let resumeByKategori = [];
                    Global.getListKategoriProblem().forEach(kategori => {
                        resumeByKategori.push({kategoriName: kategori, count: 0, persen: 0});
                    });

                    var customers = response.data.data;
                    let totalCategorized = 0;
                    let totalUnCategorized = 0;
                    customers.forEach(customer => {
                        const newData =  {
                            photo: "https://picsum.photos/seed/picsum/200",
                            fromName: `${customer.fromName}`,
                            text: customer.lastMessage,
                            fromNumber: customer.from,
                            contactId: customer.id,
                            toNumber: customer.to,
                            toName: customer.toName,
                            selected: true,
                            unreadCounter: customer.unreadCounter,
                            assignToUserName: customer.assignToUserName,
                            contactLabel: customer.label,
                            cityName: customer.cityName,
                            cityId: customer.cityId,
                            keteranganCancel: customer.keteranganCancel,
                            kategoriProblem: customer.kategoriProblem,
                            deliveryDate: customer.deliveryDate
                        };
                        
                        customer.isEditing = false;
                        if(customer.label === "PENDING" || customer.label === "UNPAID"){
                            customer.deliveryDateFormatted = customer.deliveryDate !== null && customer.deliveryDate.length > 0 ? moment(customer.deliveryDate).tz("utc").format("YYYY-MM-DD HH:MM") : "";
                        }else{
                            customer.deliveryDateFormatted = "";
                        }
                        
                        customer.contactFormatted = newData;

                        let filter = resumeByKategori.filter(data => data.kategoriName === customer.kategoriProblem);
                        if(filter.length > 0){
                            filter[0].count += 1;
                            totalCategorized += 1;
                        }else{
                            totalUnCategorized += 1;
                        }

                        let day = moment(customer.created_at).format("DD");

                    });

                    resumeByKategori.forEach(element => {
                        let totalData = totalCategorized + totalUnCategorized;
                        if(element.count > 0 && totalData > 0){
                            let persen = element.count/totalData*100;
                            element.persen = Math.round(persen);
                        }
                    });

                    this.setState({isFirstLoad: false, listMycustomer: customers, resumeByKategori: resumeByKategori, totalResumeCategorized: totalCategorized, totalResumeUncategorized: totalUnCategorized, isLoading: false, isLoadingResume: false}, () => {
                        console.log("RESUME_BY_KATEGORI", this.state.resumeByKategori);
                    });
                }else{
                    let myCustomers = [...this.state.listMycustomer];
                    let resumeByKategori = [...this.state.resumeByKategori];

                    let totalCategorized = 0;
                    let totalUnCategorized = 0;

                    response.data.data.forEach(customer => {
                        // console.log(element);
                        const newData =  {
                            photo: "https://picsum.photos/seed/picsum/200",
                            fromName: `${customer.fromName}`,
                            text: customer.lastMessage,
                            fromNumber: customer.from,
                            contactId: customer.id,
                            toNumber: customer.to,
                            toName: customer.toName,
                            selected: true,
                            unreadCounter: customer.unreadCounter,
                            assignToUserName: customer.assignToUserName,
                            contactLabel: customer.label
                        };
                        
                        customer.isEditing = false;
                        if(customer.label === "PENDING" || customer.label === "UNPAID"){
                            customer.deliveryDateFormatted = customer.deliveryDate !== null && customer.deliveryDate.length > 0 ? moment(customer.deliveryDate).format("YYYY-MM-DD HH:MM") : "";
                        }else{
                            customer.deliveryDateFormatted = "";
                        }
                        customer.contactFormatted = newData;
                        myCustomers.push(customer);

                        let filter = resumeByKategori.filter(data => data.kategoriName === customer.kategoriProblem);
                        if(filter.length > 0){
                            filter[0].count += 1;
                            totalCategorized += 1;
                        }else{
                            totalUnCategorized += 1;
                        }

                    });

                    resumeByKategori.forEach(element => {
                        let totalData = totalCategorized + totalUnCategorized;
                        if(element.count > 0 && totalData > 0){
                            let persen = element.count/totalData*100;
                            element.persen = Math.round(persen);
                        }
                    });

                    if(this.state.isFirstLoad === true){
                        this.setState({isFirstLoad: false, listMycustomer: customers, resumeByKategori: resumeByKategori, totalResumeCategorized: totalCategorized, totalResumeUncategorized: totalUnCategorized, isLoading: false}, () => {
                            console.log("RESUME_BY_KATEGORI", this.state.resumeByKategori);
                        });
                    }else{
                        this.setState({listMycustomer: customers, isLoading: false}, () => {
                            console.log("RESUME_BY_KATEGORI", this.state.resumeByKategori);
                        });
                    }
                }
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    getResume = () => {

        this.setState({isLoadingResume: true}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: this.state.startDateResume,
                endDate: this.state.endDateResume,
                searchInField: this.state.searchInField,
                filterAppNumber: this.state.filterAppNumber
            }

            console.log("PARAMS", params);

            axios
            // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
            .post(Api.contactCancelListPagedSearchable(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("MyCustomerToday : ", response.data.data);

                let resumeByKategori = [];
                Global.getListKategoriProblem().forEach(kategori => {
                    resumeByKategori.push({kategoriName: kategori, count: 0, persen: 0});
                });

                var customers = response.data.data;
                let totalCategorized = 0;
                let totalUnCategorized = 0;
                customers.forEach(customer => {
                    const newData =  {
                        photo: "https://picsum.photos/seed/picsum/200",
                        fromName: `${customer.fromName}`,
                        text: customer.lastMessage,
                        fromNumber: customer.from,
                        contactId: customer.id,
                        toNumber: customer.to,
                        toName: customer.toName,
                        selected: true,
                        unreadCounter: customer.unreadCounter,
                        assignToUserName: customer.assignToUserName,
                        contactLabel: customer.label
                    };
                    
                    customer.isEditing = false;
                    if(customer.label === "PENDING" || customer.label === "UNPAID"){
                        customer.deliveryDateFormatted = customer.deliveryDate !== null && customer.deliveryDate.length > 0 ? moment(customer.deliveryDate).format("YYYY-MM-DD HH:MM") : "";
                    }else{
                        customer.deliveryDateFormatted = "";
                    }
                    
                    customer.contactFormatted = newData;

                    let filter = resumeByKategori.filter(data => data.kategoriName === customer.kategoriProblem);
                    if(filter.length > 0){
                        filter[0].count += 1;
                        totalCategorized += 1;
                    }else{
                        totalUnCategorized += 1;
                    }

                    let day = moment(customer.created_at).format("DD");

                });

                resumeByKategori.forEach(element => {
                    let totalData = totalCategorized + totalUnCategorized;
                    if(element.count > 0 && totalData > 0){
                        let persen = element.count/totalData*100;
                        element.persen = Math.round(persen);
                    }
                });

                this.setState({resumeByKategori: resumeByKategori, totalResumeCategorized: totalCategorized, totalResumeUncategorized: totalUnCategorized, isLoadingResume: false}, () => {
                    console.log("RESUME_BY_KATEGORI", this.state.resumeByKategori);
                    console.log("TOTAL_UNCATEGORIZED", this.state.totalResumeUncategorized);
                });
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    getResumeByKategoriProblemWeekly = (replaceAll) => {

        this.setState({isLoading: true}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                filterAppNumber: this.state.filterAppNumber
            }

            console.log("PARAMS", params, "replaceAll", replaceAll);

            axios
            // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
            .post(Api.resumeContactCancelByKategoriProblemMonthly(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("Weekly : ", response.data.data);

                let resumeByKategori = [];
                let resumeByKeteranganCancel = [];
                
                var resumeData = response.data.data;
                
                console.log("resume_weekly", resumeData);

                Global.getListKategoriProblem().forEach(kategori => {
                    resumeByKategori.push({kategori_problem: kategori, week1: 0, week2: 0, week3: 0, week4: 0});
                });

                Global.getListKategoriProblem().forEach(keterangan => {
                    resumeByKeteranganCancel.push({keterangan_cancel: keterangan, week1: 0, week2: 0, week3: 0, week4: 0});
                });

                const totalWeekly = {
                    kategoriProblem: {
                        week1: 0,
                        week2: 0,
                        week3: 0,
                        week4: 0,
                    },
                    keteranganCancel: {
                        week1: 0,
                        week2: 0,
                        week3: 0,
                        week4: 0,
                    }
                };

                resumeData.by_kategori_problem.forEach(item => {

                    const filter = resumeByKategori.filter(kategori => kategori.kategori_problem === item.kategori_problem);

                    if(filter && filter.length > 0){
                        filter[0].week1 = item.week1 ? item.week1 : 0;
                        filter[0].week2 = item.week2 ? item.week2 : 0;
                        filter[0].week3 = item.week3 ? item.week3 : 0;
                        filter[0].week4 = item.week4 ? item.week4 : 0;

                        totalWeekly.kategoriProblem.week1 += item.week1 ? item.week1 : 0;
                        totalWeekly.kategoriProblem.week2 += item.week2 ? item.week2 : 0;
                        totalWeekly.kategoriProblem.week3 += item.week3 ? item.week3 : 0;
                        totalWeekly.kategoriProblem.week4 += item.week4 ? item.week4 : 0;
                    }

                });

                resumeData.by_keterangan_cancel.forEach(item => {

                    const filter = resumeByKeteranganCancel.filter(keterangan => keterangan.keterangan_cancel === item.keterangan_cancel);

                    if(filter && filter.length > 0){
                        filter[0].week1 = item.week1 ? item.week1 : 0;
                        filter[0].week2 = item.week2 ? item.week2 : 0;
                        filter[0].week3 = item.week3 ? item.week3 : 0;
                        filter[0].week4 = item.week4 ? item.week4 : 0;

                        totalWeekly.keteranganCancel.week1 += item.week1 ? item.week1 : 0;
                        totalWeekly.keteranganCancel.week2 += item.week2 ? item.week2 : 0;
                        totalWeekly.keteranganCancel.week3 += item.week3 ? item.week3 : 0;
                        totalWeekly.keteranganCancel.week4 += item.week4 ? item.week4 : 0;
                    }

                });


                let resumeWeekly = {
                    kategoriProblem: resumeByKategori,
                    keteranganCancel: resumeByKeteranganCancel
                }

                this.setState({resumeWeekly: resumeWeekly, totalByWeeks: totalWeekly, isLoading: false}, () => {
                    console.log("TOTAL_WEEKLY", this.state.totalByWeeks);
                });
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    getResumeByPermasalahanWeekly = (replaceAll) => {

        this.setState({isLoading: true}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                filterAppNumber: this.state.filterAppNumber
            }

            console.log("PARAMS", params, "replaceAll", replaceAll);

            axios
            .post(Api.resumeContactCancelByPermasalahanMonthly(), params, Api.getRequestHeader())
            .then((response) => {
                var resumeData = response.data.data;
                let resumeByPermasalahan = [];
                let stockWeekly = [];

                Global.getListPermasalahan().forEach(permasalahan => {
                    resumeByPermasalahan.push({permasalahan: permasalahan.permasalahan, week1: 0, week2: 0, week3: 0, week4: 0, total: 0});
                    stockWeekly.push({permasalahan: permasalahan.permasalahan, totalCurrentWeek: 0, totalLastWeek: 0, deviasi: 0});
                });

                resumeData.by_permasalahan.forEach(item => {

                    const filter = resumeByPermasalahan.filter(kategori => kategori.permasalahan === item.permasalahan);

                    if(filter && filter.length > 0){
                        filter[0].week1 = item.week1 ? item.week1 : 0;
                        filter[0].week2 = item.week2 ? item.week2 : 0;
                        filter[0].week3 = item.week3 ? item.week3 : 0;
                        filter[0].week4 = item.week4 ? item.week4 : 0;

                        filter[0].total = filter[0].week1 + filter[0].week2 + filter[0].week3 + filter[0].week4; 
                        // totalWeekly.kategoriProblem.week1 += item.week1 ? item.week1 : 0;
                        // totalWeekly.kategoriProblem.week2 += item.week2 ? item.week2 : 0;
                        // totalWeekly.kategoriProblem.week3 += item.week3 ? item.week3 : 0;
                        // totalWeekly.kategoriProblem.week4 += item.week4 ? item.week4 : 0;
                    }

                });

                resumeData.stock_weekly.forEach(item => {
                    const filter = stockWeekly.filter(stock => stock.permasalahan === item.permasalahan);

                    if(filter.length > 0){
                        filter[0].totalCurrentWeek = item.totalCurrentWeek;
                        filter[0].totalLastWeek = item.totalLastWeek;
                        filter[0].totalDeviasi = item.deviasi;
                    }
                });

                this.setState({resumeByPermasalahan: resumeByPermasalahan, stockWeekly: stockWeekly});
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    getWaMessages = (contactData) => {
        console.log("CONTACTDATA : ", contactData);
        if(contactData.fromNumber === ""){
          return;
        }
    
        this.setState({isLoading: true}, ()=>{
          const params = {
            from: contactData.fromNumber,
          };
          console.log("PARAMSWAMESSAGE", params);
      
          axios
            .post(Api.messagesList(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_MESSAGES",response.data);
              
              const responseData = response.data;
                
              const clonedMyCust = [...this.state.listMycustomer];
              let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

              if(myCust.length > 0){
                  myCust[0].messages = responseData.data;
              }

              this.setState({listMycustomer: clonedMyCust});
              
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoading: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    getCityList = (message) =>{
        let params = {
        };
        // console.log("CITYLIST", JSON.stringify(message));
        axios
          .post(Api.getCityList(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CITYLIST",response.data);
            this.setState({listCity: response.data.data});
    
    
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
          });
      }
    
      handleCityOnChange = (newCity) => {
        let selected = this.state.listCity.filter((city) => city.name===newCity);
    
        if(selected.length > 0){
            const editingContact = this.state.obEditContact;
            editingContact.cityName = selected[0].name;
            editingContact.cityId = selected[0].id;
            this.setState({obEditContact: editingContact}, () => {
                console.log("SELECTED CITY", selected[0]);
            });
        }
    
        
      }

    handleShowSnackbar = () => {
        this.setState({snackbarVisible: true});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, errorMessage: ""});
    }

    handleEditAnalisa = (contact) => {
        contact.updateTanggalKirim = false;
        this.setState({obEditContact: contact, showEditAnalisa: true}, () => {
            console.log("SELECTED_CONTACT", contact);
            console.log("TANGGAL_KIRIM", moment(this.state.deliveryDate).format("YYYY-MM-DDTHH:mm"));
        });
    }

    handleUpdateTglKirimChange = (event) => {
        const editingContact = this.state.obEditContact;
        editingContact.updateTanggalKirim = event.target.checked;
        this.setState({obEditContact: editingContact});
    }

    saveAnalisa = () => {
        this.setState({savingAnalisa: true}, ()=>{
            const params = {
              contactId: this.state.obEditContact.id,
            //   why1: this.state.obEditContact.why1,
            //   why2: this.state.obEditContact.why2,
            //   why3: this.state.obEditContact.why3,
            //   why4: this.state.obEditContact.why4,
            //   why5: this.state.obEditContact.why5,
                productCategory: this.state.obEditContact.productCategory,
                cityId: this.state.obEditContact.cityId,
                cityName: this.state.obEditContact.cityName,
                deliveryDate: this.state.obEditContact.deliveryDate,
                updateTanggalKirim: this.state.obEditContact.updateTanggalKirim,
                keteranganCancel: this.state.obEditContact.keteranganCancel,
                analisaCancel: this.state.obEditContact.analisaCancel,
                saran: this.state.obEditContact.saran,
                kategoriProblem: this.state.obEditContact.kategoriProblem,
                solusi: this.state.obEditContact.solusi
            };
            console.log("PARAMSWAMESSAGE", params);
            // return;
            axios
              .post(Api.saveAnalisa(), params, Api.getRequestHeader())
              .then((response) => {
                console.log("YOUR_MESSAGES",response.data);
                
                const responseData = response.data;
                  
                if(responseData.success === true){
                    const myCustomers = [...this.state.listMycustomer];
                    var findCustomer = myCustomers.filter(customer => customer.id === responseData.data.id);

                    if(findCustomer.length > 0){
                        // findCustomer[0].why1 = this.state.obEditContact.why1;
                        // findCustomer[0].why2 = this.state.obEditContact.why2;
                        // findCustomer[0].why3 = this.state.obEditContact.why3;
                        // findCustomer[0].why4 = this.state.obEditContact.why4;
                        // findCustomer[0].why5 = this.state.obEditContact.why5;
                        findCustomer[0].productCategory = this.state.obEditContact.productCategory;
                        findCustomer[0].cityId = this.state.obEditContact.cityId;
                        findCustomer[0].cityName = this.state.obEditContact.cityName;
                        findCustomer[0].deliveryDate = this.state.obEditContact.deliveryDate;
                        findCustomer[0].keteranganCancel = this.state.obEditContact.keteranganCancel;
                        findCustomer[0].analisaCancel = this.state.obEditContact.analisaCancel;
                        findCustomer[0].saran = this.state.obEditContact.saran;
                        findCustomer[0].kategoriProblem = this.state.obEditContact.kategoriProblem;
                        findCustomer[0].solusi = this.state.obEditContact.solusi;
                    }

                    this.setState({listMycustomer: myCustomers, showEditAnalisa: false, savingAnalisa: false, obEditContact: null, snackbarMessage: responseData.message}, () => {
                        this.handleShowSnackbar();
                    });

                }else{
                    this.setState({ snackbarMessage: responseData.message, savingAnalisa: false }, () => {
                        this.handleShowSnackbar();
                    });
                }
                
              })
              .catch((error) => {
                this.setState({ snackbarMessage: error.message, savingAnalisa: false }, () => {
                    this.handleShowSnackbar();
                });
                console.error("There was an error!", error);
              });
          });
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.handleReloadData()});
      }

    componentDidMount() {
        this.getMyCustomerToday(true);
        this.getResumeByKategoriProblemWeekly(true);
        this.getResumeByPermasalahanWeekly(true);
        this.getSalesTeleUsers();
        this.loadAppProfile();
        this.getCityList();
    }

    columns = [
        { id: 'assignToUserName', label: 'Sales', minWidth: 170 },
        { id: 'fromName', label: 'Nama', minWidth: 170 },
        { id: 'from', label: 'No Customer', minWidth: 200 },
        { id: 'appLabel', label: 'Aplikasi', minWidth: 200 },
        { id: 'productCategory', label: 'Kategori', minWidth: 200 },
        { id: 'cityName', label: 'Kota', minWidth: 200 },
        { id: 'deliveryDateFormatted', label: 'Tanggal Kirim', minWidth: 200},
        { id: 'label', label: 'Label', minWidth: 200 },
        { id: 'keteranganCancel', label: 'Keterangan Cancel Sales', minWidth: 200 },
        { id: 'analisaCancel', label: 'Analisa', minWidth: 200 },
        // { id: 'why1', label: 'Why1', minWidth: 200 },
        // { id: 'why2', label: 'Why2', minWidth: 200 },
        // { id: 'why3', label: 'Why3', minWidth: 200 },
        // { id: 'why4', label: 'Why4', minWidth: 200 },
        // { id: 'why5', label: 'Why5', minWidth: 200 },
        // { id: 'saran', label: 'Saran', minWidth: 200 },
        { id: 'kategoriProblem', label: 'Keterangan Analisa', minWidth: 200 },
        { id: 'solusi', label: 'Solusi', minWidth: 200 },
        // {
        //     id: 'population',
        //     label: 'Population',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'size',
        //     label: 'Size\u00a0(km\u00b2)',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'assignToUserName',
        //     label: 'Salesman',
        //     minWidth: 170,
        //     align: 'left',
        // },
        {
            id: 'action',
            label: 'Action',
            minWidth: 130,
            align: 'center',
        },
    ];

    handleChangePage = (event, newPage) => {
        console.log("NEWPAGE : ", newPage);

        var isNavigateToNewPage = newPage >= this.state.listMycustomer.length/this.state.rowsPerPage;

        this.setState({page: newPage}, () => {if(isNavigateToNewPage){this.getMyCustomerToday();}});
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    handleCloseFormAddContact = () => {
        this.setState({showAddForm: false});
    }

    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    handleCloseEditAnalisa = () => {
        this.setState({showEditAnalisa: false, obEditContact: null, errorMessage: ""});
    }

    handleAnalisaCancelChanged = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.analisaCancel = event.target.value;
        this.setState({ obEditContact: editingContact });
    };

    handleSolusiChanged = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.solusi = event.target.value;
        this.setState({ obEditContact: editingContact });
    };

    handleWhy1Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why1 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy2Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why2 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy3Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why3 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };

    handleWhy4Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why4 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy5Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why5 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    
    handleSaranChanged = (event) => {

        const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));

        editingContact.saran = event.target.value;
        this.setState({ obEditContact: editingContact });
    };

    handleProductCategoryChanged = (event) => {
        const editingContact = this.state.obEditContact;
        editingContact.productCategory = event.target.value;
        this.setState({obEditContact: editingContact}, ()=>{
          console.log(this.state.cancelCategory);
        });
    }

    handleKategoriProblemChanged = (event) => {
        const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));
        editingContact.kategoriProblem = event.target.value;
        // this.state.obEditContact = editingContact;
        this.setState({obEditContact: editingContact}, ()=>{
          console.log("ObEditContact",this.state.obEditContact);
        });
    }

    handleKeteranganCancelChanged = (event) => {
        const editingContact = this.state.obEditContact;
        editingContact.keteranganCancel = event.target.value;
        this.setState({obEditContact: editingContact}, ()=>{
            console.log(editingContact);
        });
    }

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.setState({page: 0}, () => {
                this.getMyCustomerToday(true);
            });
        }
      };

    handleShowMessages = (conversation) => {
        this.setState({currentConversation: conversation});
    }

    getFieldClassName = (fieldName) => {
        let className = "badge";
        if(this.state.searchInField === fieldName){
          className += " badge-primary";
        }else{
          className += " badge-secondary";
        }
    
        return className;
      }
    
    handleFieldSelectionChanged = (fieldName) => {
        this.setState({searchInField: fieldName}, () => {
            this.getMyCustomerToday(true);
        });
    }

    loadAppProfile = () => {
        const params = {
        };
        
        axios
          .post(
            Api.listAppProfile(),
            params,
            Api.getRequestHeaderNoAuth()
          )
          .then((response) => {
            console.log("App Profiles", response.data);
            
            let profiles = [];
            profiles.push({_id: "", waNumber: "", label: "---"});

            let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
            profilesFiltered.forEach(item => {
                profiles.push(item);
            });
            this.setState({listAppProfiles: profiles});
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, loginSuccess: false });
            console.error("There was an error!", error);
            // this.props.onLogin();
          }
        );
    };

    handleAppTypeChanged = (event) => {
        this.setState({filterAppNumber: event.target.value}, ()=>{
          console.log(this.state.filterAppNumber);
          this.getMyCustomerToday(true);
        //   this.getResumeWeekly();
        });
    }

    handleShowEmptyAnalisaOnly = (event) => {
        this.setState({showEmptyAnalisaOnly: event.target.checked}, () => {
            this.getMyCustomerToday(true);
        });
    }

    handleReloadData = () => {
        this.getMyCustomerToday(true);
        this.getResumeByKategoriProblemWeekly(true);
        this.getResumeByPermasalahanWeekly(true);
    }

    render() { 
        return Global.get().admin !== true ? (
            <div className='container text-center' style={{marginTop: 100}}>
                <ErrorOutline style={{width: 200, height: 200}} className="text-danger" />
                <h2>Lotus</h2>
                <h3>Permission denied</h3>
                <Link style={{textDecoration: "underline", width: '100%'}} to="/">
                    <div className="btn btn-warning">Back</div>
                </Link>
            </div>
        ) : ( 
            <Paper className="container-fluid" style={{padding: "20px"}}>
                {/* <div className="row">
                    <div className="col-11">
                        <h1>Analisis Data Cancel</h1>
                    </div>
                    <div className="col-1">
                    <Link to="/">
                        <div className="btn btn-danger">X</div>
                    </Link>
                    </div>
                </div>
                <hr /> */}
                {

                    // this.state.showAddForm ? 
                    // (
                    //     <>wkwkwkw</>
                    // ) : 
                    
                    // (
                        <div className="container-fluid">

                            <div className="row"  style={{marginTop: 10, marginBottom: 0}}>
                                <div className="col-4">
                                    <TextField
                                        onChange={this.handleKeyword}
                                        label="Ketik untuk mencari nama/nomor wa/kategori"
                                        type="text"
                                        className="form-control"
                                        id="namacustomer"
                                        variant="outlined"
                                        onKeyDown={this.onKeyDownHandler}
                                        onBlur={() => {
                                            
                                        }}
                                        size='small'
                                    />
                                </div>
                                
                                <div className=" col-3 text-left" style={{display: "flex", alignItems: "center",borderRadius: 10, padding: 0}}>
                                    <div className='text-sm' style={{minWidth: "105px"}}><small>Tanggal Leads</small></div> 
                                    <DateRangePicker onDateChanged={this.handleDateChanged} />
                                  {/* <span style={{marginTop: 5,}}><strong>Leads dari :</strong> &nbsp;</span> */}
                                  
                                  {/* <TextField
                                      id="dateStart"
                                      label="Tanggal Leads"
                                      type="date"
                                      defaultValue={this.state.startDate}
                                      onChange={this.handleStartDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  /> */}
                                  {/* &nbsp; <strong style={{marginTop: 5,}}>-</strong> &nbsp;
                                  <TextField
                                      id="dateEnd"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.endDate}
                                      onChange={this.handleEndDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  /> */}
                              </div>
                              <div className='col-2'>
                                <FormControl variant="outlined" style={{width: '100%'}} size='small'>
                                    <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.appTypeId}
                                        onChange={this.handleAppTypeChanged}
                                        label="Aplikasi"
                                    >
                                        {
                                        this.state.listAppProfiles?.map(
                                            (profile) => {
                                            return (<MenuItem key={profile._id} value={profile.waNumber}>{profile.label}</MenuItem>);
                                            } 
                                        )
                                        }
                                    </Select>
                                </FormControl>
                                    
                              </div>
                              {/* <div className="col-3">
                                <FormControlLabel control={<Switch onChange={this.handleShowEmptyAnalisaOnly} defaultChecked={false} />} label="Belum dianalisa saja" />
                              </div> */}
                              <div className="col-1">
                                    {
                                        this.state.isLoading === true ? 
                                            (<CircularProgress size={30} color="secondary" />) : 
                                            (<div onClick={this.handleReloadData} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                    }
                                    {/* <div onClick={() => {this.setState({showAddForm: true})}} className="btn btn-primary" style={{padding: 15}}><Add /></div> */}
                                </div>
                            </div>
                            <div className="container-fluid">
                                <small>Field Pencarian : </small>
                                {/* <div> */}
                                    <div className={this.getFieldClassName("fromName")} onClick={() => this.handleFieldSelectionChanged("fromName")} style={{cursor: "pointer"}}>Nama</div>&nbsp;
                                    <div className={this.getFieldClassName("from")} onClick={() => this.handleFieldSelectionChanged("from")} style={{cursor: "pointer"}}>Nomor</div>&nbsp;
                                    <div className={this.getFieldClassName("productCategory")} onClick={() => this.handleFieldSelectionChanged("productCategory")} style={{cursor: "pointer"}}>Kategori</div>&nbsp;
                                    <div className={this.getFieldClassName("kategoriProblem")} onClick={() => this.handleFieldSelectionChanged("kategoriProblem")} style={{cursor: "pointer"}}>Keterangan Cancel</div>
                                {/* </div> */}
                            </div>
                            <br />
                            {
                                this.state.showEditAnalisa === true ? 
                                (
                                    <></>
                                ) : 
                                (<></>)
                            }

                            <div className="row" style={{}}>
                                <div className="col-12">
                                    <Paper className="root"  style={{maxHeight: "500px", overflow: "scroll"}}>
                                        <TableContainer>
                                            <Table aria-label="sticky table" size='small'>
                                            <TableHead>
                                                <TableRow>
                                                {this.columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                    {column.label}
                                                    </TableCell>
                                                ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/* {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => { */}
                                                {this.state.listMycustomer?.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {this.columns.map((column) => {
                                                        // const value = row[column.id];
                                                        // const value = Global.isSales() && column.id === "from" ? row[column.id].slice(0, -5) + Array(5 + 1).join("x") : row[column.id];
                                                        const value = column.id === "from" ? Global.maskPhoneNumber(row[column.id]) : row[column.id];
                                                        var isEditing = row.isEditing;
                                                        var messagesDownloaded = row.messages !== undefined;
                                                        // return 
                                                        // var colValue = "";

                                                        if(column.id === "assignToUserName"){
                                                            return isEditing === true && column.id === "assignToUserName" ? 
                                                                (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {/* <ContactEditChangeSales onFinishEdit={this.handleEditOnClick} salesData={this.state.listSales} contactData={row} /> */}
                                                                    </TableCell>
                                                                ) : 
                                                                (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                                    </TableCell>
                                                                )
                                                        }
                                                        
                                                        if(column.id === "action"){
                                                            return messagesDownloaded === true && column.id === "action" ? 
                                                                (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {
                                                                            Global.get().admin === true ? 
                                                                            (
                                                                                <ToolTip title="Input keterangan cancel">
                                                                                    <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditAnalisa(row);}}  />
                                                                                </ToolTip>
                                                                            ) : 
                                                                            (<></>)
                                                                        }
                                                                        &nbsp;
                                                                        <ToolTip title="Lihat Chat">
                                                                            <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowMessages(row.contactFormatted)}}  />
                                                                        </ToolTip>
                                                                        &nbsp;
                                                                        {/* {
                                                                            Global.get().admin === true ? 
                                                                            (
                                                                                <ToolTip title="Download percakapan" key={row.from}>
                                                                                    <ExportConversation contactData={row.contactFormatted} messagesData={row.messages}  />
                                                                                </ToolTip>
                                                                            ) : 
                                                                            (<></>)
                                                                        } */}
                                                                    </TableCell>
                                                                ) : 
                                                                (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {
                                                                            Global.get().admin === true ? 
                                                                            (
                                                                                <ToolTip title="Input keterangan cancel">
                                                                                    <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditAnalisa(row);}}  />
                                                                                </ToolTip>
                                                                            ) : 
                                                                            (<></>)
                                                                        }
                                                                        &nbsp;
                                                                        <ToolTip title="Lihat Chat">
                                                                            <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowMessages(row.contactFormatted);}}  />
                                                                        </ToolTip>
                                                                        &nbsp;
                                                                        {/* {
                                                                            Global.get().admin === true ? 
                                                                            (
                                                                                <ToolTip title="Download percakapan">
                                                                                    <CloudDownloadOutlined style={{cursor: "pointer"}} className="text-success" onClick={() => {this.getWaMessages(row.contactFormatted);}}  />
                                                                                </ToolTip>
                                                                            ) : 
                                                                            (<></>)
                                                                        } */}
                                                                    </TableCell>
                                                                )
                                                        }

                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                            </TableCell>
                                                        )
                                                        
                                                    })}
                                                    </TableRow>
                                                );
                                                })}
                                            </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <TablePagination
                                            rowsPerPageOptions={[10, 25, 50, 75, 100, 200]}
                                            component="div"
                                            count={this.state.listMycustomer.length + this.state.rowsPerPage}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        /> */}
                                    </Paper>
                                </div>
                                {/* <div className="col-3">
                                    <Paper style={{paddingBottom: 10, paddingTop: 10}}>
                                        <div className="container-fluid">
                                            <TextField
                                                id="dateStartResume"
                                                label="Tanggal Leads"
                                                type="date"
                                                defaultValue={this.state.startDateResume}
                                                onChange={this.handleStartDateResumeChange}
                                                // className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth={true}
                                            />
                                            <br />
                                            <br />
                                            <div style={{fontSize: 14, fontWeight: "bold", marginBottom: "5px"}}>Resume Keterangan Cancel</div>
                                        <br />
                                        {
                                            this.state.isLoadingResume ? (<LinearProgress />) : (<></>)
                                        }
                                        <br />
                                        {
                                            this.state.resumeByKategori?.map(data => {
                                                return <div style={{borderBottom: "solid 1px", marginBottom: 5}}>
                                                    <div className="row">
                                                        <div className="col-10" style={{fontSize: 13}}>{data.kategoriName}</div>
                                                        <div className="col-1" style={{fontSize: 13, fontWeight: "bold"}}>{data.persen}%</div>
                                                    </div>
                                                    
                                                </div>
                                            })
                                        }

                                            <div style={{borderBottom: "solid 0px", marginBottom: 5, marginTop: 20}}>
                                                <div className="row">
                                                    <div className="col-9" style={{fontSize: 14, fontWeight: "bold"}}>Dikategorikan</div>
                                                    <div className="col-3" style={{fontSize: 13, fontWeight: "bold"}}>
                                                        {this.state.totalResumeCategorized} ({this.state.totalResumeCategorized + this.state.totalResumeUncategorized > 0 ? Math.round(this.state.totalResumeCategorized/(this.state.totalResumeCategorized + this.state.totalResumeUncategorized)*100) : 0}%)
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div style={{borderBottom: "solid 0px", marginBottom: 5, marginTop: 0}}>
                                                <div className="row">
                                                    <div className="col-9" style={{fontSize: 14, fontWeight: "bold"}}>Belum Dikategorikan</div>
                                                    <div className="col-3" style={{fontSize: 13, fontWeight: "bold"}}>
                                                        {this.state.totalResumeUncategorized} ({this.state.totalResumeCategorized + this.state.totalResumeUncategorized > 0 ? Math.round(this.state.totalResumeUncategorized/(this.state.totalResumeCategorized + this.state.totalResumeUncategorized)*100) : 0}%)
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </Paper>
                                </div> */}
                            </div>
                            <br />
                            <Paper style={{backgroundColor: "#e7eef4", padding: "10px"}}>
                                <div className="row">
                                    <div className="col-6">
                                        <h5><strong>Overview</strong></h5>
                                        <div style={{height: "1px", backgroundColor: "#d3d3d3", marginBottom: "20px"}}></div>
                                        <Paper className="root">
                                            <TableContainer>
                                                <Table aria-label="sticky table" size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><strong>Permasalahan</strong></TableCell>
                                                            <TableCell align='right'><strong>(1 - 7)</strong></TableCell>
                                                            <TableCell align='right'><strong>(8 - 14)</strong></TableCell>
                                                            <TableCell align='right'><strong>(15 - 21)</strong></TableCell>
                                                            <TableCell align='right'><strong>(22 - 31)</strong></TableCell>
                                                            <TableCell align="right"><strong>Total</strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.resumeByPermasalahan?.map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell>{row.permasalahan}</TableCell>
                                                                <TableCell align='right'>{row.week1}</TableCell>
                                                                <TableCell align='right'>{row.week2}</TableCell>
                                                                <TableCell align='right'>{row.week3}</TableCell>
                                                                <TableCell align='right'>{row.week4}</TableCell>
                                                                <TableCell align='right'>{row.total}</TableCell>
                                                            </TableRow>
                                                        );
                                                        })}
                                                    </TableBody>
                                                    <TableFooter>
                                                        {/* <TableRow>
                                                            <TableCell><strong>Total</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.kategoriProblem?.week1}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.kategoriProblem?.week2}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.kategoriProblem?.week3}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.kategoriProblem?.week4}</strong></TableCell>
                                                        </TableRow> */}
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </div>
                                    <div className="col-6">
                                        <div className="row" style={{paddingTop: "30px"}}>
                                            {
                                                this.state.stockWeekly.map((item, index) => {

                                                    let deviasi = item.totalCurrentWeek-item.totalLastWeek;
                                                    let upDownClassName = deviasi < 0 ? "text-success" : deviasi === 0 ? "" : "text-danger";

                                                    return <div className="col-4 text-center" style={{marginBottom: "10px"}}>
                                                        <Paper className='text-center' style={{padding: "10px"}}>
                                                            <strong>{item.permasalahan}</strong><br />
                                                            <strong>{item.totalCurrentWeek}</strong><br />
                                                            {
                                                                deviasi > 0  ? <ArrowUpward className={upDownClassName} style={{width: "12px"}} /> :  
                                                                    deviasi === 0 ?  <SwapVert style={{width: "12px"}} /> : <ArrowDownward className={upDownClassName} style={{width: "12px"}} />
                                                            }
                                                            <strong style={{fontSize: "12px"}} className={upDownClassName}>{Math.abs(deviasi)}</strong>
                                                            
                                                        </Paper>
                                                    </div>;
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                </Paper>
                            <br />
                            <Paper style={{backgroundColor: "#e7eef4", padding: "10px"}}>
                                <div className="row">
                                    <div className="col-12">
                                        <h5><strong>Resume Keterangan Cancel Berlabel Cancel</strong></h5>
                                        <div style={{height: "1px", backgroundColor: "#d3d3d3", marginBottom: "20px"}}></div>
                                        <Paper className="root">
                                            <TableContainer>
                                                <Table aria-label="sticky table" size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><strong>Resume Keterangan Cancel</strong></TableCell>
                                                            <TableCell align='right'><strong>(1 - 7)</strong></TableCell>
                                                            <TableCell align='right'><strong>(8 - 14)</strong></TableCell>
                                                            <TableCell align='right'><strong>(15 - 21)</strong></TableCell>
                                                            <TableCell align='right'><strong>(22 - 31)</strong></TableCell>
                                                            {/* <TableCell><strong>Week 5</strong></TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => { */}
                                                        {this.state.resumeWeekly?.kategoriProblem?.map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell>{row.kategori_problem}</TableCell>
                                                                <TableCell align='right'>{row.week1}</TableCell>
                                                                <TableCell align='right'>{row.week2}</TableCell>
                                                                <TableCell align='right'>{row.week3}</TableCell>
                                                                <TableCell align='right'>{row.week4}</TableCell>
                                                                {/* <TableCell align='right'>{row.week5}</TableCell> */}
                                                            </TableRow>
                                                        );
                                                        })}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TableCell><strong>Total</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.kategoriProblem?.week1}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.kategoriProblem?.week2}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.kategoriProblem?.week3}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.kategoriProblem?.week4}</strong></TableCell>
                                                            {/* <TableCell align='right'><strong>{this.state.totalByWeeks.week5}</strong></TableCell> */}
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-12">
                                        <h5><strong>Resume Keterangan Cancel Berlabel Pending</strong></h5>
                                        <div style={{height: "1px", backgroundColor: "#d3d3d3", marginBottom: "20px"}}></div>
                                        <Paper className="root">
                                            <TableContainer>
                                                <Table aria-label="sticky table" size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><strong>Resume Keterangan Cancel</strong></TableCell>
                                                            <TableCell align='right'><strong>(1 - 7)</strong></TableCell>
                                                            <TableCell align='right'><strong>(8 - 14)</strong></TableCell>
                                                            <TableCell align='right'><strong>(15 - 21)</strong></TableCell>
                                                            <TableCell align='right'><strong>(22 - 31)</strong></TableCell>
                                                            {/* <TableCell><strong>Week 5</strong></TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => { */}
                                                        {this.state.resumeWeekly?.keteranganCancel?.map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell>{row.keterangan_cancel}</TableCell>
                                                                <TableCell align='right'>{row.week1}</TableCell>
                                                                <TableCell align='right'>{row.week2}</TableCell>
                                                                <TableCell align='right'>{row.week3}</TableCell>
                                                                <TableCell align='right'>{row.week4}</TableCell>
                                                                {/* <TableCell align='right'>{row.week5}</TableCell> */}
                                                            </TableRow>
                                                        );
                                                        })}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TableCell><strong>Total</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.keteranganCancel?.week1}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.keteranganCancel?.week2}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.keteranganCancel?.week3}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.totalByWeeks?.keteranganCancel?.week4}</strong></TableCell>
                                                            {/* <TableCell align='right'><strong>{this.state.totalByWeeks.week5}</strong></TableCell> */}
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </div>
                                    {/* {
                                        this.state.resumeByKategoriWeekly.map(weekly => {
                                          return (
                                            <div className="col-3" style={{marginBottom: "30px"}}>
                                                <Paper style={{padding: "10px"}}>
                                                    <div style={{marginBottom: "15px"}}>
                                                        <strong>{weekly.name}</strong>
                                                    </div>
                                                    
                                                    {
                                                        weekly.categories.map(data => {
                                                            return <div style={{borderBottom: "solid 1px", marginBottom: 5}}>
                                                                <div className="row">
                                                                    <div className="col-10" style={{fontSize: 11}}>{data.kategoriName}</div>
                                                                    <div className="col-1" style={{fontSize: 10, fontWeight: "bold"}}>{data.count}</div>
                                                                </div>
                                                                
                                                            </div>
                                                        })
                                                    }

                                                    <div style={{borderBottom: "solid 0px", marginBottom: 5, marginTop: 20}}>
                                                        <div className="row">
                                                            <div className="col-10" style={{fontSize: 12, fontWeight: "bold"}}>Dikategorikan</div>
                                                            <div className="col-1" style={{fontSize: 12, fontWeight: "bold"}}>{weekly.totalCategorized}</div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div style={{borderBottom: "solid 0px", marginBottom: 5, marginTop: 0}}>
                                                        <div className="row">
                                                            <div className="col-10" style={{fontSize: 12, fontWeight: "bold"}}>Belum Dikategorikan</div>
                                                            <div className="col-1" style={{fontSize: 12, fontWeight: "bold"}}>{weekly.totalUnCategorized}</div>
                                                        </div>
                                                        
                                                    </div>

                                                </Paper>
                                            </div>
                                          );  
                                        })
                                    } */}
                                </div>
                                <br />
                                
                            </Paper>
                        </div>
                    // )

                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />

                {
                    this.state.currentConversation !== null ? (
                        <Dialog
                            open={this.state.currentConversation !== null}
                            // TransitionComponent={Transition}
                            // keepMounted
                            onClose={() => {this.setState({currentConversation: null})}}
                            aria-describedby=""
                        >
                            {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                            <DialogContent>
                                <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} />
                            </DialogContent>
                            <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button>
                            <Button onClick={handleClose}>Agree</Button> */}
                            </DialogActions>
                        </Dialog>
                    ) : (<></>)
                }

                {
                    this.state.showEditAnalisa === true ? (
                        <Draggable
                            // handle="#draggable-dialog-title"
                            // cancel={'[class*="MuiDialogContent-root"]'}
                        >
                            <Dialog
                                open={this.state.showEditAnalisa === true}
                                // TransitionComponent={Transition}
                                // keepMounted
                                onClose={() => {this.setState({showEditAnalisa: false})}}
                                aria-describedby="draggable-dialog-title"
                            >
                                <DialogTitle id="draggable-dialog-title">Edit Data Cancel</DialogTitle>
                                <DialogContent>
                                    <Paper className="" style={{marginTop: "10px"}}>
                                        <div className="" style={{marginBottom: "20px", marginTop: "10px"}}>
                                            <div className="card-header bg-info">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                            <strong>Nama</strong>: {this.state.obEditContact.fromName}
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                        <strong>No WA</strong> : {Global.maskPhoneNumber(this.state.obEditContact.from)}
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                            <strong>Kategori</strong> : {this.state.obEditContact.productCategory}
                                                        </div>
                                                    </div>
                                                    <div className="col-1">
                                                    <button onClick={this.handleCloseEditAnalisa} type="button" class="close" aria-label="Close">
                                                        <span aria-hidden="true" className="text-white">&times;</span>
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                
                                            {/* <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                                    <div className="col-4 bg-white">
                                                        <TextField
                                                            onChange={this.handleWhy1Changed}
                                                            fullWidth
                                                            label="Why 1"
                                                            // type="text"
                                                            id="why1"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.why1 === undefined ? "" : this.state.obEditContact.why1}
                                                        />
                                                    </div>
                                                    <div className="col-4 bg-white">
                                                        <TextField
                                                            onChange={this.handleWhy2Changed}
                                                            fullWidth
                                                            label="Why 2"
                                                            // type="text"
                                                            id="why2"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.why2 === undefined ? "" : this.state.obEditContact.why2}
                                                        />
                                                    </div>
                                                    <div className="col-4 bg-white">
                                                        <TextField
                                                            onChange={this.handleWhy3Changed}
                                                            fullWidth
                                                            label="Why 3"
                                                            // type="text"
                                                            id="why3"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.why3 === undefined ? "" : this.state.obEditContact.why3}
                                                        />
                                                    </div>
                                                </div> */}

                                                <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                                    <div className="col-6">
                                                        <FormControl variant="outlined" style={{width: '100%', marginTop: "0px"}}>
                                                            <InputLabel id="demo-simple-select-outlined-label">Pilih Kategori Produk</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.obEditContact.productCategory}
                                                                onChange={this.handleProductCategoryChanged}
                                                                label="Aplikasi"
                                                            >
                                                                {
                                                                Global.getListProductCategories().map(
                                                                    (cat) => {
                                                                    return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                                                    } 
                                                                )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-6">
                                                        <Autocomplete
                                                            id="free-solo-2-demo"
                                                            // disableClearable
                                                            freeSolo 
                                                            options={this.state.listCity?.map((option) => {
                                                                // console.log("LABEL : ", option);
                                                                return String(option.name);
                                                            })}
                                                            onChange={(event, newValue) => {
                                                                // console.log("NEWVALUE : ", event);
                                                                this.handleCityOnChange(newValue);
                                                            }} 
                                                            value={this.state.obEditContact.cityName}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Pilih Kota"
                                                                margin="0"
                                                                variant="outlined"
                                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                                            />
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                                    <div className="col-6">
                                                        <TextField
                                                            id="dateStart"
                                                            label="Tanggal Kirim"
                                                            type="datetime-local"
                                                            defaultValue={moment(this.state.obEditContact.deliveryDate).format("YYYY-MM-DDTHH:mm")}
                                                            onChange={this.handleDeliveryDateChange}
                                                            // className={classes.textField}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth={true}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <FormControl variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                                                            <InputLabel id="demo-simple-select-outlined-label">Pilih Keterangan Cancel</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.obEditContact.keteranganCancel}
                                                                onChange={this.handleKeteranganCancelChanged}
                                                                label="Aplikasi"
                                                            >
                                                                {
                                                                Global.getListKeteranganCancel().map(
                                                                    (cat) => {
                                                                    return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                                                    } 
                                                                )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                                    <div className="col-6">
                                                        <TextField
                                                            onChange={this.handleAnalisaCancelChanged}
                                                            fullWidth
                                                            label="Analisa"
                                                            // type="text"
                                                            id="analisa"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.analisaCancel === undefined ? "" : this.state.obEditContact.analisaCancel}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <FormControl variant="outlined" style={{width: '100%'}}>
                                                            <InputLabel id="demo-simple-select-outlined-label">Problem</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.obEditContact.kategoriProblem === undefined ? "" : this.state.obEditContact.kategoriProblem}
                                                                onChange={this.handleKategoriProblemChanged}
                                                                label="Kategori Problem"
                                                            >
                                                                {
                                                                Global.getListKategoriProblem().map(
                                                                    (cat) => {
                                                                    return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                                                    } 
                                                                )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                                    <div className="col-12">
                                                        <TextField
                                                            onChange={this.handleSolusiChanged}
                                                            fullWidth
                                                            label="Solusi"
                                                            // type="text"
                                                            id="solusi"
                                                            variant="outlined"
                                                            multiline={true}
                                                            value={this.state.obEditContact.solusi === undefined ? "" : this.state.obEditContact.solusi}
                                                        />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="card-footer">
                                                <hr />
                                                <div className="row">
                                                    <div className="col-12">
                                                    {
                                                            this.state.savingAnalisa ? 
                                                            (
                                                                <div className="alert alert-danger">
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <ReactLoading color="#FF0000" width="30px" height="30px" type="spin"/>
                                                                        </div>
                                                                        <div className="col-10">
                                                                            loading ...
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : 
                                                            (
                                                                
                                                                <div style={{display: 'block'}}>
                                                                    <div className='row'>
                                                                        <div className="col-12 text-center">
                                                                            <FormControlLabel style={{fontWeight: "bold"}} control={<Checkbox color="default" checked={this.state.obEditContact.updateTanggalKirim === true}  onChange={this.handleUpdateTglKirimChange} />} label="Update tanggal kirim" />
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12 text-center">
                                                                            <span onClick={this.saveAnalisa} className="btn btn-warning btn-block">Simpan Analisa</span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </Paper>
                                </DialogContent>
                                {/* <DialogActions> */}
                                
                                {/* <Button onClick={handleClose}>Disagree</Button>
                                <Button onClick={handleClose}>Agree</Button> */}
                                {/* </DialogActions> */}
                            </Dialog>
                        </Draggable>
                        
                    ) : (<></>)
                }
                
            </Paper>
        );
    }
}
 
export default DataCancelDashboard;