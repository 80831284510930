import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import ExportLeadsReport from './ExportLeadsReport';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, Autocomplete, CircularProgress, Dialog, DialogContent, DialogActions, Tooltip, DialogTitle, Tabs, Tab} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { QuestionAnswer, Search } from '@mui/icons-material';
import MessageListViewer from '../MessageListViewer';
import DateRangePicker from '../../customlib/DateRangePicker';
import MessageList from '../MessageList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class ContactLeadsReportRegular extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        appProfileId: "",
        dateRangeValue: [null,null],
        additionalData: null,
        isLoading: false,
        currentConversation: null,
        resumeTotal: {
          leads: 0,
          unpaid: 0,
          paid: 0,
          dp: 0,
          cancel: 0,
          pending: 0
        },
        value: 0,
        crossedLeads: [],
        perSales: []
    }
    
    componentDidMount() {
        this.setState(
          {appProfileId: Global.getAppProfile()._id}, ()=>{
            this.getContactLeads();
          }
        );
    }

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], additionalData: null, isLoading: true}, () => {
          // console.log("ADDITIONAL_DATA : ", this.state.additionalData);
        });

        axios
          .post(Api.getContactLeads(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

            const arrayLabels = [
              Global.labelPaid(),
              Global.labelComplaintKeterlambatan(),
              Global.labelComplaintProdukTidakSesuai(),
              Global.labelComplaintLayuRusak(),
              Global.labelComplaintReport(),
              Global.labelReport(),
              Global.labelKonfirmasiPesanan(),
              Global.labelComplaintPesananTidakDibuatkan(),
              Global.labelLainLain()
            ];

            let resumeTotal = {
              leads: 0,
              unpaid: 0,
              paid: 0,
              dp: 0,
              cancel: 0,
              pending: 0,
              totalUnder60: 0,
              totalHigher60: 0
            };

            let crossedLeads = [];
            let perSales = [];
            // let crossedLeads = [];

            tmpContactLeads.forEach(lead => {
                lead.leadsCount = lead.leads.length;
                resumeTotal.leads += lead.leads.length;
                lead.labelUnpaid = 0;
                lead.labelPaid = 0;
                lead.labelDp = 0;
                lead.labelCancel = 0;
                lead.labelPending = 0;
                lead.totalUnder60 = 0;
                lead.totalHigher60 = 0;

                let perSalesItem = {
                  sales: lead.salesName,
                  leadsOriginal: lead.leads.length,
                  leadsPending: lead.leads.filter(item => item.label === Global.labelPending()).length,
                  leadsCross: lead.leads.filter(item => item.crossedPending === true).length,
                  // leadsCross: 0,
                  leadsCrossDiterima: 0,
                  closingOriginal: lead.leads.filter(item => item.isClosing).length,
                  closingCross: lead.leads.filter(item => item.crossedPending === true && (item.label === Global.labelUnpaid() || item.label === Global.labelDp() || item.label === Global.labelPaid())).length,
                  // closingOriginal: lead.leads.filter(item => item.label === Global.labelPending() || item.label === Global.labelDp() || item.label === Global.labelPaid()).length,
                  // closingCross: lead.leads.filter(item => item.crossedPending === true && (item.label === Global.labelPending() || item.label === Global.labelDp() || item.label === Global.labelPaid())).length,
                  persenClosingOriginal: 0,
                  persenClosingCross: 0,
                  totalClosing: 0
                }

                // crossedLeadsCounts.push({crossOwner: })
                perSales.push(perSalesItem);

                lead.leads.forEach(contact => {

                  if(contact.crossedPending === true){
                    crossedLeads.push(contact);
                  }

                  if(arrayLabels.includes(contact.label)){
                    lead.labelPaid += 1;
                    resumeTotal.paid += 1;
                  }
    
                  if(contact.label === Global.labelUnpaid()){
                    lead.labelUnpaid += 1;
                    resumeTotal.unpaid += 1;
                  }
    
                  if(contact.label === Global.labelDp()){
                    lead.labelDp += 1;
                    resumeTotal.dp += 1;
                  }
    
                  if(contact.label === Global.labelCancel()){
                    lead.labelCancel += 1;
                    resumeTotal.cancel += 1;
                  }

                  if(contact.label === Global.labelPending()){
                    lead.labelPending += 1;
                    resumeTotal.pending += 1;
                  }

                  const newData =  {
                    photo: "https://picsum.photos/seed/picsum/200",
                    fromName: `${contact.fromName}`,
                    text: contact.lastMessage,
                    fromNumber: contact.from,
                    contactId: contact.id,
                    toNumber: contact.to,
                    toName: contact.toName,
                    selected: true,
                    unreadCounter: contact.unreadCounter,
                    assignToUserName: contact.assignToUserName,
                    contactLabel: contact.label
                  };
                  contact.contactFormatted = newData;

                  var firstMessageTime = moment(contact.created_at).tz("asia/jakarta"); //todays date
                  var firstResponseTime = moment(contact.firstResponse).tz("asia/jakarta"); // another date
                  
                  var duration = moment.duration(firstResponseTime.diff(firstMessageTime));
                  let delta = duration.asSeconds();
                  
                  if(delta <= 60){
                    lead.totalUnder60 += 1;
                  }else{
                    lead.totalHigher60 += 1;
                  }

                });

                resumeTotal.totalUnder60 += lead.totalUnder60;
                resumeTotal.totalHigher60 += lead.totalHigher60;

            });

            perSales.forEach(sales => {
              let countCrossed = crossedLeads.filter(lead => lead.crossedOwnerName2 === sales.sales).length;
              sales.leadsCrossDiterima = countCrossed;
            });

            this.setState({perSales: perSales,crossedLeads: crossedLeads,contactLeads: tmpContactLeads, resumeTotal: resumeTotal, isLoading: false, additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null}, () => {
              console.log("ADDITIONAL_DATA : ", this.state.additionalData);
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah Leads', minWidth: 100 },
        { id: 'leadsCount', label: '< 1 Menit>', minWidth: 100 },
        { id: 'leadsCount', label: '> 1 Menit', minWidth: 100 },
        { id: 'labelPaid', label: 'Paid', minWidth: 100 },
        { id: 'labelUnpaid', label: 'Unpaid', minWidth: 100 },
        { id: 'labelDp', label: 'Dp', minWidth: 100 },
        { id: 'labelPending', label: 'Pending', minWidth: 100 },
        { id: 'labelCancel', label: 'Cancel', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    getWaMessages = (contactData) => {
      console.log("CONTACTDATA : ", contactData);
      if(contactData.fromNumber === ""){
        return;
      }
  
      this.setState({isLoading: true}, ()=>{
        const params = {
          from: contactData.fromNumber,
        };
        console.log("PARAMSWAMESSAGE", params);
    
        axios
          .post(Api.messagesList(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("YOUR_MESSAGES",response.data);
            
            const responseData = response.data;
              
            const clonedMyCust = [...this.state.listMycustomer];
            let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

            if(myCust.length > 0){
                myCust[0].messages = responseData.data;
            }

            this.setState({listMycustomer: clonedMyCust});
            
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, isLoading: false });
            console.error("There was an error!", error);
          });
      });
  
      
    };

    handleShowMessages = (conversation) => {
      this.setState({currentConversation: conversation});
  }

  handleTabChange = (event, newValue) => {
    // setValue(newValue);
    this.setState({value: newValue},() => {
      if(newValue === 0){
        // this.getListSalesOnline();
      }
      if(newValue === 1){
        // this.autoleadsHistory();
      }
      if(newValue === 2){
        // this.listLeadsCrossedPendingBelumTeralihkan();
      }
    });
  };

    render() { 
        return ( 
            <div style={{paddingBottom: "10px"}} className='border'>
              {
                  this.state.currentConversation === "wkwkwk" ? (
                      <Dialog
                          open={this.state.currentConversation === "wkwkw"}
                          // TransitionComponent={Transition}
                          // keepMounted
                          onClose={() => {this.setState({currentConversation: null})}}
                          aria-describedby=""
                      >
                          <DialogTitle>
                            {/* <strong>{this.state.currentConversation.fromName}</strong>
                            <br />
                            <strong style={{fontSize: "10px"}}>{this.state.currentConversation.fromNumber}</strong> */}
                          </DialogTitle>
                          <DialogContent style={{height: "100%"}}>
                              {/* <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} /> */}
                              <MessageList
                                onUpdateReadCounter = {()=>{}}
                                onNewIncomingMessage={()=>{}}
                                onUpdateConversations={()=>{}}
                                onUpdateCsHelpState={()=>{}}
                                currentConversation={this.state.currentConversation}
                                onUpdateContactLabelState={()=>{}}
                                onClearCurrentConversation = {()=>{}}
                                onUpdateSlow={()=>{}}
                                onUpdateJustRead={()=>{}}
                                onUpdateUnfinishedChat={()=>{}}
                                onResetUnreadCounter={()=>{}}
                                conversationList={[]}
                                logoutHandler={()=>{}}
                                onSaveCustomerName={()=>{}}
                                style={{maxHeight: "70vh", overflowY: "none"}}
                              />
                          </DialogContent>
                          <DialogActions>
                          {/* <Button onClick={handleClose}>Disagree</Button>
                          <Button onClick={handleClose}>Agree</Button> */}
                          </DialogActions>
                      </Dialog>
                  ) : (<></>)
              }
              <Paper style={{padding: "10px", backgroundColor: "#deecfd"}}>
                <div className="row align-items-center">
                      <div className="col-3">
                          <strong>Total {this.state.contactLeads.length} Salesman</strong>
                      </div>
                      <div className="col-6 text-right">
                      {
                          Global.get().admin === true ? <ExportLeadsReport leadsReportData={this.state.contactLeads} /> : <></>
                      }
                      
                      </div>
                      <div className="col-2 text-right" style={{borderRadius: 10, padding: 5}}>
                          <DateRangePicker onDateChanged={this.handleDateChanged} />
                      </div>
                      <div className="col-1 text-left">
                      {
                              this.state.isLoading === true ? 
                                  (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                  (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                          }
                      </div>
                  </div>
                  <div className="row">
                  <div className={this.state.currentConversation !== null ? "col-8" : "col-12"}>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs 
                          value={this.state.value} 
                          onChange={this.handleTabChange} 
                          aria-label="basic tabs example"
                          indicatorColor="primary"
                          textColor="primary"
                        >
                          <Tab key={"tab0"} label="Leads Report" />
                          <Tab key={"tab1"} label="Data Cross Leads (semua data)" />
                          <Tab key={"tab2"} label="Data reporting per sales" />
                        </Tabs>
                      </Box>
                      <TabPanel value={this.state.value} index={0}>
                        <div className="container-fluid">
                          {/* <div className="row align-items-center">
                              <div className="col-3">
                                  <strong>Total {this.state.contactLeads.length} Salesman</strong>
                              </div>
                              <div className="col-6 text-right">
                              {
                                  Global.get().admin === true ? <ExportLeadsReport leadsReportData={this.state.contactLeads} /> : <></>
                              }
                              
                              </div>
                              <div className="col-2 text-right" style={{borderRadius: 10, padding: 5}}>
                                  <DateRangePicker onDateChanged={this.handleDateChanged} />
                              </div>
                              <div className="col-1 text-left">
                              {
                                      this.state.isLoading === true ? 
                                          (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                          (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                  }
                              </div>
                          </div> */}
                          <br />
                          <Paper className="root">
                            <div className="row">
                              <div className={"col-12"}>
                                <TableContainer>
                                    <Table aria-label="sticky table" size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                        {this.columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contactLeads.map((row) => {
                                          return (
                                              <Row handleShowMessages={this.handleShowMessages} key={row.salesId} row={row} />
                                          );
                                        })}
                                        <TableRow>
                                          <TableCell></TableCell>
                                          <TableCell>Total</TableCell>
                                          <TableCell>{this.state.resumeTotal.leads} leads</TableCell>
                                          <TableCell align='center'>{this.state.resumeTotal.totalUnder60}</TableCell>
                                          <TableCell align='center'>{this.state.resumeTotal.totalHigher60}</TableCell>
                                          <TableCell align='center'>{this.state.resumeTotal.paid}</TableCell>
                                          <TableCell align='center'>{this.state.resumeTotal.unpaid}</TableCell>
                                          <TableCell align='center'>{this.state.resumeTotal.dp}</TableCell>
                                          <TableCell align='center'>{this.state.resumeTotal.pending}</TableCell>
                                          <TableCell align='center'>{this.state.resumeTotal.cancel}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                              </div>
                              
                            </div>
                              
                          </Paper>
                        </div>
                      </TabPanel>
                      <TabPanel value={this.state.value} index={1}>
                        <Paper>
                          <TableContainer>
                            <Table size='small'>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Sales Cross</TableCell>
                                  <TableCell>Sales Pertama</TableCell>
                                  <TableCell>Nama Cust</TableCell>
                                  <TableCell>No Telp</TableCell>
                                  <TableCell>Label</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  this.state.crossedLeads.map(item => {
                                    return <TableRow>
                                      <TableCell>{item.crossedOwnerName2}</TableCell>
                                      <TableCell>{item.firstContactAssignedToName}</TableCell>
                                      <TableCell>{item.fromName}</TableCell>
                                      <TableCell>{item.from}</TableCell>
                                      <TableCell>{item.label}</TableCell>
                                      <TableCell>
                                        <Tooltip title="Lihat Chat">
                                            <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowMessages(item.contactFormatted)}}  />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  })
                                }
                                
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </TabPanel>
                      <TabPanel value={this.state.value} index={2}>
                      <Paper>
                          <TableContainer>
                            <Table size='small'>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Sales</TableCell>
                                  <TableCell>Leads Original</TableCell>
                                  <TableCell>Leads Pending</TableCell>
                                  <TableCell>Leads Yg Dicross</TableCell>
                                  <TableCell>Leads Yg Diterima</TableCell>
                                  <TableCell>Closing Original</TableCell>
                                  <TableCell>Closing Cross</TableCell>
                                  <TableCell>% Closing Leads Original</TableCell>
                                  <TableCell>% Closing Cross</TableCell>
                                  <TableCell>Total Closing</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  this.state.perSales.map(item => {
                                    return <TableRow>
                                      <TableCell>{item.sales}</TableCell>
                                      <TableCell>{item.leadsOriginal}</TableCell>
                                      <TableCell>{item.leadsPending}</TableCell>
                                      <TableCell>{item.leadsCross}</TableCell>
                                      <TableCell>{item.leadsCrossDiterima}</TableCell>
                                      <TableCell>{item.closingOriginal}</TableCell>
                                      <TableCell>{item.closingCross}</TableCell>
                                      <TableCell>{item.leadsOriginal > 0 ? (item.closingOriginal/item.leadsOriginal*100).toFixed(2) : 0}</TableCell>
                                      <TableCell>{item.leadsCrossDiterima > 0 ? (item.closingCross/item.leadsCrossDiterima*100).toFixed(2) : 0}</TableCell>
                                      <TableCell>{((item.closingOriginal+item.closingCross)/(item.leadsOriginal+item.leadsCrossDiterima)*100).toFixed(2)}</TableCell>
                                    </TableRow>
                                  })
                                }
                                
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </TabPanel>
                      </Box>
                    </div>
                    <div className="col-4" style={{maxHeight: "70vh", overflowY: "none"}}>
                        {
                        this.state.currentConversation !== null ? (
                            <div className='text-right'>
                              <div className="btn btn-block btn-danger" onClick={() => {this.handleShowMessages(null)}}>Close Chat</div>
                            </div>
                        ) : (<></>)
                        }
                        {
                        this.state.currentConversation !== null ? (
                            <Paper className='root'>
                                <MessageList
                                    onUpdateReadCounter = {()=>{}}
                                    onNewIncomingMessage={()=>{}}
                                    onUpdateConversations={()=>{}}
                                    onUpdateCsHelpState={()=>{}}
                                    currentConversation={this.state.currentConversation}
                                    onUpdateContactLabelState={()=>{}}
                                    onClearCurrentConversation = {()=>{}}
                                    onUpdateSlow={()=>{}}
                                    onUpdateJustRead={()=>{}}
                                    onUpdateUnfinishedChat={()=>{}}
                                    onResetUnreadCounter={()=>{}}
                                    conversationList={[]}
                                    logoutHandler={()=>{}}
                                    onSaveCustomerName={()=>{}}
                                    style={{maxHeight: "70vh", overflowY: "none"}}
                                />
                            </Paper>
                            
                        ) : (<></>)
                        }
                      </div>
                    </div>
                  
                    </Paper>
              
            </div>
         );
    }
}
 
export default ContactLeadsReportRegular;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    // const classes = {root: {}};

    const tipeLead = (tipeLead) => {
      if(tipeLead === Global.tipeLeadSulitClosing()){
        return <div className="btn btn-danger btn-block btn-sm" style={{fontSize: "11px", paddingLeft: "2px", paddingRight: "2px"}}>{tipeLead}</div>;
      }
      if(tipeLead === Global.tipeLeadSedang()){
        return <div className="btn btn-warning btn-block btn-sm" style={{fontSize: "11px", paddingLeft: "2px", paddingRight: "2px"}}>{tipeLead}</div>;
      }
      if(tipeLead === Global.tipeLeadMudahClosing()){
        return <div className="btn btn-success btn-block btn-sm" style={{fontSize: "11px", paddingLeft: "2px", paddingRight: "2px"}}>{tipeLead}</div>;
      }
    }

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">{row.salesName}</TableCell>
          <TableCell component="th"  align="left">{row.leadsCount} leads</TableCell>
          <TableCell component="th" scope="row" align="center">{row.totalUnder60}</TableCell>
          <TableCell component="th" scope="row" align="center">{row.totalHigher60}</TableCell>
          <TableCell component="th" scope="row" align="center">{row.labelPaid}</TableCell>
          <TableCell component="th" scope="row" align="center">{row.labelUnpaid}</TableCell>
          <TableCell component="th" scope="row" align="center">{row.labelDp}</TableCell>
          <TableCell component="th" scope="row" align="center">{row.labelPending}</TableCell>
          <TableCell component="th" scope="row" align="center">{row.labelCancel}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Leads Detail
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nama</TableCell>
                      {
                          // Global.get().admin === true ? <TableCell>Nomor WhatsApp</TableCell> : <></>
                      }
                      <TableCell>Nomor WhatsApp</TableCell>
                      <TableCell>Label</TableCell>
                      <TableCell>Tipe Lead</TableCell>
                      <TableCell>Kategori</TableCell>
                      <TableCell>Permasalahan</TableCell>
                      <TableCell>Alasan Umum</TableCell>
                      <TableCell>Alasan Rinci</TableCell>
                      <TableCell>Kota</TableCell>
                      <TableCell>Tanggal lead</TableCell>
                      <TableCell>Chat Terakhir</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {/* {Global.getAppProfile().appVendor === "VONAGE" && Global.isSales() ? historyRow.fromName.slice(0, -5) + Array(5 + 1).join("x") : historyRow.fromName} */}
                          {historyRow.fromName}
                        </TableCell>
                        {
                            // Global.get().admin === true ? <TableCell>{historyRow.from}</TableCell> : <></>
                            <TableCell>{Global.maskPhoneNumber(historyRow.from)}</TableCell>
                        }
                        <TableCell component="th" scope="row">
                          <div className={Global.getLabelClasses(historyRow.label)}>{historyRow.label}</div>
                        </TableCell>
                        <TableCell align="left">{tipeLead(historyRow.tipeLead)}</TableCell>
                        <TableCell align="left">{historyRow.productCategory}</TableCell>
                        <TableCell align="left">{historyRow.permasalahan}</TableCell>
                        <TableCell align="left">{historyRow.alasanUmum}</TableCell>
                        <TableCell align="left">{historyRow.alasanRinci}</TableCell>
                        <TableCell align="left">{historyRow.cityName}</TableCell>
                        <TableCell align="left">{Global.formatDateTimeToStrDateTime24(historyRow.createdAt)}</TableCell>
                        <TableCell align="left">
                          {Global.formatDateTimeToStrDateTime24(historyRow.lastMessageReceivedAt)}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Lihat Chat">
                              <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {props.handleShowMessages(historyRow.contactFormatted)}}  />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  // function MyDateRangePicker(props){
  //   const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
  //   const [startDate, endDate] = dateRange;
  //   const [message, setMessage] = React.useState("");
  //   return (
  //     <>
  //       <DatePicker
  //         readOnly={false}
  //         selectsRange={true}
  //         startDate={startDate}
  //         endDate={endDate}
  //         dateFormat="d-M-yyyy"
  //         onChange={(update) => {
  //           console.log("RANGE", update);
            
  //           if(update[0] !== null && update[1] !== null){
  //             let startDate = moment(update[0]);
  //             let endDate = moment(update[1]);
  //             let diffs = Math.abs(startDate.diff(endDate, "days"));
  //             console.log("DIFFS", diffs);
  //             if(diffs > 35){
  //               setDateRange([null, null]);
  //               setMessage("Rentang tanggal max 35 hari");
  //               return
  //             }
  //           }
            
  //           setDateRange(update);
  //           setMessage("");
  //           if(update[0] !== null && update[1] !== null){
  //             props.onDateChanged(update);
  //           }

  //         }}
  //         isClearable={false}
  //         popperPlacement="top-end"
  //         popperModifiers={[
  //           {
  //             name: "offset",
  //             options: {
  //               offset: [5, 10],
  //             },
  //           },
  //           {
  //             name: "preventOverflow",
  //             options: {
  //               rootBoundary: "viewport",
  //               tether: false,
  //               altAxis: true,
  //             },
  //           },
  //         ]}
  //         className='border rounded rounded-lg border-secondary p-2 text-center'
  //       />
  //       <div className="text-danger">
  //         {message}
  //       </div>
  //     </>
  //   );
  // }