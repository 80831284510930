import { Chip, Paper } from '@mui/material';
import { Cake, LocalFlorist, SettingsApplications } from '@mui/icons-material';
import React, { Component } from 'react';
import Global from "../../customlib/Global";


class WelcomeMessage extends Component {
    state = {  }
    render() { 
        return ( 
          <div>
            {this.props.children}
            <div className="container text-center" style={{marginTop: 100,padding:100}}>
              <Paper elevation={0} style={{padding: 30}}>
                {/* <img color='red' src="/logo-prestisa.svg" width={"30%"} alt="Prestisa"/> */}
                <h1 className="text-primary">Selamat Datang</h1>
                <h4 className="text-secondary">
                  " <span style={{fontWeight: 'bold'}}>Semangat</span> menjalankan aktivitas hari ini "
                </h4>
                <LocalFlorist className="text-warning" />
                <Cake className="text-danger" />
                <br />
                <br />
                
                {/* <div className="badge badge-pill badge-primary"> */}
                  {/* <small>Version : {Global.appVersion()}</small> */}
                  <Chip color='primary' icon={<SettingsApplications color='white' />} label={"Version : " + Global.appVersion()} />
                {/* </div> */}
                <br />
                <small>Build : {Global.buildInfo()}</small>
              </Paper>
            </div>
          </div>
             
        );
    }
}
 
export default WelcomeMessage;