import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"

import { makeStyles } from '@mui/styles';
import {Box, CircularProgress, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import DateRangePicker from '../../customlib/DateRangePicker';
import { region } from 'caniuse-lite';
import { lastDayOfDecade } from 'date-fns';

class LeadsByWilayah extends Component {
    state = { 
        contactLeads : [],
        contactLeadsDisplay : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        isTeleReport: true,
        cityList: [],
        selectedCity: "",
        totalSumProductCategories: 0,
        isLoading: false,
        closingByProductCategory: [],
        contactLeadsHourly: [],
        regionList: [],
        leadsByRegion: [],
        lavenderOrder: []
    }
    
    componentDidMount() {
      // this.getRegionList();
        // this.getSummaryReport();
        // this.getContactLeadsReport();
        this.lavenderOrderByCustomer();
    }

    getSummaryReport = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      var params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
        userId: userData.user.id,
        reportType: "TYPE_BY_SOLD_PRODUCT_CATEGORY"
      };

      console.log("PARAMS", params);
      this.setState({contactLeads: [], contactLeadsDisplay: [], totalSumProductCategories: 0, isLoading: true});

      axios
        .post(Api.getLeadsReportSummary(), params, Api.getRequestHeader())
        .then((response) => {
			console.log("SOLDCATEGPRIES : ", response.data);
			console.log("USERDATA : ", "wkwkwk");
			
			var tmpContactLeads = response.data.data;
			var sum = 0;

			let formattedData = [
        {
          id: "duka",
          product_category: "Duka",
          count_product_category: 0,
          leads: []
        },
        {
          id: "congratulation",
          product_category: "Congratulations",
          count_product_category: 0,
          leads: []
        },
        {
          id: "wedding",
          product_category: "Wedding",
          count_product_category: 0,
          leads: []
        },
        {
          id: "parcel",
          product_category: "Parcel",
          count_product_category: 0,
          leads: []
        },
        {
          id: "handbucket",
          product_category: "Handbucket",
          count_product_category: 0,
          leads: []
        },
        {
          id: "other",
          product_category: "Other",
          count_product_category: 0,
          leads: []
        },
      ];

      let closingByProductCategory = [];
      tmpContactLeads.forEach(element => {
        let kategori = element.product_category.toLocaleLowerCase();

        let currentData = null;

        if(kategori.includes("bunga papan duka cita") || kategori.includes("standing flower duka cita")){//duka
          currentData = formattedData[0];
        }else if(kategori.includes("bunga papan congratulation") || kategori.includes("standing flower congratulation")){//congratulation
          currentData = formattedData[1];
        }else if(kategori.includes("bunga papan wedding")){//wedding
          currentData = formattedData[2];
        }else if(kategori.includes("parcel buah") || kategori.includes("parcel makanan") || kategori.includes("parcel pecah belah") || kategori.includes("parcel sembako") || kategori.includes("parcel cookies") || kategori.includes("hampers baby")){//parcel
          currentData = formattedData[3];
        }else if(kategori.includes("handbouquet") || kategori.includes("handbouquet and chocholate") || kategori.includes("handbouquet dan boneka")){//handbouquet
          currentData = formattedData[4];
        }else{//other
          currentData = formattedData[5];
        }

        currentData.count_product_category += element.count_product_category;
        element.leads.forEach(lead => currentData.leads.push(lead));

        // let findExistedCategory = closingByProductCategory.find(item => item.product_category === element.product_category);

        // if(findExistedCategory){
        //   findExistedCategory.leads_count += 1;
        //   if(element.isClosing){
        //     findExistedCategory.closing_count += 1;
        //   }
        // }else{
        //   closingByProductCategory.push({
        //     product_category: element.product_category,
        //     leads_count: 1,
        //     closing_count: element.isClosing ? 1 : 0
        //   });
        // }

        // currentData.leads.push(element.leads);

      });

			formattedData.forEach(element => {

				sum += element.count_product_category;
        element.totalClosing = element.leads.filter(itemLead => [Global.labelPaid(), Global.labelUnpaid(), Global.labelDp()].includes(itemLead.label)).length;

				let arrByTime = [
					{
						id: "24000300",
						label: "24:00 - 03:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "03010600",
						label: "03:01 - 06:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "06010900",
						label: "06:01 - 09:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "09011200",
						label: "09:01 - 12:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "12011600",
						label: "12:01 - 16:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "16011800",
						label: "16:01 - 18:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "18012100",
						label: "18:01 - 21:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "21012359",
						label: "21:01 - 23:59",//4501 - 4759 pm
						count: 0
					},
				];

        let closingBycategory = 0;
				element.leads.forEach(item => {
					var originalTime = Global.formatDateTimeToStrFormat(item.created_at, "hh:mm A");
          var convertedTime = Global.convertTime12to24(originalTime);
          var timeValue = parseInt(convertedTime.replaceAll(":", ""));

					if(timeValue <= 2359){
						timeValue += 2400;
					}

          if(["PAID", "UNPAID", "DP"].includes(item.label)){
            closingBycategory += 1;
          }

					console.log("TIMEVALUE", originalTime, timeValue);
					
					if(timeValue >= 2400 && timeValue <= 2700){//24:00 - 03:00
						arrByTime[0].count += 1;
						arrByTime[0].timeValue = timeValue;
					}else if(timeValue >= 2701 && timeValue <= 3000){//03:01 - 06:00
						arrByTime[1].count += 1;
					}else if(timeValue >= 3001 && timeValue <= 3300){//06:01 - 09:00
						arrByTime[2].count += 1;
					}else if(timeValue >= 3301 && timeValue <= 3600){//09:01 - 12:00
						arrByTime[3].count += 1;
					}else if(timeValue >= 3601 && timeValue <= 4000){//12:01 - 16:00
						arrByTime[4].count += 1;
					}else if(timeValue >= 4001 && timeValue <= 4300){//16:01 - 18:00
						arrByTime[5].count += 1;
					}else if(timeValue >= 4301 && timeValue <= 4600){//18:01 - 21:00
						arrByTime[6].count += 1;
					}else if(timeValue >= 4601 && timeValue <= 4899){
						arrByTime[7].count += 1;
					}
				});

				element.byHours = arrByTime;
        element.totalClosing = closingBycategory;

			});

      formattedData.sort((a,b) => b.count_product_category-a.count_product_category);

			this.setState({contactLeads: formattedData, contactLeadsDisplay: formattedData, totalSumProductCategories: sum, isLoading: false});
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    getContactLeadsReport = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      const params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
      }

      this.setState({closingByProductCategory: []}, () => {
        // console.log("ADDITIONAL_DATA : ", this.state.additionalData);
      });

      axios
        .post(Api.getContactLeads(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONTACTLEADS : ", response.data);
          console.log("USERDATA : ", "wkwkwk");
          
          var tmpContactLeads = response.data.data;
          let tmpRegionList = [...this.state.regionList];
          
          if(userData.admin === false){
              tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
          }

          let leadsByRegion = [];//{region: "Papua", leads: []}

          let notSetRegion = {region: "NOT SET", leads: [
            {
              city: "Not set",
              province: "Not set",
              leads: [],
              closingCount: 0,
              value: 0
            }
          ]}

          let tmpLavenderOrder = [...this.state.lavenderOrder];

          tmpContactLeads.forEach(bySales => {

            bySales.leads.forEach(lead => {

              let findOrder = tmpLavenderOrder.find(order => order.phone === lead.from);
              let orderValue = 0;
              if(findOrder){
                orderValue = findOrder.total; 
              }

              if(lead.cityName !== null && lead.cityName !== undefined && lead.cityName.length > 0){
                let findRegion = tmpRegionList.find(region => region.id_kota === lead.cityId);

                if(findRegion){
                  let findLeadsByRegion = leadsByRegion.find(by => by.region === findRegion.wilayah);

                  if(findLeadsByRegion){
                    let findLeadsKotaExists = findLeadsByRegion.leads.find(byKota => byKota.city === lead.cityName);
                    if(findLeadsKotaExists){
                      findLeadsKotaExists.leads.push(lead);
                      findLeadsKotaExists.closingCount += lead.isClosing ? 1 : 0;
                      findLeadsKotaExists.value += orderValue !== undefined && orderValue !== null ? orderValue : 0;
                    }else{
                      findLeadsByRegion.leads.push({
                        city: lead.cityName,
                        province: findRegion.provinsi,
                        leads: [lead],
                        closingCount: lead.isClosing ? 1 : 0,
                        value: orderValue !== undefined && orderValue !== null ? orderValue : 0
                      });
                    }
                    // findLeadsByRegion.leads.push(lead);
                  }else{

                    leadsByRegion.push({
                      region: findRegion.wilayah,
                      leads: [{
                        city: lead.cityName,
                        province: findRegion.provinsi,
                        leads: [lead],
                        closingCount: lead.isClosing ? 1 : 0,
                        value: orderValue !== undefined && orderValue !== null ? orderValue : 0
                      }]
                    });
                  }
                }else{
                  notSetRegion.leads[0].leads.push(lead);
                  notSetRegion.leads[0].closingCount += lead.isClosing ? 1 : 0;
                  notSetRegion.value += orderValue !== undefined && orderValue !== null ? orderValue : 0;
                }
              }else{
                notSetRegion.leads[0].leads.push(lead);
                notSetRegion.leads[0].closingCount += lead.isClosing ? 1 : 0;
                notSetRegion.value += orderValue !== undefined && orderValue !== null ? orderValue : 0;
              }

            })

          });

          if(notSetRegion.leads.length > 0){
            leadsByRegion.push(notSetRegion);
          }

          this.setState({leadsByRegion: leadsByRegion, isLoading: false},() => {
            console.log("LOG__BY_REGION", leadsByRegion);
            // this.lavenderOrderByCustomer();
          });

        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getSummaryReport);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getSummaryReport);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => {
        this.getSummaryReport();
        this.getContactLeadsReport();
      });
    }

    getRegionList = () => {
      var params = {};

      console.log("PARAMS", params);
      this.setState({contactLeads: [], contactLeadsDisplay: [], isLoading: true});

      axios
        .post(Api.getRegionList(), params, Api.getRequestHeader())
        .then((response) => {
          
          var tmpRegionList = response.data.data;

          this.setState({regionList: tmpRegionList}, () => {
            // this.getSummaryReport();
            this.getContactLeadsReport();
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    lavenderOrderByCustomer = () => {
      const params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate
      };
      
      axios
        .post(
          Api.getLavenderOrderByCustomer(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("LAVENDER ORDER", response.data);

          let dataOrder = response.data.data;
          // let byRegion = [...this.state.leadsByRegion];

          // dataOrder.forEach(order => {

          //   let findRegion = byRegion.find(region => region.leads.filter(lead => lead.from === order.phone));

          //   if(findRegion){
          //     findRegion.value += order.total;
          //   }

          // });

          this.setState({lavenderOrder: dataOrder}, () => {
            this.getRegionList();
          });
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
    };

    columns = [
        { id: 'product_category', label: 'Kategori Produk', minWidth: 170 },
        { id: 'count_product_category', label: 'Jumlah', minWidth: 100 },
        { id: 'totalClosing', label: 'Closing', minWidth: 100 },
        { id: 'percentage', label: '% Closing', minWidth: 100 }
    ];

    render() { 
        return ( 
          <Paper style={{padding:10, backgroundColor: "#e6e9ed"}}>
                <div className="container-fluid">
                  <div className="panel panel-default">
                      <div className="panel-heading">
                          <div className="panel-title">
                              {/* <h1>Setup Contact</h1> */}
                          </div>
                      </div>
                      <div className="panel-body">
                          <div className="row align-items-center">
                              <div className="col-3">
                                  <strong>Total {this.state.contactLeadsDisplay.length} Categories</strong>
                              </div>
                              <div className="col-3">
                              </div>
                              <div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                              </div>
                              <div className="col-1">
                              {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.lavenderOrderByCustomer()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                            </div>
                          </div>
                          <br />
                            {
                              this.state.leadsByRegion.map(byRegion => {
                                let totalLeads = 0;
                                let totalClosing = 0;
                                let totalClosingRate = 0;
                                let totalValue = 0;
                                return <Paper className='root text-center' style={{padding: "10px", marginBottom: "20px"}}>
                                  <h4>{byRegion.region}</h4>
                                  <TableContainer>
                                      <Table aria-label="sticky table" size='small'>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell style={{minWidth: "15vw",fontWeight: "bold"}}>Kota</TableCell>
                                          <TableCell style={{minWidth: "15vw", fontWeight: "bold"}}>Provinsi</TableCell>
                                          <TableCell align='right' style={{fontWeight: "bold"}}>Jumlah Leads</TableCell>
                                          <TableCell align='right' style={{fontWeight: "bold"}}>Closing</TableCell>
                                          <TableCell align='right' style={{fontWeight: "bold"}}>Closing Rate</TableCell>
                                          <TableCell align='right' style={{fontWeight: "bold"}}>Value</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {
                                          byRegion.leads.map(lead => {
                                            console.log("LOG__ini", lead.leads);
                                            let leadsCount = lead.leads ? lead.leads.length : 0;
                                            totalLeads += leadsCount;
                                            totalClosing += lead.closingCount;
                                            totalValue += lead.value;
                                            return (
                                              <TableRow>
                                                <TableCell>{lead.city}</TableCell>
                                                <TableCell>{lead.province}</TableCell>
                                                <TableCell align='right'>{leadsCount}</TableCell>
                                                <TableCell align='right'>{lead.closingCount}</TableCell>
                                                <TableCell align='right'>{leadsCount > 0 ? (lead.closingCount/leadsCount*100).toFixed(2) : 0}%</TableCell>
                                                <TableCell align='right'>{new Intl.NumberFormat("en-id").format(lead.value)}</TableCell>
                                              </TableRow>
                                            );
                                          })
                                        }
                                        <TableRow>
                                          <TableCell colSpan={2} align='right' style={{fontWeight: "bold"}}>Total</TableCell>
                                          <TableCell align='right' style={{fontWeight: "bold"}}>{totalLeads}</TableCell>
                                          <TableCell align='right' style={{fontWeight: "bold"}}>{totalClosing}</TableCell>
                                          <TableCell align='right' style={{fontWeight: "bold"}}>
                                            {totalLeads > 0 ? (totalClosing/totalLeads*100).toFixed(2) : 0}%
                                          </TableCell>
                                          <TableCell align='right' style={{fontWeight: "bold"}}>
                                            {new Intl.NumberFormat("en-id").format(totalValue)}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                      </Table>
                                  </TableContainer>
                                  {/* <br /> */}
                                  </Paper>
                              })
                            }
                          
                      </div>
                      <div className="panel-footer">
                                      
                      </div>
                  </div>
                </div>
              </Paper>
         );
    }
}
 
export default LeadsByWilayah;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props){
  const { row, totalSumProductCategories } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  // const classes = {root: {}};

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell width="20px">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.product_category}</TableCell>
        <TableCell>{row.count_product_category}</TableCell>
        <TableCell>{row.totalClosing}</TableCell>
        {/* <TableCell>{(row.count_product_category/totalSumProductCategories*100).toFixed(2)}%</TableCell> */}
        <TableCell>{row.count_product_category > 0 ? (row.totalClosing/row.count_product_category*100).toFixed(2) : 0}%</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Leads Detail
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Jam</TableCell>
                    <TableCell>Jumlah</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.byHours.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell align="left">{historyRow.label}</TableCell>
                      <TableCell align="left">{historyRow.count}</TableCell>
                      <TableCell align="left">{historyRow.timevalue}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}