import { Paper } from "@mui/material";
import React, { Component } from 'react';
import FollowUpRutin from "./FollowUpRutin";

class FollowupRutinWrapper extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <FollowUpRutin fuType={2} />
                <br />
                <FollowUpRutin fuType={3} />
                <br />
                <FollowUpRutin fuType={4} />
            </>        
        );
    }
}
 
export default FollowupRutinWrapper;