import React from 'react';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function MonthPicker(props){
    const [yearMonth, setYearMonth] = React.useState([new Date().getFullYear(), new Date().getMonth()+1]);
    const [selected, setSelected] = React.useState(new Date());
    const [startDate, endDate] = yearMonth;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={false}
          selected={selected}
          // startDate={startDate}
          // endDate={endDate}
          dateFormat="MM-yyyy"
          
          showMonthYearPicker
          onChange={(update) => {
            // console.log("RANGE", update, new Date(update.getFullYear(), update.getMonth(), 0).getDate());
            
            // if(update[0] !== null && update[1] !== null){
            //   let startDate = moment(update[0]);
            //   let endDate = moment(update[1]);
            //   let diffs = Math.abs(startDate.diff(endDate, "days"));
            //   console.log("DIFFS", diffs);
            //   if(diffs > 35){
            //     setDateRange([null, null]);
            //     setMessage("Rentang tanggal max 35 hari");
            //     return
            //   }
            // }

            if(update!== null && update !== undefined){
              console.log("RANGE", update, update.getMonth(), new Date(update.getFullYear(), update.getMonth()+1, 0).getDate());
              let year = update.getFullYear();
              let month = update.getMonth()+1;
              let countDays = new Date(year, month, 0).getDate();
              let startDate = new Date(year, month-1, 1);
              let endDate = new Date(year, month-1, countDays);
              setYearMonth([year, month]);
              setSelected(update);
              props.onDateChanged([year, month, startDate, endDate]);
            }
            
            // setDateRange([stae]);
            // setMessage("");
            // if(update[0] !== null && update[1] !== null){
            //   props.onDateChanged(update);
            // }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
          className='border rounded rounded-lg border-secondary p-2 text-center'
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }

  export default MonthPicker;