import { Snackbar, Tooltip as ToolTip, TextField, CircularProgress, FormControl, InputLabel, Select, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Pagination, LinearProgress } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Search, QuestionAnswer, Edit, Save, CloudDownloadOutlined, TrainOutlined, Refresh} from '@mui/icons-material';
import ReactLoading from 'react-loading';
import moment from "moment-timezone";
import Modal from "react-bootstrap/Modal";
import base64 from "base-64";
import MessageList from '../MessageList';
import DateRangePicker from '../../customlib/DateRangePicker';

class LeadsParcel extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        recordCount: 0,
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 1,
        rowsPerPage: 50,
        showAddForm: false,
        keyword: "",
        showEditAnalisa: false,
        obEditHasilFu: null,
        savingAnalisa: false,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        // startDate: "2021-04-01",
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        isLoading: false,
        showFuConfirmationDialog: false,
        selectedContact: null,
        pageCount: 0,
        currentConversation: null
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleEditOnClick = (contactData) => {
        const myCustomers = [...this.state.listMycustomer];

        var findCustomer = myCustomers.filter(customer => customer.id === contactData.id);

        if(findCustomer.length > 0){
            findCustomer[0].isEditing = !findCustomer[0].isEditing;
            findCustomer[0].assignToUser = contactData.assignToUser;
            findCustomer[0].assignToUserName = contactData.assignToUserName;
        }

        this.setState({listMycustomer: myCustomers});

    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getMyCustomerToday);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getMyCustomerToday);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    getMyCustomerToday = (replaceAll) => {

        this.setState({isLoading: true}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                appProfileId: userData.appProfile._id
            }

            console.log("PARAMS", params, "replaceAll", replaceAll);

            axios
            // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
            .post(Api.leadsParcel(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("MyCustomerToday : ", response);

                // let shuffledArray = response.data.data.sort((a, b) => 0.5 - Math.random());
                let shuffledArray = response.data.data;
                    var customers = shuffledArray.leads;
                    var i = 1;
                    customers.forEach(customer => {
                        const newData =  {
                            photo: "https://picsum.photos/seed/picsum/200",
                            fromName: `${customer.fromName}`,
                            text: customer.lastMessage,
                            fromNumber: customer.from,
                            contactId: customer.id,
                            toNumber: customer.to,
                            toName: customer.toName,
                            selected: true,
                            unreadCounter: customer.unreadCounter,
                            assignToUserName: customer.assignToUserName,
                            contactLabel: customer.label
                        };
                        customer.noUrut = i;
                        customer.isEditing = false;
                        customer.lastFuResultTmp = customer.lastFuResult;
                        customer.countFuTmp = customer.countFu;
                        customer.contactFormatted = newData;
                        i++;
                    });

                    this.setState({listMycustomer: customers, isLoading: false, recordCount: shuffledArray.total, pageCount: shuffledArray.page_count});
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    getWaMessages = (contactData) => {
        console.log("CONTACTDATA : ", contactData);
        if(contactData.fromNumber === ""){
          return;
        }
    
        this.setState({isLoading: true}, ()=>{
          const params = {
            from: contactData.fromNumber,
          };
          console.log("PARAMSWAMESSAGE", params);
      
          axios
            .post(Api.messagesList(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_MESSAGES",response.data);
              
              const responseData = response.data;
                
              const clonedMyCust = [...this.state.listMycustomer];
              let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

              if(myCust.length > 0){
                  myCust[0].messages = responseData.data;
              }

              this.setState({listMycustomer: clonedMyCust});
              
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoading: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    handleShowChat = (conversation) => {
        this.setState({currentConversation: conversation});
    }

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    handleShowSnackbar = () => {
        this.setState({snackbarVisible: true});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, errorMessage: ""});
    }

    handleEditAnalisa = (contact) => {
        this.setState({obEditHasilFu: contact, showEditAnalisa: true});
    }

    saveAnalisa = () => {
        let contact = this.state.obEditHasilFu;
        console.log("EDITCHANGEDSave", contact);

        if(!Global.getListHasilFu().includes(contact.lastFuResultTmp)){
            this.setState({snackbarMessage: "Hasil FU belum diisi"}, () => {
                this.handleShowSnackbar();
            });
            return;
        }

        if(![1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].includes(contact.countFuTmp)){
            this.setState({snackbarMessage: "Jumlah FU belum diisi"}, () => {
                this.handleShowSnackbar();
            });
            return;
        }
        
        if(contact.lastFuResultTmp === "Order" && contact.totalOrder <= 0){
            this.setState({snackbarMessage: "Total order belum diisi"}, () => {
                this.handleShowSnackbar();
            });
            return;
        }

        this.setState({savingAnalisa: true}, ()=>{
            const params = {
              contactId: contact.id,
              hasilFu: contact.lastFuResultTmp,
              countFu: contact.countFuTmp,
              salesId: Global.get().user.id,
              totalOrder: Number(contact.totalOrder),
              salesName: Global.get().user.name,
              productCategory: contact.productCategory
            //   source: this.state.obEditHasilFu.source,
            };
            console.log("EDITCHANGEDSave", params);
            // return;
            axios
              .post(Api.saveHasilFu(), params, Api.getRequestHeader())
              .then((response) => {
                console.log("YOUR_MESSAGES",response.data);
                
                const responseData = response.data;
                
                if(responseData.success === true){
                    const myCustomers = [...this.state.listMycustomer];
                    var findCustomer = myCustomers.filter(customer => customer.from === responseData.data.from);

                    if(findCustomer.length > 0){
                        findCustomer[0].lastFuDate = responseData.data.lastFuDate;
                        findCustomer[0].lastFuResult = responseData.data.lastFuResult;
                        findCustomer[0].fuData = responseData.data.fuData;
                        findCustomer[0].lastFuResultTmp = responseData.data.lastFuResult;
                        findCustomer[0].countFu = responseData.data.countFu;
                        findCustomer[0].countFuTmp = responseData.data.countFu;
                    }

                    this.setState({listMycustomer: myCustomers, showEditAnalisa: false, savingAnalisa: false, obEditHasilFu: null, snackbarMessage: responseData.message}, () => {
                        this.handleShowSnackbar();
                    });

                }else{
                    this.setState({ snackbarMessage: responseData.message, savingAnalisa: false }, () => {
                        this.handleShowSnackbar();
                    });
                }
                
              })
              .catch((error) => {
                this.setState({ snackbarMessage: error.message, savingAnalisa: false }, () => {
                    this.handleShowSnackbar();
                });
                console.error("There was an error!", error);
              });
          });
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.getMyCustomerToday(true)});
      }

    componentDidMount() {
        // console.log(Global.get());
        this.getMyCustomerToday();
        this.getSalesTeleUsers();
    }

    columns = [
        // {id: "noUrut",label: "No", maxWidth: 30,align: 'left'},
        { id: 'fromName', label: 'Customer', minWidth: 170 },
        { id: 'from', label: 'No WA', minWidth: 200 },
        // { id: 'productCategory', label: 'Kategori', minWidth: 200 },
        { id: 'lastFuDate', label: 'Tgl Last Fu', minWidth: 170 },
        { id: 'productCategory', label: 'Kategori', minWidth: 200 },
        { id: 'lastFuResultTmp', label: 'Hasil Fu', minWidth: 200 },
        { id: 'countFuTmp', label: 'Jumlah Fu', minWidth: 30 },
        // { id: 'created_at', label: 'Tanggal Lead', minWidth: 200 },
        {id: 'action',label: 'Action', minWidth: 50,align: 'center',},
    ];

    handleChangePage = (event, newPage) => {
        console.log("NEWPAGE : ", newPage);
        this.setState({page: newPage}, () => {this.getMyCustomerToday();});
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    handleCloseFormAddContact = () => {
        this.setState({showAddForm: false});
    }

    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    handleShowEditAnalisa = (row) => {
        this.setState({showEditAnalisa: true, obEditHasilFu: JSON.parse(JSON.stringify(row)), errorMessage: ""});
    }
    handleCloseEditAnalisa = () => {
        this.setState({showEditAnalisa: false, obEditHasilFu: null, errorMessage: ""});
    }

    // handleHasilFuChanged = (event) => {

    //     // const editingContact = JSON.parse(JSON.stringify(this.state.obEditHasilFu));
    //     const editingContact = this.state.obEditHasilFu;

    //     editingContact.lastFuResultTmp = event.target.value;
    //     this.setState({ obEditHasilFu: editingContact }, () => {
    //         console.log("HASILFU", editingContact.lastFuResultTmp);
    //     });
    // };

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.setState({page: 0}, () => {
                this.getMyCustomerToday(true);
            });
        }
      };

    handleCloseFuConfirmationDialog = () => {
        this.setState({showFuConfirmationDialog: false, selectedSales: null});
    }

    handleShowFuConfirmationDialog = (contact) => {
        console.log("CONTACT: ", contact);
        this.setState({showFuConfirmationDialog: true, selectedSales: contact}, () => {this.handleOpenChat()});
    }

    handleFu = () => {
        
    }

    handleOpenChat = () => {
        
        this.setState({currentConversation: this.state.selectedSales.contactFormatted});
        this.handleCloseFuConfirmationDialog();
        return;
        let data = {
            salesId: Global.get().user.id,
            waNumber: this.state.selectedSales.from
        }
        
        const encode = base64.encode(JSON.stringify(data));
        console.log("dataParams : ", data, encode);

        var url = Api.getLotusUrl() + "?fu=" + encode;
        var win = window.open(url, '_blank');
        win.focus();

    }

    handleCloseChat = () => {
        this.setState({currentConversation: null});
    }

    // handleDateChanged = (rangeDate) => {

    //     let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
    //     let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
    //     console.log("START : ", startDate, ", END : ", endDate);
  
    //     this.setState({ startDate: startDate, endDate:  endDate}, () => {
    //         this.getMyCustomerToday();
    //         this.getSalesTeleUsers();
    //     });
    // }

    handleHasilFuChanged = (event, contact) => {
        let obEdit = this.state.obEditHasilFu;
        obEdit.lastFuResultTmp = event.target.value;
        this.setState({obEditHasilFu: obEdit});       
    }

    handleProductCategoryChange = (event, contact) => {
        const myCustomers = [...this.state.listMycustomer];
        let result = myCustomers.filter((cust) => cust.id===contact.id); 

        if(result.length > 0){
            result[0].productCategory = event.target.value;
            // console.log("EDITCHANGEDHasil", result[0]);
        }

        this.setState({ listMycustomer: myCustomers});        
    }

    handleJumlahFuChange = (event, contact) => {
        let obEdit = this.state.obEditHasilFu;
        obEdit.countFuTmp = event.target.value;
        this.setState({obEditHasilFu: obEdit});
    }

    handleTotalOrderChange = (event, contact) => {
        const myCustomers = [...this.state.listMycustomer];
        let result = myCustomers.filter((cust) => cust.id===contact.id); 

        if(result.length > 0){
            result[0].totalOrder = event.target.value;
            console.log("EDITCHANGEDCount", result[0]);
        }

        this.setState({ listMycustomer: myCustomers});        
    }

    render() { 
        return ( 
            <div className="container-fluid" style={{paddingLeft: "20px", paddingRight: "20px"}}>
                {/* <h3>Follow Up Rutin</h3> */}
                <div className="container-fluid">

                    <div className="row"  style={{marginTop: 0, marginBottom: 10}}>
                        <div className="col-9 text-right">
                            {/* <TextField
                                onChange={this.handleKeyword}
                                label="Ketik untuk mencari sales / nama customer / nomor wa / kategori"
                                type="text"
                                className="form-control"
                                id="namacustomer"
                                variant="outlined"
                                onKeyDown={this.onKeyDownHandler}
                                onBlur={() => {
                                    
                                }}
                            /> */}
                            <strong>Tanggal Leads</strong>
                        </div>
                        <div className="col-2">
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                            </div>
                        
                        {/* <div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
                            <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                        </div> */}
                        <div className="col-1 text-right">
                            <Button disabled={this.state.isLoading} size='small' variant='contained' color='primary' onClick={() => {this.getMyCustomerToday(true)}}><Refresh style={{width: "20px"}} /> Reload</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {
                                this.state.isLoading && (<LinearProgress />)
                            }
                        </div>
                    </div>
                    <br />

                    <Dialog open={this.state.showEditAnalisa} onClose={this.handleCloseEditAnalisa}>
                        <DialogContent>
                            <Paper className="" style={{marginTop: "10px"}}>
                                <div className="" style={{marginBottom: "20px", marginTop: "10px"}}>
                                    <div className="card-header bg-info">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                    <strong>Nama</strong>: {this.state.obEditHasilFu?.fromName}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                <strong>No WA</strong> : {Global.maskPhoneNumber(this.state.obEditHasilFu?.from)}
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                    <strong>Kategori</strong> : {this.state.obEditHasilFu?.productCategory}
                                                </div>
                                            </div>
                                            <div className="col-1">
                                            <button onClick={this.handleCloseEditAnalisa} type="button" class="close" aria-label="Close">
                                                <span aria-hidden="true" className="text-white">&times;</span>
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        
                                        <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                            <div className="col-12 bg-white">
                                                <FormControl variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                                                    <InputLabel id="demo-simple-select-outlined-label">Hasil Follow Up</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.obEditHasilFu?.lastFuResultTmp === undefined | this.state.obEditHasilFu?.lastFuResultTmp === null ? "" : this.state.obEditHasilFu?.lastFuResultTmp}
                                                        onChange={this.handleHasilFuChanged}
                                                        label="Hasil Follow Up"
                                                    >
                                                        {
                                                        Global.getListHasilFu().map(
                                                            (cat) => {
                                                            return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                                            } 
                                                        )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 bg-white">
                                                <FormControl variant="outlined" style={{width: '100%', marginTop: 15}}>
                                                    <InputLabel id="demo-simple-select-outlined-label">Jumlah FU</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="jumlahFu"
                                                        value={this.state.obEditHasilFu?.countFuTmp !== undefined && this.state.obEditHasilFu?.countFuTmp !== null ? this.state.obEditHasilFu?.countFuTmp : 0}
                                                        onChange={(event) => this.handleJumlahFuChange(event, this.state.obEditHasilFu)}
                                                        label="Jumlah FU"
                                                    >
                                                        {
                                                        [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map(
                                                            (jumlahFu) => {
                                                            return (<MenuItem key={jumlahFu} value={jumlahFu}>{jumlahFu}</MenuItem>);
                                                            } 
                                                        )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            
                                        </div>
                                        <br />
                                    </div>
                                    <div className="card-footer bg-info">
                                        <div className="row">
                                            <div className="col-7"></div>
                                            <div className="col-5 text-right">
                                                {
                                                    this.state.savingAnalisa ? 
                                                    (
                                                        <div className="alert alert-danger">
                                                            <div className="row">
                                                                <div className="col-2">
                                                                    <ReactLoading color="#FF0000" width="30px" height="30px" type="spin"/>
                                                                </div>
                                                                <div className="col-10">
                                                                    loading ...
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : 
                                                    (
                                                        <div onClick={this.saveAnalisa} className="btn btn-warning btn-block">Simpan Hasil FU</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </Paper>
                        </DialogContent>
                    </Dialog>
                    
                    {/* <Paper className="root"> */}

                        <div className="row">
                            <div className={this.state.currentConversation !== null ? "col-8" : "col-12"}>
                                <Paper className="root">
                                    <TableContainer style={{maxHeight: "80vh", overflow: "scroll"}}>
                                        <Table aria-label="sticky table" size='small'>
                                            <TableHead>
                                                <TableRow>
                                                {this.columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                    <strong>{column.label}</strong>
                                                    </TableCell>
                                                ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/* {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => { */}
                                                {this.state.listMycustomer.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        {/* <TableCell align={'left'}>{row.noUrut}</TableCell> */}
                                                        <TableCell align={'left'}>{row.fromName}</TableCell>
                                                        <TableCell align={'left'}>{row.from}</TableCell>
                                                        <TableCell align={'left'}>{Global.formatDateTimeToStrDateTime24(row.lastFuDate)}</TableCell>
                                                        <TableCell align={'left'}>{row.productCategory}</TableCell>
                                                        <TableCell align={'left'}>{row.lastFuResultTmp}</TableCell>
                                                        <TableCell align={'left'}>{row.countFuTmp}</TableCell>
                                                        <TableCell align={'left'} width={"150px"}>
                                                            {
                                                                (
                                                                    <div align={"center"}>
                                                                        <ToolTip title="Simpan">
                                                                            <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleShowEditAnalisa(row);}}  />
                                                                        </ToolTip>
                                                                        &nbsp;
                                                                        <ToolTip title="Follow Up">
                                                                            <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowFuConfirmationDialog(row)}}  />
                                                                        </ToolTip>
                                                                    </div>
                                                                )
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            this.state.isLoading && (<LinearProgress />)
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <div style={{marginTop: 10, marginLeft: 10}}>
                                            <Pagination 
                                                count={this.state.pageCount} 
                                                page={this.state.page}
                                                rowsPerPage={this.state.rowsPerPage}
                                                showFirstButton 
                                                showLastButton 
                                                onChange={this.handleChangePage}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4 text-right" style={{paddingTop: "10px", paddingRight: "30px"}}>
                                            <strong>Total : </strong>{this.state.recordCount.toLocaleString("id-ID")}
                                    </div>
                                </div>
                            </div>
                            <div className="col-4" style={{maxHeight: "60vh", overflowY: "none"}}>
                                {
                                this.state.currentConversation !== null ? (
                                    <div className='text-right'>
                                    <div className="btn btn-block btn-danger" onClick={() => {this.handleShowChat(null)}}>Close Chat</div>
                                    </div>
                                ) : (<></>)
                                }
                                {
                                this.state.currentConversation !== null ? (
                                    <Paper className='root'>
                                        <MessageList
                                            onUpdateReadCounter = {()=>{}}
                                            onNewIncomingMessage={()=>{}}
                                            onUpdateConversations={()=>{}}
                                            onUpdateCsHelpState={()=>{}}
                                            currentConversation={this.state.currentConversation}
                                            onUpdateContactLabelState={()=>{}}
                                            onClearCurrentConversation = {()=>{}}
                                            onUpdateSlow={()=>{}}
                                            onUpdateJustRead={()=>{}}
                                            onUpdateUnfinishedChat={()=>{}}
                                            onResetUnreadCounter={()=>{}}
                                            conversationList={[]}
                                            logoutHandler={()=>{}}
                                            onSaveCustomerName={()=>{}}
                                            style={{maxHeight: "70vh", overflowY: "none"}}
                                        />
                                    </Paper>
                                    
                                ) : (<></>)
                                }
                          </div>
                        </div>
                    {/* </Paper> */}
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />

                <Modal
                    show={this.state.showFuConfirmationDialog}
                    onHide={this.handleCloseFuConfirmationDialog}
                    backdrop="static"
                    keyboard={false}
                >
                <Modal.Header closeButton>Konfirmasi</Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-4 text-right" style={{fontSize: "13px"}}>
                            <strong>Nama :</strong>
                        </div>
                        <div className="col-8 text-left" style={{fontSize: "13px"}}>
                            {this.state.selectedSales !== null ? this.state.selectedSales.fromName : ""}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 text-right" style={{fontSize: "13px"}}>
                            <strong>Nomor WhatsApp :</strong>
                        </div>
                        <div className="col-8 text-left" style={{fontSize: "13px"}}>
                            {this.state.selectedSales !== null ? Global.maskPhoneNumber(this.state.selectedSales.from) : ""}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span onClick={this.handleOpenChat} className="btn btn-primary">
                    Follow Up Sekarang
                    </span>
                    <span
                    onClick={this.handleCloseFuConfirmationDialog}
                    className="btn btn-warning"
                    >
                    Batal
                    </span>
                </Modal.Footer>
                </Modal>
                
            </div>
        );
    }
}
 
export default LeadsParcel;