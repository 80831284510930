import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';
import Global from "../../../customlib/Global";

class DetailClosingAndAvgResponseBySales extends Component {
    state = { 
        leadsData: null,
        performaSalesByLabel: [],
        summary : {
          leads: 0,
          closing: 0,
          persenClosing: 0,
          avgResponseTime: "00:00",
          avgClosingTime: "00:00"
        },
        isLoading: false
    } 

    calculate = () => {

        if(this.state.leadsData === null){
            console.log("Leada data null");
            return;
        }

        var tmpContactLeadsHourly = [
            {
                id: "24000300",
                label: "24:00 - 03:00",//2400 - 2700 am
                count: 0,
                detail: []
            },
            {
                id: "03010600",
                label: "03:01 - 06:00",//2701 - 3000 am
                count: 0,
                detail: []
            },
            {
                id: "06010900",
                label: "06:01 - 09:00",//3001 - 3300 am
                count: 0,
                detail: []
            },
            {
                id: "09011200",
                label: "09:01 - 12:00",//3301 - 3600 am
                count: 0,
                detail: []
            },
            {
                id: "12011600",
                label: "12:01 - 16:00",//3601 - 4000 pm
                count: 0,
                detail: []
            },
            {
                id: "16011800",
                label: "16:01 - 18:00",//4001 - 4200 pm
                count: 0,
                detail: []
            },
            {
                id: "18012100",
                label: "18:01 - 21:00",//4201 - 4500 pm
                count: 0,
                detail: []
            },
            {
                id: "21012359",
                label: "21:01 - 23:59",//4501 - 4759 pm
                count: 0,
                detail: []
            },
        ]

        let excludeLabelRandom = this.state.leadsData.leads.filter((item) => item.label !== Global.labelRandomLeads());

        excludeLabelRandom.forEach(contact => {

            var originalTime = Global.formatDateTimeToStrFormat(contact.created_at,"hh:mm A");
            var convertedTime = Global.convertTime12to24(originalTime);

            var timeValue = parseInt(convertedTime.replaceAll(":", ""));

            console.log("TIMEVALUE", timeValue);

            if(timeValue <= 2359){
                timeValue += 2400;
            }
            
            if(timeValue >= 2400 && timeValue <= 2700){
                tmpContactLeadsHourly[0].count += 1;
                tmpContactLeadsHourly[0].detail.push(contact);
            }else if(timeValue >= 2701  && timeValue <= 3000){
                tmpContactLeadsHourly[1].count += 1;
                tmpContactLeadsHourly[1].detail.push(contact);
            }else if(timeValue >= 3001 && timeValue <= 3300){
                tmpContactLeadsHourly[2].count += 1;
                tmpContactLeadsHourly[2].detail.push(contact);
            }else if(timeValue >= 3301 && timeValue <= 3600){
                tmpContactLeadsHourly[3].count += 1;
                tmpContactLeadsHourly[3].detail.push(contact);
            }else if(timeValue >= 3601 && timeValue <= 4000){
                tmpContactLeadsHourly[4].count += 1;
                tmpContactLeadsHourly[4].detail.push(contact);
            }else if(timeValue >= 4001 && timeValue <= 4200){
                tmpContactLeadsHourly[5].count += 1;
                tmpContactLeadsHourly[5].detail.push(contact);
            }else if(timeValue >= 4201 && timeValue <= 4500){
                tmpContactLeadsHourly[6].count += 1;
                tmpContactLeadsHourly[6].detail.push(contact);
            }else if(timeValue >= 4501 && timeValue <= 4759){
                tmpContactLeadsHourly[7].count += 1;
                tmpContactLeadsHourly[7].detail.push(contact);
            }
            
        });

        var performaSalesByLabel = [];
            var total = {
              leads: 0,
              closing: 0,
              persenClosing: 0,
              totalLessOneMinute: 0,
              totalMoreOneMinute: 0,
              avgResponseTime: 0,
              avgClosingTime: 0
            };

        tmpContactLeadsHourly.forEach(lead => {

            let leads = lead.detail;

            let jumlahLeads = leads.length;
            // let totalClosing = lead.leads.filter(itemLead => itemLead.label === Global.labelUnpaid() || itemLead.label === Global.labelPaid() || itemLead.label === Global.labelDp()).length;
            let totalClosing = lead.detail.filter(itemLead => itemLead.isClosing === true).length;
            let persenClosing = totalClosing > 0 ? (totalClosing/jumlahLeads*100).toFixed(1) : 0;
            let avgResponseTime = 0;
            let avgClosingTime = 0;

            total.leads += jumlahLeads;
            total.closing += totalClosing;

            var performaSalesByLabelItem = {
                salesName: lead.label,
                jumlahLeads: jumlahLeads,
                totalClosing: totalClosing,
                persenClosing: persenClosing,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00"
            };

            performaSalesByLabel.push(performaSalesByLabelItem);

            let totalResponseTime = 0;
            let totalClosingTime = 0;
            leads.forEach(item => {
                var delta = 0;
                if(item.closingDeltaTime !== undefined && item.closingDeltaTime !== null){
                    totalClosingTime += item.closingDeltaTime;
                }
                if(item.avgResponseTime != null){
                    // var firstMessageTime = moment(item.createdAt).tz("asia/jakarta"); //todays date
                    // var firstResponseTime = moment(item.firstResponse).tz("asia/jakarta"); // another date
                    
                    // var duration = moment.duration(firstResponseTime.diff(firstMessageTime));
                    delta = item.avgResponseTime;
                    totalResponseTime += delta < 0 ? 0 : delta;
                }else{
                    delta = 24 * 3600;
                }
                item.responseTime = delta;
                if(delta <= 60){
                    performaSalesByLabelItem.totalLessOneMinute += 1;
                }else{
                    performaSalesByLabelItem.totalMoreOneMinute += 1;
                }
            });

            avgResponseTime = totalResponseTime > 0 ? totalResponseTime/jumlahLeads : 0;
            performaSalesByLabelItem.avgResponseTime = Global.formatTime(Math.round(avgResponseTime));
            total.avgResponseTime += avgResponseTime;
            
            avgClosingTime = totalClosingTime > 0 ? totalClosingTime/totalClosing : 0;
            performaSalesByLabelItem.avgClosingTime = Global.formatTime(Math.round(avgClosingTime));
            total.avgClosingTime += avgClosingTime;

            total.totalLessOneMinute += performaSalesByLabelItem.totalLessOneMinute;
            total.totalMoreOneMinute += performaSalesByLabelItem.totalMoreOneMinute;

        });

        total.persenClosing = (total.closing/total.leads*100).toFixed(2);
        total.avgResponseTime = Global.formatTime(Math.round(total.avgResponseTime/total.leads));
        total.avgClosingTime = Global.formatTime(Math.round(total.avgClosingTime/total.closing));

        this.setState({contactLeads: tmpContactLeadsHourly, performaSalesByLabel: performaSalesByLabel, summary: total, isLoading: false}, () => {
            console.log("PERFORMA : ", this.state.performaSalesByLabel, this.state.summary);
        });
        console.log("RESULT", tmpContactLeadsHourly);

    }

    componentDidMount() {
        this.setState({leadsData: this.props.leadsData}, () => {
            this.calculate();
        });
    }

    render() { 
        return (<div className='rounded' style={{padding: 20, backgroundColor: "#e6e9ed"}}>
            <div className="row">
                <div className="col-11 text-center" style={{paddingTop: 10}}>
                 <strong>Detail Closing & Avg Response Time</strong> 
                 {this.state.leadsData && <h4>{this.state.leadsData.salesName}</h4>}
                </div>
                <div className="col-1 text-right">
                    <div className="btn btn-danger" onClick={this.props.onClose} >X</div>
                </div>
            </div>
            <br />
                <Paper className="root">
                    <TableContainer>
                        <Table aria-label="sticky table" size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell key={"head2"} align={"center"}>
                                        <strong>Time Section</strong>
                                    </TableCell>
                                    <TableCell key={"head2"} align={"center"}>
                                        <strong>Jumlah Leads</strong>
                                    </TableCell>
                                    <TableCell key={"head3"} align={"center"}>
                                        <strong>Closing</strong>
                                    </TableCell>
                                    <TableCell key={"head4"} align={"center"}>
                                        <strong>% Closing</strong>
                                    </TableCell>
                                    <TableCell key={"head5"} align={"center"}>
                                        <strong>&lt; 1 Minute</strong>
                                    </TableCell>
                                    <TableCell key={"head6"} align={"center"}>
                                        <strong>&gt; 1 minute</strong>
                                    </TableCell>
                                    <TableCell key={"head7"} align={"center"}>
                                        <strong>Avg Response Time</strong>
                                    </TableCell>
                                    <TableCell key={"head8"} align={"center"}>
                                        <strong>Avg Time Closing</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.performaSalesByLabel.map((row) => {
                                return (
                                    <TableRow>
                                        <TableCell align='center'>{row.salesName}</TableCell>
                                        <TableCell align='center'>{row.jumlahLeads}</TableCell>
                                        <TableCell align='center'>{row.totalClosing}</TableCell>
                                        <TableCell align='center'>{row.persenClosing}</TableCell>
                                        <TableCell align='center'>{row.totalLessOneMinute}</TableCell>
                                        <TableCell align='center'>{row.totalMoreOneMinute}</TableCell>
                                        <TableCell align='center'>{row.avgResponseTime}</TableCell>
                                        <TableCell align='center'>{row.avgClosingTime}</TableCell>
                                    </TableRow>
                                );
                                })}
                                <TableRow>
                                    <TableCell align='center'><strong>Summary</strong></TableCell>
                                    <TableCell align='center'><strong>{this.state.summary.leads}</strong></TableCell>
                                    <TableCell align='center'><strong>{this.state.summary.closing}</strong></TableCell>
                                    <TableCell align='center'><strong>{this.state.summary.persenClosing}</strong></TableCell>
                                    <TableCell align='center'><strong>{this.state.summary.totalLessOneMinute}</strong></TableCell>
                                    <TableCell align='center'><strong>{this.state.summary.totalMoreOneMinute}</strong></TableCell>
                                    <TableCell align='center'><strong>{this.state.summary.avgResponseTime}</strong></TableCell>
                                    <TableCell align='center'><strong>{this.state.summary.avgClosingTime}</strong></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
        </div>);
    }
}
 
export default DetailClosingAndAvgResponseBySales;