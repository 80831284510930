import { Alert, Button, Container, Menu, MenuItem, Popover } from '@mui/material';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import momen from "moment";
import axios from 'axios';

class TopAlert extends Component {
    state = { 
        anchorEl: null,
        isMenuOpen: false,
        appNumber: Global.getAppProfile().waNumber
     } 

    handleClick = (event) => {
        console.log(event);
        this.setState({anchorEl: event.target, isMenuOpen: true});
    };
    handleClose = (messageText) => {
        this.setState({anchorEl: null, isMenuOpen: false});
        // this.props.onOpenCreateOrder(messageText);
    };

    getContactInfo = (waNumber) => {
        console.log("INIPROPS", this.props)
        let params = {
            waNumber: waNumber
        } 
        axios
        .post(Api.getContactInfo(), params, Api.getRequestHeader())
        .then((response) => {
            let responseData = response.data;
            if(responseData.success === true){
                let contact = this.props.generateItemConversation(responseData.data);
                this.props.handleConversationSelected(contact);
            }
        })
        .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
        });
    }

    render() { 
        return (
            <div>
                <Alert variant="filled" severity="warning" action={
                    <Button variant='outlined' size="small" onClick={this.handleClick}>
                        Cek sekarang!
                    </Button>
                }>
                    Ada {this.props.listPesanBelumDibalas.length} customer yang pesannya belum kamu balas!!!
                </Alert>
                <Menu
                    id={"menu-pesan-belum-dibalas"}
                    anchorEl={this.state.anchorEl}
                    open={this.state.isMenuOpen}
                    onClose={this.handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        this.props.listPesanBelumDibalas?.map(item => {
                            return <MenuItem disabled={this.state.appNumber !== item.to} onClick={() => this.getContactInfo(item.from)}>
                                <Container className='border rounded border-warning' style={{backgroundColor: "#fef8e5"}}>
                                    <small><strong>{item.fromName}</strong></small>
                                    <div style={{display: "flex"}}>
                                        <small style={{flex: 1}}>{Global.maskPhoneNumber(item.from)}</small>
                                        <div style={{width: "20px"}}></div>
                                        <div className='text-right'>
                                            <small style={{flex: 1}}><strong>{item.aplikasi} {item.isCsCustomer ? "(CS)" : ""}</strong></small>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {/* <small><strong>Last Message : </strong></small> */}
                                        <div className="col-6">
                                            <span className={Global.getLabelClasses(item.label)}>{item.label}</span>
                                        </div>
                                        <div className="col-6 text-right">
                                            <small style={{flex: 1}}>{Global.formatDateTimeToStrDateTime24(item.lastMessageReceivedAt, true)}</small>
                                        </div>
                                        
                                        
                                    </div>
                                </Container>
                            </MenuItem>
                        })
                    }
                </Menu>
                {/* <Popover 
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 50, left: 400 }}
                    open={true}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    >
                    The content of the Popover.
                </Popover> */}
            </div>
            
        );
    }
}
 
export default TopAlert;