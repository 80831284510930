import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"

import { makeStyles } from '@mui/styles';
import {Box, CircularProgress, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import DateRangePicker from '../../customlib/DateRangePicker';

class SoldProductCategories extends Component {
    state = { 
        contactLeads : [],
        contactLeadsDisplay : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        isTeleReport: true,
        cityList: [],
        selectedCity: "",
        totalSumProductCategories: 0,
        isLoading: false,
        closingByProductCategory: [],
        contactLeadsHourly: []
    }
    
    componentDidMount() {
        this.getSummaryReport();
        this.getContactLeadsReport();
    }

    getSummaryReport = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      var params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
        userId: userData.user.id,
        reportType: "TYPE_BY_SOLD_PRODUCT_CATEGORY"
      };

      console.log("PARAMS", params);
      this.setState({contactLeads: [], contactLeadsDisplay: [], totalSumProductCategories: 0, isLoading: true});

      axios
        .post(Api.getLeadsReportSummary(), params, Api.getRequestHeader())
        .then((response) => {
			console.log("SOLDCATEGPRIES : ", response.data);
			console.log("USERDATA : ", "wkwkwk");
			
			var tmpContactLeads = response.data.data;
			var sum = 0;

			let formattedData = [
        {
          id: "duka",
          product_category: "Duka",
          count_product_category: 0,
          leads: []
        },
        {
          id: "congratulation",
          product_category: "Congratulations",
          count_product_category: 0,
          leads: []
        },
        {
          id: "wedding",
          product_category: "Wedding",
          count_product_category: 0,
          leads: []
        },
        {
          id: "parcel",
          product_category: "Parcel",
          count_product_category: 0,
          leads: []
        },
        {
          id: "handbucket",
          product_category: "Handbucket",
          count_product_category: 0,
          leads: []
        },
        {
          id: "other",
          product_category: "Other",
          count_product_category: 0,
          leads: []
        },
      ];

      let closingByProductCategory = [];
      tmpContactLeads.forEach(element => {
        let kategori = element.product_category.toLocaleLowerCase();

        let currentData = null;

        if(kategori.includes("bunga papan duka cita") || kategori.includes("standing flower duka cita")){//duka
          currentData = formattedData[0];
        }else if(kategori.includes("bunga papan congratulation") || kategori.includes("standing flower congratulation")){//congratulation
          currentData = formattedData[1];
        }else if(kategori.includes("bunga papan wedding") || kategori.includes("standing flower wedding")){//wedding
          currentData = formattedData[2];
        }else if(kategori.includes("parcel buah") || kategori.includes("parcel makanan") || kategori.includes("parcel pecah belah") || kategori.includes("parcel sembako") || kategori.includes("parcel cookies") || kategori.includes("hampers baby")){//parcel
          currentData = formattedData[3];
        }else if(kategori.includes("handbouquet") || kategori.includes("handbouquet and chocholate") || kategori.includes("handbouquet dan boneka")){//handbouquet
          currentData = formattedData[4];
        }else{//other
          currentData = formattedData[5];
        }

        currentData.count_product_category += element.count_product_category;
        element.leads.forEach(lead => currentData.leads.push(lead));

        // let findExistedCategory = closingByProductCategory.find(item => item.product_category === element.product_category);

        // if(findExistedCategory){
        //   findExistedCategory.leads_count += 1;
        //   if(element.isClosing){
        //     findExistedCategory.closing_count += 1;
        //   }
        // }else{
        //   closingByProductCategory.push({
        //     product_category: element.product_category,
        //     leads_count: 1,
        //     closing_count: element.isClosing ? 1 : 0
        //   });
        // }

        // currentData.leads.push(element.leads);

      });

			formattedData.forEach(element => {

				sum += element.count_product_category;
        element.totalClosing = element.leads.filter(itemLead => [Global.labelPaid(), Global.labelUnpaid(), Global.labelDp()].includes(itemLead.label)).length;

				let arrByTime = [
					{
						id: "24000300",
						label: "24:00 - 03:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "03010600",
						label: "03:01 - 06:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "06010900",
						label: "06:01 - 09:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "09011200",
						label: "09:01 - 12:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "12011600",
						label: "12:01 - 16:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "16011800",
						label: "16:01 - 18:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "18012100",
						label: "18:01 - 21:00",//4501 - 4759 pm
						count: 0
					},
					{
						id: "21012359",
						label: "21:01 - 23:59",//4501 - 4759 pm
						count: 0
					},
				];

        let closingBycategory = 0;
				element.leads.forEach(item => {
					var originalTime = Global.formatDateTimeToStrFormat(item.created_at, "hh:mm A");
          var convertedTime = Global.convertTime12to24(originalTime);
          var timeValue = parseInt(convertedTime.replaceAll(":", ""));

					if(timeValue <= 2359){
						timeValue += 2400;
					}

          if(["PAID", "UNPAID", "DP"].includes(item.label)){
            closingBycategory += 1;
          }

					console.log("TIMEVALUE", originalTime, timeValue);
					
					if(timeValue >= 2400 && timeValue <= 2700){//24:00 - 03:00
						arrByTime[0].count += 1;
						arrByTime[0].timeValue = timeValue;
					}else if(timeValue >= 2701 && timeValue <= 3000){//03:01 - 06:00
						arrByTime[1].count += 1;
					}else if(timeValue >= 3001 && timeValue <= 3300){//06:01 - 09:00
						arrByTime[2].count += 1;
					}else if(timeValue >= 3301 && timeValue <= 3600){//09:01 - 12:00
						arrByTime[3].count += 1;
					}else if(timeValue >= 3601 && timeValue <= 4000){//12:01 - 16:00
						arrByTime[4].count += 1;
					}else if(timeValue >= 4001 && timeValue <= 4300){//16:01 - 18:00
						arrByTime[5].count += 1;
					}else if(timeValue >= 4301 && timeValue <= 4600){//18:01 - 21:00
						arrByTime[6].count += 1;
					}else if(timeValue >= 4601 && timeValue <= 4899){
						arrByTime[7].count += 1;
					}
				});

				element.byHours = arrByTime;
        element.totalClosing = closingBycategory;

			});

      formattedData.sort((a,b) => b.count_product_category-a.count_product_category);

			this.setState({contactLeads: formattedData, contactLeadsDisplay: formattedData, totalSumProductCategories: sum, isLoading: false});
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    getContactLeadsReport = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      const params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
      }

      this.setState({closingByProductCategory: []}, () => {
        // console.log("ADDITIONAL_DATA : ", this.state.additionalData);
      });

      axios
        .post(Api.getContactLeads(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONTACTLEADS : ", response.data);
          console.log("USERDATA : ", "wkwkwk");
          
          var tmpContactLeads = response.data.data;
          
          if(userData.admin === false){
              tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
          }

          var tmpContactLeadsHourly = [
            {
              id: "24000300",
              label: "Shift 3",
              timeSection: "24:00 - 03:00",//2400 - 2700 am
              countLeadsPapan: 0,
              countLeadsNonPapan: 0,
              countClosingPapan: 0,
              countClosingNonPapan: 0,
              detail: []
            },
            {
              id: "03010600",
              label: "Shift 3",//2701 - 3000 am
              timeSection: "03:01 - 06:00",//2701 - 3000 am
              countLeadsPapan: 0,
              countLeadsNonPapan: 0,
              countClosingPapan: 0,
              countClosingNonPapan: 0,
              detail: []
            },
            {
              id: "06010900",
              label: "Shift 1",
              timeSection: "06:01 - 09:00",//3001 - 3300 am
              countLeadsPapan: 0,
              countLeadsNonPapan: 0,
              countClosingPapan: 0,
              countClosingNonPapan: 0,
              detail: []
            },
            {
              id: "09011200",
              label: "Shift 1",
              timeSection: "09:01 - 12:00",//3301 - 3600 am
              countLeadsPapan: 0,
              countLeadsNonPapan: 0,
              countClosingPapan: 0,
              countClosingNonPapan: 0,
              detail: []
            },
            {
              id: "12011600",
              label: "Shift 1 & 2",
              timeSection: "12:01 - 16:00",//3601 - 4000 pm
              countLeadsPapan: 0,
              countLeadsNonPapan: 0,
              countClosingPapan: 0,
              countClosingNonPapan: 0,
              detail: []
            },
            {
              id: "16011800",
              label: "Shift 2",
              timeSection: "16:01 - 18:00",//4001 - 4200 pm
              countLeadsPapan: 0,
              countLeadsNonPapan: 0,
              countClosingPapan: 0,
              countClosingNonPapan: 0,
              detail: []
            },
            {
              id: "18012100",
              label: "Shift 2",
              timeSection: "18:01 - 21:00",//4201 - 4500 pm
              countLeadsPapan: 0,
              countLeadsNonPapan: 0,
              countClosingPapan: 0,
              countClosingNonPapan: 0,
              detail: []
            },
            {
              id: "21012359",
              label: "Shift 2 & 3",
              timeSection: "21:01 - 23:59",//4501 - 4759 pm
              countLeadsPapan: 0,
              countLeadsNonPapan: 0,
              countClosingPapan: 0,
              countClosingNonPapan: 0,
              detail: []
            },
          ]
          
          let closingByProductCategory = [];
          tmpContactLeads.forEach(lead => {
              lead.leads.forEach(contact => {
                
                let category = contact.productCategory;

                if(category !== undefined && category !== null && category.length > 0){
                  let findExistedCategory = closingByProductCategory.find(item => item.product_category === contact.productCategory);

                  if(findExistedCategory){
                    findExistedCategory.leads_count += 1;
                    if(contact.isClosing){
                      findExistedCategory.closing_count += 1;
                    }
                  }else{
                    closingByProductCategory.push({
                      product_category: contact.productCategory,
                      leads_count: 1,
                      closing_count: contact.isClosing ? 1 : 0
                    });
                  }
                }

                var originalTime = Global.formatDateTimeToStrFormat(contact.createdAt, "hh:mm A");
                var convertedTime = Global.convertTime12to24(originalTime);

                var timeValue = parseInt(convertedTime.replaceAll(":", ""));

                console.log("TIMEVALUE", timeValue);

                if(timeValue <= 2359){
                  timeValue += 2400;
                }

                
                let kategori = contact.productCategory ? contact.productCategory.toLocaleLowerCase() : "-";
                let listKategoriBungaPapan = ["Bunga Papan Duka Cita".toLocaleLowerCase(), "Bunga Papan Congratulation".toLocaleLowerCase(), "Bunga Papan Wedding".toLocaleLowerCase()];

                let isBungaPapan = listKategoriBungaPapan.includes(kategori);

                if(timeValue >= 2400 && timeValue <= 2700){
                  tmpContactLeadsHourly[0].countLeadsPapan +=  isBungaPapan ? 1 : 0;
                  tmpContactLeadsHourly[0].countLeadsNonPapan += isBungaPapan ? 0 : 1;
                  tmpContactLeadsHourly[0].countClosingPapan += isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[0].countClosingNonPapan += !isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[0].detail.push(contact);
                }else if(timeValue >= 2701  && timeValue <= 3000){
                  tmpContactLeadsHourly[1].countLeadsPapan +=  isBungaPapan ? 1 : 0;
                  tmpContactLeadsHourly[1].countLeadsNonPapan += isBungaPapan ? 0 : 1;
                  tmpContactLeadsHourly[1].countClosingPapan += isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[1].countClosingNonPapan += !isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[1].detail.push(contact);
                }else if(timeValue >= 3001 && timeValue <= 3300){
                  tmpContactLeadsHourly[2].countLeadsPapan +=  isBungaPapan ? 1 : 0;
                  tmpContactLeadsHourly[2].countLeadsNonPapan += isBungaPapan ? 0 : 1;
                  tmpContactLeadsHourly[2].countClosingPapan += isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[2].countClosingNonPapan += !isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[2].detail.push(contact);
                }else if(timeValue >= 3301 && timeValue <= 3600){
                  tmpContactLeadsHourly[3].countLeadsPapan +=  isBungaPapan ? 1 : 0;
                  tmpContactLeadsHourly[3].countLeadsNonPapan += isBungaPapan ? 0 : 1;
                  tmpContactLeadsHourly[3].countClosingPapan += isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[3].countClosingNonPapan += !isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[3].detail.push(contact);
                }else if(timeValue >= 3601 && timeValue <= 4000){
                  tmpContactLeadsHourly[4].countLeadsPapan +=  isBungaPapan ? 1 : 0;
                  tmpContactLeadsHourly[4].countLeadsNonPapan += isBungaPapan ? 0 : 1;
                  tmpContactLeadsHourly[4].countClosingPapan += isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[4].countClosingNonPapan += !isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[4].detail.push(contact);
                }else if(timeValue >= 4001 && timeValue <= 4200){
                  tmpContactLeadsHourly[5].countLeadsPapan +=  isBungaPapan ? 1 : 0;
                  tmpContactLeadsHourly[5].countLeadsNonPapan += isBungaPapan ? 0 : 1;
                  tmpContactLeadsHourly[5].countClosingPapan += isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[5].countClosingNonPapan += !isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[5].detail.push(contact);
                }else if(timeValue >= 4201 && timeValue <= 4500){
                  tmpContactLeadsHourly[6].countLeadsPapan +=  isBungaPapan ? 1 : 0;
                  tmpContactLeadsHourly[6].countLeadsNonPapan += isBungaPapan ? 0 : 1;
                  tmpContactLeadsHourly[6].countClosingPapan += isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[6].countClosingNonPapan += !isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[6].detail.push(contact);
                }else if(timeValue >= 4501 && timeValue <= 4759){
                  tmpContactLeadsHourly[7].countLeadsPapan +=  isBungaPapan ? 1 : 0;
                  tmpContactLeadsHourly[7].countLeadsNonPapan += isBungaPapan ? 0 : 1;
                  tmpContactLeadsHourly[7].countClosingPapan += isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[7].countClosingNonPapan += !isBungaPapan && contact.isClosing ? 1 : 0;
                  tmpContactLeadsHourly[7].detail.push(contact);
                }
                
                
              });
          });

          this.setState({closingByProductCategory: closingByProductCategory, contactLeadsHourly: tmpContactLeadsHourly}, () => {
            // console.log("ADDITIONAL_DATA : ", this.state.additionalData);
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getSummaryReport);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getSummaryReport);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => {
        this.getSummaryReport();
        this.getContactLeadsReport();
      });
    }

    columns = [
        { id: 'product_category', label: 'Kategori Produk', minWidth: 170 },
        { id: 'count_product_category', label: 'Jumlah', minWidth: 100 },
        { id: 'totalClosing', label: 'Closing', minWidth: 100 },
        { id: 'percentage', label: '% Closing', minWidth: 100 }
    ];

    render() { 
        return ( 
          <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>
                <div className="container-fluid">
                  <div className="panel panel-default">
                      <div className="panel-heading">
                          <div className="panel-title">
                              {/* <h1>Setup Contact</h1> */}
                          </div>
                      </div>
                      <div className="panel-body">
                          <div className="row align-items-center">
                              <div className="col-3">
                                  <strong>Total {this.state.contactLeadsDisplay.length} Categories</strong>
                              </div>
                              <div className="col-3">
                              </div>
                              <div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                              </div>
                              <div className="col-1">
                              {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getSummaryReport()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                            </div>
                          </div>
                          <br />
                          <Paper className='root text-center' style={{padding: "10px"}}>
                            <h4>Sold Categories</h4>
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell width={"20px"} />
                                  {this.columns.map((column) => (
                                      <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{ minWidth: column.minWidth }}
                                      >
                                      {column.label}
                                      </TableCell>
                                  ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                      {this.state.contactLeadsDisplay.map((row) => {
                                        return (<Row row={row} totalSumProductCategories={this.state.totalSumProductCategories} />);
                                      })}
                                  </TableBody>
                                </Table>
                            </TableContainer>
                          </Paper>
                          <br />
                          <Paper className="root text-center" style={{padding: "10px"}}>
                            <h4>Leads By Product Categories By Time Sections</h4>
                              <TableContainer>
                                  <Table aria-label="sticky table" size='small'>
                                  <TableHead>
                                      <TableRow>
                                        <TableCell>Shift</TableCell>
                                        <TableCell>Time Section</TableCell>
                                        <TableCell>Jumlah Leads Papan</TableCell>
                                        <TableCell>Jumlah Leads Non Papan</TableCell>
                                        <TableCell>CR Leads Papan</TableCell>
                                        <TableCell>CR Leads Non Papan</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  {this.state.contactLeadsHourly.map((column, index) => (
                                    <TableRow key={"rowPersection" + index}>
                                      <TableCell>{column.label}</TableCell>
                                      <TableCell key={column.id+"a"} align={column.align}>
                                        {column.timeSection}
                                      </TableCell>
                                      <TableCell key={column.id+"a"} align={column.align}>
                                        {column.countLeadsPapan}
                                      </TableCell>
                                      <TableCell key={column.id+"a"} align={column.align}>
                                        {column.countLeadsNonPapan}
                                      </TableCell>
                                      <TableCell key={column.id+"a"} align={column.align}>
                                      {column.countLeadsPapan > 0 ? (column.countClosingPapan/column.countLeadsPapan*100).toFixed(1) : 0}%
                                      </TableCell>
                                      <TableCell key={column.id+"a"} align={column.align}>
                                      {column.countLeadsNonPapan > 0 ? (column.countClosingNonPapan/column.countLeadsNonPapan*100).toFixed(1) : 0}%
                                      </TableCell>
                                    </TableRow>
                                        ))}
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                              
                          </Paper>
                          <br />
                          <Paper className='root text-center' style={{padding: "10px"}}>
                            <h4>Closing By product Categories</h4>
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Kategori Produk</TableCell>
                                    <TableCell align='right'>Jumlah Leads</TableCell>
                                    <TableCell align='right'>Closing</TableCell>
                                    <TableCell align='right'>% Closing</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                      {this.state.closingByProductCategory.map((row) => {
                                        return (<TableRow>
                                          <TableCell>{row.product_category}</TableCell>
                                          <TableCell align='right'>{row.leads_count}</TableCell>
                                          <TableCell align='right'>{row.closing_count}</TableCell>
                                          <TableCell align='right'>{row.leads_count > 0 ? (row.closing_count/row.leads_count*100).toFixed(2) : 0}%</TableCell>
                                        </TableRow>);
                                      })}
                                  </TableBody>
                                </Table>
                            </TableContainer>
                          </Paper>
                          
                      </div>
                      <div className="panel-footer">
                                      
                      </div>
                  </div>
                </div>
              </Paper>
         );
    }
}
 
export default SoldProductCategories;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props){
  const { row, totalSumProductCategories } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  // const classes = {root: {}};

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell width="20px">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.product_category}</TableCell>
        <TableCell>{row.count_product_category}</TableCell>
        <TableCell>{row.totalClosing}</TableCell>
        {/* <TableCell>{(row.count_product_category/totalSumProductCategories*100).toFixed(2)}%</TableCell> */}
        <TableCell>{row.count_product_category > 0 ? (row.totalClosing/row.count_product_category*100).toFixed(2) : 0}%</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Leads Detail
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Jam</TableCell>
                    <TableCell>Jumlah</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.byHours.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell align="left">{historyRow.label}</TableCell>
                      <TableCell align="left">{historyRow.count}</TableCell>
                      <TableCell align="left">{historyRow.timevalue}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}