import { TextField, Snackbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip as ToolTip } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import "./ContactsDashboard.css";
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Add, Search, Edit, Send} from '@mui/icons-material';
import AddContact from './AddContact';
import ContactEditChangeSales from './ContactEditChangeSales';


class ContactsSupplierDashboard extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 0,
        rowsPerPage: 10,
        showAddForm: false,
        keyword: "",
        appProfileId: ""
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleEditOnClick = (contactData) => {
        const myCustomers = [...this.state.listMycustomer];

        var findCustomer = myCustomers.filter(customer => customer.id === contactData.id);

        if(findCustomer.length > 0){
            findCustomer[0].isEditing = !findCustomer[0].isEditing;
            findCustomer[0].assignToUser = contactData.assignToUser;
            findCustomer[0].assignToUserName = contactData.assignToUserName;
        }

        this.setState({listMycustomer: myCustomers});

    }

    checkSupplierAvailability = (supplierData) => {

        console.log("BEFORE REPLACED", supplierData.phone);
        supplierData.phone = supplierData.phone.replaceAll("+", "");
        supplierData.phone = supplierData.phone.replaceAll("-", "");
        supplierData.phone = supplierData.phone.replaceAll(" ", "");

        const params = {
            purchasingId: Global.get().user.id,
            waNumber: supplierData.phone,
            appProfileId: Global.getAppProfile()._id
        };

        axios
        .post(Api.checkSupplierAvailability(), params, Api.getRequestHeader())
        .then((response) => {
            console.log("SUPPLIER_AVAILABILITY: ", response.data);

            let responseData = response.data;

            if(responseData.success === true){
                
                console.log("AFTER REPLACED", supplierData.phone);

                if(this.props.onCheckIfConversationExists(supplierData.phone) === false){
                    this.addContact(supplierData);
                }else{
                    this.props.onRefresh();
                }
            }else{
                this.handleShowSnackbar(responseData.message);
            }

            
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    }

    addContact = (supplierData) => {

        this.setState({isLoading: true}, () => {
            let userData = Global.get();
        
            const params = {
                contactNumber: supplierData.phone,
                contactName: supplierData.name,
                assignToUserId: Global.get().user.id,
                assignToUserName: Global.get().user.name,
                addedById: Global.get().user.id,
                addedByName: Global.get().user.name,
                appProfileId: Global.getAppProfile()._id
            };

            console.log(params);
            // return;
            axios
            .post(Api.addContact(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("RESPONSE ADD CONTACT: ", response.data);

                let responseData = response.data;

                if(responseData.success){
                    // this.props.onCloseForm();
                    this.props.onRefresh();
                }else{
                    this.handleShowSnackbar(responseData.message);
                    this.props.onRefresh();
                    // this.setState({isInputError: true, errorMessage: responseData.message});
                }

                // this.setState({listSales: response.data.data});
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
        });
        
    };

    getMyCustomerToday = (replaceAll) => {

        var userData = Global.get();
        const params = {
            userId: userData.user.id,
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            keywords: this.state.keyword,
            appProfileId: this.state.appProfileId
        }

        console.log("PARAMS", params, "replaceAll", replaceAll);

        axios
        // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
        .post(Api.supplierListPagedSearchable(), params, Api.getRequestHeader())
        .then((response) => {
            console.log("MyCustomerToday : ", response.data.data);

            if(replaceAll === true){

                var suppliers = response.data.data;
                suppliers.forEach(supplier => {
                    let newData =  {
                        photo: "https://picsum.photos/seed/picsum/200",
                        fromName: `${supplier.name}`,
                        text: supplier.lastMessage,
                        fromNumber: supplier.from,
                        contactId: supplier.id,
                        toNumber: supplier.to,
                        toName: supplier.toName,
                        selected: true,
                        unreadCounter: supplier.unreadCounter,
                        assignToUserName: supplier.assignToUserName
                      };
                    
                    // this.props.onClose();
                    supplier.action = 
                    <>
                        {
                            Global.get().admin === true ? 
                            (
                                <ToolTip title="Ubah sales">
                                    <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditOnClick(supplier);}}  />
                                </ToolTip>
                            ) : 
                            (<></>)
                        }
                        &nbsp;
                        <ToolTip title="Kirim Pesan">
                            <Send style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.checkSupplierAvailability(supplier);}}  />
                        </ToolTip>
                    </>;
                    supplier.isEditing = false;
                });

                this.setState({listMycustomer: suppliers});
            }else{
                let mySuppliers = [...this.state.listMycustomer];

                response.data.data.forEach(supplier => {
                    // console.log(element);
                    let newData =  {
                        photo: "https://picsum.photos/seed/picsum/200",
                        fromName: `${supplier.name}`,
                        text: supplier.lastMessage,
                        fromNumber: supplier.from,
                        contactId: supplier.id,
                        toNumber: supplier.to,
                        toName: supplier.toName,
                        selected: true,
                        unreadCounter: supplier.unreadCounter,
                        assignToUserName: supplier.assignToUserName
                      };
                    
                    // this.props.onClose();
                    // customer.action = <div onClick={() => {this.props.onSelect(newData);/*this.props.onClose();*/}} className="btn btn-primary"><QuestionAnswer /> <small>Buka Chat</small></div>;
                    supplier.action = 
                    <>
                        {
                            Global.get().admin === true ? 
                            (
                                <ToolTip title="Ubah sales">
                                    <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditOnClick(supplier);}}  />
                                </ToolTip>
                            ) : 
                            (<></>)
                        }
                        &nbsp;
                        <ToolTip title="Kirim Pesan">
                            <Send style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.checkSupplierAvailability(supplier);}}  />
                        </ToolTip>
                    </>;

                    supplier.isEditing = false;
                    // supplier.
                    mySuppliers.push(supplier);
                });

                this.setState({listMycustomer: mySuppliers});
            }

            
        })
        .catch((error) => {

            

            console.error("There was an error!", userData.user.id, error);
        });
    };

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    handleShowSnackbar = (message) => {
        this.setState({snackbarVisible: true, snackbarMessage: message});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, snackbarMessage: ""});
    }

    componentDidMount() {
        this.setState({appProfileId: Global.getAppProfile()._id},()=>{
            console.log(Global.get());
            this.getMyCustomerToday();
            this.getSalesTeleUsers();
        });
    }

    columns = [
        { id: 'name', label: 'Nama', minWidth: 100 },
        { id: 'speciality', label: 'Spesialisasi', minWidth: 100 },
        { id: 'operational_hour', label: 'Jam Buka', minWidth: 100 },
        // {
        //     id: 'population',
        //     label: 'Population',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'size',
        //     label: 'Size\u00a0(km\u00b2)',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'assignToUserName',
        //     label: 'Salesman',
        //     minWidth: 170,
        //     align: 'left',
        // },
        {
            id: 'action',
            label: 'Action',
            minWidth: 50,
            align: 'center',
        },
    ];

    handleChangePage = (event, newPage) => {
        console.log("NEWPAGE : ", newPage);

        var isNavigateToNewPage = newPage >= this.state.listMycustomer.length/this.state.rowsPerPage;

        this.setState({page: newPage}, () => {if(isNavigateToNewPage){this.getMyCustomerToday();}});
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    handleCloseFormAddContact = () => {
        this.setState({showAddForm: false});
    }

    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          this.getMyCustomerToday(true);
        }
      };

    render() { 
        return ( 
            <div className="container">
                <div className="row">
                    <div className="col-10"><h1>{this.state.showAddForm ? "Tambah Kontak" : "Daftar Supplier"}</h1></div>
                    <div className="col-2">
                        <div className="btn btn-danger" onClick={this.state.showAddForm ? this.handleCloseFormAddContact : this.props.onClose}>
                            {
                                this.state.showAddForm ? "Batalkan" : "X"
                            }
                        </div>
                    </div>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />
                
                {

                    this.state.showAddForm ? 
                    (
                        <AddContact onCloseForm={() => {this.setState({showAddForm: false}, () => {this.getMyCustomerToday()})}} />
                    ) : 
                    
                    (
                        <div className="container">
                            <div className="row"  style={{marginTop: 10, marginBottom: 10}}>
                                <div className="col-10">
                                    <TextField
                                        onChange={this.handleKeyword}
                                        label="Ketik untuk mencari nama atau nomor wa"
                                        type="text"
                                        className="form-control"
                                        id="namacustomer"
                                        variant="outlined"
                                        onKeyDown={this.onKeyDownHandler}
                                        onBlur={() => {
                                            
                                        }}
                                    />
                                </div>
                                <div className="col-2">
                                    <div onClick={() => {this.getMyCustomerToday(true)}} className="btn btn-primary" style={{padding: 15}}><Search /></div>&nbsp;
                                    <div onClick={() => {this.setState({showAddForm: true})}} className="btn btn-primary" style={{padding: 15}}><Add /></div>
                                </div>
                            </div>
                            <Paper className="root">
                                <TableContainer style={{maxHeight: 440}}>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {this.columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {this.columns.map((column) => {
                                                const value = row[column.id];
                                                var isEditing = row.isEditing;
                                                // return 

                                                    {
                                                        return isEditing === true && column.id === "assignToUserName" ? 
                                                        (
                                                            <TableCell key={column.id} align={column.align}>
                                                                <ContactEditChangeSales onFinishEdit={this.handleEditOnClick} salesData={this.state.listSales} contactData={row} />
                                                            </TableCell>
                                                        ) : 
                                                        (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                            </TableCell>
                                                        )
                                                    }
                                                    
                                            })}
                                            </TableRow>
                                        );
                                        })}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                    component="div"
                                    count={this.state.listMycustomer.length + this.state.rowsPerPage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    )

                }
                
            </div>
        );
    }
}
 
export default ContactsSupplierDashboard;