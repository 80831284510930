import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import {Paper, Tabs, Tab, Typography, Box} from '@mui/material';
import FollowUpHarian from './FollowUpHarian';
import { Link } from 'react-router-dom';
import FollowUpRutin from './FollowUpRutin';
import FollowUpReport from './FollowUpReport';
import Global from "../../customlib/Global";
import DataPendingEffectivityRate from './DataPendingEffectivityRate';
import DataPendingLeads from './DataPendingLeads';
import LeadsParcel from './LeadsParcel';
import DataSampah from './DataSampah';
import DataRiset from './DataRiset';
import FollowupRutinWrapper from './FollowupRutinWrapper';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class DataPending extends Component {

    state = { 
        value : 0,
        isYana: Global.isYana(),
        isAdmin: Global.get().admin
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    // classes = useStyles();
    // const [value, setValue] = React.useState(0);

    getRisetType1 = () => {
      let isYana = Global.isYana();
      let isAdmin = Global.get().admin;

      if(isAdmin || isYana){
        return Global.get().user.id;
      }else{
        return Global.get().user.id;
      }

    }

    getRisetType2 = () => {
      let isYana = Global.isYana();
      let isAdmin = Global.get().admin;
      
      if(isAdmin || isYana){
        return Global.get().user.id;
      }else{
        return Global.get().user.id;
      }

    }

    componentDidMount() {
        this.setState({value: 0});
    }
    

    handleChange = (event, newValue) => {
        // setValue(newValue);
        console.log("newValue", newValue);
        this.setState({value:newValue});
    };

    render() { 
        return ( 
            <>
            <Paper className={this.useStyles.root}>
                {/* <div className="container-fluid text-right bg-primary">
                    <Link to="/">
                        <div className="btn btn-primary">Close</div>
                    </Link>
                </div> */}
                {/* <div className="container text-center">
                  <div className="row">
                      <div className="col-11" style={{paddingLeft: "30px"}}><h1>Data Pending</h1></div>
                      <div className="col-1 text-right">
                          <Link to="/">
                              <div className="btn btn-danger">X</div>
                          </Link>
                      </div>
                  </div>
                </div> */}
                {
                    this.state.isYana || this.state.isAdmin ? (
                      <Tabs
                          value={this.state.value}
                          onChange={this.handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          centered
                      >
                  
                    <Tab key="tab0" label="Follow Up harian" />
                    <Tab key="tab1" label="Follow Up Rutin" />
                    {
                      Global.get().isDataPendingReportUser === true ? 
                      (
                        <Tab key="tab2" label="Report Follow Up" />
                      ) : 
                      (<></>)
                    }
                    
                    {
                      Global.get().isDataPendingReportUser === true ? 
                      (
                        <Tab key="tab3" label="Effectivity Rate" />
                      ) : 
                      (<></>)
                    }
                    <Tab key="tab4" label="Leads Parcel" />
                    <Tab key="tab5" label="Data Sampah" />
                    <Tab key="tab6" label="Riset 1" />
                    <Tab key="tab7" label="Riset 2" />
                    {/* <Tab key="tab4" label="Data pending Leads" /> */}
                    {/* {
                      Global.get().isDataPendingReportUser === true ? 
                      (
                        <Tab key="tab4" label="Dashboard" />
                      ) : 
                      (<></>)
                    } */}
                </Tabs>) : (
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab key="tab8" label="Riset 1" />
                    <Tab key="tab9" label="Riset 2" />
                    <Tab key="tab10" label="Riset 3" />
                    <Tab key="tab11" label="Riset 4" />
                    <Tab key="tab12" label="Riset 5" />
                    <Tab key="tab13" label="Riset 6" />
                    <Tab key="tab3" label="Effectivity Rate" />
                  </Tabs>
                )
            }
                
            {
              this.state.isYana || this.state.isAdmin ? (
                <>
                  <TabPanel value={this.state.value} index={0}>
                      <FollowUpHarian />
                  </TabPanel>
                  <TabPanel value={this.state.value} index={1}>
                      {/* <FollowUpRutin /> */}
                      <FollowupRutinWrapper />
                  </TabPanel>

                  {
                    Global.get().isDataPendingReportUser === true ? 
                    (
                      <TabPanel value={this.state.value} index={2}>
                        <FollowUpReport />
                    </TabPanel>
                    ) : 
                    (<></>)
                  }

                  {
                    Global.get().isDataPendingReportUser === true ? 
                    (
                      <TabPanel value={this.state.value} index={3}>
                        <DataPendingEffectivityRate />
                      </TabPanel>
                    ) : 
                    (<></>)
                  }

                  <TabPanel value={this.state.value} index={4}>
                      <LeadsParcel />
                  </TabPanel>

                  <TabPanel value={this.state.value} index={5}>
                      <DataSampah />
                  </TabPanel>
                  <TabPanel value={this.state.value} index={6}>
                      <DataRiset risetType={1} pageAdder={0} />
                  </TabPanel>
                  <TabPanel value={this.state.value} index={7}>
                      <DataRiset risetType={2} pageAdder={0} />
                  </TabPanel>
                  <TabPanel value={this.state.value} index={8}>
                      <DataRiset risetType={this.getRisetType1()} pageAdder={0} />
                  </TabPanel>
                  <TabPanel value={this.state.value} index={9}>
                      <DataRiset risetType={this.getRisetType2()} pageAdder={5}  />
                  </TabPanel>
                </>
              ) : (
                <>
                <TabPanel value={this.state.value} index={0}>
                    <DataRiset risetType={this.getRisetType1()} pageAdder={0} />
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <DataRiset risetType={this.getRisetType2()} pageAdder={5}  />
                </TabPanel>
                <TabPanel value={this.state.value} index={2}>
                    <DataRiset risetType={this.getRisetType2()} pageAdder={10}  />
                </TabPanel>
                <TabPanel value={this.state.value} index={3}>
                    <DataRiset risetType={this.getRisetType2()} pageAdder={15}  />
                </TabPanel>
                <TabPanel value={this.state.value} index={4}>
                    <DataRiset risetType={this.getRisetType2()} pageAdder={20}  />
                </TabPanel>
                <TabPanel value={this.state.value} index={5}>
                    <DataRiset risetType={this.getRisetType2()} pageAdder={25}  />
                </TabPanel>
                <TabPanel value={this.state.value} index={6}>
                  <DataPendingEffectivityRate />
                </TabPanel>
                </>
              )
            }

            </Paper>

            {/* {
              Global.get().isDataPendingReportUser === true ? 
              (
                <TabPanel value={this.state.value} index={4}>
                  <DataCancelDashboard />
                </TabPanel>
              ) : 
              (<></>)
            } */}

            
            </>
         );
    }
}
 
export default DataPending;