import React, { Component } from "react";
import axios from "axios";
import Api from "../../customlib/Api";
import Global from "../../customlib/Global";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import crypto from "crypto-js";

class Login extends Component {
  state = {
    email: "",
    password: "",
    errorMessage: "",
    loginSuccess: true,
    appTypeId: "",
    listAppProfiles: [],
    isLoading: false
  };

  componentDidMount() {
    this.loadAppProfile();
  }

  doLogin = () => {
    const params = {
      email: this.state.email,
      password: this.state.password,
      appProfileId: this.state.appTypeId
    };
    
    console.log("PARAMLOGIN", params);

    this.setState({isLoading: true}, () => {
      axios
      .post(
			Api.login(),
			params,
			// querystring.stringify(params),
			// JSON.stringify(params),
			Api.getRequestHeaderNoAuth()
      )
      .then((response) => {
        console.log("Login RESPONSE", response.data);
        let responseData = response.data;
        if (responseData.success) {

			let strResponseData = JSON.stringify(responseData);
			localStorage.setItem("auth", strResponseData);

			var cipherText = crypto.AES.encrypt(strResponseData, Global.getStringRequirements()).toString();
			localStorage.setItem("auth", cipherText);
			this.setState({isLoading: false});
			Global.add(responseData);
			this.autoHandoverEventReminderCustomer();
			this.props.onLogin();
        } else {
			console.log("GAGALLOGIN", responseData.message, responseData.success);
			this.setState({
				errorMessage: responseData.message,
				loginSuccess: false,
				isLoading: false
			});
        }
      })
      .catch((error) => {
			this.setState({ errorMessage: error.message, loginSuccess: false, isLoading: false });
			console.error("There was an error!", error);
			this.props.onLogin();
      });
    });

    
  };
  
  autoHandoverEventReminderCustomer = () => {
    const params = {
      appProfileId: Global.getAppProfile()._id,
      userId: Global.get().user.id
    };
    
    console.log("PARAMAUTOHANDOVER", params);
    axios
      .post(
        Api.autoHandoverEventReminderCustomer(),
        params,
        Api.getRequestHeader()
      )
      .then((response) => {
        console.log("AUTOHANDOVER", response.data);
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, loginSuccess: false });
        console.error("There was an error!", error);
        this.props.onLogin();
      });
  };

  loadAppProfile = () => {
    const params = {
    };
    
    axios
      .post(
        Api.listAppProfile(),
        params,
        Api.getRequestHeaderNoAuth()
      )
      .then((response) => {
        console.log("App Profiles", response.data);

        const profiles = response.data.data.filter((profile) => {return profile.enabled === true});

        this.setState({listAppProfiles: profiles});
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, loginSuccess: false });
        console.error("There was an error!", error);
        // this.props.onLogin();
      });
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
    // console.log(this.state.email);
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
    // console.log(this.state.password);
  };

  onKeyDownHandler = e => {
    if (e.keyCode === 13 && this.state.appTypeId !== "" && this.state.appTypeId !== null && this.state.appTypeId !== undefined) {
      this.doLogin();
    }
  };

  handleAppTypeChanged = (event) => {
    this.setState({appTypeId: event.target.value}, ()=>{
      console.log(this.state.appTypeId);
    });
  }


  render() {
    // console.log("LOGINRENDER", this.state.loginSuccess);
    return (
      <>
        <div
          className="container-fluid bg-secondary"
          style={{ height: "100vh", backgroundColor: "#941d59" }}
        >
          <div className="row">
            <div className="col-4"></div>
            <div
              className="col-4 bg-primarya rounded-lg shadow-lg padding-0"
              style={{ marginTop: "18vh", padding: 0, backgroundColor: "#941d59" }}
            >
              <div
                className="text-center rounded-top"
                style={{ paddingTop: 25, paddingBottom: 25}}
              >
                <img src="logo-prestisa.svg" width={"30%"} alt="Prestisa"/>
              </div>
              <div className="bg-white rounded" style={{marginLeft: "10px", marginRight: "10px", paddingBottom: "10px"}}>
                <form>
                  <div className="container">
                    <h3 className="text-center">Lotus</h3>
                    <div className="form-group">
                      <label><strong>Email address</strong></label>
                      <input
                        onChange={this.handleEmailChange}
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onKeyDown={this.onKeyDownHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label><strong>Password</strong></label>
                      <input
                        onChange={this.handlePasswordChange}
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        onKeyDown={this.onKeyDownHandler}
                      />
                    </div>
                    <FormControl variant="outlined" style={{width: '100%'}} size="small">
                      <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.appTypeId}
                        onChange={this.handleAppTypeChanged}
                        label="Aplikasi"
                      >
                        {
                          this.state.listAppProfiles.map(
                            (profile) => {
                              return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                            } 
                          )
                        }
                      </Select>
                    </FormControl>
                    <div
                      className={
                        this.state.loginSuccess
                          ? "d-none"
                          : "alert alert-danger"
                      }
                      style={{marginTop: "10px"}}
                      role="alert"
                    >
                      {this.state.errorMessage}
                    </div>
                  </div>
                </form>
              </div>
              <div
                    className="rounded-bottom"
                    style={{ padding: 15}}
                  >
                    <div onClick={this.doLogin} className="btn btn-danger btn-block">
                        {
							this.state.isLoading ? (<><CircularProgress size={"20px"} /> Please wait ...</>) : (<>Sign In</>)
						}
						
                    </div>

                    <div className="text-center text-white" style={{marginTop: "10px"}}>
                    <small>Build : {Global.buildInfo()}</small>
                    </div>
                  </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
