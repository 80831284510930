import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import ExportLeadsReport from './ExportLeadsReport';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, Autocomplete, CircularProgress, Dialog, DialogContent, DialogActions, Tooltip, DialogTitle, Switch, FormControlLabel, DialogContentText, Button, InputLabel, MenuItem, Select, FormControl, Alert, Tabs, Tab, Checkbox, TableFooter, Container, Chip} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { Chat, QuestionAnswer, Refresh, Search } from '@mui/icons-material';
import MessageListViewer from '../MessageListViewer';
import DateRangePicker from '../../customlib/DateRangePicker';
import MessageList from '../MessageList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class LeadsMonitoring extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        appProfileId: "",
        dateRangeValue: [null,null],
        additionalData: null,
        isLoading: false,
        currentConversation: null,
        resumeTotal: {
          leads: 0,
          unpaid: 0,
          paid: 0,
          dp: 0,
          cancel: 0,
          pending: 0
        },
        listSalesOnline: [],
        selectedSales: null,
        alasan: "",
        showConfirmDialog: false,
        errorMessage: "",
        listAutoleadsHistory: [],
        isLoadingAutoleadsHistory: false,
        listCrossedPending: [],
        isLoadingcrossed: false,
        value: 0,
        autoleadsHistoryDate: moment().tz("asia/jakarta").format("YYYY-MM-DD"),
        isCheckAll: false,
        listSalesYangOnline: [],
        selectedTargetSales: null,
        errorMessageCrosPending: ""
    }
    
    componentDidMount() {
        this.setState(
          {appProfileId: Global.getAppProfile()._id}, ()=>{
            this.getListSalesOnline();
            this.getContactLeads();
            this.autoleadsHistory();
            this.listSalesYangOnline();
          }
        );
    }

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], additionalData: null, isLoading: true}, () => {
          // console.log("ADDITIONAL_DATA : ", this.state.additionalData);
        });

        axios
          .post(Api.getContactLeads(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            let tmpContactLeads = response.data.data;
            let tmpLeadsSorted = [];
            let tmpLeadFormatted = [];
            var timeNow = moment(new Date()).tz("asia/jakarta"); //

            tmpContactLeads.forEach(lead => {
              lead.leads.forEach(contact => {
                let timeStamps = parseInt(moment(contact.created_at).format("x"));
                let hour = Global.formatDateTimeToStrFormat(contact.created_at, "H");
                // let firstResponseTime = moment(contact.firstResponse).tz("asia/jakarta"); // another date
                console.log("stamps", timeStamps);
                contact.leadTimeStamp = timeStamps;
                contact.leadHour = parseInt(hour);
                contact.isAutoleadsOn = false;
                // contact.firstResponseTime = firstResponseTime;

                const newData =  {
                  photo: "https://picsum.photos/seed/picsum/200",
                  fromName: `${contact.fromName}`,
                  text: contact.lastMessage,
                  fromNumber: contact.from,
                  contactId: contact.id,
                  toNumber: contact.to,
                  toName: contact.toName,
                  selected: true,
                  unreadCounter: contact.unreadCounter,
                  assignToUserName: contact.assignToUserName,
                  contactLabel: contact.label
                };

                var leadsDateTime = moment(contact.created_at).tz("asia/jakarta"); //todays date
                
                var duration = moment.duration(timeNow.diff(leadsDateTime));
                let delta = duration.asSeconds();

                if(delta <= 600){
                  contact.isLessThan10Minutes = true;
                }

                contact.contactFormatted = newData;
                tmpLeadsSorted.push(contact);
              });
            });

            tmpLeadsSorted.sort((a, b) => b.leadTimeStamp - a.leadTimeStamp);

            let resume = [];
            let leadsUnder10Minutes = tmpLeadsSorted.filter(itemLead => itemLead.isLessThan10Minutes === true);
            
            let currentI = 1;
            leadsUnder10Minutes.forEach(item => {
              let existedResume = resume.find(findItem => findItem.firstContactAssignedTo === item.firstContactAssignedTo);
              if(existedResume){
                existedResume.count += 1;
                // item.count = currentI;
              }else{
                // currentI = 1;
                // item.count = currentI;
                resume.push(
                  {
                    firstContactAssignedTo: item.firstContactAssignedTo,
                    count: currentI
                  }
                );
                // currentI += 1;
              }
            });

            resume.forEach(resumeItem => {
              let filterLeadsUnder10Minutes = leadsUnder10Minutes.filter(item => item.firstContactAssignedTo === resumeItem.firstContactAssignedTo);
              let decreement = 0;
              filterLeadsUnder10Minutes.forEach(item => {
                item.count = resumeItem.count - decreement;
                decreement += 1;
              });
            })


            // let currentHour = -1;
            // let currentIndex = -1;
            // tmpLeadsSorted.forEach(lead => {
            //   if(currentHour !== lead.leadHour){
            //     currentIndex += 1;
            //     currentHour = lead.leadHour;
            //     tmpLeadFormatted.push({
            //       leadHour: lead.leadHour,
            //       leads: [lead]
            //     });
            //   }else{
            //     tmpLeadFormatted[currentIndex].leads.push(lead);
            //   }
            // })

            this.setState({contactLeads: tmpLeadsSorted, isLoading: false}, () => {
              console.log("FORMATTEDLEAD", this.state.contactLeads);
              this.getListSalesOnline();
            });
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    getListSalesOnline = () => {
      axios
        .post(Api.getSalesChatList(), {appProfileId: Global.getAppProfile()._id}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESCHATLIST : ", response.data.data);

            const responseData = response.data.data;

            let leadsData = [...this.state.contactLeads];
            // responseData.forEach(item => {
            //   let leadsBySales = leadsData.filter(lead => lead.firstContactAssignedTo === item.lavenderSalesId);
            //   if(leadsBySales.length > 0){
                
            //     console.log("KETEMU", item.lavenderSalesId, item.lavenderSalesName);
            //     let autoleadsState = item.allowReceiveLeads === 1 ? true : false;
            //     leadsBySales.forEach(itemLead => {itemLead.isAutoleadsOn = autoleadsState})
            //   }else{
            //     console.log("TIDAk KETEMU", item.lavenderSalesId, item.lavenderSalesName);
            //   }
            // })

            this.setState({listSalesOnline: responseData, contactLeads: leadsData}, () => {
              this.getDailyClosingRate();
            });
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    getDailyClosingRate = () => {

      // this.setState({}, () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          appProfileId: this.state.appProfileId,
          includeNonClosingSales: true
        }

        axios
          .post(Api.dailyClosingRateReport(), params, Api.getRequestHeader())
          .then((response) => {
            var data = response.data.data;
            var tmpContactLeads = data.leadsReport;
            
            let tmpListSalesOnline = [...this.state.listSalesOnline];
            tmpContactLeads.forEach(item => {
              let sales = tmpListSalesOnline.find(sls => sls.lavenderSalesId === item.salesId);

              if(sales){
                sales.totalLeads = item.leadsCount;
                sales.totalClosing = item.leads.length;
              }

            });

            this.setState({listSalesOnline: tmpListSalesOnline});

          })
          .catch((error) => {
            this.setState({isLoading: false});
            console.error("There was an error!", error);
          });
      // });

        
      };

    async handleSubmit(salesUserId) {

      let selectedSales = this.state.listSalesOnline.find(sales => sales.lavenderSalesId === salesUserId);
      
      if(!selectedSales){
        return;
      }
      
      if(selectedSales.allowReceiveLeads === 1 && this.state.alasan === ""){
        this.setState({errorMessage: "Untuk menonaktifkan, alasan harus dipilih"});
        return;
      }

      var params = [
        {
          id: selectedSales.id, 
          lavenderSalesId: selectedSales.lavenderSalesId, 
          lavenderSalesName: selectedSales.lavenderSalesName, 
          salesType: selectedSales.salesType, 
          allowReceiveLeads: selectedSales.allowReceiveLeads === 1 ? 0 : 1,
          appProfileId: Global.getAppProfile()._id,
          isNightShift: selectedSales.isNightShift,
          reason: this.state.alasan,
          saveLog: true
        }
      ];
      
      console.log("PARAMS", params);
      // return;

      axios
        .post(Api.doSaveSales(), params, 
          Api.getRequestHeader())
        .then((response) => {
          console.log("doSaveSales : ", response.data);
          let responseData = response.data;
          if(responseData.success){
            // this.getContactLeads();
            this.setState({selectedSales: null, showConfirmDialog: false, errorMessage: "", alasan: ""});
            this.getListSalesOnline();
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }

    handleSwitchAutoleadsChange = (event, data) => {
      console.log(event);
      console.log(data);
      this.setState({selectedSales: data, showConfirmDialog: true});
      // this.handleSubmit(data.lavenderSalesId);
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    columns = [
        { id: 'created_at', label: 'Tanggal Leads', minWidth: 170 },
        { id: 'firstResponseTime', label: 'Respon Pertama', minWidth: 170 },
        { id: 'fromName', label: 'Nama Cust', minWidth: 170 },
        { id: 'from', label: 'No Telp', minWidth: 170 },
        { id: 'label', label: 'Label', minWidth: 170 },
        { id: 'salesName', label: 'Sales', minWidth: 170 },
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    getWaMessages = (contactData) => {
      console.log("CONTACTDATA : ", contactData);
      if(contactData.fromNumber === ""){
        return;
      }
  
      this.setState({isLoading: true}, ()=>{
        const params = {
          from: contactData.fromNumber,
        };
        console.log("PARAMSWAMESSAGE", params);
    
        axios
          .post(Api.messagesList(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("YOUR_MESSAGES",response.data);
            
            const responseData = response.data;
              
            const clonedMyCust = [...this.state.listMycustomer];
            let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

            if(myCust.length > 0){
                myCust[0].messages = responseData.data;
            }

            this.setState({listMycustomer: clonedMyCust});
            
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, isLoading: false });
            console.error("There was an error!", error);
          });
      });
  
      
    };

    autoleadsHistory = () => {
  
      this.setState({isLoadingAutoleadsHistory: true, listAutoleadsHistory: []}, ()=>{
        const params = {
          appProfileId: Global.getAppProfile()._id,
          date: this.state.autoleadsHistoryDate
        };
        console.log("PARAMS_AUTOLEADSHISTORY", params);
    
        axios
          .post(Api.autoleadsHistory(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("AUTOLEADSHISTORY",response.data);
            
            const responseData = response.data;
            
            this.setState({listAutoleadsHistory: responseData.data});
            
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, isLoadingAutoleadsHistory: false });
            console.error("There was an error!", error);
          });
      });
  
      
    };

    listLeadsCrossedPendingBelumTeralihkan = () => {
  
      this.setState({isLoadingCrossed: true, listCrossedPending: []}, ()=>{
        const params = {
          appProfileId: Global.getAppProfile()._id
        };
        console.log("PARAMS_CROSSED", params);
    
        axios
          .post(Api.listLeadsCrossedPendingBelumTeralihkan(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CROSSEDPENDING",response.data);
            
            const responseData = response.data;
            var leads = responseData.data;
            leads.forEach(item => {
              const newData =  {
                photo: "https://picsum.photos/seed/picsum/200",
                fromName: `${item.fromName}`,
                text: item.lastMessage,
                fromNumber: item.from,
                contactId: item.id,
                toNumber: item.to,
                toName: item.toName,
                selected: true,
                unreadCounter: item.unreadCounter,
                assignToUserName: item.assignToUserName,
                contactLabel: item.label
              };

              item.isChecked = false;
              item.contactFormatted = newData;
            })

            
            this.setState({listCrossedPending: leads});
            
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, isLoadingCrossed: false });
            console.error("There was an error!", error);
          });
      });
  
      
    };

    listSalesYangOnline = () => {
  
      // this.setState({isLoadingCrossed: true, listCrossedPending: []}, ()=>{
        const params = {
          appProfileId: Global.getAppProfile()._id
        };
        console.log("PARAMS_CROSSED", params);
    
        axios
          .post(Api.listSalesYangOnline(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CROSSEDPENDING",response.data);
            
            const responseData = response.data;
            var salesOnline = responseData.data;
            
            this.setState({listSalesYangOnline: salesOnline});
            
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, isLoadingCrossed: false });
            console.error("There was an error!", error);
          });
      // });
  
      
    };
  doCrossedPending = () => {

    // this.setState({isLoadingCrossed: true, listCrossedPending: []}, ()=>{

      let listCustomer = [...this.state.listCrossedPending];

      let waNumbers = [];
      let selectedWaNumbers = listCustomer.filter(item => item.isChecked === true);

      if(selectedWaNumbers){
        selectedWaNumbers.forEach(item => {
          waNumbers.push(item.from);
        });
      }

      if(waNumbers.length <= 0){
        this.setState({errorMessageCrosPending: "Customer belum ditentukan"});
        return;
      }

      if(this.state.selectedTargetSales === null){
        this.setState({errorMessageCrosPending: "Sales belum ditentukan"});
        return;
      }

      

      const params = {
        appProfileId: Global.getAppProfile()._id,
        waNumbers: waNumbers,
        crossToSalesId: this.state.selectedTargetSales?.lavenderSalesId,
        crossToSalesName: this.state.selectedTargetSales?.lavenderSalesName
      };
      console.log("PARAMS_CROSSED", params);
      // return;
      axios
        .post(Api.doCrossedPendingManually(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CROSSEDPENDING",response.data);
          
          const responseData = response.data;
          if(responseData.success === true){
            this.listLeadsCrossedPendingBelumTeralihkan();
            listCustomer.forEach(item => item.isChecked = false);
            this.setState({selectedTargetSales: null, listCrossedPending: listCustomer, errorMessageCrosPending: responseData.message});
          }else{
            this.setState({errorMessageCrosPending: responseData.message});
          }
          
        })
        .catch((error) => {
          this.setState({ errorMessageCrosPending: error.message, isLoadingCrossed: false });
          console.error("There was an error!", error);
        });
    // });

    
  };

  handleShowMessages = (conversation) => {
    this.setState({currentConversation: conversation});
  }

  handleCheckAll = (event) => {
    let crossedPending = [...this.state.listCrossedPending];
    crossedPending.map(item => item.isChecked = event.target.checked);

    this.setState({listCrossedPending: crossedPending, isCheckAll: event.target.checked});
  }

  handleCheckItem = (item) => {
    let crossedPending = [...this.state.listCrossedPending];
    let find = crossedPending.find(pending => pending.from === item.from);

    if(find){
      find.isChecked = !item.isChecked;
    }

    this.setState({listCrossedPending: crossedPending});
  }

  handleTargetSalesChange = (event) => {
    console.log("SALESNYA", event.target.value);
    this.setState({selectedTargetSales: event.target.value});
  }

  ALASAN = [
    "Closing <40%",
    "Leads masuk dalam 10 menit terakhir >5",
    "Closing >50%",
    "Sales sudah melakukan FU/Jualan di Dacen",
    "Banyak antrian chat belum terbalas"
  ];

  handleAlasanChange = (event) => {
    this.setState({alasan: event.target.value});
  }

  handleTabChange = (event, newValue) => {
    // setValue(newValue);
    this.setState({value: newValue},() => {
      if(newValue === 0){
        this.getListSalesOnline();
      }
      if(newValue === 1){
        this.autoleadsHistory();
      }
      if(newValue === 2){
        this.listLeadsCrossedPendingBelumTeralihkan();
      }
    });
  };

    render() { 
        return ( 
            <div style={{paddingBottom: "10px"}} className='border'>
              {
                this.state.selectedSales !== null ? (
                  <Dialog
                      open={this.state.showConfirmDialog}
                      onClose={() => this.setState({showConfirmDialog: false, selectedSales: null, alasan: ""})}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                      <DialogTitle id="alert-dialog-title">
                          Konfirmasi!
                      </DialogTitle>
                      <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              Yakin akan {this.state.selectedSales.allowReceiveLeads === 0 ? "Mengaktifkan" : "Menonaktifkan"} autoleads untuk {this.state.selectedSales.lavenderSalesName} ?
                          </DialogContentText>

                          {
                            this.state.selectedSales?.allowReceiveLeads === 1 && 
                            <FormControl fullWidth className=''>
                              <InputLabel id="demo-simple-select-label">Alasan</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.alasan}
                                label="Alasan"
                                onChange={this.handleAlasanChange}
                              >

                                {
                                  this.ALASAN.map(item => {
                                    return (<MenuItem value={item}>{item}</MenuItem>);
                                  })
                                }
                                
                              </Select>
                            </FormControl>
                          }

                          {
                            this.state.errorMessage !== "" && (
                              <Alert style={{marginTop: "10px"}} severity="error">{this.state.errorMessage}</Alert>
                            )
                          }
                          
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={() => this.handleSubmit(this.state.selectedSales.lavenderSalesId)}>Ya</Button>
                          <Button onClick={() => this.setState({showConfirmDialog: false, selectedSales: null, alasan: ""})}>Tidak</Button>
                      </DialogActions>
                  </Dialog>
                ) : (<></>)
              }
              {
                  this.state.currentConversation === "wkwkwkw" ? (
                      <Dialog
                          open={this.state.currentConversation === "wkwkwk"}
                          // TransitionComponent={Transition}
                          // keepMounted
                          onClose={() => {this.setState({currentConversation: null})}}
                          aria-describedby=""
                      >
                          <DialogTitle>
                            {/* <strong>{this.state.currentConversation.fromName}</strong>
                            <br />
                            <strong style={{fontSize: "10px"}}>{this.state.currentConversation.fromNumber}</strong> */}
                          </DialogTitle>
                          <DialogContent style={{height: "100%"}}>
                              {/* <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} /> */}
                              <MessageList
                                onUpdateReadCounter = {()=>{}}
                                onNewIncomingMessage={()=>{}}
                                onUpdateConversations={()=>{}}
                                onUpdateCsHelpState={()=>{}}
                                currentConversation={this.state.currentConversation}
                                onUpdateContactLabelState={()=>{}}
                                onClearCurrentConversation = {()=>{}}
                                onUpdateSlow={()=>{}}
                                onUpdateJustRead={()=>{}}
                                onUpdateUnfinishedChat={()=>{}}
                                onResetUnreadCounter={()=>{}}
                                conversationList={[]}
                                logoutHandler={()=>{}}
                                onSaveCustomerName={()=>{}}
                                style={{maxHeight: "70vh", overflowY: "none"}}
                            />
                          </DialogContent>
                          <DialogActions>
                          {/* <Button onClick={handleClose}>Disagree</Button>
                          <Button onClick={handleClose}>Agree</Button> */}
                          </DialogActions>
                      </Dialog>
                  ) : (<></>)
              }
              <div className="container-fluid">

                <div className="row align-items-center">
                    <div className="col-3">
                        <strong>Total {this.state.contactLeads.length} Leads</strong>
                    </div>
                    <div className="col-5"></div>
                    <div className="col-2 text-right" style={{borderRadius: 10, padding: 5}}>
                        <DateRangePicker onDateChanged={this.handleDateChanged} />
                    </div>
                    <div className="col-2 text-left">
                    {
                        this.state.isLoading === true ? 
                            (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                            (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                    }
                    &nbsp;
                    {
                        this.state.isLoading === true ? 
                            (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                            (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Refresh /></div>)
                    }
                    </div>
                </div>

                <Paper style={{padding: "10px", backgroundColor: "#deecfd"}}>
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs 
                        value={this.state.value} 
                        onChange={this.handleTabChange} 
                        aria-label="basic tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab key={"tab0"} label="Autoleads ON/OFF" />
                        <Tab key={"tab1"} label="Autoleads History" />
                        <Tab key={"tab2"} label="Leads Pending" />
                      </Tabs>
                    </Box>
                    <TabPanel value={this.state.value} index={0}>
                      <div className="row">
                      {
                        this.state.listSalesOnline.map(item => {

                          let totalLeads = item.totalLeads;
                          let totalClosing = item.totalClosing;

                          let percentClosing = Math.round(totalClosing/totalLeads*100);

                          return <div className="col-3" key={item._id}>
                            <FormControlLabel 
                              control={
                                <Switch 
                                  onChange={(event) => this.handleSwitchAutoleadsChange(event, item)} 
                                  checked={item.allowReceiveLeads === 1} />
                                } 
                              label={`${item.lavenderSalesName}`} />
                              {
                                (item.totalLeads && item.totalLeads > 0) &&  <Tooltip  title={`Jumlah Leads = ${item.totalLeads}, Closing Rate = ${percentClosing}%`}>
                                  <span style={{marginBottom: "20px"}}>
                                    <span className='badge badge-primary badge-pill' style={{fontSize: 10}}>{`${totalLeads}`}</span>&nbsp;
                                    <span className='badge badge-warning badge-pill' style={{fontSize: 10}}>{`${percentClosing}%`}</span>
                                  </span>
                                </Tooltip>
                              }
                          </div>
                        })
                      }
                    </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                      
                      <strong>History tanggal</strong> : {this.state.autoleadsHistoryDate}
                      <TableContainer>
                          <Table aria-label="sticky table" size={"small"}>
                            <TableHead>
                                <TableRow>
                                  <TableCell>Jam OFF</TableCell>
                                  <TableCell>Kembali ON</TableCell>
                                  <TableCell>Sales</TableCell>
                                  <TableCell>Alasan OFF</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                this.state.listAutoleadsHistory?.map(history => {
                                  return (
                                    <TableRow>
                                      <TableCell>{Global.formatDateTimeToStrDateTime24(history.offAt)}</TableCell>
                                      <TableCell>{Global.formatDateTimeToStrTime24(history.onAt)}</TableCell>
                                      <TableCell>{history.lavenderSalesName}</TableCell>
                                      <TableCell>{history.reason}</TableCell>
                                    </TableRow>
                                  );
                                })
                              }
                            </TableBody>
                          </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2}>
                      <strong>Leads Pending Tanggal : </strong> : {this.state.autoleadsHistoryDate}
                      <TableContainer>
                            <Table aria-label="sticky table" size={"small"}>
                              <TableHead>
                                  <TableRow>
                                    <TableCell style={{fontWeight: "bold"}}>
                                      <Checkbox checked={this.state.isCheckAll} key={"checkall"} onChange={this.handleCheckAll} />
                                    </TableCell>
                                    <TableCell style={{fontWeight: "bold"}}>Sales</TableCell>
                                    <TableCell style={{fontWeight: "bold"}}>Tanggal Leads</TableCell>
                                    <TableCell style={{fontWeight: "bold"}}>Nama Customer</TableCell>
                                    <TableCell style={{fontWeight: "bold"}}>No Telp</TableCell>
                                    <TableCell style={{fontWeight: "bold"}}>Label</TableCell>
                                    <TableCell style={{fontWeight: "bold"}}>Jenis Produk</TableCell>
                                    <TableCell style={{fontWeight: "bold"}}>Alasan Rinci</TableCell>
                                    <TableCell style={{fontWeight: "bold"}}>Action</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  this.state.listCrossedPending?.map(history => {
                                    return (
                                      <TableRow>
                                        <TableCell><Checkbox onChange={() => this.handleCheckItem(history)} checked={history.isChecked} key={"check" + history._id} /> </TableCell>
                                        <TableCell>{history.assignToUserName}</TableCell>
                                        <TableCell>{Global.formatDateTimeToStrDateTime24(history.created_at)}</TableCell>
                                        <TableCell>{history.fromName}</TableCell>
                                        <TableCell>{history.from}</TableCell>
                                        <TableCell>{history.label}</TableCell>
                                        <TableCell>{history.productCategory}</TableCell>
                                        <TableCell>{history.alasanRinci}</TableCell>
                                        <TableCell>
                                          <Tooltip title="Lihat Chat">
                                              <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowMessages(history.contactFormatted)}}  />
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                }
                              </TableBody>
                              <TableFooter>
                                {/* Alihkan kepada :  */}
                                  
                              </TableFooter>
                            </Table>
                        </TableContainer>
                        <div className="row" style={{marginTop: "20px"}}>
                          <div className="col-3">
                            <FormControl fullWidth size='small'>
                              <InputLabel id="demo-simple-select-label">Alihkan kepada</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.selectedTargetSales}
                                label="Alihkan kepada"
                                onChange={this.handleTargetSalesChange}
                              >
                                {
                                  this.state.listSalesYangOnline.map(item => {
                                    return <MenuItem value={item}>{item.lavenderSalesName}</MenuItem>;
                                  })
                                }
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-2">
                            <div className="btn btn-primary" onClick={this.doCrossedPending}>
                              Alihkan
                            </div>
                          </div>
                          <div className="col-7 text-danger">{this.state.errorMessageCrosPending}</div>
                        </div>
                    </TabPanel>
                  </Box>
                </Paper>
                <br />
                <Paper className="root">
                  <div className="row">
                    <div className={this.state.currentConversation === null ? "col-12" : "col-8"}  style={{maxHeight: "50vh",overflow: "scroll"}}>
                      <TableContainer>
                          <Table aria-label="sticky table" size={"small"}>
                          <TableHead>
                              <TableRow>
                                <TableCell>Tgl Leads</TableCell>
                                <TableCell>Respon Pertama</TableCell>
                                <TableCell>Nama</TableCell>
                                <TableCell>Nomor</TableCell>
                                <TableCell>Label</TableCell>
                                <TableCell>Sales</TableCell>
                                <TableCell>Urutan</TableCell>
                                <TableCell>Chat</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {this.state.contactLeads.map((row) => {
                                return (
                                    <TableRow>
                                      <TableCell>{Global.formatDateTimeToStrDateTime24(row.created_at)}</TableCell>
                                      <TableCell>{Global.formatDateTimeToStrDateTime24(row.firstResponse)}</TableCell>
                                      <TableCell align="left">{row.fromName}</TableCell>
                                      <TableCell align="left">{row.from}</TableCell>
                                      <TableCell>
                                        <div className={Global.getLabelClasses(row.label)}>{row.label}</div>
                                      </TableCell>
                                      <TableCell align="left">{row.firstContactAssignedToName}</TableCell>
                                      <TableCell align="left">{row.count}</TableCell>
                                      {/* <TableCell>
                                        <Tooltip title="Lihat Chat">
                                          <Switch
                                            checked={row.isAutoleadsOn}
                                            onChange={(event) => this.handleSwitchAutoleadsChange(event, row)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                          />
                                        </Tooltip>
                                      </TableCell> */}
                                      <TableCell>
                                        <Tooltip title="Lihat Chat">
                                            <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowMessages(row.contactFormatted)}}  />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                );
                              })}
                              {/* <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>{this.state.resumeTotal.leads} leads</TableCell>
                                <TableCell align='center'>{this.state.resumeTotal.totalUnder60}</TableCell>
                                <TableCell align='center'>{this.state.resumeTotal.totalHigher60}</TableCell>
                                <TableCell align='center'>{this.state.resumeTotal.paid}</TableCell>
                                <TableCell align='center'>{this.state.resumeTotal.unpaid}</TableCell>
                                <TableCell align='center'>{this.state.resumeTotal.dp}</TableCell>
                                <TableCell align='center'>{this.state.resumeTotal.pending}</TableCell>
                                <TableCell align='center'>{this.state.resumeTotal.cancel}</TableCell>
                              </TableRow> */}
                          </TableBody>
                          </Table>
                      </TableContainer>
                    </div>
                    <div className="col-4" style={{maxHeight: "70vh", overflowY: "none"}}>
                                {
                                this.state.currentConversation !== null ? (
                                    <div className='text-right'>
                                    <div className="btn btn-block btn-danger" onClick={() => {this.handleShowMessages(null)}}>Close Chat</div>
                                    </div>
                                ) : (<></>)
                                }
                                {
                                this.state.currentConversation !== null ? (
                                    <Paper className='root'>
                                        <MessageList
                                            onUpdateReadCounter = {()=>{}}
                                            onNewIncomingMessage={()=>{}}
                                            onUpdateConversations={()=>{}}
                                            onUpdateCsHelpState={()=>{}}
                                            currentConversation={this.state.currentConversation}
                                            onUpdateContactLabelState={()=>{}}
                                            onClearCurrentConversation = {()=>{}}
                                            onUpdateSlow={()=>{}}
                                            onUpdateJustRead={()=>{}}
                                            onUpdateUnfinishedChat={()=>{}}
                                            onResetUnreadCounter={()=>{}}
                                            conversationList={[]}
                                            logoutHandler={()=>{}}
                                            onSaveCustomerName={()=>{}}
                                            style={{maxHeight: "70vh", overflowY: "none"}}
                                        />
                                    </Paper>
                                    
                                ) : (<></>)
                                }
                          </div>
                  </div>
                    
                </Paper>
              </div>
            </div>
         );
    }
}
 
export default LeadsMonitoring;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    // const classes = {root: {}};

    const tipeLead = (tipeLead) => {
      if(tipeLead === Global.tipeLeadSulitClosing()){
        return <div className="btn btn-danger btn-block btn-sm" style={{fontSize: "11px", paddingLeft: "2px", paddingRight: "2px"}}>{tipeLead}</div>;
      }
      if(tipeLead === Global.tipeLeadSedang()){
        return <div className="btn btn-warning btn-block btn-sm" style={{fontSize: "11px", paddingLeft: "2px", paddingRight: "2px"}}>{tipeLead}</div>;
      }
      if(tipeLead === Global.tipeLeadMudahClosing()){
        return <div className="btn btn-success btn-block btn-sm" style={{fontSize: "11px", paddingLeft: "2px", paddingRight: "2px"}}>{tipeLead}</div>;
      }
    }

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
              { (row.leadHour < 10 ? "0" + row.leadHour : row.leadHour) + ":00" } - { (row.leadHour < 9 ? "0" + (row.leadHour+1) : row.leadHour+1) + ":00" }
          </TableCell>
          <TableCell component="th" scope="row">{row.leads.length}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Leads Detail
                </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tgl Leads</TableCell>
                      <TableCell>Respon Pertama</TableCell>
                      <TableCell>Nama</TableCell>
                      <TableCell>Nomor</TableCell>
                      <TableCell>Label</TableCell>
                      <TableCell>Sales</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell>{Global.formatDateTimeToStrDateTime24(historyRow.createdAt)}</TableCell>
                        <TableCell>{Global.formatDateTimeToStrDateTime24(historyRow.firstResponse)}</TableCell>
                        <TableCell align="left">{historyRow.fromName}</TableCell>
                        <TableCell align="left">{historyRow.from}</TableCell>
                        <TableCell>
                          <div className={Global.getLabelClasses(historyRow.label)}>{historyRow.label}</div>
                        </TableCell>
                        <TableCell align="left">{historyRow.firstContactAssignedToName}</TableCell>
                        {/* <TableCell align="left">{tipeLead(historyRow.tipeLead)}</TableCell>
                        
                        <TableCell align="left">{historyRow.alasanUmum}</TableCell>
                        <TableCell align="left">{historyRow.alasanRinci}</TableCell>
                        <TableCell align="left">{historyRow.cityName}</TableCell>
                        <TableCell align="left">{moment(historyRow.createdAt).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}</TableCell>
                        <TableCell align="left">
                          {moment(historyRow.lastMessageReceivedAt).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}
                        </TableCell> */}
                        <TableCell>
                          <Tooltip title="Lihat Chat">
                              <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {props.handleShowMessages(historyRow.contactFormatted)}}  />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  // function MyDateRangePicker(props){
  //   const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
  //   const [startDate, endDate] = dateRange;
  //   const [message, setMessage] = React.useState("");
  //   return (
  //     <>
  //       <DatePicker
  //         readOnly={false}
  //         selectsRange={true}
  //         startDate={startDate}
  //         endDate={endDate}
  //         dateFormat="d-M-yyyy"
  //         onChange={(update) => {
  //           console.log("RANGE", update);
            
  //           if(update[0] !== null && update[1] !== null){
  //             let startDate = moment(update[0]);
  //             let endDate = moment(update[1]);
  //             let diffs = Math.abs(startDate.diff(endDate, "days"));
  //             console.log("DIFFS", diffs);
  //             if(diffs > 35){
  //               setDateRange([null, null]);
  //               setMessage("Rentang tanggal max 35 hari");
  //               return
  //             }
  //           }
            
  //           setDateRange(update);
  //           setMessage("");
  //           if(update[0] !== null && update[1] !== null){
  //             props.onDateChanged(update);
  //           }

  //         }}
  //         isClearable={false}
  //         popperPlacement="top-end"
  //         popperModifiers={[
  //           {
  //             name: "offset",
  //             options: {
  //               offset: [5, 10],
  //             },
  //           },
  //           {
  //             name: "preventOverflow",
  //             options: {
  //               rootBoundary: "viewport",
  //               tether: false,
  //               altAxis: true,
  //             },
  //           },
  //         ]}
  //         className='border rounded rounded-lg border-secondary p-2 text-center'
  //       />
  //       <div className="text-danger">
  //         {message}
  //       </div>
  //     </>
  //   );
  // }