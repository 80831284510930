import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, TableFooter } from '@mui/material';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Check, Close, QuestionAnswer, TrainRounded } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../MessageListViewer';

class Weekly extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null,
        resumeByKategori: [],
        years: [],
        selectedMonth: moment().format("M"),
        selectedYear: moment().format("YYYY"),
        dataPerforma: [],
        contactLeadsHourly: [],
        listAppProfiles: [],
        filterAppNumber: "",
        performaTotalCancel: 0,
        performaTotalPengurangLeads: 0,
        performaTotalLeads: 0,
        summaryWeekly: {},
        performaSalesByLabel: [],
        weeklyLeadsPercentage: []
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.handleRefresh();
          // this.getResumeWeekly(true);
        }
      );
    }

    handleRefresh = () => {
        this.loadAppProfile();
        this.getContactLeads();
    }

    handleChangeMonth = (event) => {
      this.setState({selectedMonth: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleChangeYear = (event) => {
      this.setState({selectedYear: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        console.log(this.state.appProfileId);
        // this.getContactLeads();
        this.handleRefresh();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
  };

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], contactLeadsHourly: [], performaTotalCancel: 0, performaTotalLeads: 0, performaTotalPengurangLeads: 0, additionalData: null, isLoading: true}, () => {
            axios
            .post(Api.getContactLeads(), params, Api.getRequestHeader())
            .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

            const arrayLabels = [
                Global.labelRandomLeads(),
                Global.labelKonfirmasiPesanan(),
                Global.labelReport(),
            ];

            let totalCancel = 0;
            let totalLeads = 0;
            let totalPengurang = 0;

            let summaryWeekly = {
                w1: {leads: 0, pengurang: 0, cancel: 0},
                w2: {leads: 0, pengurang: 0, cancel: 0},
                w3: {leads: 0, pengurang: 0, cancel: 0},
                w4: {leads: 0, pengurang: 0, cancel: 0},
                w5: {leads: 0, pengurang: 0, cancel: 0},
            };

            tmpContactLeads.forEach(lead => {
                lead.leadsCount = lead.leads.length;
                lead.labelCancel = 0;
                lead.pengurangLeads = 0;
                totalLeads += lead.leads.length;
                
                //Weekly
                lead.w1 = {leads:0, pengurang:0, cancel: 0};
                lead.w2 = {leads:0, pengurang:0, cancel: 0};
                lead.w3 = {leads:0, pengurang:0, cancel: 0};
                lead.w4 = {leads:0, pengurang:0, cancel: 0};
                lead.w5 = {leads:0, pengurang:0, cancel: 0};

                lead.leads.forEach(contact => {

                    let day = Global.formatDateTimeToStrFormat(contact.created_at, "D");

                    if(arrayLabels.includes(contact.label)){
						lead.pengurangLeads += 1;
						totalPengurang += 1;
                    }              
                    if(contact.label === Global.labelCancel()){
						lead.labelCancel += 1;
						totalCancel += 1;
                    }

                    if (day <= 7) {//w1
						lead.w1.leads += 1;
						summaryWeekly.w1.leads += 1;
						if(arrayLabels.includes(contact.label)){
							lead.w1.pengurang += 1;
							summaryWeekly.w1.pengurang += 1;
						}              
						if(contact.label === Global.labelCancel()){
							lead.w1.cancel += 1;
							summaryWeekly.w1.cancel += 1;
						}
                    } else if (day <= 14) {//w2
						lead.w2.leads += 1;
						summaryWeekly.w2.leads += 1;
						if(arrayLabels.includes(contact.label)){
							lead.w2.pengurang += 1;
							summaryWeekly.w2.pengurang += 1;
						}              
						if(contact.label === Global.labelCancel()){
							lead.w2.cancel += 1;
							summaryWeekly.w2.cancel += 1;
						}
                    } else if (day <= 21) {//w3
						lead.w3.leads += 1;
						summaryWeekly.w3.leads += 1;
						if(arrayLabels.includes(contact.label)){
							lead.w3.pengurang += 1;
							summaryWeekly.w3.pengurang += 1;
						}              
						if(contact.label === Global.labelCancel()){
							lead.w3.cancel += 1;
							summaryWeekly.w3.cancel += 1;
						}
                    } else if (day <= 31) {//w4
						lead.w4.leads += 1;
						summaryWeekly.w4.leads += 1;
						if(arrayLabels.includes(contact.label)){
							lead.w4.pengurang += 1;
							summaryWeekly.w4.pengurang += 1;
						}              
						if(contact.label === Global.labelCancel()){
							lead.w4.cancel += 1;
							summaryWeekly.w4.cancel += 1;
						}
                    } 
					// else if (day <= 31) {//w5
					// 	lead.w5.leads += 1;
					// 	summaryWeekly.w5.leads += 1;
					// 	if(arrayLabels.includes(contact.label)){
					// 		lead.w5.pengurang += 1;
					// 		summaryWeekly.w5.pengurang += 1;
					// 	}              
					// 	if(contact.label === Global.labelCancel()){
					// 		lead.w5.cancel += 1;
					// 		summaryWeekly.w5.cancel += 1;
					// 	}
                    // }
                });

            });

            this.setState({contactLeads: tmpContactLeads, summaryWeekly: summaryWeekly, performaTotalCancel: totalCancel, performaTotalLeads: totalLeads, performaTotalPengurangLeads: totalPengurang, isLoading: false, additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null}, () => {
                console.log("CONTACTLEADS : ", this.state.contactLeads);
            });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      });
      
    };

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah FU', minWidth: 100 },
        // { id: 'totalAdaResponLavender', label: 'Respon Lavender', minWidth: 100 },
        { id: 'totalAdaResponHsm', label: 'Respon HSM', minWidth: 100 },
        // { id: 'responRateLavender', label: 'Respon Rate Lavender', minWidth: 100 },
        { id: 'responRate', label: 'Respon Rate HSM', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => this.handleRefresh());
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        return ( 
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

            {
              this.state.currentConversation !== null ? (
                  <Dialog
                      open={this.state.currentConversation !== null}
                      // TransitionComponent={Transition}
                      // keepMounted
                      onClose={() => {this.setState({currentConversation: null})}}
                      aria-describedby=""
                  >
                      {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                      <DialogContent style={{height: "100%"}}>
                          <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} />
                      </DialogContent>
                      <DialogActions>
                      {/* <Button onClick={handleClose}>Disagree</Button>
                      <Button onClick={handleClose}>Agree</Button> */}
                      </DialogActions>
                  </Dialog>
              ) : (<></>)
            }

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-2" style={{padding: "5px"}}>
                            <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                          </div>
                          <div className="col-2">
                              <FormControl variant="outlined" style={{width: '100%'}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.appProfileId}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl>
                            </div>
                          <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                              
                              {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.handleRefresh}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                              
                            </div>
                        </div>                        
                        <h4>Weekly</h4>
                        <small>Persentase leads cancel mingguan untuk masing sales</small>
                        <br /><br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                      <TableCell key={"head1"} align={"left"} style={{fontWeight: "bold"}}>
                                        Sales
                                      </TableCell>
                                      <TableCell key={"head2"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 1
                                      </TableCell>
                                      <TableCell key={"head3"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 2
                                      </TableCell>
                                      <TableCell key={"head4"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 3
                                      </TableCell>
                                      <TableCell key={"head5"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 4
                                      </TableCell>
                                      <TableCell key={"head1"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 5
                                      </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.contactLeads.map((row) => {
                                    return (
                                        <TableRow>
                                          <TableCell>{row.salesName}</TableCell>
                                          <TableCell align={"center"}>{row.w1.leads > 0 ? Math.round(row.w1.cancel / (row.w1.leads-row.w1.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"}>{row.w2.leads > 0 ? Math.round(row.w2.cancel / (row.w2.leads-row.w2.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"}>{row.w3.leads > 0 ? Math.round(row.w3.cancel / (row.w3.leads-row.w3.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"}>{row.w4.leads > 0 ? Math.round(row.w4.cancel / (row.w4.leads-row.w4.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"}>{row.w5.leads > 0 ? Math.round(row.w5.cancel / (row.w5.leads-row.w5.pengurang) * 100) : 0}%</TableCell>
                                        </TableRow>
                                    );
                                    })}
                                    <TableRow>
                                          <TableCell style={{fontWeight: "bold"}}>Summary</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w1 && this.state.summaryWeekly.w1.leads > 0 ? Math.round(this.state.summaryWeekly.w1.cancel / (this.state.summaryWeekly.w1.leads-this.state.summaryWeekly.w1.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w2 && this.state.summaryWeekly.w2.leads > 0 ? Math.round(this.state.summaryWeekly.w2.cancel / (this.state.summaryWeekly.w2.leads-this.state.summaryWeekly.w2.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w3 && this.state.summaryWeekly.w3.leads > 0 ? Math.round(this.state.summaryWeekly.w3.cancel / (this.state.summaryWeekly.w3.leads-this.state.summaryWeekly.w3.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w4 && this.state.summaryWeekly.w4.leads > 0 ? Math.round(this.state.summaryWeekly.w4.cancel / (this.state.summaryWeekly.w4.leads-this.state.summaryWeekly.w4.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w5 && this.state.summaryWeekly.w5.leads > 0 ? Math.round(this.state.summaryWeekly.w5.cancel / (this.state.summaryWeekly.w5.leads-this.state.summaryWeekly.w5.pengurang) * 100) : 0}%</TableCell>
                                        </TableRow>
                                </TableBody>
                                <TableFooter>
                                </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>
            </Paper>
         );
    }
}
 
export default Weekly;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});


  function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }