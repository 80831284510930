import { Campaign, FirstPage, Info, LastPage, NavigateBefore, NavigateNext, Search } from '@mui/icons-material';
import { Autocomplete, Avatar, Box, Button, Chip, CircularProgress, Container, Divider, Fab, FormControl, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, InputAdornment, InputLabel, LinearProgress, ListSubheader, OutlinedInput, Paper, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import axios from "axios";
import ucwords from 'ucwords';
import base64 from "base-64";
import { color } from 'chart.js/helpers';
class FlowFormOrder extends Component {
    state = { 
        countries: [],
        provinces: [],
        cities: [],
        productCategories: [],
        colors: [],
        flowers: [],
        jambuls: [],
        sizes: [],
        catalogs: [],
        selectedParams: {
            sku: "",
            productCategory: null,
            country: null,
            province: null,
            city: null,
            color: null,
            flower: null,
            jambul: null,
            size: null
        },
        selectedProduct: null,
        listTemplates: [],
        isLoadingCatalogs: false,
        isSendingHsm: false
    } 

    loadAttributes = () => {
        axios
          .get(Api.lavenderGetAttributes(), Api.getRequestHeaderNoAuth())
          .then((response) => {
            const colors = response.data.color;
            const flowers = response.data.flower;
            const jambuls = response.data.jambul;
            const sizes = response.data.ukuran;

            colors.forEach(element => {
                element.label = element.name;
            });
            flowers.forEach(element => {
                element.label = element.name;
            });
            jambuls.forEach(element => {
                element.label = element.name;
            });
            sizes.forEach(element => {
                element.label = element.name;
            });

            this.setState({colors: colors, flowers: flowers, jambuls: jambuls, sizes: sizes});

          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadProductCategories = () => {
        axios
          .get(Api.lavenderGetProductCategories(), Api.getRequestHeaderNoAuth())
          .then((response) => {
            let categories = response.data.categories;
            categories.forEach(item => {
                item.label = item.name;
            });

            this.setState({productCategories: categories});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadCountries = () => {
        axios
          .get(Api.lavenderGetCountries(), Api.getRequestHeaderNoAuth())
          .then((response) => {
            let countries = response.data;
            countries.forEach(item => {
                item.label = item.name;
            });

            this.setState({countries: countries});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadProvinces = () => {
        console.log("PROVINCES", this.state.selectedParams.country);
        if(this.state.selectedParams.country === null || this.state.selectedParams.country === undefined){
            return;
        }

        axios
          .get(Api.lavenderGetAreaByParent(this.state.selectedParams.country?.id), Api.getRequestHeaderNoAuth())
          .then((response) => {
            let provinces = response.data;
            provinces.forEach(item => {
                item.label = item.name;
            });

            this.setState({provinces: provinces});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadCities = () => {
        console.log("CITIES", this.state.selectedParams.province);
        if(this.state.selectedParams.province === null || this.state.selectedParams.province === undefined){
            return;
        }

        axios
          .get(Api.lavenderGetAreaByParent(this.state.selectedParams.province.id), Api.getRequestHeaderNoAuth())
          .then((response) => {
            let cities = response.data;
            cities.forEach(item => {
                item.label = item.name;
            });

            this.setState({cities: cities});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadCatalogs = () => {
        this.setState({isLoadingCatalogs: true}, () => {
            let selected = {...this.state.selectedParams};
            let payload = {
                categoryId : selected?.productCategory?.id ?? "", 
                countryId : selected?.country?.id ?? "", 
                provinceId : selected?.province?.id ?? "", 
                cityId : selected?.city?.id ?? "", 
                colorId : selected?.color?.id ?? "", 
                flowerId : selected?.flower?.id ?? "", 
                jambulId : selected?.jambul?.id ?? "", 
                name : selected?.sku ?? "", 
                sort : "", 
                sizeId : selected?.size?.id ?? ""
            };
            console.log("LOG___load_catalogs", payload);
            axios
            .get(Api.lavenderGetProductCatalogs(payload), Api.getRequestHeaderNoAuth())
            .then((response) => {
                this.setState({catalogs: response.data, isLoadingCatalogs: false});
            })
            .catch((error) => {
                this.setState({isLoadingCatalogs: false});
                console.error("There was an error!", error);
            });
        });
    };
    
    loadCatalogsByUrl = (url) => {
        this.setState({isLoadingCatalogs: true}, () => {
            let selected = {...this.state.selectedParams};
            let payload = {
                categoryId : selected?.id ?? "", 
                countryId : selected?.id ?? "", 
                provinceId : selected?.id ?? "", 
                cityId : selected?.id ?? "", 
                colorId : selected?.id ?? "", 
                flowerId : selected?.id ?? "", 
                jambulId : selected?.id ?? "", 
                name : selected?.sku ?? "", 
                sort : "", 
                sizeId : selected?.id ?? ""
            };

            let params = `category_id=${payload.categoryId}&city=${payload.cityId}&color=${payload.colorId}&country=${payload.countryId}&flower=${payload.flowerId}&jambul=${payload.jambulId}&name=${payload.name}&province=${payload.provinceId}&sort=&ukuran=${payload.sizeId}`;

            axios
            .get(`${url}&${params}`, Api.getRequestHeaderNoAuth())
            .then((response) => {
                this.setState({catalogs: response.data, isLoadingCatalogs: false});
            })
            .catch((error) => {
                this.setState({isLoadingCatalogs: false});
                console.error("There was an error!", error);
            });
        });
    };

    handleSkuChange = (event) => {
        let selected = {...this.state.selectedParams};
        selected.sku = event.target.value;
        this.setState({selectedParams: selected});
    }
    handleCategoryChange = (event, newValue) => {
        let selected = {...this.state.selectedParams};
        let findCategory = this.state.productCategories.find(item => item.label === newValue);
        console.log("LOG___cate", findCategory);
        selected.productCategory = findCategory;
        if(findCategory){
            this.setState({selectedParams: selected}, () => this.loadCatalogs());
        }
    }
    handleCountryChange = (event, newValue) => {
        console.log("LOG___COUNTRYCHANGE", newValue);
        if(newValue === null || newValue === undefined){
            return;
        }
        let selected = {...this.state.selectedParams};

        let findCountry = this.state.countries.find(item => item.label === newValue);

        selected.country = findCountry;
        if(findCountry){
            this.setState({selectedParams: selected}, () => {
                this.loadProvinces();
                this.loadCatalogs();
            });
        }
    }
    handleProvinceChange = (event, newValue) => {
        let selected = {...this.state.selectedParams};
        let findCountry = this.state.provinces.find(item => item.label === newValue);
        console.log("LOG___province", newValue, findCountry)
        selected.province = findCountry;
        if(findCountry){
            this.setState({selectedParams: selected}, () => {this.loadCities();this.loadCatalogs();});
        }
    }
    handleCityChange = (event, newValue) => {
        let selected = {...this.state.selectedParams};
        let findCountry = this.state.cities.find(item => item.label === newValue);
        selected.city = findCountry;
        if(findCountry){
            this.setState({selectedParams: selected}, () => this.loadCatalogs());
        }
    }
    handleColorChange = (event, newValue) => {
        console.log("CHANGED_COLOR", newValue);
        let selected = {...this.state.selectedParams};
        let findColor = this.state.colors.find(item => item.label === newValue)
        selected.color = findColor;
        if(findColor){
            this.setState({selectedParams: selected}, () => this.loadCatalogs());
        }
    }
    handleFlowerChange = (event, newValue) => {
        let selected = {...this.state.selectedParams};
        let findFlower = this.state.flowers.find(item => item.label === newValue);
        selected.flower = findFlower;
        if(findFlower){
            this.setState({selectedParams: selected}, () => this.loadCatalogs());
        }
    }
    handleJambulChange = (event, newValue) => {
        let selected = {...this.state.selectedParams};
        let findJambul = this.state.jambuls.find(item => item.label === newValue)
        selected.jambul = findJambul;
        if(findJambul){
            this.setState({selectedParams: selected}, () => this.loadCatalogs());
        }
    }
    handleSizeChange = (event, newValue) => {
        let selected = {...this.state.selectedParams};
        let findSize = this.state.sizes.find(item =>item.label === newValue);
        selected.size = findSize;
        if(findSize){
            this.setState({selectedParams: selected}, () => this.loadCatalogs());
        }
    }

    handleSelectProduct = (product) => {
        this.setState({selectedProduct: product}, () => {
            console.log("LOG__SELECTED_PRODUCT", product);
        });
    }

    getHsmTemplate = () => {

        // this.setState(
        //     {isLoading: true}, 
        //     () => {
                var userData = Global.get();
                const messageData = this.props.messageData();
                const params = {
                    userId: userData.user.id,
                    waBussinesNumber: messageData.from,
                    appProfileId: Global.getAppProfile()._id
                };

                console.log("API URL : ", Api.getWaMessageTemplates());
                console.log("PARAMS : ", params);
                console.log("HEADER : ", Api.getRequestHeader());

                axios
                .post(Api.getWaMessageTemplates(), params, Api.getRequestHeader())
                .then((response) => {
                    
                    console.log("MyCustomerToday : ", response.data.data.templates);
                    var responseData = response.data.data;
                    var templates = responseData.templates;

                    templates.forEach(element => {
                        element.labelName = ucwords(element.name.replaceAll("_", " "));
                        let count = (element.structure.body.match(/{{/g) || []).length;
                        var params = [];
                        for(var i=0; i < count; i++){
                            params.push({paramValue : "", paramIndex: i, paramLabel: i+1});
                        }
                        element.params = params;
                    });

                    console.log("TEMPLATES : ", templates);

                    this.setState({listTemplates: templates}, () => {console.log(this.state.listTemplates)});
                    // this.setState({listSales: response.data.data});
                })
                .catch((error) => {
                    this.setState({isLoading: false});
                    console.error("There was an error!", userData.user.id, error);
                });
            // }
        // );
        
    };

    handleSendTemplate = () => {

        if(this.state.isSendingHsm){
            return;
        }

        const hsmNameByApp = [
            {
                waNumber: "6281231828249",
                hsmName: "template_hsm_membawa_link_order_prestisa_111024",
                website: "prestisa.com"
            },
            {
                waNumber: "6287883795283",
                hsmName: "template_hsm_membawa_link_form_order_loveftw_111024",
                website: "loveftw.com"
            }
        ];

        let waNumber =  Global.getAppProfile().waNumber;
        let hsmName = hsmNameByApp.find(item => item.waNumber === waNumber);

        if(hsmName === undefined || hsmName === null){
            return;
        }

        let templateData = this.state.listTemplates.find(item => item.name === hsmName.hsmName);

        if(templateData === undefined || templateData === null){
            return;
        }

        console.log("SENDTHISTEMPLATE : ", templateData);
        // return;
        // console.log("MESSAGEDATA : ", this.props.messageData());

        const messageData = this.props.messageData();
        const templateParams = templateData.params;
        const templateHeader = templateData.header;
        let templateText = templateData.structure.body;
        let paramToSend = [];

        var i = 0;
        templateParams.forEach(param => {
            console.log("PARAMVALUE "+1, param);
            // templateText = templateText.replaceAll("{{"+(i+1)+"}}", param.paramValue);
            // paramToSend.push(param.paramValue);
            
            let paramsJson = {
                userId: Global.get().user.id,
                productCode: this.state.selectedProduct.product_code,
                website: hsmName.website,
                waNumber: messageData.to,
                page: "order"
            }

            console.log("LOG___paramHSM", paramsJson);
            let payloadBase64 = base64.encode(JSON.stringify(paramsJson));
            // let url = `https://prestisa.id/order/${this.state.selectedProduct.product_code}`;
            let url = `https://order.prestisa.id/order/${paramsJson.website}/${paramsJson.productCode}/${paramsJson.waNumber}/${paramsJson.userId}`;
            templateText = templateText.replaceAll("{{"+(i+1)+"}}", url);
            paramToSend.push(url);
            ++i;
        });

        if(templateHeader !== undefined && templateHeader.type === "IMAGE"){
            messageData.message.type = "IMAGE";
            messageData.message.fileName = templateHeader.imageUrl;
            messageData.message.caption = templateText;
        }
        else if(templateHeader !== undefined && templateHeader.type === "VIDEO"){
            messageData.message.type = "VIDEO";
            messageData.message.fileName = templateHeader.imageUrl;
            messageData.message.caption = templateText;
        }
        else if(templateHeader !== undefined && templateHeader.type === "DOCUMENT"){
            messageData.message.type = "DOCUMENT";
            messageData.message.fileName = templateHeader.imageUrl;
            messageData.message.caption = templateText;
        }
        else{
            messageData.message.text = templateText;
        }

        console.log("MESSAGEDATA_FINAL",templateData.name, messageData);
        // return;

        this.setState(
            {isSendingHsm: true, isSending: true, sendingTemplateId: templateData.id}, 
            () => {

                let params = {
                    templateParameters : {
                        from: messageData.from,
                        to: messageData.to,
                        toName: messageData.contact.name,
                        templateName: templateData.name,
                        templateNameSpace: templateData.nameSpace,
                        templateLanguage: templateData.language,
                        placeholders: paramToSend,
                        fileName: templateHeader !== undefined ? templateHeader.description : "",
                        hsmCategory: templateData.category
                    },
                    message: messageData,
                    appProfileId: Global.getAppProfile()._id
                };

                if(Global.getAppProfile().appVendor === "QONTAK"){
                    params.templateParameters.templateId = templateData.id;
                    params.templateParameters.organizationId = templateData.organizationId;
                }

                if(templateHeader !== undefined){
                    if(templateHeader.type === "IMAGE"){
                        params.templateParameters.header = {
                            type: templateHeader.type,
                            mediaUrl: templateHeader.imageUrl
                        }
                    }
                    if(templateHeader.type === "VIDEO"){
                        params.templateParameters.header = {
                            type: templateHeader.type,
                            mediaUrl: templateHeader.imageUrl
                        }
                    }
                    if(templateHeader.type === "DOCUMENT"){
                        params.templateParameters.header = {
                            type: templateHeader.type,
                            mediaUrl: templateHeader.imageUrl
                        }
                    }
                }

                console.log("TEMPATEPARAMS : ",params);
                // return;
                var userData = Global.get();
                axios
                .post(Api.sendMessageTemplate(), params, Api.getRequestHeader())
                .then((response) => {
                    console.log("RESPONSE_TEMPLATE",response.data);
                    this.setState({isSendingHsm: false, sendingTemplateId: 0});
                    this.props.pushMessageHandler(response.data.data.messageData);
                    this.props.onClose();
                })
                .catch((error) => {
                    this.setState({isSendingHsm: false, isSending: false, sendingTemplateId: 0});
                    console.error("There was an error!", userData.user.id, error);
                });
            }
        );

    }

    onKeyDownHandler = (e) => {
        if(e.keyCode === 13){
            this.loadCatalogs();
            console.log("CTRL + ENTER");
        }
      };

    componentDidMount() {
        this.loadAttributes();
        this.loadProductCategories();
        this.loadCatalogs();
        this.loadCountries();
        this.getHsmTemplate();
    }

    render() { 
        return (
            <Box maxWidth={"xl"}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Paper elevation={3} sx={{p: 1, textAlign: "center", mt: 1, position: "fixed", width: "21vw"}}>
                            <h4>Produk Yang Dipilih</h4>
                            <Divider sx={{mt: 1, mb: 1}} />
                            {
                                this.state.selectedProduct !== null ? (
                                    <Box alignItems={"center"} alignContent={"center"} textAlign={"center"} sx={{textAlign: "center", pt: 1}}>
                                        <center>
                                        <Avatar 
                                            src={`${Api.getLavenderUrl()}/${this.state.selectedProduct.image}?w=248&fit=crop&auto=format`} 
                                            sx={{width: "12vw", height: "12vw"}}
                                        />
                                        </center>
                                        <br />
                                        <small><strong>{this.state.selectedProduct.name}</strong></small><br />
                                        <Chip size='small' label={this.state.selectedProduct.product_code} variant='outlined' /><br />
                                        
                                        <small>{this.state.selectedProduct.description}</small><br />
                                        {
                                            this.state.isSendingHsm ? 
                                                <Box>
                                                    <Divider sx={{mt: 1, mb: "1px"}} />
                                                    <LinearProgress sx={{mb: 2}} />
                                                </Box> : <Divider sx={{mt: 1, mb: 2}} />
                                        }
                                        
                                        <Button variant='contained' fullWidth color='secondary' disabled={this.state.isSendingHsm} onClick={this.handleSendTemplate}>
                                            Kirim link order
                                        </Button>
                                    </Box>
                                ) : (<>Pilih produk terlebih dahulu</>)
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={9}>
                        <h4>Pencarian Produk</h4>
                        <Grid container spacing={1}>
                            <Grid item xs={9}>
                                <FormControl sx={{ marginBottom: 2, marginTop: 1}} fullWidth variant="outlined" size='small'>
                                    <InputLabel htmlFor="txt-search">SKU</InputLabel>
                                    <OutlinedInput
                                        id="txt-search"
                                        type={'text'}
                                        startAdornment={
                                        <InputAdornment position="start">
                                            {/* <IconButton> */}
                                                <Search />
                                            {/* </IconButton> */}
                                        </InputAdornment>
                                        }
                                        placeholder='Ketikkan SKU yang ingin dicari'
                                        label="SKU"
                                        onChange={this.handleSkuChange}
                                        onKeyDown={this.onKeyDownHandler}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant='contained' fullWidth color='primary' sx={{mt: "9px"}} onClick={this.loadCatalogs}>
                                    <Search /> Cari
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Autocomplete
                                    // disablePortal
                                    id="auto-kategori-produk"
                                    options={this.state.productCategories}
                                    renderInput={(params) => <TextField {...params} label="Kategori Produk" />}
                                    onChange={(event, newValue) => this.handleCategoryChange(event, newValue)}
                                    onInputChange={(event, newValue) => this.handleCategoryChange(event, newValue)}
                                    // autoHighlight
                                    // freeSolo={true}
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    // disablePortal
                                    id="auto-negara"
                                    // freeSolo
                                    options={this.state.countries}
                                    renderInput={(params) => <TextField {...params} label="Negara"
                                    // autoHighlight
                                    size='small'
                                    // onChange={(event, newValue) => {this.handleCountryChange(event, newValue)}} />}
                                    onChange={(event, newValue) => {console.log("LOG___", event, newValue)}} />}
                                    onInputChange={(event, newValue) => {this.handleCountryChange(event, newValue)}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    // disablePortal
                                    id="auto-provinsi"
                                    // freeSolo
                                    options={this.state.provinces}
                                    renderInput={(params) => <TextField {...params} label="Provinsi" />}
                                    // autoHighlight
                                    size='small' 
                                    onChange={(event, newValue) => this.handleProvinceChange(event, newValue)}
                                    onInputChange={(event, newValue) => {this.handleProvinceChange(event, newValue)}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    // disablePortal
                                    id="auto-kota"
                                    options={this.state.cities}
                                    // freeSolo
                                    autoHighlight
                                    renderInput={(params) => <TextField {...params} label="Kota" />}
                                        size='small'
                                        onChange={(event, newValue) => this.handleCityChange(event, newValue)}
                                        onInputChange={(event, newValue) => {this.handleCityChange(event, newValue)}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    // disablePortal
                                    id="auto-warna-papan"
                                    options={this.state.colors}
                                    // freeSolo
                                    autoHighlight
                                    renderInput={(params) => <TextField {...params} label="Warna Papan" />}
                                        size='small'
                                        onChange={(event, newValue) => this.handleColorChange(event, newValue)} 
                                        onInputChange={(event, newValue) => this.handleColorChange(event, newValue)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    // disablePortal
                                    id="auto-bunga"
                                    options={this.state.flowers}
                                    renderInput={(params) => <TextField {...params} label="Bunga"/>}
                                    autoHighlight
                                    size='small'
                                    onChange={(event, newValue) => this.handleFlowerChange(event, newValue)}
                                    onInputChange={(event, newValue) => this.handleFlowerChange(event, newValue)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    // disablePortal
                                    id="auto-jumlah-jambul"
                                    options={this.state.jambuls}
                                    renderInput={(params) => <TextField {...params} label="Jumlah Jambul"/>}
                                    autoHighlight
                                    size='small'
                                    onChange={(event, newValue) => this.handleJambulChange(event, newValue)} 
                                    onInputChange={(event, newValue) => this.handleJambulChange(event, newValue)} 
                                    
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    // disablePortal
                                    id="auto-ukuran-papan"
                                    options={this.state.sizes}
                                    renderInput={(params) => <TextField {...params} label="Ukuran Papan"/>}
                                    autoHighlight
                                    size='small'
                                    onChange={(event, newValue) => this.handleSizeChange(event, newValue)} 
                                    onInputChange={(event, newValue) => this.handleSizeChange(event, newValue)} 
                                    
                                />
                            </Grid>
                        </Grid>
                        {
                            this.state.isLoadingCatalogs ? (
                                <Box textAlign={"center"}>
                                    <LinearProgress sx={{marginTop: 2}} />
                                    <strong><small>Menunggu katalog dari lavender...</small></strong>
                                </Box>
                            ) : (<Divider sx={{marginTop: 2}} />)
                        }
                        
                        <ImageList cols={4} gap={15} sx={{marginTop: 2}}>
                            {/* <ImageListItem key="Subheader" cols={2}>
                                <ListSubheader component="div">December</ListSubheader>
                            </ImageListItem> */}
                            {this.state.catalogs?.data?.map((product) => (
                                <ImageListItem key={product.id} onClick={() => this.handleSelectProduct(product)} sx={{cursor: "pointer"}}>
                                <img
                                    srcSet={`${Api.getLavenderUrl()}/${product.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${Api.getLavenderUrl()}/${product.image}?w=248&fit=crop&auto=format`}
                                    alt={product.name}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={product.name}
                                    subtitle={"Rp " + Intl.NumberFormat('en-ID', { maximumSignificantDigits: 2 }).format(
                                        product.price,
                                      )}
                                    // actionIcon={
                                    // <IconButton
                                    //     sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    //     aria-label={`info about ${product.name}`}
                                    // >
                                    //     <Info />
                                    // </IconButton>
                                    // }
                                    sx={{fontSize: "10px"}}
                                />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Grid>
                </Grid>

                <Fab variant="extended" size="small" color='' sx={{position: "fixed", bottom: 50, right: "4vw"}}>
                    <ToggleButtonGroup
                        size='small'
                        color="primary"
                        // value={alignment}
                        exclusive
                        // onChange={handleChange}
                        aria-label="Platform"
                        >
                        <ToggleButton value="first" disabled={this.state.catalogs?.first_page_url === null} onClick={() => this.loadCatalogsByUrl(this.state.catalogs?.first_page_url)}>
                            <Tooltip title="First">
                                <FirstPage />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="prev" disabled={this.state.catalogs?.prev_page_url === null} onClick={() => this.loadCatalogsByUrl(this.state.catalogs?.prev_page_url)}>
                            <Tooltip title="Previous">
                                <NavigateBefore />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="next" disabled={this.state.catalogs?.next_page_url === null} onClick={() => this.loadCatalogsByUrl(this.state.catalogs?.next_page_url)}>
                            <Tooltip title="Next">
                                <NavigateNext />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="last" disabled={this.state.catalogs?.last_page_url === null} onClick={() => this.loadCatalogsByUrl(this.state.catalogs?.last_page_url)}>
                            <Tooltip title="Last">
                                <LastPage />
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Fab>

            </Box>
        );
    }
}
 
export default FlowFormOrder;