import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch, TextField } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Api from "../../customlib/Api";
import Global from "../../customlib/Global";
import { ContactSupportOutlined } from '@mui/icons-material';

class EditPinnedMessage extends Component {
    state = { 
        noteData: {
            _id: null,
            contact_number: this.props.currentConversation.fromNumber,
            price_category: "",
            most_ordered_city: "",
            color_and_request_order: "",
            payment_method: "",
            sender_use_logo: false,
            previous_sender_name: "",
            additional_note: ""
        }
    } 

    handlePriceCategoryChange = (event) => {
        let note = {...this.state.noteData}
        note.price_category = event.target.value;
        this.setState({noteData: note});
    }
    handleMostOrderedCityChange = (event) => {
        let note = {...this.state.noteData}
        note.most_ordered_city = event.target.value;
        this.setState({noteData: note});
    }
    handleColorAndRequestOrderChange = (event) => {
        let note = {...this.state.noteData}
        note.color_and_request_order = event.target.value;
        this.setState({noteData: note});
    }
    handlePaymentMethodChange = (event) => {
        let note = {...this.state.noteData}
        note.payment_method = event.target.value;
        this.setState({noteData: note});
    }
    handleSenderUseLogoChange = (event) => {
        let note = {...this.state.noteData}
        note.sender_use_logo = event.target.value;
        this.setState({noteData: note});
    }
    handlePreviousSenderNameChange = (event) => {
        let note = {...this.state.noteData}
        note.previous_sender_name = event.target.value;
        this.setState({noteData: note});
    }
    handleAdditionalNoteChange = (event) => {
        let note = {...this.state.noteData}
        note.additional_note = event.target.value;
        this.setState({noteData: note});
    }

    componentDidMount() {
        this.setState({noteData: {...this.props.noteData}});
    }

    handleSubmit = () => {

        let params = {...this.state.noteData};
        if(this.isEditing()){
            params.id = params._id;
        }
        params.contact_number = this.props.currentConversation.fromNumber;
        console.log("PARAMS ", params);
        // return;
        axios
          .post(Api.contactNoteSave(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACT_NOTE_LIST : ", response.data);
    
            const responseData = response.data;
    
            if(responseData.success === true){
              this.props.callback();
              this.props.onClose();
            }else{
              this.setState({errorMessage: responseData.message});
            }
    
            
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
          });
    }

    isEditing = () => {
        console.log("NOTEDATA", this.state.noteData);
        return this.state.noteData._id !== null && this.state.noteData._id !== undefined && this.state.noteData._id !== "";
    }

    render() { 
        return (<>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.props.isDialogOpen ?? false}
                onClose={this.props.onClose}
            >
                <DialogTitle sx={{backgroundColor: "#deecfd"}}>
                    <div className="row">
                        <div className="col-10">
                            {this.isEditing() ? "Edit" : "Create New"} Note
                        </div>
                        <div className="col-2 text-right">
                            <Button color='error' size='small' onClick={this.props.onClose}>X</Button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent
                    sx={{backgroundColor: "#deecfd"}}>
                    <DialogContentText>
                        Isi form berikut untuk membuat catatan pada chat ini
                    </DialogContentText>
                    <Paper
                        noValidate
                        component="form"
                        sx={{padding: "15px", mt: 2}}
                    >
                        <FormControl sx={{ mt: 0, minWidth: 120 }} size='small' fullWidth>
                            <InputLabel htmlFor="kategori-harga">Kategori Harga</InputLabel>
                            <Select
                                autoFocus
                                value={this.state.noteData.price_category}
                                onChange={this.handlePriceCategoryChange}
                                label="Kategori Harga"
                                inputProps={{
                                    name: 'kategori-harga',
                                    id: 'kategori-harga',
                                }}
                                fullWidth
                                size='small'
                            >
                                <MenuItem value="Range 500.000 - 700.000">Range 500.000 - 700.000</MenuItem>
                                <MenuItem value="Range 700.000 - 800.000">Range 700.000 - 800.000</MenuItem>
                                <MenuItem value="Range 800.000 - 1.000.000">Range 800.000 - 1.000.000</MenuItem>
                                <MenuItem value="Range 1.000.000 - 1.200.000">Range 1.000.000 - 1.200.000</MenuItem>
                                <MenuItem value="Range 1.200.000 - 1.500.000">Range 1.200.000 - 1.500.000</MenuItem>
                                <MenuItem value="Range 1.500.000 - 1.500.000">Range 1.500.000 - 2.000.000</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ mt: 2, minWidth: 120 }} size='small' fullWidth>
                            <InputLabel htmlFor="kota-sering-pesan">Kota yang sering dipesan</InputLabel>
                            <Select
                                // autoFocus
                                value={this.state.noteData.most_ordered_city}
                                onChange={this.handleMostOrderedCityChange}
                                label="Kota yang sering dipesan"
                                inputProps={{
                                    name: 'kota-sering-pesan',
                                    id: 'kota-sering-pesan',
                                }}
                                fullWidth
                                size='small'
                            >
                                <MenuItem value="JABODETABEK">Jabodetabek</MenuItem>
                                <MenuItem value="JAWA">Jawa</MenuItem>
                                <MenuItem value="LUAR JAWA">Luar Jawa</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ mt: 2, minWidth: 120 }} size='small' fullWidth>
                            {/* <InputLabel htmlFor="warna-request">Warna dan request pesanan</InputLabel> */}
                            <TextField id='warna-request' onChange={this.handleColorAndRequestOrderChange} value={this.state.noteData.color_and_request_order} size='small' fullWidth variant='outlined' label="Warna dan request pesanan"  />
                        </FormControl>
                        <FormControl sx={{ mt: 2, minWidth: 120 }} size='small' fullWidth>
                            <InputLabel htmlFor="pembayaran">Metode Pembayaran</InputLabel>
                            <Select
                                // autoFocus
                                value={this.state.noteData.payment_method}
                                onChange={this.handlePaymentMethodChange}
                                label="Pembayaran"
                                inputProps={{
                                    name: 'pembayaran',
                                    id: 'pembayaran',
                                }}
                                fullWidth
                                size='small'
                            >
                                <MenuItem value="CASH">Cash</MenuItem>
                                <MenuItem value="PIUTANG">Piutang</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ mt: 2, minWidth: 120 }} size='small' fullWidth>
                            <InputLabel htmlFor="pakai-logo">Nama pengirim pakai logo?</InputLabel>
                            <Select
                                // autoFocus
                                value={this.state.noteData.sender_use_logo}
                                onChange={this.handleSenderUseLogoChange}
                                label="Nama pengirim pakai logo?"
                                inputProps={{
                                    name: 'pakai-logo',
                                    id: 'pakai-logo',
                                }}
                                fullWidth
                                size='small'
                            >
                                <MenuItem value="Nama Pengirim Pakai LOGO">Nama Pengirim Pakai LOGO</MenuItem>
                                <MenuItem value="Nama Pengirim Tidak Pakai LOGO">Nama Pengirim Tidak Pakai LOGO</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ mt: 2, minWidth: 120 }} size='small' fullWidth>
                            {/* <InputLabel htmlFor="warna-request">Nama pengirim pemesanan sebelumnya</InputLabel> */}
                            <TextField id='nama-pengirim' onChange={this.handlePreviousSenderNameChange} value={this.state.noteData.previous_sender_name} size='small' fullWidth variant='outlined' label="Nama pengirim pemesanan sebelumnya"  />
                        </FormControl>
                        <FormControl sx={{ mt: 2, minWidth: 120 }} size='small' fullWidth>
                            {/* <InputLabel htmlFor="warna-request">Warna dan request pesanan</InputLabel> */}
                            <TextField id='note-tambahan' onChange={this.handleAdditionalNoteChange} value={this.state.noteData.additional_note} multiline size='small' fullWidth variant='outlined' label="Note tambahan"  />
                        </FormControl>
                    </Paper>
                    {/* <br />
                    <Divider /> */}
                </DialogContent>
                <DialogActions sx={{backgroundColor: "#deecfd"}}>
                    <Button style={{marginRight: "13px"}} color='primary' variant='contained' onClick={this.handleSubmit}>{this.isEditing() ? "Update" : "Create"} Note</Button>
                    {/* <Button color='error' variant='outlined' onClick={() => {}}>Close</Button> */}
                </DialogActions>
            </Dialog>
        </>);
    }
}
 
export default EditPinnedMessage;