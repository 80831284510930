import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, TableFooter } from '@mui/material';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Check, Close, QuestionAnswer, TrainRounded } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../MessageListViewer';
import AllDataCancelByUser from './AllDataCancelFUByUser';

class SummaryDatabaseDataCancel extends Component {
    state = { 
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        totalDatabase: [],
        summary : {
          total: 0,
          totalClosing: 0,
          persentaseClosing: 0
        },
        performaClosing: [],
        isLoadingPerformaClosing: false
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.handleRefresh();
          // this.getResumeWeekly(true);
        }
      );

      this.loadPerformaClosing();
    }

    handleRefresh = () => {
        this.loadAppProfile();
        this.getContactLeads();
    }

    handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        console.log(this.state.appProfileId);
        // this.getContactLeads();
        this.handleRefresh();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
  };

    loadPerformaClosing = () => {
      const params = {
      };
      
      this.setState({isLoadingPerformaClosing: true}, () => {
        axios
          .post(
            Api.performaClosingAfterFuDataCancel(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("App Profiles", response.data);
            this.setState({performaClosing: response.data.data, isLoadingPerformaClosing: false});
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, loginSuccess: false, isLoadingPerformaClosing: false });
            console.error("There was an error!", error);
          }
        );
      });
      
  };

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], contactLeadsHourly: [], performaTotalCancel: 0, performaTotalLeads: 0, performaTotalPengurangLeads: 0, additionalData: null, isLoading: true}, () => {
            axios
            .post(Api.summaryDatabaseDataCancel(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("SUMMARY : ", response.data);
                let data = response.data.data;
                let summary = {...this.state.summary};
                data.forEach(item => {
                  summary.total += item.total;
                  summary.totalClosing += item.totalClosing;
                  // summary.persentaseClosing += parseFloat(item.persentaseClosing);
                });
                summary.persentaseClosing = summary.total <= 0 ? 0 : summary.totalClosing/summary.total*100;
                this.setState({isLoading: false, totalDatabase: data, summary: summary});
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
      
    };

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => this.handleRefresh());
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        
        return ( 
          <>
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-2" style={{padding: "5px"}}>
                            {/* <MyDateRangePicker onDateChanged={this.handleDateChanged} /> */}
                          </div>
                          <div className="col-2">
                              {/* <FormControl variant="outlined" style={{width: '100%'}} size='small'>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.appProfileId}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl> */}
                            </div>
                          <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                              
                              {/* {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.handleRefresh}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              } */}
                              
                            </div>
                        </div>

                        {/* Performa sales by label */}
                        {/* <Divider style={{marginTop: "10px", marginBottom: "10px"}} /> */}
                        <div className="row align-items-center">
                            <div className="col-10">
                              <h4>Total Database Data Cancel</h4>
                            </div>
                            <div className="col-2 text-right" style={{borderRadius: 10, padding: 5}}>
                            {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.handleRefresh}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                            </div>
                        </div>
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                          <TableCell key={"head1"} align={"left"}>
                                              <strong>Nama</strong>
                                          </TableCell>
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>Jumlah</strong>
                                          </TableCell>
                                          <TableCell key={"head3"} align={"center"}>
                                              <strong>Closing</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>% Closing</strong>
                                          </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.totalDatabase.map((row) => {
                                        return (
                                            <TableRow>
                                            <TableCell>{row.assignToUserName}</TableCell>
                                            <TableCell align='center'>{row.total}</TableCell>
                                            <TableCell align='center'>{row.totalClosing}</TableCell>
                                            <TableCell align='center'>{row.persentaseClosing}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                        <TableRow>
                                            <TableCell><strong>Summary</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.total}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.totalClosing}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.persentaseClosing.toFixed(2)}</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        {/* Performa sales by label */}
                        
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>
            </Paper>
            <br />
            <Paper className="root" style={{padding:20, backgroundColor: "#e6e9ed"}}>
              <div className="row align-items-center">
                <div className="col-10">
                  <h4>Performance Closing After FU</h4>
                </div>
                <div className="col-2 text-right" style={{borderRadius: 10, padding: 5}}>
                {
                    this.state.isLoadingPerformaClosing ? 
                    (
                      <>
                        Sedang memuat
                        <LinearProgress color="secondary" />
                      </>
                    ) : 
                    (
                      <div className="btn btn-primary" onClick={this.loadPerformaClosing}>
                        <Refresh /> Refresh
                      </div>
                    )
                  }
                </div>
                </div>
              <Paper>
              <TableContainer>
                  <Table aria-label="sticky table" size='small'>
                      <TableHead>
                          <TableRow>
                            <TableCell key={"head1"} align={"left"}>
                                <strong>Keterangan</strong>
                            </TableCell>
                            <TableCell key={"head2"} align={"center"}>
                                <strong>Jumlah Data</strong>
                            </TableCell>
                            <TableCell key={"head3"} align={"center"}>
                                <strong>Jumlah Closing</strong>
                            </TableCell>
                            <TableCell key={"head4"} align={"center"}>
                                <strong>% Closing</strong>
                            </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {this.state.performaClosing.map((row) => {
                          return (
                              <TableRow>
                              <TableCell>{row.closingType}</TableCell>
                              <TableCell align='center'>{row.jumlahData}</TableCell>
                              <TableCell align='center'>{row.jumlahClosing}</TableCell>
                              <TableCell align='center'>{row.persenClosing}</TableCell>
                              </TableRow>
                          );
                          })}
                          {/* <TableRow>
                              <TableCell><strong>Summary</strong></TableCell>
                              <TableCell align='center'><strong>{this.state.summary.total}</strong></TableCell>
                              <TableCell align='center'><strong>{this.state.summary.totalClosing}</strong></TableCell>
                              <TableCell align='center'><strong>{this.state.summary.persentaseClosing.toFixed(2)}</strong></TableCell>
                          </TableRow> */}
                      </TableBody>
                  </Table>
              </TableContainer>
              </Paper>
          </Paper>
            <br />
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>
              <AllDataCancelByUser />
            </Paper>
            
            </>
         );
    }
}
 
export default SummaryDatabaseDataCancel;