import Global from "./Global";

class Api {

    isProduction = true;

    isConsoleEnabled = false;
    apiUrl = "http://localhost:8000/api/";
    baseImageFolder = "http://localhost:8000/images/";
    baseDocsFolder = "http://localhost:8000/docs/";
    baseVideosFolder = "http://localhost:8000/videos/";
    baseAudiosFolder = "http://localhost:8000/audios/";
    socketUrl = "http://localhost:4002/";
    lavenderUrl = "https://lavender.prestisa.id/";
    lotusUrl = "http://localhost:3000";


    // apiUrlProduction = "http://34.101.255.51/api/";
    // baseImageFolderProduction = "http://34.101.255.51/images/";
    // baseDocsFolderProduction = "http://34.101.255.51/docs/";
    // socketUrlProduction = "http://34.101.255.51:4001/";

    apiUrlProduction = "https://lotus.prestisa.id/lavenger-backend/public/api/";
    baseImageFolderProduction = "https://lotus.prestisa.id/lavenger-backend/public/images/";
    baseDocsFolderProduction = "https://lotus.prestisa.id/lavenger-backend/public/docs/";
    baseVideosFolderProduction = "https://lotus.prestisa.id/lavenger-backend/public/videos/";
    baseAudiosFolderProduction = "https://lotus.prestisa.id/lavenger-backend/public/audios/";
    // socketUrlProduction = "https://34.126.168.205:4002/";
    socketUrlProduction = "https://testlotus.prestisa.id:4002/";
    lavenderUrlProduction = "https://lavender.prestisa.id/";
    lotusUrlProduction = "https://lotus.prestisa.id/";

    // eslint-disable-next-line no-useless-constructor
    constructor() { }

    getRequestHeader() {
        return {
            headers: {
                "Content-Type": `application/x-www-form-urlencoded`,
                Accept: `application/json`,
                // "Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNzYzMWM2ZDcyNjFkNzZjNDFiNzllOTcwN2VhOTEwZmVmZmZlYzNkZjZkNTFlZWE4OTc0NzkyN2ExOGQ5ZmM0YWNkYmM4ZDdkZTJmOTViMmQiLCJpYXQiOjE2MTc4NjMxNjAsIm5iZiI6MTYxNzg2MzE2MCwiZXhwIjoxNjQ5Mzk5MTYwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.PCWEbaXWX0_GL7SQlKYjKhB34Jhbd3QZYZ1s9RyOka2_1-3e10x8VXB_76Nj7QGc01MyL0pDVzydauQfk9pZoI3DgP64nkPZzHSwKv1Ravl6nO6QCjDzF0ZGiA6fYf-VyiRkRgSbporDTqB5nwxtDYNYWEYzCB8rLAP5q2mmyJoneaT6fbnVbEKOewTpx3wbHdFfxH-rzdZgfR8r6u1H91-OZztVac71N8u0YQfO1Tyz1Y9MxvHJS7mAJCP36dj2ZIyyY4KP5XBnT-1avDwJHv19s5uTlMi4wdKwJkuIslbp5oUzWblS6m6yO46hjyQT5ewUa31FkbHDa_C4co1ZF1muj2mN13NuW0T6nyRCTqITEiAE6d2V3_UNSlJqhiFGmwseE4U9ONkzISPqvob-Z6PdKs3UdBUwkmWT02lBUZkXFf_kjObisTP4gDKKr7qsJMx3-9M4r7i3MGJm_AV1VB7xaRbhpZMykBvZOPTd6piacwBbzTM3S2YTrTbF_cLVzUvAs3EYQ1NivSj8q5NzUJfZWqiuCE0fUvR9vwZ5VhytbmSenHCKO_dpoHOqmtoeSEw1sU-Mno6sxlEmpdPi641VFpK0394fw596f71pLCrtuXEjsnDnxbIKG5by1vTHMSwfg9sogErLWXej5kuMs6WTj_1zzajdg21FfxDvAWI`,
                Authorization: "Bearer " + Global.get().access_token,
            },
        };
    }

    getRequestHeaderNoAuth() {
        return {
            headers: {
                "Content-Type": `application/x-www-form-urlencoded`,
                Accept: `application/json`,
                // "Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTc2YjUyN2FiMGNiMGNlOTUwZGQ2OWYxZWJkOWRmMDU0NGVjYTE4MTQ3MDg0OWY0YmRlZmUxMDkzYjRmNGJiNTcyYzQ0ZDE4Zjk5MWU3ZDIiLCJpYXQiOjE2MDk3Mjc1MDAsIm5iZiI6MTYwOTcyNzUwMCwiZXhwIjoxNjQxMjYzNTAwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.vaBxznomz3Z5_HpiRZ5J_Z3PNeKj57APwx_w66IorcmJVI_bVD1gBodFM_nhX6p6LdSJmkX1Eah64WIBJJiZ__emsN54XLt59BHHvOpSSoYfRKM0NHrpjlXCNzoDaiEY6BVwr74_9rVlR615G5qa_7azUeT2oSiTwUtXWdRUkDDJSq9SxkO_cXms4Zes3WZKSAQmv1NOCUxlbU0NX8Vt3y8bo4oE-eaGktKHua8iyvpABrYITyVmfFJRSu0owe5KzB560oFGLIxJ6ipR3jRO59U-R3mmV7-twIc1mVXY6B96rsyRZX6NFrUAEVyVByGbsjV4Jx4qr3ABN0GzUgLi5HxFS7PvMTTTlYBuB0GEBx5uBFWiH5T1nQLfAmpxSMF0050nyDRk1xH8HNARU3MoadsYwCD6N-81IOz9mm6hh8gXo-d8ZUJdGxi1Iegsh4xPV4ntvEf2CGjBFFZk-GYzgX4yfcilR3Q7pS5N56rukSkBFiipuPRjgtCwXoGZeHB1udWTIyNXLOIcwAR6k27KOP1kvBGy0AMcnxTYJK08VaZGQh6BnrXw3hw_n0GXXFVe0gPUO4IxrHFy0vyuWAFg4xOKdJT0bWykigikvFQM8nkOKvJTZjsbulAyeZo-XGr8FNFeO1WtE0QP7zyd5h6wzuLW7pr_-zc8hAvmJyw-Dbs`,
                // "Authorization": 'Bearer ' + Global.get().access_token,
            },
        };
    }

    getRequestHeaderEmpty() {
        return {
            headers: {},
        };
    }

    setConsoleEnabled(value){
        this.isConsoleEnabled = value;
    }

    getApiUrl() {
        return this.isProduction ? this.apiUrlProduction : this.apiUrl;
    }

    getLavenderUrl() {
        return this.isProduction ? this.lavenderUrlProduction : this.lavenderUrl;
    }

    getLotusUrl() {
        return this.isProduction ? this.lotusUrlProduction : this.lotusUrl;
    }

    getSocketUrl() {
        return this.isProduction ? this.socketUrlProduction : this.socketUrl;
    }

    getBaseImageUrl() {
        return this.isProduction ? this.baseImageFolderProduction : this.baseImageFolder;
    }

    getBaseDocsUrl() {
        return this.isProduction ? this.baseDocsFolderProduction : this.baseDocsFolder;
    }
    
    getBaseVideosUrl() {
        return this.isProduction ? this.baseVideosFolderProduction : this.baseVideosFolder;
    }
    
    getBaseAudiosUrl() {
        return this.isProduction ? this.baseAudiosFolderProduction : this.baseAudiosFolder;
    }

    login() {
        return this.getApiUrl() + "login";
    }

    contactList() {
        return this.getApiUrl() + "contact-list";
    }

    messagesList() {
        return this.getApiUrl() + "messages-list";
    }

    sendMessage() {
        return this.getApiUrl() + "send-message";
    }

    messageTemplate() {
        return this.getApiUrl() + "get-message-template";
    }

    resetUnreadCounter() {
        return this.getApiUrl() + "reset-unread-counter";
    }

    getParentlessContact() {
        return this.getApiUrl() + "parentless-contact-list";
    }

    getAssignedContact() {
        return this.getApiUrl() + "assigned-contact-list";
    }

    getSalesTeleUserList() {
        return this.getApiUrl() + "sales-tele-user-list";
    }

    getSalesChatList() {
        return this.getApiUrl() + "get-sales-chat-list";
    }

    assignContactToUser() {
        return this.getApiUrl() + "assign-contact-to-user";
    }

    bulkAssignContactToUser() {
        return this.getApiUrl() + "bulk-assign-contact-to-user";
    }

    allUserList() {
        return this.getApiUrl() + "all-user-list";
    }

    saveContactManager() {
        return this.getApiUrl() + "save-contact-manager";
    }

    getContactManager() {
        return this.getApiUrl() + "get-contact-manager";
    }

    sendMultimediaMessage() {
        return this.getApiUrl() + "send-multimedia-message";
    }

    getContactLeads() {
        return this.getApiUrl() + "contact-leads-report";
    }

    logout() {
        return this.getApiUrl() + "logout";
    }

    doSaveSales() {
        return this.getApiUrl() + "save-sales";
    }

    getMyCustomerToday() {
        return this.getApiUrl() + "get-todays-contacts";
    }

    deleteSales() {
        return this.getApiUrl() + "delete-sales";
    }

    resendMessage() {
        return this.getApiUrl() + "resend-message";
    }

    getWaMessageTemplates() {
        return this.getApiUrl() + "wa-message-template";
    }

    sendMessageTemplate() {
        return this.getApiUrl() + "send-message-template";
    }

    addContact() {
        return this.getApiUrl() + "add-contact";
    }

    contactListPagedSearchable() {
        return this.getApiUrl() + "contact-list-paged-searchable";
    }

    lavengerUsersList() {
        return this.getApiUrl() + "lavenger-users";
    }

    saveChatUserIds() {
        return this.getApiUrl() + "save-chat-user-ids";
    }

    markAsread() {
        return this.getApiUrl() + "mark-as-read";
    }

    salesResponseTimeReport() {
        return this.getApiUrl() + "sales-response-time-reports";
    }

    setCsHelp() {
        return this.getApiUrl() + "set-cs-help";
    }

    csHelpList() {
        return this.getApiUrl() + "cs-help-list";
    }

    csHelpUnsolvedCount() {
        return this.getApiUrl() + "cs-help-unsolved-count";
    }

    csSaveNote() {
        return this.getApiUrl() + "cs-save-note";
    }

    listAppProfile() {
        return this.getApiUrl() + "list-app-profile";
    }

    saveAppProfile() {
        return this.getApiUrl() + "save-app-profile";
    }

    supplierListPagedSearchable() {
        return this.getApiUrl() + "supplier-list-paged-searchable";
    }

    checkSupplierAvailability() {
        return this.getApiUrl() + "check-supplier-availability";
    }

    releaseSupplier() {
        return this.getApiUrl() + "release-supplier";
    }

    setContactLabel() {
        return this.getApiUrl() + "set-contact-label";
    }

    getHandoverLists() {
        return this.getApiUrl() + "handover-lists";
    }

    bulkAssignContactToUserAuto() {
        return this.getApiUrl() + "bulk-assign-contact-to-user-auto";
    }

    contactCancelListPagedSearchable() {
        return this.getApiUrl() + "contact-cancel-list-paged-searchable";
    }

    historyPerubahanLabelByLeads() {
        return this.getApiUrl() + "resume-history-label-by-leads";
    }

    resumeContactCancelByKategoriProblemMonthly() {
        return this.getApiUrl() + "resume-contact-cancel-by-kategori-monthly";
    }

    resumeContactCancelByPermasalahanMonthly() {
        return this.getApiUrl() + "resume-contact-cancel-by-permasalahan-monthly";
    }

    saveAnalisa() {
        return this.getApiUrl() + "save-analisa";
    }

    getDataPendingList(){
        return this.getApiUrl() + "data-pending-list";
    }

    getContactInfo(){
        return this.getApiUrl() + "contact-info";
    }

    getContactByNumber() {
        return this.getApiUrl() + "get-contact-by-number";
    }

    saveHasilFu(){
        return this.getApiUrl() + "save-hasil-fu";
    }

    getDataPendingFuToday(){
        return this.getApiUrl() + "data-pending-fu-today";
    }

    getDataPendingFuNext(){
        return this.getApiUrl() + "data-pending-fu-next";
    }

    getListSlowResponseMessage() {
        return this.getApiUrl() + "list-slow-response-message";
    }

    getListJustReadMessage() {
        return this.getApiUrl() + "list-just-read-message";
    }

    getListUnfinishedChat() {
        return this.getApiUrl() + "list-unfinished-chat";
    }

    getListHsmImages(type) {
        return this.getLavenderUrl() + "api/tele/dev/06fb3bbd09b99c8db8386c22efa6aba0/imagehsm/getall?tipe=" + type;
    }

    getDataPendingReportFu() {
        return this.getApiUrl() + "data-pending-fu-report";
    }

    getLeadsReportSummary() {
        return this.getApiUrl() + "leads-report-summary";
    }

    getCityList() {
        return this.getApiUrl() + "city-list";
    }

    listRunningCheckpoint() {
        return this.getApiUrl() + "list-running-checkpoint";
    }

    updateCheckpoint() {
        return this.getApiUrl() + "update-checkpoint";
    }

    checkPointReport() {
        return this.getApiUrl() + "check-point-report";
    }

    datapendingPivotTable(){
        return this.getApiUrl() + "data-pending-pivot-table";
    }

    autoHandoverEventReminderCustomer(){
        return this.getApiUrl() + "auto-handover-event-reminder-customer";
    }
    
    dailyClosingRateReport(){
        return this.getApiUrl() + "daily-closing-rate-report";
    }

    listCustomerWajibBayar(){
        return this.getApiUrl() + "list-customer-wajib-bayar";
    }

    listChatNggantung(){
        return this.getApiUrl() + "list-chat-nggantung";
    }

    teleResponseTimeReport() {
        return this.getApiUrl() + "tele-response-time-reports";
    }

    usersBySlugRole() {
        return this.getApiUrl() + "users-by-slug-role";
    }

    verifySession() {
        return this.getApiUrl() + "verify-session";
    }

    checkpointUserList() {
        return this.getApiUrl() + "checkpoint-user-list";
    }
    
    saveCheckpointUser() {
        return this.getApiUrl() + "save-checkpoint-user";
    }

    deleteCheckpointUser() {
        return this.getApiUrl() + "delete-checkpoint-user";
    }

    saveCustomerName() {
        return this.getApiUrl() + "save-customer-name";
    }

    dataCancelResponseTimeReport() {
        return this.getApiUrl() + "data-cancel-response-time-reports";
    }

    historyContactLabel() {
        return this.getApiUrl() + "history-contact-label";
    }

    riwayatPerpindahanContact() {
        return this.getApiUrl() + "monitoring-riwayat-perpindahan-contact";
    }

    getDataPendingLeads() {
        return this.getApiUrl() + "data-pending-leads-reports";
    }

    getDataPendingLeadsParcel() {
        return this.getApiUrl() + "data-pending-leads-parcel-reports";
    }

    getLeadsBelumAdaLabel() {
        return this.getApiUrl() + "leads-belum-ada-label";
    }

    getListDailyFu() {
        return this.getApiUrl() + "list-fu-tele-daily";
    }

    allContactBerlabelCancel() {
        return this.getApiUrl() + "all-contact-berlabel-cancel";
    }
    
    kedisiplinanFuTele = () => {
        return this.getApiUrl() + "kedisiplinan-fu-tele";
    }

    leadsParcel = () => {
        return this.getApiUrl() + "leads-parcel";
    }

    dataSampah = () => {
        return this.getApiUrl() + "data-sampah";
    }

    dataRiset = () => {
        return this.getApiUrl() + "data-riset";
    }

    inobundByReferral = () => {
        return this.getApiUrl() + "inbound-by-referral";
    }

    listAnalisaDataCancel = () => {
        return this.getApiUrl() + "list-analisa-data-cancel";
    }

    weeklyCancelLeadsPercentage = () => {
        return this.getApiUrl() + "weekly-cancel-leads-percentage";
    }

    summaryDatabaseDataCancel = () => {
        return this.getApiUrl() + "summary-database-data-cancel";
    }

    allDataCancelByUser = () => {
        return this.getApiUrl() + "all-data-cancel-by-user";
    }

    performaClosingAfterFuDataCancel = () => {
        return this.getApiUrl() + "data-cancel-performa-closing-after-fu";
    }

    listCountry = () => {
        return this.getLavenderUrl() + "api/geo/countries";
    }

    listProvince = (countryId) => {
        return this.getLavenderUrl() + "api/geo/children/" + countryId;
    }

    listCity = (countryId) => {
        return this.getLavenderUrl() + "api/geo/children/" + countryId;
    }

    listProduct = (keyword, page) => {
        return this.getLavenderUrl() + "api/service/0efade19437fb381e3526380bc53659c/orders/search-product?s=" + keyword + "&page=" + page;
    }

    lavenderUploadImage = (keyword, page) => {
        return this.getLavenderUrl() + "api/service/0efade19437fb381e3526380bc53659c/orders/upload-custom-image";
    }

    lavenderGetCustomerInfo = (email, phone) => {
        return this.getLavenderUrl() + "api/service/0efade19437fb381e3526380bc53659c/search-customer?email="+email+"&phone="+phone;
    }

    lavenderGetCustomerQna = (phone) => {
        return this.getLavenderUrl() + "api/service/0efade19437fb381e3526380bc53659c/profile-customer?phone="+phone;
    }

    lavenderSubmitCustomerProfile = () => {
        return this.getLavenderUrl() + "api/service/0efade19437fb381e3526380bc53659c/customer-profile/create";
    }

    loginMonitoring = () => {
        return this.getApiUrl() + "login-monitoring";
    }

    autoleadsHistory = () => {
        return this.getApiUrl() + "autoleads-history";
    }

    hsmFuList = () => {
        return this.getApiUrl() + "hsm-fu-list";
    }

    saveHsmFu = () => {
        return this.getApiUrl() + "save-hsm-fu";
    }

    deleteHsmFu = () => {
        return this.getApiUrl() + "delete-hsm-fu";
    }

    listLeadsCrossedPendingBelumTeralihkan = () => {
        return this.getApiUrl() + "list-leads-crossed-pending-belum-teralihkan";
    }

    listSalesYangOnline = () => {
        return this.getApiUrl() + "list-online-sales";
    }

    doCrossedPendingManually = () => {
        return this.getApiUrl() + "do-crossed-pending-manually";
    }

    hsmMonitoringDaily = () => {
        return this.getApiUrl() + "hsm-monitoring-daily";
    }

    saveHsmTemplate = () => {
        return this.getApiUrl() + "wa-save-message-template";
    }

    getLatestVersionInfo = () => {
        return this.getApiUrl() + "static/app-version";
    }

    getLavenderOrderByCustomer = () => {
        return this.getApiUrl() + "lavender-order-by-customer";
    }

    getRegionList = () => {
        return this.getApiUrl() + "region-list";
    }

    getContactNoteList = () => {
        return this.getApiUrl() + "contact/note-list";
    }
    contactNoteSave = () => {
        return this.getApiUrl() + "contact/note-save";
    }
    contactNoteDelete = () => {
        return this.getApiUrl() + "contact/note-delete";
    }
    csResponseTimeReport = () => {
        return this.getApiUrl() + "cs/response-time-report";
    }
    csActiveCustomers = () => {
        return this.getApiUrl() + "cs/active-customers";
    }
    csInQueueCustomers = () => {
        return this.getApiUrl() + "cs/in-queue-customers";
    }
    csInQueueCustomersCount = () => {
        return this.getApiUrl() + "cs/in-queue-customers-count";
    }
    listFu123 = (appProfileId) => {
        return this.getApiUrl() + "sales-online/list-fu123/" + appProfileId;
    }
    isHasCustomerFu = (appProfileId) => {
        return this.getApiUrl() + "sales-online/is-has-customer-fu/" + appProfileId;
    }
    teleChatByCustomer = () => {
        return this.getApiUrl() + "report/tele/customer-leads";
    }

    lavenderGetProductCatalogs = (payload) => {
        return this.getLavenderUrl() + `api/service/0efade19437fb381e3526380bc53659c/product-catalog/search?category_id=${payload.categoryId}&city=${payload.cityId}&color=${payload.colorId}&country=${payload.countryId}&flower=${payload.flowerId}&jambul=${payload.jambulId}&name=${payload.name}&province=${payload.provinceId}&sort=&ukuran=${payload.sizeId}`
    }

    lavenderGetAttributes = () => {
        return this.getLavenderUrl() + "api/service/0efade19437fb381e3526380bc53659c/product-catalog/get-attribute"
    }
    lavenderGetProductCategories = () => {
        return this.getLavenderUrl() + "api/service/0efade19437fb381e3526380bc53659c/product-catalog/categories"
    }
    lavenderGetCountries = () => {
        return this.getLavenderUrl() + "api/geo/countries";
    }
    lavenderGetAreaByParent = (parentId) => {
        return this.getLavenderUrl() + `api/geo/children/${parentId}`;
    }
}

const instance = new Api();
Object.freeze(instance);

export default instance;
