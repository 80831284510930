import React, { Component } from 'react';
import axios from "axios";
import {TextField, Autocomplete, Drawer, Paper, TableContainer, TableRow, TableCell, TableHead} from '@mui/material';
import Api from "../../customlib/Api";
import Modal from "react-bootstrap/Modal";
import { Button, Container, Form, FormGroup, Label, ButtonGroup, Table} from 'reactstrap';
import { FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import './SettingContact.css'; 
import Global from '../../customlib/Global';
import ucwords from 'ucwords';
import HsmForm from './HsmForm';

class HsmSettings extends Component {

    constructor(props) {
      super(props);
      this.state = {
        item: this.emptyCustomer,
        sales: []
      };
      // this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    state = { 
        contacts : [],
        hsmFuList : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        salesData: [],
        selectedSales : {},//id = id, lavenderSalesId, lavenderSalesName, salesType
        id: 0,
        lavenderSalesId: 0,
        lavenderSalesName: "",
        salesType: "Acquisition",
        bottom: false,
        salesInfo: {}, //id = id, lavenderSalesId, lavenderSalesName, salesType
        showDeleteSalesLavenderDialog: false,
        idDelete:0,
        idSalesDelete:0,
        nameSalesDelete:"",
        allowReceiveLeads: 1,
        isNightShift: false,
        selectedHsmFu: null
    }

    componentDidMount() {
        this.getCheckpointUsers();
        this.getUserList();
        this.setState({id: 0});
        
    }

    getUserList = () => {
        axios
        // .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .post(Api.allUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({salesData: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    getCheckpointUsers = () => {

      var userData = Global.get();
      const params = {
          userId: userData.user.id,
          waBussinesNumber: Global.getAppProfile().waNumber,
          appProfileId: Global.getAppProfile()._id
      };

      axios
        .post(Api.getWaMessageTemplates(), params, Api.getRequestHeader())
        .then((response) => {
            console.log("HSMFULIST : ", response.data.data);
            this.setState({hsmFuList: response.data.data.templates});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleUserTypeChange = (event) => {
      let editUser = this.state.currentEditUser;
      editUser.userType = event.target.value;
      this.setState({
          currentEditUser: editUser
      });
      
    };

    handleUserSelectionChanged = (userName) => {
      const userData = [...this.state.salesData];
      let selected = userData.filter((sales) => sales.name===userName);
      console.log("FINDSELECTION : ", selected);

      if(selected.length > 0){
          let newData = JSON.parse(JSON.stringify(selected[0]));
          
          newData.lavenderSalesId = selected.id;

          let editUser = this.state.currentEditUser;
          editUser.userId = selected[0].id;
          editUser.userName = selected[0].name;
          this.setState({
            selectedSales: newData,
            currentEditUser: editUser
          });

          console.log("currentEditUser", editUser);
      }

  }

  selectHsmFu = (hsmFu) => {
    console.log("USER_DATA", hsmFu);

    let hsm = {
      name: hsmFu.name,
      status: hsmFu.status,
      category: hsmFu.category,
      headerFormat: hsmFu.structure.header ? hsmFu.structure.header.format : "NONE",
      body: hsmFu.structure.body
    };

    this.setState({selectedHsmFu: hsm, 'bottom': true});
  }

  addCheckPointUser = () => {
    const newSelectedHsmFu = { 
      hsmName: ""
    }
    this.setState({selectedHsmFu: newSelectedHsmFu, 'bottom': true});
  }


  cancel(){
    this.setState({ ...this.state, 'bottom': false });
  }

  async handleSubmit(event) {
    event.preventDefault();
    
    var params = {
        id: this.state.selectedHsmFu._id ? this.state.selectedHsmFu?._id : null, 
        hsmName: this.state.selectedHsmFu?.hsmName, 
        type: this.state.selectedHsmFu?.type
      };

    console.log("PARAMS", params);
    // return;
    axios
      .post(Api.saveHsmFu(), params, 
        Api.getRequestHeader())
      .then((response) => {
        console.log("doSaveHsmFu : ", response.data);
        let responseData = response.data;
        if(responseData.success){
            console.log(responseData);
            this.getCheckpointUsers();
            
            this.setState({ ...this.state, 'bottom': false });
        }
        
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  handleRemoveSales = () => {
      console.log(this.state.idDelete);
      console.log(this.state.idSalesDelete);

    axios
    .post(Api.deleteHsmFu(), {id: this.state.selectedHsmFu?._id}, Api.getRequestHeader())
    .then((response) => {
      console.log("doDeleteHSmFu : ", response.data);
      let responseData = response.data;
      if(responseData.success){
          console.log(responseData);
          this.getCheckpointUsers();
          this.handleCloseLogoutDialog();
      }
      
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
  }

  handleRemoveSalesDialog = (userData) => {

    this.setState({ 
      showDeleteSalesLavenderDialog: true,
      selectedHsmFu: userData
    });


  }

  handleSwitchLeads = () => {
    let allowLeads = 0;
    if(this.state.allowReceiveLeads === 1){
      allowLeads = 0;
    }else{
      allowLeads = 1;
    }

    this.setState({allowReceiveLeads: allowLeads});

  }

  handleSwitchNightShift = () => {
    let enabled = false;
    if(this.state.currentEditUser.enabled === true){
      enabled = false;
    }else{
      enabled = true;
    }

    const currentUser = this.state.currentEditUser;
    currentUser.enabled = enabled;

    this.setState({currentEditUser: currentUser});

  }

  handleCloseLogoutDialog = () => {
    this.setState({ showDeleteSalesLavenderDialog: false });
  };

  handleHsmNameChange = (event) => {
    let selected = {...this.state.selectedHsmFu};
    selected.hsmName = event.target.value;
    this.setState({selectedHsmFu: selected});
  }
    
    render() { 

      const toggleDrawer = (anchor, open) => (event) => {
        
        this.setState({[anchor]: open });
      };
 

        return (
          <Paper style={{padding: 20}}>
            <div>
              {/* <AppNavbar/> */}

              <div>

                <Modal
                  show={this.state.showDeleteSalesLavenderDialog}
                  onHide={this.handleCloseLogoutDialog}
                  backdrop="static"
                  keyboard={false}>
                  <Modal.Header closeButton>Konfirmasi</Modal.Header>
                  <Modal.Body>Yakin akan menghapus  {this.state.nameSalesDelete}?</Modal.Body>
                  <Modal.Footer>
                    <span onClick={this.handleRemoveSales} className="btn btn-danger">
                      Ya  
                    </span>
                    <span
                      onClick={this.handleCloseLogoutDialog}
                      className="btn btn-warning" >
                      Batalkan
                    </span>
                  </Modal.Footer>
                </Modal>
                
                  <React.Fragment key={'bottom'}>
                    
                    <Drawer width='40%' anchor={'right'} open={this.state['bottom']} onClose={toggleDrawer('bottom', false)}>
                      {/* {list(anchor)} */}
                      <div 
                        role="presentation"
                        // onClick={toggleDrawer('bottom', false)}
                        // onKeyDown={toggleDrawer('bottom', false)}
                      >
                        
                        <div className="col-12 text-left" style={{paddingTop: 8,}}>
                          <HsmForm 
                            hsmInfo={this.state.selectedHsmFu} 
                            closeDrawer={() => this.setState({ ...this.state, 'bottom': false })} 
                            reload={this.getCheckpointUsers}
                          />
                        </div>
                      </div>
                    </Drawer>
                  </React.Fragment>
                
              </div>

              {/* <TemporaryDrawer/> */}
          
              <Container fluid> 
                <div className="float-right"> 
                  {/* <Button color="success" tag={Link} to="/addsales">Add Sales</Button> &nbsp; */}
                  <Button color="success" onClick={this.addCheckPointUser}>Add HSM </Button>
                </div>
                <div>
                  <h3>HSM List</h3>
                </div>
                <TableContainer>
                  <Table size='small'>
                    <TableHead>
                      <TableCell>HSM Name</TableCell>
                      <TableCell>Beautified HSM Name</TableCell>
                      <TableCell>Kategori</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableHead>
                    {
                      this.state.hsmFuList && this.state.hsmFuList.map(item => {
                        return (
                          <TableRow>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{ucwords(item.name.replaceAll("_", " "))}</TableCell>
                            <TableCell>{ucwords(item.category)}</TableCell>
                            <TableCell>{ucwords(item.status)}</TableCell>
                            <TableCell>
                              <ButtonGroup size='sm'>
                                <Button onClick={ () => this.selectHsmFu(item)} className="btn btn-info">Edit </Button>
                                {/* <Button size="sm" color="danger" onClick={() => this.handleRemoveSalesDialog(item)}>Delete</Button> */}
                              </ButtonGroup>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </Table>
                </TableContainer>
              </Container>
            </div>
          </Paper>
        );

    }
}

export default HsmSettings;
