import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import moment from "moment";
import Global from "../../customlib/Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportTeleFuEffectivityRate extends Component {

    formatTime = (seconds) => {
        var duration = moment.duration(seconds, 'seconds');
        var formatted = duration.format("hh:mm:ss");
        return seconds < 60 ? "00:"+formatted : formatted;
    }

    getResumeData = () => {
        let leadsData = [];
        let clonedData = [...this.props.leadsReportData];
        clonedData.forEach(element => {
            element.responRateLavender = (element.responRateLavender*100).toFixed(2);
            element.responRateHsm = (element.responRateHsm*100).toFixed(2);
        });
        return clonedData;
    }

    getDetailData = () => {
        let leadsData = [];
        this.props.leadsReportData.forEach(element => {
            // element.jumlahFuHSM = element.fromHsm.length;
            // element.jumlahFuLavender = element.fromLavender.length;
            element.fromHsm.forEach(item => {
                item.source = "FU HSM";
                item.maskedFrom = Global.maskPhoneNumber(item.from);
                item.isHasResponse = item.responseTime <= 0 ? "TIDAK" : "YA";
                item.formattedDelta = this.formatTime(item.responseTime);
                item.tanggalFu = Global.formatDateTimeToStrDateTime24(item.created_at);
                leadsData.push(item);
            });
            element.fromLavender.forEach(item => {
                item.source = "FU LAVENDER";
                item.maskedFrom = Global.maskPhoneNumber(item.from);
                item.isHasResponse = item.responseTime <= 0 ? "TIDAK" : "YA";
                item.formattedDelta = this.formatTime(item.responseTime);
                item.tanggalFu = Global.formatDateTimeToStrDateTime24(item.created_at);
                leadsData.push(item);
            });
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary"><ImportExport />Export ke Excel</button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getResumeData} name="Resume - Effectivity Rate">
                    <ExcelColumn label="Sales" value="salesName"/>
                    <ExcelColumn label="Jumlah FU" value="leadsCount"/>
                    {/* <ExcelColumn label="Jumlah FU Lavender" value="jumlahFuLavender"/>
                    <ExcelColumn label="Jumlah FU HSM" value="JumlahFuHsm"/> */}
                    <ExcelColumn label="Respon Lavender" value="totalAdaResponLavender"/>
                    <ExcelColumn label="Respon HSM" value="totalAdaResponHsm"/>
                    <ExcelColumn label="Respon Rate Lavender" value="responRateLavender"/>
                    <ExcelColumn label="Respon Rate HSM" value="responRateHsm"/>
                </ExcelSheet>
                <ExcelSheet data={this.getDetailData} name="Detail - Effectivity Rate">
                    <ExcelColumn label="Sales" value="assignToUserName"/>
                    <ExcelColumn label="Nama" value="fromName"/>
                    <ExcelColumn label="No Customer" value="maskedFrom"/>
                    <ExcelColumn label="Sumber FU" value="source"/>
                    <ExcelColumn label="Tanggal FU" value="tanggalFu"/>
                    <ExcelColumn label="Ada Respon?" value="isHasResponse"/>
                    <ExcelColumn label="Delta" value="formattedDelta"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportTeleFuEffectivityRate;