import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

function LotusDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    console.log("DROPZONE",acceptedFiles);
    
    if(allowedFileTypes.includes(acceptedFiles[0].type)){

      if(acceptedFiles[0].size > 20971520){
        setErrorMessage("File terlalu besar, maks 20MB");
        return;
      }

      props.onAcceptedFiles(acceptedFiles);
      setAcceptedFiles(acceptedFiles);
    }else{
      setErrorMessage("File tersebut tidak diijinkan untuk dikirim");
    }

    // if(acceptedFiles[0].size > 20971520){
    

  }, [])
  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
    onDrop, 
    noClick: true,
    noKeyboard: true});

  const allowedFileTypes = [
    "image/apng", 
    "image/avif", 
    "image/gif", 
    "image/jpeg", 
    "image/png", 
    "image/svg+xml", 
    "image/webp", 
    "application/pdf",
    "video/mp4",
    "video/mpeg",
    "audio/x-acc",
    "audio/wav",
    "audio/ogg",
    "audio/mpeg",
    "audio/mpg",
    "audio/mpeg3",
    "audio/mp3"
  ];
  const [acceptedFiles, setAcceptedFiles] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  return (
    <div className='text-center' style={{padding: 0}} {...getRootProps()}>
      <div className="border rounded" style={{padding: 20}}>
        <input {...getInputProps()} />
        <h3>Drag 'n' drop File disini</h3>
        atau
        <br />
        <br />
        <button className='btn btn-primary' onClick={open}>
          Browse File
        </button>

        {
          errorMessage != "" ? (<div className='text-danger'>{errorMessage}</div>) : (<></>)
        }

      </div>
    </div>
  )
}

export default LotusDropzone;