import React, { Component } from 'react';
import axios from "axios";
import {TextField, Autocomplete, Drawer, Paper} from '@mui/material';
import Api from "../../customlib/Api";
import Modal from "react-bootstrap/Modal";
import { Button, Container, Form, FormGroup, Label, ButtonGroup, Table} from 'reactstrap';
import { FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import './SettingContact.css'; 
import Global from '../../customlib/Global';

class CheckpointSettings extends Component {

    constructor(props) {
      super(props);
      this.state = {
        item: this.emptyCustomer,
        sales: []
      };
      // this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    state = { 
        contacts : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        salesData: [],
        selectedSales : {},//id = id, lavenderSalesId, lavenderSalesName, salesType
        id: 0,
        lavenderSalesId: 0,
        lavenderSalesName: "",
        salesType: "Acquisition",
        bottom: false,
        salesInfo: {}, //id = id, lavenderSalesId, lavenderSalesName, salesType
        showDeleteSalesLavenderDialog: false,
        idDelete:0,
        idSalesDelete:0,
        nameSalesDelete:"",
        allowReceiveLeads: 1,
        isNightShift: false,
        currentEditUser: {
          _id: "", 
          userId: 0, 
          userName: "", 
          userType: "OTHER", 
          enabled: true,
          appProfileId: Global.getAppProfile()._id
        }
    }

    componentDidMount() {
        this.getCheckpointUsers();
        this.getUserList();
        this.setState({id: 0});
        
    }

    getUserList = () => {
        axios
        // .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .post(Api.allUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({salesData: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    getCheckpointUsers = () => {
      axios
        .post(Api.checkpointUserList(), {appProfileId: Global.getAppProfile()._id}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESCHATLIST : ", response.data.data);
            this.setState({sales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };


    // handleAddButton = () => {
    //     let newData = 
    //     {
    //         id: 0,
    //         lavenderSalesId: 0,
    //         lavenderSalesName: "",
    //         salesType: "Acquisition"
    //     };

    //     const sales = [...this.state.contacts, newData];

    //     this.setState({contacts: []}, ()=>{
    //         this.setState({contacts: sales});
    //     });

    // }

    handleUserTypeChange = (event) => {
      let editUser = this.state.currentEditUser;
      editUser.userType = event.target.value;
      this.setState({
          currentEditUser: editUser
      });
      
    };

    handleUserSelectionChanged = (userName) => {
      const userData = [...this.state.salesData];
      let selected = userData.filter((sales) => sales.name===userName);
      console.log("FINDSELECTION : ", selected);

      if(selected.length > 0){
          let newData = JSON.parse(JSON.stringify(selected[0]));
          
          newData.lavenderSalesId = selected.id;

          let editUser = this.state.currentEditUser;
          editUser.userId = selected[0].id;
          editUser.userName = selected[0].name;
          this.setState({
            selectedSales: newData,
            currentEditUser: editUser
          });

          console.log("currentEditUser", editUser);
      }

  }

  editCheckpointUser = (userData) => {
    console.log("USER_DATA", userData);
    this.setState({currentEditUser: userData, 'bottom': true});
  }

  addCheckPointUser = () => {
    const newUserData = { 
      appProfileId: Global.getAppProfile()._id,
      userId: 0,
      userName: "",
      enabled: true,
      userType: "OTHER",
      _id: ""
    }
    this.setState({currentEditUser: newUserData, 'bottom': true});
  }


  cancel(){
    this.setState({ ...this.state, 'bottom': false });
  }

  async handleSubmit(event) {
    event.preventDefault();
    
    var params = {
        id: this.state.currentEditUser._id, 
        userId: this.state.currentEditUser.userId, 
        userName: this.state.currentEditUser.userName, 
        userType: this.state.currentEditUser.userType, 
        enabled: this.state.currentEditUser.enabled,
        appProfileId: Global.getAppProfile()._id
      };

    console.log("PARAMS", params);
    // return;
    axios
      .post(Api.saveCheckpointUser(), params, 
        Api.getRequestHeader())
      .then((response) => {
        console.log("doSaveSales : ", response.data);
        let responseData = response.data;
        if(responseData.success){
            console.log(responseData);
            // this.props.history.push('/setting');
            this.getCheckpointUsers();
            // return;
            
            this.setState({ ...this.state, 'bottom': false });
        }
        // this.setState({contacts: response.data.data});
        
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  handleRemoveSales = () => {
      console.log(this.state.idDelete);
      console.log(this.state.idSalesDelete);

    axios
    .post(Api.deleteCheckpointUser(), {id: this.state.currentEditUser._id, appProfileId: this.state.currentEditUser.appProfileId}, Api.getRequestHeader())
    .then((response) => {
      console.log("doSaveSales : ", response.data);
      let responseData = response.data;
      if(responseData.success){
          console.log(responseData);
          this.getCheckpointUsers();
          this.handleCloseLogoutDialog();
      }
      
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
  }

  handleRemoveSalesDialog = (userData) => {

    this.setState({ 
      showDeleteSalesLavenderDialog: true,
      currentEditUser: userData
    });


  }

  handleSwitchLeads = () => {
    let allowLeads = 0;
    if(this.state.allowReceiveLeads === 1){
      allowLeads = 0;
    }else{
      allowLeads = 1;
    }

    this.setState({allowReceiveLeads: allowLeads});

  }

  handleSwitchNightShift = () => {
    let enabled = false;
    if(this.state.currentEditUser.enabled === true){
      enabled = false;
    }else{
      enabled = true;
    }

    const currentUser = this.state.currentEditUser;
    currentUser.enabled = enabled;

    this.setState({currentEditUser: currentUser});

  }

  handleCloseLogoutDialog = () => {
    this.setState({ showDeleteSalesLavenderDialog: false });
  };
    
    render() { 

      const { sales  } = this.state;

      const customerList = sales.map(saless => {
        return <tr key={saless._id}>
            {/* <td style={{whiteSpace: 'nowrap'}}>{saless.id}</td> 
            <td style={{whiteSpace: 'nowrap'}}>{saless.lavenderSalesId}</td>  */}
            <td style={{whiteSpace: 'nowrap'}}>{saless.userName}</td> 
            <td style={{whiteSpace: 'nowrap'}}>{saless.userType}</td> 
            <td style={{whiteSpace: 'nowrap'}}>{saless.enabled === true ? "YES" : "NO"}</td> 
            <td>
            <ButtonGroup>
                <button onClick={ () => this.editCheckpointUser(saless)} className="btn btn-info">Edit </button>
                
                {/* <Button size="sm" color="danger" onClick={() => this.handleRemoveSales(saless.id, saless.lavenderSalesId)}>Delete</Button> */}
                <Button size="sm" color="danger" onClick={() => this.handleRemoveSalesDialog(saless)}>Delete</Button>
            </ButtonGroup>
            </td>
        </tr>
    });

      const toggleDrawer = (anchor, open) => (event) => {
        
        // console.log('this.state.id=> '+this.state.id);
        // if(this.state.id === 0){
        //   // this.setState({
        //     this.state.salesType = 'Acquisition';
        //     this.state.allowReceiveLeads = 1;
        //     // }); 
        // }

        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }

         
        // if(open === false){
          
        //   this.state.id = 0;
        //   this.state.lavenderSalesName = '';
        //   // if(this.state.salesType === ''){
        //     // this.state.salesType = 'Acquisition';
        //   // }else{
        //     this.state.salesType = '';
        //   // }

        //   // this.setState({ id: 0, lavenderSalesName: "", salesType: "", [anchor]: open });

        // }else{
        //   if(open === true && this.state.id === ''){
        //     // this.setState({
        //       this.state.salesType = 'Acquisition';
        //       // [anchor]: open
        //       // }); 
        //   }
        // }

      
        this.setState({[anchor]: open });
      };
 

        return (
          <Paper style={{padding: 20}}>
            <div>
              {/* <AppNavbar/> */}

              <div>

                <Modal
                  show={this.state.showDeleteSalesLavenderDialog}
                  onHide={this.handleCloseLogoutDialog}
                  backdrop="static"
                  keyboard={false}>
                  <Modal.Header closeButton>Konfirmasi</Modal.Header>
                  <Modal.Body>Yakin akan menghapus  {this.state.nameSalesDelete}?</Modal.Body>
                  <Modal.Footer>
                    <span onClick={this.handleRemoveSales} className="btn btn-danger">
                      Ya  
                    </span>
                    <span
                      onClick={this.handleCloseLogoutDialog}
                      className="btn btn-warning" >
                      Batalkan
                    </span>
                  </Modal.Footer>
                </Modal>
                
                  <React.Fragment key={'bottom'}>
                    
                    <Drawer width='40%' anchor={'right'} open={this.state['bottom']} onClose={toggleDrawer('bottom', false)}>
                      {/* {list(anchor)} */}
                      <div 
                        role="presentation"
                        // onClick={toggleDrawer('bottom', false)}
                        // onKeyDown={toggleDrawer('bottom', false)}
                      >
                        
                        <div className="col-12 text-left" style={{paddingTop: 8,}}>
                          <Container>
                              <h1>Input Salesman</h1>
                              <hr/>
                              <Form onSubmit={this.handleSubmit}>
                              
                              <FormGroup>
                                  <Label for="firstname">Nama Sales</Label>
                              {(this.state.salesData && this.state.salesData.length > 0) ? 
                                  <div class="col-12">
                                  <Autocomplete
                                      id="free-solo-2-demo"
                                      // disableClearable
                                      freeSolo 
                                      options={this.state.salesData.map((option) => {
                                          // console.log("LABEL : ", option);
                                          return String(option.name);
                                      })}
                                      fullWidth
                                      onChange={(event, newValue) => {
                                          // console.log("NEWVALUE : ", event);
                                          this.handleUserSelectionChanged(newValue);
                                      }} 
                                      // value={this.state.contactData.lavenderSalesName}
                                      value={this.state.currentEditUser !== undefined ? this.state.currentEditUser.userName : ""}
                                      renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          label="User"
                                          // margin="0"
                                          variant="outlined"
                                          InputProps={{ ...params.InputProps, type: 'search' }}
                                          fullWidth
                                      />
                                      )}
                                  />
                              </div>
                              : 
                              <div></div>
                              }
                              </FormGroup>
                              
                              <FormGroup variant="outlined">
                                  <Label for="lastname">Sales tipe     </Label>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.currentEditUser !== undefined ? this.state.currentEditUser.userType : ""}
                                      onChange={this.handleUserTypeChange}
                                      label="Tipe Sales"
                                      fullWidth>
                                      <MenuItem value={"CS"}>Customer Service</MenuItem>
                                      <MenuItem value={"SALES"}>Sales</MenuItem>
                                      <MenuItem value={"PURCHASING"}>Purchasing</MenuItem>
                                      <MenuItem value={"OTHER"}>Other</MenuItem>
                                  </Select>
                              </FormGroup> 
                                    
                              <FormGroup variant="outlined">
                                  <FormControlLabel
                                    label= {this.state.currentEditUser !== undefined && this.state.currentEditUser.enabled === true ? "Aktif" : "Tidak aktif"}
                                    control={
                                      <Switch
                                        checked={this.state.currentEditUser !== undefined && this.state.currentEditUser.enabled === true}
                                        onChange={this.handleSwitchNightShift}
                                        name="switchNightShift"
                                        // color="primary"
                                      />
                                    }
                                    
                                  />
                              </FormGroup> 
                                      
                              <br />
                              
                              <FormGroup>
                                  <div className="row">
                                      <div className="col-6">
                                          <Button className="btn-block" color="primary" type="submit">Save</Button>
                                      </div>
                                      <div className="col-6">
                                          <Button className="btn-block" color="secondary" onClick={() => this.cancel()} >Cancel</Button>
                                      </div>
                                  </div>
                              </FormGroup>
                              </Form>
                          </Container>
                            
                          </div>
                      </div>
                          
  

                    </Drawer>
                  </React.Fragment>
                
              </div>

              {/* <TemporaryDrawer/> */}
          
              <Container fluid> 
                <div className="float-right"> 
                  {/* <Button color="success" tag={Link} to="/addsales">Add Sales</Button> &nbsp; */}
                  <Button color="success" onClick={this.addCheckPointUser}>Add Checkpoint User </Button>
                </div>
                <div>
                  <h3>User List</h3>
                </div>
                <Table className="mt-4">
                  <thead>
                    <tr>
                      {/* <th width="20%">Id</th>
                      <th width="20%">Sales Id</th>  */}
                      <th width="20%">User Name</th> 
                      <th width="20%">User Type</th> 
                      <th width="10%">Enabled</th> 
                      <th width="10%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>

                  {(this.state.sales && this.state.sales.length > 0) ? 
                    customerList
                  : <div></div>}
                  </tbody>
                </Table>
              </Container>
            </div>
          </Paper>
        );

    }
}

export default CheckpointSettings;
