import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import moment from "moment";
import Global from "../../customlib/Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

var dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class ExportLeadsReport extends Component {

    getleadsReportData = () => {
        return this.props.leadsReportData;
        // let leadsData = [];
        // this.props.leadsReportData.forEach(element => {
        //     element.forEach(lead => {
        //         leadsData.push(lead);
        //     });
        // });
        // return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary"><ImportExport />Export ke Excel</button>}>
                {
                    this.getleadsReportData().map(item => {
                        console.log("DATAITEM", item);
                        return (
                            <ExcelSheet data={item.leads} name={item.salesName}>
                                <ExcelColumn label="Customer" value="fromName"/>
                                <ExcelColumn label="WhatsApp Number" value="from"/>
                                <ExcelColumn label="Lead" value={(col) => Global.formatDateTimeToStrDateTime24(col.created_at)}/>
                                <ExcelColumn label="Label" value="label"/>
                                <ExcelColumn label="Average"
                                            value={(col) => col.responseTime === 86400 ? "Belum direspon" : Global.formatTime(col.responseTime < 0 ? 0 : col.responseTime)}/>
                                
                                <ExcelColumn label="Avg in Seconds" value="avgResponseTime"/>
                                {/* <ExcelColumn label="Last Message Date"
                                            value={(col) => Global.formatDateTimeToStrDateTime24(col.lastMessageReceivedAt)}/> */}
                                
                            </ExcelSheet>
                        );
                    })
                }
                
            </ExcelFile>
        );
    }
}
 
export default ExportLeadsReport;