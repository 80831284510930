import { TextField, CircularProgress, Snackbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip as ToolTip, Dialog, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Global from "../../../customlib/Global";
import Api from "../../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Search, QuestionAnswer, Edit, ErrorOutline} from '@mui/icons-material';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../../MessageListViewer';
import { Link } from 'react-router-dom';

class MonitoringPerpindahanContact extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 0,
        rowsPerPage: 200,
        showAddForm: false,
        keyword: "",
        showEditAnalisa: false,
        obEditContact: null,
        savingAnalisa: false,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        isLoading: false,
        currentConversation: null,
        searchInField: "fromName",
        filterAppNumber: "",
        listAppProfiles: [],
        contactInfo: {}
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleEditOnClick = (contactData) => {
        const myCustomers = [...this.state.listMycustomer];

        var findCustomer = myCustomers.filter(customer => customer.id === contactData.id);

        if(findCustomer.length > 0){
            findCustomer[0].isEditing = !findCustomer[0].isEditing;
            findCustomer[0].assignToUser = contactData.assignToUser;
            findCustomer[0].assignToUserName = contactData.assignToUserName;
        }

        this.setState({listMycustomer: myCustomers});

    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate, endDate: formatStartDate}, () => this.getMyCustomerToday(true));
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, () =>  this.getMyCustomerToday(true));
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    getMyCustomerToday = (replaceAll) => {

        this.setState({isLoading: true}, ()=>{
            var userData = Global.get();

            const params = {
                contactNumber: this.state.keyword,
                appProfileId: Global.getAppProfile()._id,
                searchInField: "contactNumber"
            }

            console.log("PARAMS", params, "replaceAll", replaceAll);

            axios
            .post(Api.riwayatPerpindahanContact(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("MyCustomerToday : ", response.data.data);

                var customers = response.data.data;
                customers.riwayat.forEach(element => {
                    element.created_at_formatted = Global.formatDateTimeToStrDateTime24(element.created_at);
                    if(element.executorName === undefined || element.executorName === null || element.executorName === ""){
                        element.executorName = "System Lotus";
                    }

                    switch (element.functionName) {
                        case "addContact":
                            element.functionName = "Tambah kontak";
                            break;
                        case "addContactBulk":
                            element.functionName = "Tambah kontak masal";
                            break;
                        case "assignContactToUserFunction":
                            element.functionName = "Pindah kontak ke user lain";
                            break;
                        case "getContactByNumber":
                            element.functionName = "Follow Up";
                            break;
                        case "handoverCsCustomerToEp":
                            element.functionName = "Handover dari CS ke EP";
                            break;
                        case "incomingMessages":
                            element.functionName = "Pesan masuk";
                            break;
                        case "moveCustomer":
                            element.functionName = "Pindah kontak ke user lain";
                            break;
                        case "setContactLabelProcess":
                            element.functionName = "Set label";
                            break;
                    
                        default:
                            break;
                    }

                });
                this.setState({listMycustomer: customers.riwayat, contactInfo: customers.contactInfo, isLoading: false});
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    getWaMessages = (contactData) => {
        console.log("CONTACTDATA : ", contactData);
        if(contactData.fromNumber === ""){
          return;
        }
    
        this.setState({isLoading: true}, ()=>{
          const params = {
            from: contactData.fromNumber,
          };
          console.log("PARAMSWAMESSAGE", params);
      
          axios
            .post(Api.messagesList(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_MESSAGES",response.data);
              
              const responseData = response.data;
                
              const clonedMyCust = [...this.state.listMycustomer];
              let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

              if(myCust.length > 0){
                  myCust[0].messages = responseData.data;
              }

              this.setState({listMycustomer: clonedMyCust});
              
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoading: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    handleShowSnackbar = () => {
        this.setState({snackbarVisible: true});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, errorMessage: ""});
    }

    handleEditAnalisa = (contact) => {
        this.setState({obEditContact: contact, showEditAnalisa: true});
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.getMyCustomerToday(true)});
      }

    componentDidMount() {
        console.log(Global.get());
        this.getMyCustomerToday(true);
    }

    columns = [
        { id: 'created_at_formatted', label: 'Tanggal', minWidth: 170},
        { id: 'executorName', label: 'Pelaku', minWidth: 170},
        { id: 'userNameAfter', label: 'Target', minWidth: 200 },
        { id: 'functionName', label: 'Fungsi', minWidth: 200 },
        // {
        //     id: 'action',
        //     label: 'Action',
        //     minWidth: 130,
        //     align: 'center',
        // },
    ];

    handleChangePage = (event, newPage) => {
        console.log("NEWPAGE : ", newPage);

        var isNavigateToNewPage = newPage >= this.state.listMycustomer.length/this.state.rowsPerPage;

        this.setState({page: newPage}, () => {if(isNavigateToNewPage){this.getMyCustomerToday();}});
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    handleCloseFormAddContact = () => {
        this.setState({showAddForm: false});
    }

    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    handleCloseEditAnalisa = () => {
        this.setState({showEditAnalisa: false, obEditContact: null, errorMessage: ""});
    }

    handleWhy1Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why1 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy2Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why2 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy3Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why3 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };

    handleWhy4Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why4 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    handleWhy5Changed = (event) => {
        const editingContact = this.state.obEditContact;

        editingContact.why5 = event.target.value;
        this.setState({ obEditContact: editingContact });
    };
    
    handleSaranChanged = (event) => {

        const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));

        editingContact.saran = event.target.value;
        this.setState({ obEditContact: editingContact });
    };

    handleKategoriProblemChanged = (event) => {
        const editingContact = JSON.parse(JSON.stringify(this.state.obEditContact));
        editingContact.kategoriProblem = event.target.value;
        // this.state.obEditContact = editingContact;
        this.setState({obEditContact: editingContact}, ()=>{
          console.log("ObEditContact",this.state.obEditContact);
        });
      }

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.setState({page: 0}, () => {
                this.getMyCustomerToday(true);
            });
        }
      };

    handleShowMessages = (conversation) => {
        this.setState({currentConversation: conversation});
    }

    getFieldClassName = (fieldName) => {
        let className = "badge";
        if(this.state.searchInField === fieldName){
          className += " badge-primary";
        }else{
          className += " badge-secondary";
        }
    
        return className;
      }
    
    handleFieldSelectionChanged = (fieldName) => {
        this.setState({searchInField: fieldName}, () => {
            this.getMyCustomerToday(true);
        });
    }

    loadAppProfile = () => {
        const params = {
        };
        
        axios
          .post(
            Api.listAppProfile(),
            params,
            Api.getRequestHeaderNoAuth()
          )
          .then((response) => {
            console.log("App Profiles", response.data);
            
            let profiles = [];
            profiles.push({_id: "", waNumber: "", label: "---"});

            let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
            profilesFiltered.forEach(item => {
                profiles.push(item);
            });
            this.setState({listAppProfiles: profiles});
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, loginSuccess: false });
            console.error("There was an error!", error);
            // this.props.onLogin();
          }
        );
    };

    handleAppTypeChanged = (event) => {
        this.setState({filterAppNumber: event.target.value}, ()=>{
          console.log(this.state.filterAppNumber);
          this.getMyCustomerToday(true);
          this.getResumeWeekly();
        });
      }

    render() { 
        return Global.get().admin !== true ? (
            <div className='container text-center' style={{marginTop: 100}}>
                <ErrorOutline style={{width: 200, height: 200}} className="text-danger" />
                <h2>Lotus</h2>
                <h3>Permission denied</h3>
                <Link style={{textDecoration: "underline", width: '100%'}} to="/">
                    <div className="btn btn-warning">Back</div>
                </Link>
            </div>
        ) : ( 
            <div className="containe-fluid" style={{padding: "20px"}}>
                <div className="row">
                    <div className="col-11">
                        <h1>Riwayat Perpindahan Contact</h1>
                    </div>
                    <div className="col-1">
                    <Link to="/">
                        <div className="btn btn-danger">X</div>
                    </Link>
                    </div>
                </div>
                <hr />
                {

                    // this.state.showAddForm ? 
                    // (
                    //     <>wkwkwkw</>
                    // ) : 
                    
                    // (
                        <div className="container-fluid">

                            <div className="row"  style={{marginTop: 10, marginBottom: 0}}>
                                <div className="col-5">
                                    <TextField
                                        onChange={this.handleKeyword}
                                        label="Ketik nomor wa pelanggan"
                                        type="text"
                                        className="form-control"
                                        id="namacustomer"
                                        variant="outlined"
                                        onKeyDown={this.onKeyDownHandler}
                                        onBlur={() => {
                                            
                                        }}
                                    />
                                </div>
                                
                                {/* <div className="text-right" style={{borderRadius: 10, padding: 5}}>
                                  
                                    <TextField
                                        id="dateStart"
                                        label="Tanggal Leads"
                                        type="date"
                                        defaultValue={this.state.startDate}
                                        onChange={this.handleStartDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div> */}
                              {/* <div className='col-2'>
                                <FormControl variant="outlined" style={{width: '100%'}}>
                                    <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.appTypeId}
                                        onChange={this.handleAppTypeChanged}
                                        label="Aplikasi"
                                    >
                                        {
                                        this.state.listAppProfiles.map(
                                            (profile) => {
                                            return (<MenuItem key={profile._id} value={profile.waNumber}>{profile.label}</MenuItem>);
                                            } 
                                        )
                                        }
                                    </Select>
                                </FormControl>
                                    
                              </div> */}
                              <div className="col-1">
                                    {
                                        this.state.isLoading === true ? 
                                            (<div style={{paddingTop: "15px"}}><CircularProgress size={30} color="secondary" /></div>) : 
                                            (<div onClick={() => {this.getMyCustomerToday(true)}} className="btn btn-primary" style={{padding: 15}}><Search /></div>)
                                    }
                                    {/* <div onClick={() => {this.setState({showAddForm: true})}} className="btn btn-primary" style={{padding: 15}}><Add /></div> */}
                                </div>
                            </div>
                            {/* <div className="container-fluid">
                                <small>Field Pencarian : </small>
                                    <div className={this.getFieldClassName("fromName")} onClick={() => this.handleFieldSelectionChanged("fromName")} style={{cursor: "pointer"}}>Nama</div>&nbsp;
                                    <div className={this.getFieldClassName("from")} onClick={() => this.handleFieldSelectionChanged("from")} style={{cursor: "pointer"}}>Nomor</div>&nbsp;
                                    <div className={this.getFieldClassName("productCategory")} onClick={() => this.handleFieldSelectionChanged("productCategory")} style={{cursor: "pointer"}}>Kategori</div>&nbsp;
                                    <div className={this.getFieldClassName("kategoriProblem")} onClick={() => this.handleFieldSelectionChanged("kategoriProblem")} style={{cursor: "pointer"}}>Keterangan Cancel</div>
                            </div> */}
                            <br />
                            
                            <Paper style={{backgroundColor: "#e7eef4", padding: "10px"}}>
                                <h5><strong>Contact Info</strong></h5>
                                <div style={{height: "1px", backgroundColor: "#d3d3d3", marginBottom: "20px"}}></div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-3"><strong>Nama</strong></div>
                                            <div className="col-1 text-right">:</div>
                                            <div className="col-8">{this.state.contactInfo.fromName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3"><strong>Nomor</strong></div>
                                            <div className="col-1 text-right">:</div>
                                            <div className="col-8">{this.state.contactInfo.from}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3"><strong>Label</strong></div>
                                            <div className="col-1 text-right">:</div>
                                            <div className="col-8">{this.state.contactInfo.label}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                    <div className="row">
                                            <div className="col-5"><strong>User</strong></div>
                                            <div className="col-1 text-right">:</div>
                                            <div className="col-6">{this.state.contactInfo.assignToUserName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5"><strong>Waktu Inbox Terakhir</strong></div>
                                            <div className="col-1 text-right">:</div>
                                            <div className="col-6">{Global.formatDateTimeToStrDateTime24(this.state.contactInfo.lastMessageReceivedAt, true)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5"><strong>Pesan Terakhir</strong></div>
                                            <div className="col-1 text-right">:</div>
                                            <div className="col-6">{this.state.contactInfo.lastMessage}</div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                            <br />
                            <div className="row">
                                <div className="col-12">
                                    <h5>Riwayat Contact</h5>
                                </div>
                                <div className="col-12">
                                    <Paper className="root">
                                        <TableContainer style={{maxHeight: 440}}>
                                            <Table aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                {this.columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                    {column.label}
                                                    </TableCell>
                                                ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {this.columns.map((column) => {
                                                        // const value = row[column.id];
                                                        const value = Global.isSales() && column.id === "from" ? row[column.id].slice(0, -5) + Array(5 + 1).join("x") : row[column.id];
                                                        var isEditing = row.isEditing;
                                                        var messagesDownloaded = row.messages !== undefined;
                                                        // return 
                                                        // var colValue = "";
                                                        
                                                        if(column.id === "assignToUserName"){
                                                            return isEditing === true && column.id === "assignToUserName" ? 
                                                                (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {/* <ContactEditChangeSales onFinishEdit={this.handleEditOnClick} salesData={this.state.listSales} contactData={row} /> */}
                                                                    </TableCell>
                                                                ) : 
                                                                (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                                    </TableCell>
                                                                )
                                                        }
                                                        
                                                        if(column.id === "action"){
                                                            return messagesDownloaded === true && column.id === "action" ? 
                                                                (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {
                                                                            Global.get().admin === true ? 
                                                                            (
                                                                                <ToolTip title="Input keterangan cancel">
                                                                                    <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditAnalisa(row);}}  />
                                                                                </ToolTip>
                                                                            ) : 
                                                                            (<></>)
                                                                        }
                                                                        &nbsp;
                                                                        <ToolTip title="Lihat Chat">
                                                                            <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowMessages(row.contactFormatted)}}  />
                                                                        </ToolTip>
                                                                        &nbsp;
                                                                        {/* {
                                                                            Global.get().admin === true ? 
                                                                            (
                                                                                <ToolTip title="Download percakapan" key={row.from}>
                                                                                    <ExportConversation contactData={row.contactFormatted} messagesData={row.messages}  />
                                                                                </ToolTip>
                                                                            ) : 
                                                                            (<></>)
                                                                        } */}
                                                                    </TableCell>
                                                                ) : 
                                                                (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {
                                                                            Global.get().admin === true ? 
                                                                            (
                                                                                <ToolTip title="Input keterangan cancel">
                                                                                    <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditAnalisa(row);}}  />
                                                                                </ToolTip>
                                                                            ) : 
                                                                            (<></>)
                                                                        }
                                                                        &nbsp;
                                                                        <ToolTip title="Lihat Chat">
                                                                            <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowMessages(row.contactFormatted);}}  />
                                                                        </ToolTip>
                                                                        &nbsp;
                                                                        {/* {
                                                                            Global.get().admin === true ? 
                                                                            (
                                                                                <ToolTip title="Download percakapan">
                                                                                    <CloudDownloadOutlined style={{cursor: "pointer"}} className="text-success" onClick={() => {this.getWaMessages(row.contactFormatted);}}  />
                                                                                </ToolTip>
                                                                            ) : 
                                                                            (<></>)
                                                                        } */}
                                                                    </TableCell>
                                                                )
                                                        }

                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                            </TableCell>
                                                        )
                                                        
                                                    })}
                                                    </TableRow>
                                                );
                                                })}
                                            </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <TablePagination
                                            rowsPerPageOptions={[10, 25, 50, 75, 100, 200]}
                                            component="div"
                                            count={this.state.listMycustomer.length + this.state.rowsPerPage}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        /> */}
                                    </Paper>
                                </div>
                            </div>
                            <br />
                        </div>
                    // )

                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />

                {
                    this.state.currentConversation !== null ? (
                        <Dialog
                            open={this.state.currentConversation !== null}
                            // TransitionComponent={Transition}
                            // keepMounted
                            onClose={() => {this.setState({currentConversation: null})}}
                            aria-describedby=""
                        >
                            {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                            <DialogContent>
                                <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} />
                            </DialogContent>
                            <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button>
                            <Button onClick={handleClose}>Agree</Button> */}
                            </DialogActions>
                        </Dialog>
                    ) : (<></>)
                }
                
            </div>
        );
    }
}
 
export default MonitoringPerpindahanContact;

function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }