import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import axios from "axios";
import moment from 'moment';
import { Box, Collapse, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Refresh } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import DateRangePicker from '../../customlib/DateRangePicker';

class ResumeKedisiplinanFuTele extends Component {
    state = {
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        appProfileId: "",
        isLoading: false,
        dataKedisiplinan: [],
        listAppProfiles: [],
        resumeByTerlambatSales: []
    }

    loadKedisiplinanFuTele = () => {
        this.setState({isLoading: true, dataKedisiplinan: []}, ()=>{
              var userData = Global.get();
              const params = {
                  appProfileId: this.state.appProfileId,
                  startDate: this.state.startDate,
                  endDate: this.state.endDate
              }
    
              console.log("PARAMS", params);
    
              axios
                .post(Api.kedisiplinanFuTele(), params, Api.getRequestHeader())
                .then((response) => {

                    let data = response?.data?.data;

                    let resumeByTerlambatSales = [];

                    data.forEach(item => {
                        let totalTerlambat = 0;
                        item.data.forEach(bySales => {

                            let findSales = resumeByTerlambatSales.filter(resumeItem => resumeItem.assignToUser === bySales.assignToUser);

                            let incOnTime = 0;
                            let incTerlambat = 0;
                            if(bySales.time > 81500){
                              incTerlambat = 1;
                            }else{
                                incOnTime = 1;
                            }

                            if(findSales.length > 0){
                              findSales[0].jumlahFu = findSales[0].jumlahFu + bySales.total;
                              findSales[0].jumlahTerlambat = findSales[0].jumlahTerlambat + incTerlambat;
                              findSales[0].jumlahOnTime = findSales[0].jumlahOnTime + incOnTime;
                            }else{
                              resumeByTerlambatSales.push(
                                {
                                  assignToUser: bySales.assignToUser,
                                  assignToUserName: bySales.assignToUserName,
                                  jumlahFu: bySales.total,
                                  jumlahTerlambat: incTerlambat,
                                  jumlahOnTime: incOnTime,

                                }
                              );
                            }

                            // if(bySales.time > 81500){
                            //     totalTerlambat += 1;
                            //     bySales.isOnTime = false;
                            // }else{
                            //     bySales.isOnTime = true;
                            // }
                        });
                        // item.totalTerlambat = totalTerlambat;
                    });

                    this.setState({dataKedisiplinan: data, resumeByTerlambatSales: resumeByTerlambatSales, isLoading: false}, () => {console.log(this.state.resumeByTerlambatSales)});
                })
                .catch((error) => {
                    this.setState({isLoading: false});
                    console.error("There was an error!", userData.user.id, error);
                });
          });
    };

    loadAppProfile = () => {
        const params = {
        };
        
        axios
          .post(
            Api.listAppProfile(),
            params,
            Api.getRequestHeaderNoAuth()
          )
          .then((response) => {
            console.log("App Profiles", response.data);
            
            let profiles = [];
            profiles.push({_id: "", waNumber: "", label: "---"});
  
            let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
            profilesFiltered.forEach(item => {
                profiles.push(item);
            });
            this.setState({listAppProfiles: profiles});
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, loginSuccess: false });
            console.error("There was an error!", error);
            // this.props.onLogin();
          }
        );
    };

    handleAppTypeChanged = (event) => {
        this.setState({appProfileId: event.target.value}, ()=>{
          console.log(this.state.appProfileId);
          this.loadKedisiplinanFuTele();
        });
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

        console.log("START : ", startDate, ", END : ", endDate);

        this.setState({ startDate: startDate, endDate:  endDate}, this.loadKedisiplinanFuTele);
    }
    
    componentDidMount() {
        this.setState({appProfileId: Global.getAppProfile()._id}, () => {
            this.loadAppProfile();
            this.loadKedisiplinanFuTele();
        });
    }

    render() { 
        return (
            <div className="container-fluid">
                <Paper style={{paddingTop: "10px"}}>

                    <div className="row">
                        {/* <div className="col-4 text-right">Resume Kedisiplinan FU Tele</div> */}
                        <div className="col-5 text-right" style={{paddingTop: "7px"}}>Filter Tanggal</div>
                        <div className="col-3" style={{padding: "5px"}}>
                            <DateRangePicker onDateChanged={this.handleDateChanged} />
                        </div>
                        <div className="col-2">
                            <FormControl variant="outlined" style={{width: '100%'}} size='small'>
                                <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.appProfileId}
                                    onChange={this.handleAppTypeChanged}
                                    label="Aplikasi"
                                >
                                    {
                                    this.state.listAppProfiles.map(
                                        (profile) => {
                                        return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                        } 
                                    )
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                            
                            {
                            this.state.isLoading ? 
                            (
                                <>
                                Sedang memuat
                                <LinearProgress color="secondary" />
                                </>
                            ) : 
                            (
                                <div className="btn btn-primary" onClick={() => this.loadKedisiplinanFuTele()}>
                                <Refresh /> Refresh
                                </div>
                            )
                            }
                            
                        </div>
                    </div>

                    <br />
                    
                    <TableContainer>
                        <Table width="100%" aria-label="sticky table" size='small'>
                        <TableHead>
                            {/* <TableCell></TableCell> */}
                            <TableCell width={"60%"}>Sales</TableCell>
                            <TableCell align='center'>Jumlah FU</TableCell>
                            <TableCell align='center'>On Time</TableCell>
                            <TableCell align='center'>Terlambat</TableCell>
                        </TableHead>
                        <TableBody>
                            {
                              this.state.resumeByTerlambatSales.map(bySales => {
                                return (
                                  <TableRow>
                                    {/* <TableCell></TableCell> */}
                                    <TableCell>{bySales.assignToUserName}</TableCell>
                                    <TableCell align='center'>{bySales.jumlahFu}</TableCell>
                                    <TableCell align='center'>{bySales.jumlahOnTime}</TableCell>
                                    <TableCell align='center'>{bySales.jumlahTerlambat}</TableCell>
                                  </TableRow>
                                );
                              })
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                
                            </TableRow>
                        </TableFooter>
                        </Table>
                    </TableContainer>

                </Paper>
            </div>
        );
    }
}
 
export default ResumeKedisiplinanFuTele;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell width={"20px"}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">{moment(row.tanggal).format("DD MMMM YYYY")}</TableCell>
          <TableCell component="th" scope="row">{row.data.length} Orang</TableCell>
          <TableCell component="th" scope="row">{row.totalTerlambat} Orang</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Detail
                </Typography> */}
                <Table size="small" aria-label="purchases" width="100%">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Sales</strong></TableCell>
                      <TableCell><strong>Customer</strong></TableCell>
                      <TableCell><strong>FU Pertama</strong></TableCell>
                      <TableCell><strong>Status</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.data.map((historyRow) => {
                        return (
                            <TableRow key={historyRow.firstFu}>
                                <TableCell>{historyRow.assignToUserName}</TableCell>
                                <TableCell width={"40%"}>{historyRow.fromName}</TableCell>
                                <TableCell>{historyRow.time_formatted}</TableCell>
                                <TableCell className={!historyRow.isOnTime ? "text-danger" : ""}>{!historyRow.isOnTime ? (<strong>TERLAMBAT</strong>) : "TEPAT WAKTU"}</TableCell>
                            </TableRow>
                    )})}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }