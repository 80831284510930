import React, { Component } from 'react';
import axios from "axios";
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import SettingContactItem from './SettingContactItem';
import { Paper } from '@mui/material';

class AssignContact extends Component {
    state = { 
        contacts : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null
    }

    constructor(){
        super();
        Global.getSocket().on("newContactReceived", data => {
            console.log("NEW_CONTACT_COMING", data);
            this.getParentlessContacts();
            }
        );
    }
    
    componentDidMount() {
        this.getSalesTeleUsers();
        this.getParentlessContacts();
    }

    getParentlessContacts = () => {
        axios
          .post(Api.getParentlessContact(), {}, Api.getRequestHeader())
          .then((response) => {
            // console.log("PARENTLESSCONTACT : ", response.data);
            this.setState({contacts: response.data.data});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            // console.log("SALESTELEUSER : ", response.data.data);
            this.setState({sales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };


    /**
     * data = {userId, contactId, userName}
     * @param {*} data 
     */
    handleAddToUpdateList = (data) => {
        const contactToUpdate = [...this.state.contactSelectedToUpdate];

        var findData = contactToUpdate.filter((contact) => contact.contactId === data.contactId);

        if(findData.length > 0){
            findData[0].id= data.id;
            findData[0].name = data.name;
        }else{
            contactToUpdate.push(data);
        }
        
        console.log("ADDTOUPDATELIST : ", contactToUpdate);
        this.setState({contactSelectedToUpdate: contactToUpdate});

    }

    handleRemoveFromUpdateList = (data) => {
        const contactToUpdate = [...this.state.contactSelectedToUpdate];

        var findData = contactToUpdate.filter((contact) => contact.contactId === data.contactId);

        if(findData.length > 0){
            contactToUpdate.pop(findData[0]);
        }
        console.log("REMOVEFROMUPDATELIST : ", contactToUpdate);
        this.setState({contactSelectedToUpdate: contactToUpdate});
    }

    handleCheckAll = (event) => {
        this.setState({checkAll : event.target.checked ? event.target.checked : null});
    }

    handleBulkUpdate = () => {
        const updateContacts = [];
        const cloneSelected = [...this.state.contactSelectedToUpdate];
        cloneSelected.forEach(contact => updateContacts.push({contactId: contact.contactId, userId: contact.id, userName: contact.name}));
        // for(const contact in this.state.contactSelectedToUpdate){
        //     console.log("CONTACT : ", contact);
        //     updateContacts.push({contactId: contact.contactId, userId: contact.id, userName: contact.name});
        // }

        axios
        .post(Api.bulkAssignContactToUser(), {contacts : updateContacts}, Api.getRequestHeader())
        .then((response) => {
        console.log("BULKRESPONSE : ", response.data);
        // this.setState({sales: response.data.data});
        })
        .catch((error) => {
        console.error("There was an error!", error);
        });

        console.log("BULK : ", updateContacts);
    }

    render() { 
        // const { from, fromName,  } = this.props.contactData;
        return ( 
            <Paper style={{padding: 20}}>
                <div className="container-fluid">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div className="panel-title">
                                {/* <h1>Setup Contact</h1> */}
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-10">
                                    Berikut ini adalah daftar contact customer yang belum di berikan kepada sales / tele
                                </div>
                                <div className="col-2">
                                    <span onClick={this.handleBulkUpdate} className="btn btn-warning">
                                        Bulk Update
                                    </span>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-1">
                                    <input type="checkbox" onChange={this.handleCheckAll}/>
                                </div>
                                <div className="col-2">
                                    Nama
                                </div>
                                <div className="col-2">
                                    No WA
                                </div>
                                <div className="col-3">
                                    Pesan
                                </div>
                                <div className="col-2">
                                    Berikan kepada
                                </div>
                                <div className="col-2">
                                    Aksi
                                </div>
                            </div>
                            <hr />
                            {
                                this.state.contacts.map(
                                    (contact) => (
                                        <SettingContactItem forceCheck={this.state.checkAll} onChecked={this.handleAddToUpdateList} onUnchecked={this.handleRemoveFromUpdateList} key={contact.id} contactData={contact} salesData={this.state.sales} />
                                    )
                                )
                            }
                        </div>
                        <div className="panel-footer">
                            {/* <Link to="/">
                                <div className="btn btn-primary">Close</div>
                            </Link> */}
                        </div>
                    </div>
                </div>
            </Paper>
         );
    }
}
 
export default AssignContact;