import { Add, ArrowDropDown, ChevronLeft, ChevronRight, Delete, Edit } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Paper, Tooltip } from '@mui/material';
import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import EditPinnedMessage from './EditPinnedMessage';

class PinnedMessageBubble extends Component {
    state = { 
        showDetailPinned: false,
        contactNotes: [],
        showDialogPinnedMessage: false,
        selectedNote: null,
        currentPage: 1,
        showDialogDeleteNote: false
    }
    
    toggleShowDetailPinned = () => {
        this.setState({showDetailPinned: !this.state.showDetailPinned});
    }    

    handleShowDialogPinnedMessage = () => {
        this.setState({showDialogPinnedMessage: !this.state.showDialogPinnedMessage});
    }

    loadContactNoteList = () => {

        let params = {
          waNumber: this.props.currentConversation.fromNumber,
        };
        
        axios
          .post(Api.getContactNoteList(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACT_NOTE_LIST : ", response.data);
    
            const responseData = response.data;
    
            if(responseData.success === true){
              this.setState({contactNotes: responseData.data});
            }else{
              this.setState({errorMessage: responseData.message});
            }
    
            
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
          });
    }

    componentDidMount() {
        this.loadContactNoteList();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(prevProps, prevState);

        if(this.props.currentConversation.fromNumber !== prevProps.currentConversation.fromNumber){
            this.setState({selectedNote: null, currentPage: 1, showDialogPinnedMessage: false}, () => {
                this.loadContactNoteList();
            });
        }
    }

    handleNextPage = () => {
        let countNotes = this.state.contactNotes.length;
        let current = this.state.currentPage;

        if(current < countNotes){
            this.setState({currentPage: current + 1})
        }

    }

    handlePreviousPage = () => {
        let countNotes = this.state.contactNotes.length;
        let current = this.state.currentPage;

        if(current > 1){
            this.setState({currentPage: current - 1})
        }

    }

    handleEditNote = () => {
        let selected = this.state.contactNotes[this.state.currentPage - 1];
        this.setState({showDialogPinnedMessage: true, selectedNote: selected});
    }

    handleAddNote = () => {
        let selected = null;
        this.setState({showDialogPinnedMessage: true, selectedNote: selected});
    }

    handleShowDialogDeleteNote = () => {
        this.setState({showDialogDeleteNote: !this.state.showDialogDeleteNote});
    }

    handleDeleteNote = () => {
        let params = {
            id: this.state.contactNotes[this.state.currentPage-1]._id,
          };
          
        axios
        .post(Api.contactNoteDelete(), params, Api.getRequestHeader())
        .then((response) => {
            console.log("CONTACT_NOTE_LIST : ", response.data);
    
            const responseData = response.data;
    
            if(responseData.success === true){
                this.handlePreviousPage();
                this.handleShowDialogDeleteNote();
                this.loadContactNoteList();
            }else{
                this.setState({errorMessage: responseData.message});
            }
    
            
        })
        .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
        });
    }

    render() { 
        return (
            <>
                <div className="badge badge-secondary" style={{cursor: "pointer", marginLeft: "10px"}} onClick={this.state.contactNotes.length <= 0 ? ()=>{this.handleShowDialogPinnedMessage(true)} : this.toggleShowDetailPinned}>
                    {this.state.contactNotes.length <= 0 ? "Add Note" : "View Notes"} 
                    {this.state.contactNotes.length <= 0 ? <Add /> : <ArrowDropDown />}
                </div>
                {
                    this.state.showDetailPinned && (<Paper sx={{position: "absolute", top: 40, left: "8vw", padding: "6px", backgroundColor: "#deecfd99", minWidth: "45vw"}}>
                        <div style={{fontWeight: "normal", fontSize: "13px", backgroundColor: "#deecfd", padding: "10px", borderRadius: "5px"}} className='text-xs'>
                            <div className="text-center">
                                <div className="row">
                                    <div className="col-10 text-left">
                                        <h5>Notes</h5>
                                    </div>
                                    <div className="col-2">
                                        <Tooltip title="Close">
                                            <Button variant='text' color='error' size='small' onClick={this.toggleShowDetailPinned}><strong>X</strong></Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <div className='row'>
                                    <div className="col-5 text-secondary" style={{fontWeight: "bold"}}>Kategori Harga</div>
                                    <div className="col-7">
                                        : {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].price_category}
                                    </div>
                                </div>
                                <Divider />
                                <div className='row'>
                                    <div className="col-5 text-secondary" style={{fontWeight: "bold"}}>Kota yang sering dipesan</div>
                                    <div className="col-7">
                                        : {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].most_ordered_city}
                                    </div>
                                </div>
                                <Divider />
                                <div className='row'>
                                    <div className="col-5 text-secondary" style={{fontWeight: "bold"}}>Warna dan request pesanan</div>
                                    <div className="col-7">
                                        : {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].color_and_request_order}
                                    </div>
                                </div>
                                <Divider />
                                <div className='row'>
                                    <div className="col-5 text-secondary" style={{fontWeight: "bold"}}>Metode Pembayaran</div>
                                    <div className="col-7">
                                        : {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].payment_method}
                                    </div>
                                </div>
                                <Divider />
                                <div className='row'>
                                    <div className="col-5 text-secondary" style={{fontWeight: "bold"}}>Nama pengirim pakai logo?</div>
                                    <div className="col-7">
                                        : {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].sender_use_logo}
                                    </div>
                                </div>
                                <Divider />
                                <div className='row'>
                                    <div className="col-5 text-secondary" style={{fontWeight: "bold"}}>Nama pengirim pemesanan sebelumnya</div>
                                    <div className="col-7">
                                        : {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].previous_sender_name}
                                    </div>
                                </div>
                                <Divider />
                                <div className='row'>
                                    <div className="col-5 text-secondary" style={{fontWeight: "bold"}}>Note Tambahan</div>
                                    <div className="col-7">
                                        : {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].additional_note}
                                    </div>
                                </div>
                            </div>
                            <div style={{fontWeight: "bold", fontSize: "10px", marginTop: "15px", marginBottom: "10px"}} className='text-xs text-secondary'>
                                <div>Created by {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].created_by_name}</div>
                                {/* <Divider /> */}
                                {/* <div>Updated by {this.state.contactNotes.length > 0 && this.state.contactNotes[this.state.currentPage-1].updated_by_name}</div> */}
                                {/* <Divider /> */}
                            </div>
                            
                        </div>
                        
                        <div className="row" style={{marginTop: "10px"}}>
                            <div className="col-6">
                                <div className="text-center">
                                    <Tooltip title={"Edit note"}>
                                        <Button size='small' onClick={this.handleEditNote} color='secondary' variant="text"><Edit /></Button>
                                    </Tooltip>
                                    <Tooltip title={"Delete note"}>
                                        <Button size='small' onClick={this.handleShowDialogDeleteNote} color='error' variant="text"><Delete /></Button>
                                    </Tooltip>
                                    <Tooltip title={"Create new note"}>
                                        <Button size='small' onClick={this.handleAddNote} color='primary' variant="text"><Add /></Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="text-center">
                                    <Tooltip title={"Previous"}>
                                        <Button onClick={this.handlePreviousPage} variant="text"><ChevronLeft /></Button>
                                    </Tooltip>
                                    {this.state.currentPage} of {this.state.contactNotes.length} Notes
                                    <Tooltip title={"Next"}>
                                        <Button onClick={this.handleNextPage} variant="text"><ChevronRight /></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                    </Paper>)
                }

                {
                    this.state.showDialogPinnedMessage && <EditPinnedMessage 
                        callback={this.loadContactNoteList} 
                        noteData={this.state.selectedNote} 
                        isDialogOpen={this.state.showDialogPinnedMessage} 
                        currentConversation={this.props.currentConversation}
                        onClose={() => {this.setState({showDialogPinnedMessage: false})}} />
                }

                <Dialog
                    fullWidth={true}
                    maxWidth={"xs"}
                    open={this.state.showDialogDeleteNote}
                    onClose={this.handleShowDialogDeleteNote}
                >
                    <DialogTitle>
                        <h5>Konfirmasi</h5>
                    </DialogTitle>
                    <DialogContent>
                        Yakin akan menghapus catatan ini ?
                    </DialogContent>
                    <DialogActions>
                        <Button style={{marginRight: "13px"}} color='error' variant='contained' onClick={this.handleDeleteNote}>Delete Note</Button>
                        <Button style={{marginRight: "13px"}} color='warning' variant='contained' onClick={this.handleShowDialogDeleteNote}>Cancel</Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    }
}
 
export default PinnedMessageBubble;