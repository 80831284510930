import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import {Paper, Tabs, Tab, Typography, Box} from '@mui/material';
import AssignContact from "../AssignContact";
import EditContact from "../EditContact";
import { Link } from 'react-router-dom';
import SettingManager from '../SettingManager';
import SalesManagement from '../SalesManagement';
import ExportLeadsReport from '../ContactLeadsReport/ExportLeadsReport';
import SalesResponseTimeReport from '../SalesResponseTimeReport';
import ContactLeadsReportRegular from './ContactLeadsReportRegular';
import ContactLeadsReportHourly from './ContactLeadsReportHourly';
import TeleFuReport from './TeleFuReport';
import EventReminderReport from './EventReminderReport';
import BySalesByLabelByCategoryByCity from './BySalesByLabelByCategoryByCity';
import Global from '../../customlib/Global';
import BySalesByLabelByCategory from './BySalesByLabelByCategory';
import ByCitiByCategory from './ByCityByCategory';
import ByCityByCustomerByCategoryByUrl from './ByCityByCustomerByCategoryByUrl';
import ByURL from './ByURL';
import SoldProductCategories from './SoldProductCategory';
import LeadsByWilayah from './LeadsByWilayah';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class LeadsReportSummary extends Component {

    state = { 
        value : 0,
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    // classes = useStyles();
    // const [value, setValue] = React.useState(0);

    componentDidMount() {
        this.setState({value: 0});
    }
    

    handleChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };

    render() { 
        return ( 
            <>
            <Paper className={this.useStyles.root}>
                {/* <div className="container-fluid text-right bg-primary">
                    <Link to="/">
                        <div className="btn btn-primary">Close</div>
                    </Link>
                </div> */}
                
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    centered
                >
                    {
                      Global.get().admin === true ? (<Tab key="tab0" label="By Sales,Label,Kategori,Kota" />) : (<></>)
                    }
                    <Tab key="tab1" label="Overview Leads" />
                    {
                      Global.get().admin === true ? (<Tab key="tab2" label="Kategori Iklan" />) : (<></>)
                    }
                    {
                      Global.get().admin === true ? (<Tab key="tab3" label="URL Iklan" />) : (<></>)
                    }
                    {
                      Global.get().admin === true ? (<Tab key="tab4" label="Jumlah URL Iklan" />) : (<></>)
                    }
                    {
                      Global.get().admin === true ? (<Tab key="tab5" label="Sold Categories" />) : (<></>)
                    }
                    {
                      Global.get().admin === true ? (<Tab key="tab6" label="Leads By Wilayah" />) : (<></>)
                    }
                </Tabs>
            </Paper>
            <TabPanel value={this.state.value} index={0}>
              <BySalesByLabelByCategoryByCity />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
                <BySalesByLabelByCategory />
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
                <ByCitiByCategory />
            </TabPanel>
            <TabPanel value={this.state.value} index={3}>
                <ByCityByCustomerByCategoryByUrl />
            </TabPanel>
            <TabPanel value={this.state.value} index={4}>
                <ByURL />
            </TabPanel>
            <TabPanel value={this.state.value} index={5}>
                <SoldProductCategories />
            </TabPanel>
            <TabPanel value={this.state.value} index={6}>
                <LeadsByWilayah />
            </TabPanel>
            </>
         );
    }
}
 
export default LeadsReportSummary;