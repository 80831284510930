import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, TableFooter } from '@mui/material';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Check, Close, QuestionAnswer, TrainRounded } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../MessageListViewer';

class ReportPerformaDataCancel extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null,
        resumeByKategori: [],
        years: [],
        selectedMonth: moment().format("M"),
        selectedYear: moment().format("YYYY"),
        dataPerforma: [],
        contactLeadsHourly: [],
        listAppProfiles: [],
        filterAppNumber: "",
        performaTotalCancel: 0,
        performaTotalPengurangLeads: 0,
        performaTotalLeads: 0,
        summaryWeekly: {},
        performaSalesByLabel: [],
        weeklyLeadsPercentage: []
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.handleRefresh();
          // this.getResumeWeekly(true);
        }
      );
    }

    handleRefresh = () => {
        this.loadAppProfile();
        this.getContactLeads();
        this.getWeeklyCancelLeadsPercentage();
    }

    handleChangeMonth = (event) => {
      this.setState({selectedMonth: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleChangeYear = (event) => {
      this.setState({selectedYear: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        console.log(this.state.appProfileId);
        // this.getContactLeads();
        this.handleRefresh();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
  };

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], contactLeadsHourly: [], performaTotalCancel: 0, performaTotalLeads: 0, performaTotalPengurangLeads: 0, additionalData: null, isLoading: true}, () => {
            axios
            .post(Api.getContactLeads(), params, Api.getRequestHeader())
            .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

            const arrayLabels = [
                Global.labelRandomLeads(),
                Global.labelKonfirmasiPesanan(),
                Global.labelReport(),
            ];

            var performaSalesByLabel = [];

            var tmpContactLeadsHourly = [
                {
                    id: "24000300",
                    label: "24:00 - 03:00",//2400 - 2700 am
                    count: 0,
                    detail: []
                },
                {
                    id: "03010600",
                    label: "03:01 - 06:00",//2701 - 3000 am
                    count: 0,
                    detail: []
                },
                {
                    id: "06010900",
                    label: "06:01 - 09:00",//3001 - 3300 am
                    count: 0,
                    detail: []
                },
                {
                    id: "09011200",
                    label: "09:01 - 12:00",//3301 - 3600 am
                    count: 0,
                    detail: []
                },
                {
                    id: "12011600",
                    label: "12:01 - 16:00",//3601 - 4000 pm
                    count: 0,
                    detail: []
                },
                {
                    id: "16011800",
                    label: "16:01 - 18:00",//4001 - 4200 pm
                    count: 0,
                    detail: []
                },
                {
                    id: "18012100",
                    label: "18:01 - 21:00",//4201 - 4500 pm
                    count: 0,
                    detail: []
                },
                {
                    id: "21012359",
                    label: "21:01 - 23:59",//4501 - 4759 pm
                    count: 0,
                    detail: []
                },
            ]

            let totalCancel = 0;
            let totalLeads = 0;
            let totalPengurang = 0;

            let summaryWeekly = {
                w1: {leads: 0, pengurang: 0, cancel: 0},
                w2: {leads: 0, pengurang: 0, cancel: 0},
                w3: {leads: 0, pengurang: 0, cancel: 0},
                w4: {leads: 0, pengurang: 0, cancel: 0},
                w5: {leads: 0, pengurang: 0, cancel: 0},
            };

            tmpContactLeads.forEach(lead => {
                lead.leadsCount = lead.leads.length;
                lead.labelCancel = 0;
                lead.pengurangLeads = 0;
                totalLeads += lead.leads.length;
                
                //Weekly
                lead.w1 = {leads:0, pengurang:0, cancel: 0};
                lead.w2 = {leads:0, pengurang:0, cancel: 0};
                lead.w3 = {leads:0, pengurang:0, cancel: 0};
                lead.w4 = {leads:0, pengurang:0, cancel: 0};
                lead.w5 = {leads:0, pengurang:0, cancel: 0};

                var performaSalesByLabelItem = {
                    salesName: lead.salesName,
                    jumlahLeads: lead.leads.length,
                    paid: lead.leads.filter(itemLead => itemLead.label === Global.labelPaid()).length,
                    unpaid: lead.leads.filter(itemLead => itemLead.label === Global.labelUnpaid()).length,
                    pending: lead.leads.filter(itemLead => itemLead.label === Global.labelPending()).length,
                    cancel: lead.leads.filter(itemLead => itemLead.label === Global.labelCancel()).length,
                    random: lead.leads.filter(itemLead => itemLead.label === Global.labelRandomLeads()).length
                };

                performaSalesByLabel.push(performaSalesByLabelItem);

                lead.leads.forEach(contact => {

                    let day = Global.formatDateTimeToStrFormat(contact.created_at, "D");

                    if(arrayLabels.includes(contact.label)){
                    lead.pengurangLeads += 1;
                    totalPengurang += 1;
                    }              
                    if(contact.label === Global.labelCancel()){
                    lead.labelCancel += 1;
                    totalCancel += 1;
                    }

                    if (day <= 7) {//w1
                    lead.w1.leads += 1;
                    summaryWeekly.w1.leads += 1;
                    if(arrayLabels.includes(contact.label)){
                        lead.w1.pengurang += 1;
                        summaryWeekly.w1.pengurang += 1;
                    }              
                    if(contact.label === Global.labelCancel()){
                        lead.w1.cancel += 1;
                        summaryWeekly.w1.cancel += 1;
                    }
                    } else if (day <= 14) {//w2
                    lead.w2.leads += 1;
                    summaryWeekly.w2.leads += 1;
                    if(arrayLabels.includes(contact.label)){
                        lead.w2.pengurang += 1;
                        summaryWeekly.w2.pengurang += 1;
                    }              
                    if(contact.label === Global.labelCancel()){
                        lead.w2.cancel += 1;
                        summaryWeekly.w2.cancel += 1;
                    }
                    } else if (day <= 21) {//w3
                    lead.w3.leads += 1;
                    summaryWeekly.w3.leads += 1;
                    if(arrayLabels.includes(contact.label)){
                        lead.w3.pengurang += 1;
                        summaryWeekly.w3.pengurang += 1;
                    }              
                    if(contact.label === Global.labelCancel()){
                        lead.w3.cancel += 1;
                        summaryWeekly.w3.cancel += 1;
                    }
                    } else if (day <= 28) {//w4
                    lead.w4.leads += 1;
                    summaryWeekly.w4.leads += 1;
                    if(arrayLabels.includes(contact.label)){
                        lead.w4.pengurang += 1;
                        summaryWeekly.w4.pengurang += 1;
                    }              
                    if(contact.label === Global.labelCancel()){
                        lead.w4.cancel += 1;
                        summaryWeekly.w4.cancel += 1;
                    }
                    } else if (day <= 31) {//w5
                    lead.w5.leads += 1;
                    summaryWeekly.w5.leads += 1;
                    if(arrayLabels.includes(contact.label)){
                        lead.w5.pengurang += 1;
                        summaryWeekly.w5.pengurang += 1;
                    }              
                    if(contact.label === Global.labelCancel()){
                        lead.w5.cancel += 1;
                        summaryWeekly.w5.cancel += 1;
                    }
                    } 
                    
                    //Hourly
                    var originalTime = Global.formatDateTimeToStrDateTime12(contact.createdAt, true);
                    var timeAndAmPm = originalTime.split(" ");
                    var time = timeAndAmPm[0];
                    var amPm = timeAndAmPm[1];
                    var convertedTime = this.convertTime12to24(originalTime);

                    var timeValue = parseInt(convertedTime.replaceAll(":", ""));

                    console.log("TIMEVALUE", timeValue);

                    if(contact.label === Global.labelCancel()){
                        if(timeValue <= 2359){
                            timeValue += 2400;
                        }
                        
                        if(timeValue >= 2400 && timeValue <= 2700){
                            tmpContactLeadsHourly[0].count += 1;
                            tmpContactLeadsHourly[0].detail.push(contact);
                        }else if(timeValue >= 2701  && timeValue <= 3000){
                            tmpContactLeadsHourly[1].count += 1;
                            tmpContactLeadsHourly[1].detail.push(contact);
                        }else if(timeValue >= 3001 && timeValue <= 3300){
                            tmpContactLeadsHourly[2].count += 1;
                            tmpContactLeadsHourly[2].detail.push(contact);
                        }else if(timeValue >= 3301 && timeValue <= 3600){
                            tmpContactLeadsHourly[3].count += 1;
                            tmpContactLeadsHourly[3].detail.push(contact);
                        }else if(timeValue >= 3601 && timeValue <= 4000){
                            tmpContactLeadsHourly[4].count += 1;
                            tmpContactLeadsHourly[4].detail.push(contact);
                        }else if(timeValue >= 4001 && timeValue <= 4200){
                            tmpContactLeadsHourly[5].count += 1;
                            tmpContactLeadsHourly[5].detail.push(contact);
                        }else if(timeValue >= 4201 && timeValue <= 4500){
                            tmpContactLeadsHourly[6].count += 1;
                            tmpContactLeadsHourly[6].detail.push(contact);
                        }else if(timeValue >= 4501 && timeValue <= 4759){
                            tmpContactLeadsHourly[7].count += 1;
                            tmpContactLeadsHourly[7].detail.push(contact);
                        }
                    }
                    
                });

            });

            this.setState({contactLeads: tmpContactLeads, performaSalesByLabel: performaSalesByLabel, contactLeadsHourly: tmpContactLeadsHourly, summaryWeekly: summaryWeekly, performaTotalCancel: totalCancel, performaTotalLeads: totalLeads, performaTotalPengurangLeads: totalPengurang, isLoading: false, additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null}, () => {
                console.log("CONTACTLEADS : ", this.state.contactLeads);
            });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      });
      
    };

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    getResumeWeekly = (replaceAll) => {

        this.setState({isLoading: true, resumeByKategori: [], totalWeekly: {
          totalW1: 0,
          totalW2: 0,
          totalW3: 0,
          totalW4: 0,
          totalW5: 0,
        }}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: `${this.state.selectedYear}-${this.state.selectedMonth}-1`,
                endDate: `${this.state.selectedYear}-${this.state.selectedMonth}-1`,
                filterAppNumber: this.state.filterAppNumber
            }
  
            console.log("PARAMS", params, "replaceAll", replaceAll);
  
            axios
            .post(Api.allContactBerlabelCancel(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("Weekly : ", response.data.data);
  
                let resumeByKategori = [];
                Global.getListKategoriProblem().forEach(kategori => {
                    resumeByKategori.push({kategoriName: kategori, w1: 0, w2: 0, w3: 0, w4: 0, w5: 0});
                });
  
                var resumeData = response.data.data;
                var totalWeekly = {
                  totalW1: 0,
                  totalW2: 0,
                  totalW3: 0,
                  totalW4: 0,
                  totalW5: 0,
                };
                
                resumeByKategori.forEach(byKat => {
                  resumeData.forEach((weekly, index) => {
                    if(index === 0){
                      byKat.w1 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                      totalWeekly.totalW1 = totalWeekly.totalW1 + byKat.w1;
                    }else if(index === 1){
                      byKat.w2 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                      totalWeekly.totalW2 = totalWeekly.totalW2 + byKat.w2;
                    }else if(index === 2){
                      byKat.w3 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                      totalWeekly.totalW3 = totalWeekly.totalW3 + byKat.w3;
                    }else if(index === 3){
                      byKat.w4 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                      totalWeekly.totalW4 = totalWeekly.totalW4 + byKat.w4;
                    }else if(index === 4){
                      byKat.w5 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                      totalWeekly.totalW5 = totalWeekly.totalW5 + byKat.w5;
                    }
                  });
                });
                
                this.setState({resumeByKategori: resumeByKategori, totalWeekly: totalWeekly, isLoading: false}, () => {});
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });
  
    };

    getWeeklyCancelLeadsPercentage = () => {

        this.setState({isLoading: true, resumeByKategori: [], totalWeekly: {
          totalW1: 0,
          totalW2: 0,
          totalW3: 0,
          totalW4: 0,
          totalW5: 0,
        }}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: `${this.state.startDate}`,
                endDate: `${this.state.endDate}`,
                filterAppNumber: this.state.filterAppNumber
            }
  
            console.log("PARAMS", params);
  
            axios
            .post(Api.weeklyCancelLeadsPercentage(), params, Api.getRequestHeader())
            .then((response) => {
                
                console.log(response.data);
                this.setState({weeklyLeadsPercentage: response.data.data});
                // this.setState({resumeByKategori: resumeByKategori, totalWeekly: totalWeekly, isLoading: false}, () => {});
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });
  
    };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah FU', minWidth: 100 },
        // { id: 'totalAdaResponLavender', label: 'Respon Lavender', minWidth: 100 },
        { id: 'totalAdaResponHsm', label: 'Respon HSM', minWidth: 100 },
        // { id: 'responRateLavender', label: 'Respon Rate Lavender', minWidth: 100 },
        { id: 'responRate', label: 'Respon Rate HSM', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.handleRefresh());
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        return ( 
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

            {
              this.state.currentConversation !== null ? (
                  <Dialog
                      open={this.state.currentConversation !== null}
                      // TransitionComponent={Transition}
                      // keepMounted
                      onClose={() => {this.setState({currentConversation: null})}}
                      aria-describedby=""
                  >
                      {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                      <DialogContent style={{height: "100%"}}>
                          <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} />
                      </DialogContent>
                      <DialogActions>
                      {/* <Button onClick={handleClose}>Disagree</Button>
                      <Button onClick={handleClose}>Agree</Button> */}
                      </DialogActions>
                  </Dialog>
              ) : (<></>)
            }

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-2" style={{padding: "5px"}}>
                            <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                          </div>
                          <div className="col-2">
                              <FormControl variant="outlined" style={{width: '100%'}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.appProfileId}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl>
                            </div>
                          <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                              
                              {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.handleRefresh}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                              
                            </div>
                        </div>

                        {/* Performa sales by label */}
                        <Divider style={{marginTop: "10px", marginBottom: "10px"}} />
                        <div className="row align-items-center">
                            <div className="col-6">
                              <h4>Performa Sales By Label</h4>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-1"></div>
                            <div className="col-2 text-center" style={{borderRadius: 10, padding: 5}}></div>
                        </div>
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                        <TableCell key={"head1"} align={"left"}>
                                            <strong>Sales</strong>
                                        </TableCell>
                                        <TableCell key={"head2"} align={"left"}>
                                            <strong>Jumlah Leads</strong>
                                        </TableCell>
                                        <TableCell key={"head3"} align={"center"}>
                                            <strong>Paid</strong>
                                        </TableCell>
                                        <TableCell key={"head4"} align={"center"}>
                                            <strong>Unpaid</strong>
                                        </TableCell>
                                        <TableCell key={"head5"} align={"center"}>
                                            <strong>Pending</strong>
                                        </TableCell>
                                        <TableCell key={"head5"} align={"center"}>
                                            <strong>Cancel</strong>
                                        </TableCell>
                                        <TableCell key={"head5"} align={"center"}>
                                            <strong>Random</strong>
                                        </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.performaSalesByLabel.map((row) => {
                                        return (
                                            <TableRow>
                                            <TableCell>{row.salesName}</TableCell>
                                            <TableCell align='center'>{row.jumlahLeads}</TableCell>
                                            <TableCell align='center'>{row.paid}</TableCell>
                                            <TableCell align='center'>{row.unpaid}</TableCell>
                                            <TableCell align='center'>{row.pending}</TableCell>
                                            <TableCell align='center'>{row.cancel}</TableCell>
                                            <TableCell align='center'>{row.random}</TableCell>
                                            </TableRow>
                                        );
                                        })}
                                        {/* <TableRow>
                                            <TableCell><strong>Summary</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.performaTotalCancel}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.performaTotalLeads-this.state.performaTotalPengurangLeads}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.performaTotalLeads > 0 ? Math.round(this.state.performaTotalCancel / (this.state.performaTotalLeads-this.state.performaTotalPengurangLeads) * 100) : 0}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.performaTotalLeads > 0 ? Math.round(this.state.performaTotalCancel / (this.state.performaTotalLeads-this.state.performaTotalPengurangLeads) * 100) : 0}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.performaTotalLeads > 0 ? Math.round(this.state.performaTotalCancel / (this.state.performaTotalLeads-this.state.performaTotalPengurangLeads) * 100) : 0}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.performaTotalLeads > 0 ? Math.round(this.state.performaTotalCancel / (this.state.performaTotalLeads-this.state.performaTotalPengurangLeads) * 100) : 0}</strong></TableCell>
                                        </TableRow> */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        {/* Performa sales by label */}
                        <br />
                        <Divider style={{marginTop: "10px", marginBottom: "10px"}} />
                        <div className="row align-items-center">
                            <div className="col-6">
                              <h4>Performa Cancel Sales</h4>
                                {/* <strong>Total {this.state.contactLeads.length} Salesman</strong> */}
                            </div>
                            <div className="col-2">
                              
                              {/* <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Bulan</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={this.state.selectedMonth}
                                  label="Bulan"
                                  onChange={this.handleChangeMonth}
                                >
                                  <MenuItem value={1}>Januari</MenuItem>
                                  <MenuItem value={2}>Februari</MenuItem>
                                  <MenuItem value={3}>Maret</MenuItem>
                                  <MenuItem value={4}>April</MenuItem>
                                  <MenuItem value={5}>Mei</MenuItem>
                                  <MenuItem value={6}>Juni</MenuItem>
                                  <MenuItem value={7}>Juli</MenuItem>
                                  <MenuItem value={8}>Agustus</MenuItem>
                                  <MenuItem value={9}>September</MenuItem>
                                  <MenuItem value={10}>Oktober</MenuItem>
                                  <MenuItem value={11}>November</MenuItem>
                                  <MenuItem value={12}>Desember</MenuItem>
                                </Select>
                              </FormControl> */}
                            </div>
                            <div className="col-1">
                              {/* <MyDateRangePicker onDateChanged={this.handleDateChanged} /> */}
                              {/* <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tahun</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={this.state.selectedYear}
                                  label="Bulan"
                                  onChange={this.handleChangeYear}
                                >
                                  {
                                    this.state.years.map(year => {
                                      return (<MenuItem value={year}>{year}</MenuItem>);
                                    })
                                  }
                                </Select>
                              </FormControl> */}
                            </div>
                            <div className="col-2 text-center" style={{borderRadius: 10, padding: 5}}>
                              
                              {/* {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={() => this.getResumeWeekly(true)}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              } */}
                              
                            </div>
                        </div>
                        <br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                      <TableCell key={"head1"} align={"left"}>
                                        <strong>Sales</strong>
                                      </TableCell>
                                      <TableCell key={"head2"} align={"center"}>
                                        <strong>Cancel</strong>
                                      </TableCell>
                                      <TableCell key={"head3"} align={"center"}>
                                        <strong>Leads</strong>
                                      </TableCell>
                                      <TableCell key={"head4"} align={"center"}>
                                        <strong>%</strong>
                                      </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.contactLeads.map((row) => {
                                    return (
                                        <TableRow>
                                          <TableCell>{row.salesName}</TableCell>
                                          <TableCell align='center'>{row.labelCancel}</TableCell>
                                          <TableCell align='center'>{row.leadsCount-row.pengurangLeads}</TableCell>
                                          <TableCell align='center'>{Math.round(row.labelCancel / (row.leadsCount-row.pengurangLeads) * 100)}</TableCell>
                                        </TableRow>
                                    );
                                    })}
                                    <TableRow>
                                          <TableCell><strong>Summary</strong></TableCell>
                                          <TableCell align='center'><strong>{this.state.performaTotalCancel}</strong></TableCell>
                                          <TableCell align='center'><strong>{this.state.performaTotalLeads-this.state.performaTotalPengurangLeads}</strong></TableCell>
                                          <TableCell align='center'><strong>{this.state.performaTotalLeads > 0 ? Math.round(this.state.performaTotalCancel / (this.state.performaTotalLeads-this.state.performaTotalPengurangLeads) * 100) : 0}</strong></TableCell>
                                        </TableRow>
                                </TableBody>
                                <TableFooter>
                                </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <br /><br />

                        <h4>Weekly % Cancel</h4>
                        <small>Persentase leads berlabel cancel terhadap semua leads mingguan</small>
                        <br /><br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                      <TableCell key={"head1"} align={"left"} style={{fontWeight: "bold"}}>
                                        Sales
                                      </TableCell>
                                      <TableCell key={"head2"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 1
                                      </TableCell>
                                      <TableCell key={"head3"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 2
                                      </TableCell>
                                      <TableCell key={"head4"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 3
                                      </TableCell>
                                      <TableCell key={"head5"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 4
                                      </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.weeklyLeadsPercentage.map((row) => {
                                    return (
                                        <TableRow>
                                          <TableCell>{row.salesName}</TableCell>
                                          <TableCell align={"center"}>{(row.week1 <= 0 ? 0 : row.week1/row.week1AllLeads*100).toFixed(2)}%</TableCell>
                                          <TableCell align={"center"}>{(row.week2 <= 0 ? 0 : row.week2/row.week2AllLeads*100).toFixed(2)}%</TableCell>
                                          <TableCell align={"center"}>{(row.week3 <= 0 ? 0 : row.week3/row.week3AllLeads*100).toFixed(2)}%</TableCell>
                                          <TableCell align={"center"}>{(row.week4 <= 0 ? 0 : row.week4/row.week4AllLeads*100).toFixed(2)}%</TableCell>
                                        </TableRow>
                                    );
                                    })}
                                    <TableRow>
                                          <TableCell style={{fontWeight: "bold"}}>Summary</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w1 && this.state.summaryWeekly.w1.leads > 0 ? Math.round(this.state.summaryWeekly.w1.cancel / (this.state.summaryWeekly.w1.leads-this.state.summaryWeekly.w1.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w2 && this.state.summaryWeekly.w2.leads > 0 ? Math.round(this.state.summaryWeekly.w2.cancel / (this.state.summaryWeekly.w2.leads-this.state.summaryWeekly.w2.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w3 && this.state.summaryWeekly.w3.leads > 0 ? Math.round(this.state.summaryWeekly.w3.cancel / (this.state.summaryWeekly.w3.leads-this.state.summaryWeekly.w3.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w4 && this.state.summaryWeekly.w4.leads > 0 ? Math.round(this.state.summaryWeekly.w4.cancel / (this.state.summaryWeekly.w4.leads-this.state.summaryWeekly.w4.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w5 && this.state.summaryWeekly.w5.leads > 0 ? Math.round(this.state.summaryWeekly.w5.cancel / (this.state.summaryWeekly.w5.leads-this.state.summaryWeekly.w5.pengurang) * 100) : 0}%</TableCell>
                                        </TableRow>
                                </TableBody>
                                <TableFooter>
                                </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <br />
                        <br />
                        <h4>Weekly</h4>
                        <small>Persentase leads cancel mingguan untuk masing sales</small>
                        <br /><br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                      <TableCell key={"head1"} align={"left"} style={{fontWeight: "bold"}}>
                                        Sales
                                      </TableCell>
                                      <TableCell key={"head2"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 1
                                      </TableCell>
                                      <TableCell key={"head3"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 2
                                      </TableCell>
                                      <TableCell key={"head4"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 3
                                      </TableCell>
                                      <TableCell key={"head5"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 4
                                      </TableCell>
                                      <TableCell key={"head1"} align={"center"} style={{fontWeight: "bold"}}>
                                        Week 5
                                      </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.contactLeads.map((row) => {
                                    return (
                                        <TableRow>
                                          <TableCell>{row.salesName}</TableCell>
                                          <TableCell align={"center"}>{row.w1.leads > 0 ? Math.round(row.w1.cancel / (row.w1.leads-row.w1.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"}>{row.w2.leads > 0 ? Math.round(row.w2.cancel / (row.w2.leads-row.w2.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"}>{row.w3.leads > 0 ? Math.round(row.w3.cancel / (row.w3.leads-row.w3.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"}>{row.w4.leads > 0 ? Math.round(row.w4.cancel / (row.w4.leads-row.w4.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"}>{row.w5.leads > 0 ? Math.round(row.w5.cancel / (row.w5.leads-row.w5.pengurang) * 100) : 0}%</TableCell>
                                        </TableRow>
                                    );
                                    })}
                                    <TableRow>
                                          <TableCell style={{fontWeight: "bold"}}>Summary</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w1 && this.state.summaryWeekly.w1.leads > 0 ? Math.round(this.state.summaryWeekly.w1.cancel / (this.state.summaryWeekly.w1.leads-this.state.summaryWeekly.w1.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w2 && this.state.summaryWeekly.w2.leads > 0 ? Math.round(this.state.summaryWeekly.w2.cancel / (this.state.summaryWeekly.w2.leads-this.state.summaryWeekly.w2.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w3 && this.state.summaryWeekly.w3.leads > 0 ? Math.round(this.state.summaryWeekly.w3.cancel / (this.state.summaryWeekly.w3.leads-this.state.summaryWeekly.w3.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w4 && this.state.summaryWeekly.w4.leads > 0 ? Math.round(this.state.summaryWeekly.w4.cancel / (this.state.summaryWeekly.w4.leads-this.state.summaryWeekly.w4.pengurang) * 100) : 0}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.w5 && this.state.summaryWeekly.w5.leads > 0 ? Math.round(this.state.summaryWeekly.w5.cancel / (this.state.summaryWeekly.w5.leads-this.state.summaryWeekly.w5.pengurang) * 100) : 0}%</TableCell>
                                        </TableRow>
                                </TableBody>
                                <TableFooter>
                                </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <br />
                        <br />
                        <h4>Hourly</h4>
                        <small>Leads cancel berdasarkan periode jam</small>
                        <br /><br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                    {this.state.contactLeadsHourly.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell />
                                      {this.state.contactLeadsHourly.map((column) => (
                                          <TableCell
                                              key={column.id+"a"}
                                              align={column.align}
                                          >
                                          {column.count}
                                          </TableCell>
                                      ))}
                                  </TableRow>
                                </TableBody>
                                </Table>
                            </TableContainer>
                            
                        </Paper>
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>
            </Paper>
         );
    }
}
 
export default ReportPerformaDataCancel;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});


  function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }