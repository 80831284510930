import React, { Component } from 'react';
import "./ConversationSearch.css";
import { CloseRounded } from '@mui/icons-material';
import Api from '../../customlib/Api';
import Global from '../../customlib/Global';
import axios from 'axios';
import ConversationListItem from '../ConversationListItem';
import { CircularProgress, TextField, Tooltip, Paper, FormControlLabel, Switch } from '@mui/material';

class ConversationSearch extends Component {
  state = {
    listMycustomer: [],
    page: 0,
    rowsPerPage: 30,
    keyword: "",
    useLimit: false,
    isLoading: false,
    searchInMessages: false,
    searchInField: "fromName"
  }

  handleKeyword = (event) => {
    this.setState({ keyword: event.target.value });
  };

  onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      this.getMyCustomerToday();
    }
  };

  getMyCustomerToday = () => {

    if(this.state.keyword.length <= 0){
      this.setState({listMycustomer: []});
      return;
    }

    this.setState({isLoading: true}, () => {
      var userData = Global.get();
      const params = {
          userId: userData.user.id,
          page: this.state.page,
          rowsPerPage: this.state.rowsPerPage,
          keywords: this.state.keyword,
          useLimit: this.state.useLimit,
          appProfileId: Global.getAppProfile()._id,
          searchInMessages: this.state.searchInMessages,
          searchInField: this.state.searchInField
      }

      console.log("PARAMS", params);

      axios
      .post(Api.contactListPagedSearchable(), params, Api.getRequestHeader())
      .then((response) => {
          console.log("MyCustomerToday : ", response.data);
          var customers = response.data.data;
          var contacts = [];
          var isSearchInMessages = this.state.searchInMessages;
          customers.forEach(customer => {
            if(isSearchInMessages){
              // let newData =  {
              //   photo: "https://picsum.photos/seed/picsum/200",
              //   fromName: `${customer.contact.name}`,
              //   text: customer.message.text,
              //   fromNumber: customer.from,
              //   contactId: customer.id,
              //   toNumber: customer.to,
              //   toName: customer.toName,
              //   selected: false,
              //   unreadCounter: customer.unreadCounter,
              //   assignToUserName: customer.assignToUserName,
              //   assignToUser: customer.assignToUser,
              //   firstContactAssignedTo: customer.firstContactAssignedTo,
              //   firstContactAssignedToName: customer.firstContactAssignedToName,
              //   csHelpState: customer.csHelpState,
              //   csId: customer.csId,
              //   csName: customer.csName,
              //   csArchived: customer.csArchived,
              //   contactLabel: customer.label,
              //   productCategory: customer.productCategory,
              //   cityId: customer.cityId,
              //   cityName: customer.cityName
              // };

              contacts.push(this.props.generateItemConversation(customer));
            }else{
              // let newData =  {
              //   photo: "https://picsum.photos/seed/picsum/200",
              //   fromName: `${customer.fromName}`,
              //   text: customer.lastMessage,
              //   fromNumber: customer.from,
              //   contactId: customer.id,
              //   toNumber: customer.to,
              //   toName: customer.toName,
              //   selected: false,
              //   unreadCounter: customer.unreadCounter,
              //   assignToUserName: customer.assignToUserName,
              //   assignToUser: customer.assignToUser,
              //   firstContactAssignedTo: customer.firstContactAssignedTo,
              //   firstContactAssignedToName: customer.firstContactAssignedToName,
              //   csHelpState: customer.csHelpState,
              //   csId: customer.csId,
              //   csName: customer.csName,
              //   csArchived: customer.csArchived,
              //   contactLabel: customer.label,
              //   productCategory: customer.productCategory,
              //   cityId: customer.cityId,
              //   cityName: customer.cityName
              // };
              contacts.push(this.props.generateItemConversation(customer));
            }
              
          });

          this.setState({listMycustomer: contacts, isLoading: false});
      })
      .catch((error) => {
        this.setState({isLoading: false});
        console.error("There was an error!", userData.user.id, error);
      });
    });

  };

  handleSwitchSearchContext = () => {
    let mode = false;
    if(this.state.searchInMessages === true){
      mode = false;
    }else{
      mode = true;
    }

    this.setState({searchInMessages: mode, keyword: this.state.keyword}, () => {
      this.getMyCustomerToday();
      // this.getCsHelpList();
    });

  }

  getFieldClassName = (fieldName) => {
    let className = "badge";
    if(this.state.searchInField === fieldName){
      className += " badge-primary";
    }else{
      className += " badge-secondary";
    }

    return className;
  }

  handleFieldSelectionChanged = (fieldName) => {
    this.setState({searchInField: fieldName}, () => {
      this.getMyCustomerToday();
    });
  }

  componentDidMount() {
    this.getMyCustomerToday();
  }

  render() { 
    return ( 

      <Paper style={{marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 20}}>
        <div className="text-right">
          <Tooltip title="Akhiri pencarian">
            <CloseRounded onClick={this.props.onCloseSearch} className="text-danger" style={{cursor: "pointer"}}/>
          </Tooltip>
          
        </div>
        <div className="conversation-search">
          <TextField
            onChange={this.handleKeyword}
            label={this.state.searchInMessages === true ? "Pencarian pesan" : "Cari nama / no wa / sales"}
            type="text"
            className="form-control"
            id="namacustomer"
            variant="outlined"
            onKeyDown={this.onKeyDownHandler}
            onBlur={() => {
                
            }}
        />
        <br />
        <div className="container-fluid">
          <small>Field Pencarian</small>
          <div>
            <div className={this.getFieldClassName("fromName")} onClick={() => this.handleFieldSelectionChanged("fromName")} style={{cursor: "pointer"}}>Nama</div>&nbsp;
            <div className={this.getFieldClassName("from")} onClick={() => this.handleFieldSelectionChanged("from")} style={{cursor: "pointer"}}>Nomor</div>&nbsp;
            <div className={this.getFieldClassName("assignToUserName")} onClick={() => this.handleFieldSelectionChanged("assignToUserName")} style={{cursor: "pointer"}}>Sales</div>
          </div>
        </div>
        {/* <br /> */}
        <div className="text-right">
          <FormControlLabel
            label= "Cari di dalam pesan"
            control={
              <Switch
                checked={this.state.searchInMessages}
                onChange={this.handleSwitchSearchContext}
                name="switchSearchContext"
                color="primary"
              />
            }
            
          />
        </div> 
        </div>

        {
          this.state.isLoading === true ? 
          (
            <div className="container text-center" style={{padding: 10}}>
              <br /><br />
              <CircularProgress color="secondary" />
              <br />
              sedang memuat ...
            </div>
          ) : (<></>)
        }

        <div style={{overflowY: 'scroll', marginBottom: 10, maxHeight: 500}}>
          {
            this.state.listMycustomer.map((conversation) => {
              
              return (
                <ConversationListItem
                  onSelect={this.props.onSelect}
                  key={this.state.searchInMessages === true ? conversation.contactId : conversation.fromNumber}
                  data={conversation}
                />
              )
                    
              })
          }
        </div>
        
      </Paper>
    );
  }
}
 
export default ConversationSearch;