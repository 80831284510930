import React, { Component } from 'react';
import axios from "axios";
import Api from "../../../customlib/Api";
import moment from "moment";
import { Dialog, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Global from "../../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class LeadsPerSections extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null,
        resumeByKategori: [],
        years: [],
        selectedMonth: moment().format("M"),
        selectedYear: moment().format("YYYY"),
        dataPerforma: [],
        contactLeadsHourly: [],
        listAppProfiles: [],
        filterAppNumber: "",
        performaTotalCancel: 0,
        performaTotalPengurangLeads: 0,
        performaTotalLeads: 0,
        summaryWeekly: {},
        performaSalesByLabel: [],
        weeklyLeadsPercentage: [],
        summary : {
          leads: 0,
          closing: 0,
          persenClosing: 0,
          avgResponseTime: "00:00",
          avgClosingTime: "00:00"
        },
        dialogOpen: false,
        selectedSales: null,
        lavenderOrder: []
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.handleRefresh();
          // this.getResumeWeekly(true);
        }
      );
    }

    handleRefresh = () => {
        this.loadAppProfile();
        this.lavenderOrderByCustomer();
        // this.getContactLeads();
    }

    handleChangeMonth = (event) => {
      this.setState({selectedMonth: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleChangeYear = (event) => {
      this.setState({selectedYear: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        console.log(this.state.appProfileId);
        // this.getContactLeads();
        this.handleRefresh();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
    };
    
    lavenderOrderByCustomer = () => {
      const params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate
      };
      
      axios
        .post(
          Api.getLavenderOrderByCustomer(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("LAVENDER ORDER", response.data);
          this.setState({lavenderOrder: response.data.data}, () => {
            this.getContactLeads();
          });
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
    };

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            appProfileId: this.state.appProfileId
        }

        this.setState({contactLeads: [], contactLeadsHourly: [], performaTotalCancel: 0, performaTotalLeads: 0, performaTotalPengurangLeads: 0, additionalData: null, isLoading: true}, () => {
            axios
            .post(Api.getContactLeads(), params, Api.getRequestHeader())
            .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }
            
            var performaSalesByLabel = [];
            var total = {
              leads: 0,
              closing: 0,
              closingTime: 0,
              totalQty: 0,
              totalOrder: 0,
              persenClosing: 0,
              totalLessOneMinute: 0,
              totalMoreOneMinute: 0,
              avgResponseTime: 0,
              avgClosingTime: 0,
              persenMoreOneMinute: 0
            };

            var tmpContactLeadsHourly = [
              {
                id: "24000300",
                label: "Shift 3",
                timeSection: "24:00 - 03:00",//2400 - 2700 am
                jumlahLeads: 0,
                totalClosing: 0,
                persenClosing: 0,
                totalQty: 0,
                totalOrder: 0,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                totalResponseTime: 0,
                totalClosingTime: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00",
                persenMoreOneMinute: 0,
                detail: []
              },
              {
                id: "03010600",
                label: "Shift 3",//2701 - 3000 am
                timeSection: "03:01 - 06:00",//2701 - 3000 am
                jumlahLeads: 0,
                totalClosing: 0,
                persenClosing: 0,
                totalQty: 0,
                totalOrder: 0,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                totalResponseTime: 0,
                totalClosingTime: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00",
                persenMoreOneMinute: 0,
                detail: []
              },
              {
                id: "06010900",
                label: "Shift 1",
                timeSection: "06:01 - 09:00",//3001 - 3300 am
                jumlahLeads: 0,
                totalClosing: 0,
                persenClosing: 0,
                totalQty: 0,
                totalOrder: 0,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                totalResponseTime: 0,
                totalClosingTime: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00",
                persenMoreOneMinute: 0,
                detail: []
              },
              {
                id: "09011200",
                label: "Shift 1",
                timeSection: "09:01 - 12:00",//3301 - 3600 am
                jumlahLeads: 0,
                totalClosing: 0,
                persenClosing: 0,
                totalQty: 0,
                totalOrder: 0,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                totalResponseTime: 0,
                totalClosingTime: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00",
                persenMoreOneMinute: 0,
                detail: []
              },
              {
                id: "12011600",
                label: "Shift 1 & 2",
                timeSection: "12:01 - 16:00",//3601 - 4000 pm
                jumlahLeads: 0,
                totalClosing: 0,
                persenClosing: 0,
                totalQty: 0,
                totalOrder: 0,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                totalResponseTime: 0,
                totalClosingTime: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00",
                persenMoreOneMinute: 0,
                detail: []
              },
              {
                id: "16011800",
                label: "Shift 2",
                timeSection: "16:01 - 18:00",//4001 - 4200 pm
                jumlahLeads: 0,
                totalClosing: 0,
                persenClosing: 0,
                totalQty: 0,
                totalOrder: 0,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                totalResponseTime: 0,
                totalClosingTime: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00",
                persenMoreOneMinute: 0,
                detail: []
              },
              {
                id: "18012100",
                label: "Shift 2",
                timeSection: "18:01 - 21:00",//4201 - 4500 pm
                jumlahLeads: 0,
                totalClosing: 0,
                persenClosing: 0,
                totalQty: 0,
                totalOrder: 0,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                totalResponseTime: 0,
                totalClosingTime: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00",
                persenMoreOneMinute: 0,
                detail: []
              },
              {
                id: "21012359",
                label: "Shift 2 & 3",
                timeSection: "21:01 - 23:59",//4501 - 4759 pm
                jumlahLeads: 0,
                totalClosing: 0,
                persenClosing: 0,
                totalQty: 0,
                totalOrder: 0,
                totalLessOneMinute: 0,
                totalMoreOneMinute: 0,
                totalResponseTime: 0,
                totalClosingTime: 0,
                avgResponseTime: "00:00",
                avgClosingTime: "00:00",
                persenMoreOneMinute: 0,
                detail: []
              },
            ]

            tmpContactLeads.forEach(lead => {

              let leadsExcludeLabelRandom = lead.leads.filter(itemLead => itemLead.label !== Global.labelRandomLeads());

              // let jumlahLeads = leadsExcludeLabelRandom.length;
              // let totalClosing = lead.leads.filter(itemLead => itemLead.label === Global.labelUnpaid() || itemLead.label === Global.labelPaid() || itemLead.label === Global.labelDp()).length;
              // let totalClosing = leadsExcludeLabelRandom.filter(itemLead => itemLead.isClosing).length;
              // let persenClosing = totalClosing > 0 ? (totalClosing/jumlahLeads*100).toFixed(1) : 0;
              // let avgResponseTime = 0;
              // let avgClosingTime = 0;

              // total.leads += jumlahLeads;
              // total.closing += totalClosing;

              // var performaSalesByLabelItem = {
              //     salesName: lead.salesName,
              //     jumlahLeads: jumlahLeads,
              //     totalClosing: totalClosing,
              //     persenClosing: persenClosing,
              //     totalLessOneMinute: 0,
              //     totalMoreOneMinute: 0,
              //     avgResponseTime: "00:00",
              //     avgClosingTime: "00:00"
              // };

              // performaSalesByLabel.push(performaSalesByLabelItem);

              let totalResponseTime = 0;
              let totalClosingTime = 0;

              let lavenderOrderData = [...this.state.lavenderOrder];
              leadsExcludeLabelRandom.forEach(item => {

                var originalTime = Global.formatDateTimeToStrFormat(item.created_at, "hh:mm A");
                var convertedTime = Global.convertTime12to24(originalTime);

                var timeValue = parseInt(convertedTime.replaceAll(":", ""));

                console.log("TIMEVALUE", timeValue);

                if(timeValue <= 2359){
                  timeValue += 2400;
                }

                let section = null;

                if(timeValue >= 2400 && timeValue <= 2700){
                  section = tmpContactLeadsHourly[0];
                }else if(timeValue >= 2701  && timeValue <= 3000){
                  section = tmpContactLeadsHourly[1];
                }else if(timeValue >= 3001 && timeValue <= 3300){
                  section = tmpContactLeadsHourly[2];
                }else if(timeValue >= 3301 && timeValue <= 3600){
                  section = tmpContactLeadsHourly[3];
                }else if(timeValue >= 3601 && timeValue <= 4000){
                  section = tmpContactLeadsHourly[4];
                }else if(timeValue >= 4001 && timeValue <= 4200){
                  section = tmpContactLeadsHourly[5];
                }else if(timeValue >= 4201 && timeValue <= 4500){
                  section = tmpContactLeadsHourly[6];
                }else if(timeValue >= 4501 && timeValue <= 4759){
                  section = tmpContactLeadsHourly[7];
                }

                

                let penjualan = lavenderOrderData.find(order => order.phone === item.from);
                if(penjualan){
                  item.totalQty = penjualan.qty_items;
                  item.totalOrder = penjualan.total;
                }else{
                  item.totalQty = 0;
                  item.totalOrder = 0;
                }

                var delta = 0;
                if(item.closingDeltaTime !== undefined && item.closingDeltaTime !== null){
                  totalClosingTime += item.closingDeltaTime;
                  section.totalClosingTime += item.closingDeltaTime;
                }
                if(item.avgResponseTime != null){
                  delta = item.avgResponseTime;
                  section.totalResponseTime += delta < 0 ? 0 : delta;
                }else{
                  delta = 24 * 3600;
                  section.totalResponseTime += delta;
                }

                item.responseTime = delta;
                if(delta <= 60){
                  section.totalLessOneMinute += 1;
                }else{
                  section.totalMoreOneMinute += 1;
                }

                section.totalQty += item.totalQty;
                section.totalOrder += item.totalOrder;
                section.totalClosing += item.isClosing ? 1 : 0;
                section.jumlahLeads += 1;
              });

            });

            tmpContactLeadsHourly.forEach(item => {
              let avgResponseTime = item.totalResponseTime > 0 ? item.totalResponseTime/item.jumlahLeads : 0;
              item.avgResponseTime = Global.formatTime(Math.round(avgResponseTime));
              total.avgResponseTime += avgResponseTime;
              
              // avgClosingTime = totalClosingTime > 0 ? totalClosingTime/jumlahLeads : 0;
              let avgClosingTime = item.totalClosingTime > 0 ? item.totalClosingTime/item.totalClosing : 0;
              item.avgClosingTime = Global.formatTime(Math.round(avgClosingTime));
              total.avgClosingTime += avgClosingTime;

              item.persenClosing = (item.jumlahLeads > 0 ? item.totalClosing/item.jumlahLeads*100 : 0).toFixed(2);
              item.persenMoreOneMinute = item.jumlahLeads > 0 && item.totalMoreOneMinute > 0 ? (item.totalMoreOneMinute/item.jumlahLeads*100).toFixed(2) : 0;

              total.totalLessOneMinute += item.totalLessOneMinute;
              total.totalMoreOneMinute += item.totalMoreOneMinute;
              total.closing += item.totalClosing;
              total.closingTime += item.totalClosingTime;
              total.leads += item.jumlahLeads;
              total.totalQty += item.totalQty;
              total.totalOrder += item.totalOrder;

            })

            total.persenClosing = (total.closing/total.leads*100).toFixed(2);
            total.persenMoreOneMinute = (total.totalMoreOneMinute/total.leads*100).toFixed(2);
            total.avgResponseTime = Global.formatTime(Math.round(total.avgResponseTime/performaSalesByLabel.length));
            // total.avgClosingTime = Global.formatTime(Math.round(total.avgClosingTime/performaSalesByLabel.length));
            total.avgClosingTime = Global.formatTime(Math.round(total.avgClosingTime/total.closing));

            this.setState({contactLeads: tmpContactLeads, performaSalesByLabel: tmpContactLeadsHourly, summary: total, isLoading: false, additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null}, () => {
                // console.log("CONTACTLEADS : ", this.state.contactLeads);
            });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      });
      
    };

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => this.handleRefresh());
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    handleSelectSales = (salesName) => {
      console.log("CONTACTLEADS", this.state.contactLeads);
      let selected = this.state.contactLeads.find((item) => item.salesName === salesName);
      console.log("SELECTED", selected);
      if(selected){
        this.setState({selectedSales: selected}, this.toggleShowDialogDetail);
      }

    }

    toggleShowDialogDetail = () => {

      if(this.state.dialogOpen){
        this.setState({dialogOpen: !this.state.dialogOpen, selectedSales: null});
      }else{
        this.setState({dialogOpen: !this.state.dialogOpen});
      }

      
    }

    render() { 
        
        return ( 
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-2" style={{padding: "5px"}}>
                            <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                          </div>
                          <div className="col-2">
                              <FormControl variant="outlined" style={{width: '100%'}} size='small'>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.appProfileId}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl>
                            </div>
                          <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                              
                              {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.handleRefresh}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                              
                            </div>
                        </div>

                        {/* Performa sales by label */}
                        {/* <Divider style={{marginTop: "10px", marginBottom: "10px"}} /> */}
                        {/* <div className="row align-items-center">
                            <div className="col-6">
                              <h4>Closing & Average Response Time</h4>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-1"></div>
                            <div className="col-2 text-center" style={{borderRadius: 10, padding: 5}}></div>
                        </div> */}
                        <br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                          {/* <TableCell key={"head0"} align={"left"} width={10}></TableCell> */}
                                          <TableCell key={"head1"} align={"left"}>
                                              <strong>Shift</strong>
                                          </TableCell>
                                          <TableCell key={"head1"} align={"left"}>
                                              <strong>Section Time</strong>
                                          </TableCell>
                                          {/* <TableCell key={"head1"} align={"left"}>
                                              <strong>Sales</strong>
                                          </TableCell> */}
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>Leads</strong>
                                          </TableCell>
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>Customer</strong>
                                          </TableCell>
                                          <TableCell key={"head2"} align={"center"}>
                                              <strong>Sales Qty</strong>
                                          </TableCell>
                                          {/* <TableCell key={"head3"} align={"center"}>
                                              <strong>Closing</strong>
                                          </TableCell> */}
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>% Closing</strong>
                                          </TableCell>
                                          <TableCell key={"head4"} align={"center"}>
                                              <strong>Sales value</strong>
                                          </TableCell>
                                          <TableCell key={"head8"} align={"center"}>
                                              <strong>Avg Time Closing</strong>
                                          </TableCell>
                                          <TableCell key={"head6"} align={"center"}>
                                              <strong>% Respon &gt; 1 minute</strong>
                                          </TableCell>
                                          {/* <TableCell key={"head7"} align={"center"}>
                                              <strong>Avg Response Time</strong>
                                          </TableCell>
                                          <TableCell key={"head5"} align={"center"}>
                                              <strong>&lt; 1 Minute</strong>
                                          </TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.performaSalesByLabel.map((row) => {
                                        return (
                                            <TableRow>
                                              {/* <TableCell width={10} align='center'><Analytics onClick={() => this.handleSelectSales(row.salesName)} color='primary' style={{cursor: "pointer"}} /></TableCell> */}
                                              {/* <TableCell>{row.salesName}</TableCell> */}
                                              <TableCell>{row.label}</TableCell>
                                              <TableCell>{row.timeSection}</TableCell>
                                              <TableCell align='center'>{row.jumlahLeads}</TableCell>
                                              <TableCell align='center'>{row.totalClosing}</TableCell>
                                              <TableCell align='center'>{row.totalQty}</TableCell>
                                              {/* <TableCell align='center'>{row.totalClosing}</TableCell> */}
                                              <TableCell align='center'>{row.persenClosing}</TableCell>
                                              <TableCell align='center'>{new Intl.NumberFormat("en-id").format(row.totalOrder)}</TableCell>
                                              <TableCell align='center'>{row.avgClosingTime}</TableCell>
                                              {/* <TableCell align='center'>{row.totalLessOneMinute}</TableCell> */}
                                              <TableCell align='center'>{row.persenMoreOneMinute}%</TableCell>
                                              {/* <TableCell align='center'>{row.avgResponseTime}</TableCell> */}
                                            </TableRow>
                                        );
                                        })}
                                        <TableRow>
                                            {/* <TableCell></TableCell> */}
                                            <TableCell><strong></strong></TableCell>
                                            <TableCell><strong></strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.leads}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.closing}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.totalQty}</strong></TableCell>
                                            {/* <TableCell align='center'><strong>{this.state.summary.closing}</strong></TableCell> */}
                                            <TableCell align='center'><strong>{this.state.summary.persenClosing}</strong></TableCell>
                                            <TableCell align='center'><strong>{new Intl.NumberFormat("en-id").format(this.state.summary.totalOrder)}</strong></TableCell>
                                            <TableCell align='center'><strong>{this.state.summary.avgClosingTime}</strong></TableCell>
                                            {/* <TableCell align='center'><strong>{this.state.summary.totalLessOneMinute}</strong></TableCell> */}
                                            <TableCell align='center'><strong>{this.state.summary.persenMoreOneMinute}%</strong></TableCell>
                                            {/* <TableCell align='center'><strong>{this.state.summary.avgResponseTime}</strong></TableCell> */}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        {/* Performa sales by label */}
                        
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>

              {/* <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={this.state.dialogOpen}
                onClose={this.toggleShowDialogDetail}
              >
                <DialogTitle>Optional sizes</DialogTitle>
                <DialogContent className='bg-secondary'>
                  {
                    this.state.selectedSales && <DetailClosingAndAvgResponseBySales leadsData={this.state.selectedSales} onClose={this.toggleShowDialogDetail} />
                  }
                </DialogContent>
              </Dialog> */}

            </Paper>
         );
    }
}
 
export default LeadsPerSections;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});


function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }