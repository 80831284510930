import { Campaign, Close, RecordVoiceOver, Refresh } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Tab, Table, TableCell, TableHead, TableRow, Tabs, Tooltip } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Api from '../../customlib/Api';
import Global from '../../customlib/Global';

class FollowUp123 extends Component {
    state = { 
        isOpen: false,
        listFu1: [],
        listFu2: [],
        listFu3: [],
        selectedTab: "fu1",
        isLoading: true
    } 

    loadListCustomerFu = () => {

        this.setState({isLoading: true}, () => {
            let appProfileId = Global.getAppProfile()._id;
            axios
            .get(Api.listFu123(appProfileId), Api.getRequestHeader())
            .then((response) => {
                let responseData = response.data;
                console.log("LISTFU123", responseData);
                if(responseData.success === true){

                    let listFu1 = [];
                    let listFu2 = [];
                    let listFu3 = [];

                    var tmpContactLeads = responseData.data;
                    
                    var userData = Global.get();
                    console.log("USERDATA : ", userData);

                    if(Global.get().admin === false){
                        listFu1 = tmpContactLeads.fu1.filter(element => element.assignToUser === userData.user.id);
                        listFu2 = tmpContactLeads.fu2.filter(element => element.assignToUser === userData.user.id);
                        listFu3 = tmpContactLeads.fu3.filter(element => element.assignToUser === userData.user.id);
                    }else{
                        listFu1 = tmpContactLeads.fu1;
                        listFu2 = tmpContactLeads.fu2;
                        listFu3 = tmpContactLeads.fu3;
                    }

                    this.setState({listFu1: listFu1, listFu2: listFu2, listFu3: listFu3,isLoading: false }, () => {
                        // console.log("LISTFU123_STATE", this.state.listCustomers);
                    });
                    // let contact = this.props.generateItemConversation(responseData.data);
                    // this.props.handleConversationSelected(contact);
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message, isLoading: false });
                console.error("There was an error!", error);
            });
        });

        
    }

    componentDidMount() {
        this.setState({isOpen: true}, () => {
            this.loadListCustomerFu();
        });
    }

    handleTabChange = (event, value) => {
        this.setState({selectedTab: value});
    }

    handleOpenChat = (contact) => {
        let conversation = this.props.generateItemConversation(contact);
        this.props.handleConversationSelected(conversation);
    }

    render() { 
        return (<>
            <Dialog
                open={this.state.isOpen}
                onClose={this.props.onClose}
                maxWidth="md"
            >
                <DialogTitle>
                    <div className="row">
                        <div className="col-9">
                            List Customer FU
                        </div>
                        <div className="col-3 text-right">
                            <Tooltip title="Reload">
                                <IconButton size='small' onClick={this.loadListCustomerFu}><Refresh color='primary' /></IconButton>
                            </Tooltip>
                            <Tooltip title="Close">
                                <IconButton size='small' onClick={this.props.onClose}><Close color='error' /></IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', minWidth: "30vw", maxWidth: "30vw" }}>
                        <Tabs
                            value={this.state.selectedTab}
                            onChange={this.handleTabChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                            variant='fullwidth'
                            centered
                        >
                            <Tab value="fu1" label="FU 1" />
                            <Tab value="fu2" label="FU 2" />
                            <Tab value="fu3" label="FU 3" />
                        </Tabs>
                        {/* {
                            this.state.isLoading && <LinearProgress sx={{marginTop: "20px"}} />
                        } */}
                        <Box sx={{minHeight: "70vh", maxHeight: "70vh", overflowY: "auto"}}>
                        {
                            this.state.isLoading && <LinearProgress sx={{marginTop: "20px"}} />
                        }
                        {
                            this.state.selectedTab === "fu1" && <Table size='small'>
                                {
                                    this.state.listFu1 && this.state.listFu1.map(element => {
                                        return <TableRow>
                                            <TableCell colSpan={3} style={{maxWidth: "20vw", overflow: "hidden"}}>
                                            <strong>{element.fromName}</strong><br />
                                                <Box sx={{overflowY: "hidden", maxHeight: "40px"}}>
                                                    <small>{element.lastMessage}</small>
                                                </Box>
                                                <small><strong><RecordVoiceOver style={{width: 13, marginRight: "5px"}} />{element.assignToUserName}</strong></small>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title={"Follow Up 1"} >
                                                    <IconButton color='error' onClick={() => this.handleOpenChat(element)}><Campaign color='error' /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </Table>
                        }
                        {
                            this.state.selectedTab === "fu2" && <Table size='small'>
                                {
                                    this.state.listFu2 && this.state.listFu2.map(element => {
                                        return <TableRow>
                                            <TableCell colSpan={3} style={{maxWidth: "20vw", overflow: "hidden"}}>
                                                <strong>{element.fromName}</strong><br />
                                                <Box sx={{overflowY: "hidden", maxHeight: "40px"}}>
                                                    <small>{element.lastMessage}</small>
                                                </Box>
                                                <small><strong><RecordVoiceOver style={{width: 13, marginRight: "5px"}} />{element.assignToUserName}</strong></small>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title={"Follow Up 2"} >
                                                    <IconButton color='error' onClick={() => this.handleOpenChat(element)}><Campaign color='error' /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </Table>
                        }
                        {
                            this.state.selectedTab === "fu3" && <Table size='small'>
                                {
                                    this.state.listFu3 && this.state.listFu3.map(element => {
                                        return <TableRow>
                                            <TableCell colSpan={3} style={{maxWidth: "20vw", overflow: "hidden"}}>
                                                <strong>{element.fromName}</strong><br />
                                                <Box sx={{overflowY: "hidden", maxHeight: "40px"}}>
                                                    <small>{element.lastMessage}</small>
                                                </Box>
                                                <small><strong><RecordVoiceOver style={{width: 13, marginRight: "5px"}} />{element.assignToUserName}</strong></small>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title={"Follow Up 3"} >
                                                    <IconButton color='error' onClick={() => this.handleOpenChat(element)}><Campaign color='error' /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </Table>
                        }

                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>);
    }
}
 
export default FollowUp123;