import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, CircularProgress, Autocomplete, TextField} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Search } from '@mui/icons-material';
import DateRangePicker from '../../customlib/DateRangePicker';

class ByURL extends Component {
    state = { 
        contactLeads : [],
        contactLeadsDisplay : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        isTeleReport: true,
        cityList: [],
        selectedCity: "",
        isLoading: false
    }
    
    componentDidMount() {
        this.getContactLeads();
    }

    getContactLeads = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      var params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
        userId: userData.user.id,
        reportType: "TYPE_BYURL",
        cityName: this.state.selectedCity
      };

      console.log("PARAMS", params);
      this.setState({contactLeads: [], contactLeadsDisplay: [], isLoading: true});

      axios
        .post(Api.getLeadsReportSummary(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONTACTLEADS : ", response.data);
          console.log("USERDATA : ", "wkwkwk");
          
          var tmpContactLeads = response.data.data.summary;
          var tmpCityList = response.data.data.cities;
          
          // if(userData.admin === false){
          //   tmpContactLeads = tmpContactLeads.filter(element => element.salesName === userData.user.name);
          // }

          // tmpContactLeads.forEach(lead => {
          //   lead.leadsCount = lead.leads.length;
          //   tmpCityList.push(lead.city);
          // });

          this.setState({contactLeads: tmpContactLeads, cityList: tmpCityList, contactLeadsDisplay: tmpContactLeads, isLoading: false});
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleCityOnChange = (city) => {
      console.log("SELECTEDCITY", city);

      this.setState({selectedCity: city}, () => {
        this.getContactLeads();
      });
    }

    columns = [
        { id: 'source_url', label: 'URL', minWidth: 170 },
        { id: 'count_url', label: 'Jumlah', minWidth: 100 }
    ];

    render() { 
        return ( 
          <Paper style={{padding:20}}>
                <div className="container-fluid">
                  <div className="panel panel-default">
                      <div className="panel-heading">
                          <div className="panel-title">
                              {/* <h1>Setup Contact</h1> */}
                          </div>
                      </div>
                      <div className="panel-body">
                          <div className="row align-items-center">
                              <div className="col-3">
                                  <strong>Total {this.state.contactLeadsDisplay.length} URL</strong>
                              </div>
                              <div className="col-3">
                              <Autocomplete
                                    id="free-solo-2-demo"
                                    // disableClearable
                                    freeSolo 
                                    options={this.state.cityList.map((option) => {
                                        // console.log("LABEL : ", option);
                                        return String(option);
                                    })}
                                    onChange={(event, newValue) => {
                                        // console.log("NEWVALUE : ", event);
                                        this.handleCityOnChange(newValue);
                                      }} 
                                    value={this.state.selectedCity}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pilih Kota"
                                        margin="0"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />
                                    )}
                                    size='small'
                                />
                              </div>
                              <div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                              </div>
                              <div className="col-1">
                              {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                            </div>
                          </div>
                          <br />
                          <Paper className="root">
                              <TableContainer>
                                  <Table aria-label="sticky table" size='small'>
                                  <TableHead>
                                      <TableRow>
                                      {this.columns.map((column) => (
                                          <TableCell
                                              key={column.id}
                                              align={column.align}
                                              style={{ minWidth: column.minWidth }}
                                          >
                                          {column.label}
                                          </TableCell>
                                      ))}
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {this.state.contactLeadsDisplay.map((row) => {
                                      return (
                                          <TableRow>
                                            <TableCell>{row.source_url}</TableCell>
                                            <TableCell>{row.count_url}</TableCell>
                                          </TableRow>
                                      );
                                      })}
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                              
                          </Paper>
                          
                      </div>
                      <div className="panel-footer">
                                      
                      </div>
                  </div>
                </div>
              </Paper>
         );
    }
}
 
export default ByURL;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" align='left'>
            {row.city}
          </TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Berdasarkan Kota dan Kategori Produk
                </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Kota</TableCell> */}
                      <TableCell>Kategori Produk</TableCell>
                      <TableCell>Jumlah</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        {/* <TableCell component="th" scope="row">
                          <div>{historyRow.city}</div>
                        </TableCell> */}
                        <TableCell align="left">{historyRow.product_category === "" ? "---" : historyRow.product_category}</TableCell>
                        <TableCell align="left">
                          {historyRow.count_category}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }