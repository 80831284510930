import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import {Paper, Tabs, Tab, Typography, Box} from '@mui/material';
import FollowUpHarian from './FollowUpHarian';
import { Link } from 'react-router-dom';
import FollowUpRutin from './FollowUpRutin';
import FollowUpReport from './FollowUpReport';
import Global from "../../customlib/Global";
import DataPendingEffectivityRate from './DataPendingEffectivityRate';
import DataPendingLeads from './DataPendingLeads';
import DataCancelDashboard from './DataCancelDashboard';
import ReportLeadsDataCancel from './RportLeadsDataCancel';
import ReportPerformaDataCancel from './ReportPerformaDataCancel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class AnalisaDataCancel extends Component {

    state = { 
        value : 0,
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    // classes = useStyles();
    // const [value, setValue] = React.useState(0);

    componentDidMount() {
        this.setState({value: 0});
    }
    

    handleChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };

    render() { 
        return ( 
            <>
            <Paper className={this.useStyles.root}>
                <div className="container text-center">
                  <div className="row">
                      <div className="col-11" style={{paddingLeft: "30px"}}><h1>Analisa Data Cancel</h1></div>
                      <div className="col-1 text-right">
                          <Link to="/">
                              <div className="btn btn-danger">X</div>
                          </Link>
                      </div>
                  </div>
                </div>
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab key="tab0" label="Input Analisa" />
                    <Tab key="tab1" label="Report Leads" />
                    <Tab key="tab2" label="Report Performa" />
                </Tabs>
            </Paper>
            <TabPanel value={this.state.value} index={0}>
                <DataCancelDashboard />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
                <ReportLeadsDataCancel />
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
                <ReportPerformaDataCancel />
            </TabPanel>
            </>
         );
    }
}
 
export default AnalisaDataCancel;