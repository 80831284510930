import React, { Component } from 'react';

class OneTabWarning extends Component {
    state = {  } 
    render() { 
        return (
            <div className="container text-center" style={{marginTop: 100}}>
                <h1>PERHATIAN!</h1>
                <h4 style={{color: "#135a91"}}>Hanya <strong><span style={{color: "#FF0000"}}>1</span></strong> tab Lotus yang boleh dibuka</h4>
                Refresh tab ini setelah anda menutup tab yang lain!
            </div>
        );
    }
}
 
export default OneTabWarning;