import React, { Component } from 'react';
import { List, ListItem, ListItemText, Paper, Button, Divider } from '@mui/material';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import Api from '../../customlib/Api';
import axios from 'axios';
import Global from '../../customlib/Global';
import ReactLoading from 'react-loading';

class SetupChatUser2 extends Component {
    state = { 
        listUsers: [],
        countLavengerUser: 0,
        countNotLavengerUser: 0,
        isSaving: false,
        message: "Klik User Lavender agar menjadi User Chat",
        isError: false
    }

    getLavengerUsers = () => {
        axios
        .post(Api.lavengerUsersList(), {}, Api.getRequestHeader())
        .then((response) => {

            var users = response.data.data;
            var countLavengerUser = users.filter(user => user.isLavengerUser === true).length;
            var countNotLavengerUser = users.filter(user => user.isLavengerUser === false).length;

            console.log("LAVENGERUSERS : ", users);
            this.setState({listUsers: users, countLavengerUser: countLavengerUser, countNotLavengerUser: countNotLavengerUser});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    saveLavengerUsers = () => {

        this.setState({isSaving: true}, () => {

            const newChatUsers = this.state.listUsers.filter(user => user.isLavengerUser === true);

            var newChatUserIds = [];
            newChatUsers.forEach(user => {
                newChatUserIds.push(user.id);
            });

            const params = {
                "ids": newChatUserIds,
                "updatedBy": Global.get().user.id,
                "updatedByName": Global.get().user.name
            };

            console.log("PARAMS : ", params);

            let message = "";

            axios
            .post(Api.saveChatUserIds(), params, Api.getRequestHeader())
            .then((response) => {

                message = response.data.message;


                console.log("RESPONSE : ", response.data);
                this.setState({isSaving: false, message: message, isError: !response.data.success});
            })
            .catch((error) => {
                this.setState({isSaving: false, message: message, isError: true});
                console.error("There was an error!", error);
            });

        });
    };

    handleMoveUser = (userData) => {
        const users = [...this.state.listUsers];
        var findUser = users.filter(user => user.id === userData.id);
        

        try{
            if(findUser.length > 0){
                findUser[0].isLavengerUser = !findUser[0].isLavengerUser;
            }

            var countLavengerUser = users.filter(user => user.isLavengerUser === true).length;
            var countNotLavengerUser = users.filter(user => user.isLavengerUser === false).length;
            this.setState(
                {
                    listUsers: users, 
                    countLavengerUser: countLavengerUser, 
                    countNotLavengerUser: countNotLavengerUser,
                    message: "Perubahan belum disimpan, klik tombol simpan"
                }
            );

        }catch(err){
            console.log(err);
        }


    }

    componentDidMount() {
        this.getLavengerUsers();
    }

    render() { 
        return ( 
            <Paper>
                <div className="container">
                    <div className="row">
                        <div className="col-10">

                            {
                                this.state.isSaving ? 
                                (
                                    <><ReactLoading type="bubbles" width="50" height="20" /> Sedang menyimpan ....</>
                                ) : 
                                (
                                    <div className={this.state.isError ? "alert alert-danger" : "alert alert-primary"}>
                                        {this.state.message}
                                    </div>
                                )
                            }

                        </div>
                        <div className="col-2">
                            <div className="btn btn-primary btn-block" onClick={this.saveLavengerUsers}>
                                Simpan
                            </div>
                        </div>
                    </div>
                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                    <div className="row">
                        <div className="col-6">
                            <Paper style={{width: "100%", padding: 10}}>
                                <h4>User Lavender</h4>
                                <div style={{marginBottom: 10}} className="badge badge-primary">{this.state.countNotLavengerUser} User</div>
                                <Divider />
                                <List style={{maxHeight: 470, overflowY: "scroll"}}>
                                    {
                                    this.state.listUsers.filter(user => user.isLavengerUser === false).map(user => 
                                        (<ListItem button key={user.id} onClick={() => {this.handleMoveUser(user)}}>
                                            <div className="row">
                                                <div className="col-11">
                                                    <ListItemText primary={user.name} />
                                                </div>
                                                <div className="col-1">
                                                    <ChevronRight style={{marginTop: 5}} />
                                                </div>
                                            </div>
                                        </ListItem>)
                                    )
                                    }
                                </List>
                            </Paper>
                        </div>
                        {/* <div className="col-2"></div> */}
                        <div className="col-6">
                            <Paper style={{width: "100%", padding: 10}}>
                                <h4>User Chat</h4>
                                <div style={{marginBottom: 10}} className="badge badge-primary">{this.state.countLavengerUser} User</div>
                                <Divider />
                                <List style={{maxHeight: 470, overflowY: "scroll"}}>
                                    {
                                    this.state.listUsers.filter(user => user.isLavengerUser === true).map(user => 
                                        (<ListItem button key={user.id} onClick={() => {this.handleMoveUser(user)}}>
                                        <div className="row">
                                            <div className="col-1 text-left">
                                                <ChevronLeft style={{marginTop: 5}} />
                                            </div>
                                            <div className="col-11">
                                                <ListItemText primary={user.name} />
                                            </div>
                                        </div>
                                    </ListItem>)
                                    )
                                    }
                                    
                                </List>
                            </Paper>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}
 
export default SetupChatUser2;