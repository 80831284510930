import React, { Component } from "react";
import Message from "../Message";
import moment from "moment";
import axios from "axios";

import "./MessageList.css";
import Api from "../../customlib/Api";
import Global from "../../customlib/Global";
import ReactLoading from "react-loading";
import mp3File from "../../sound/notification.mp3";
import newContactMp3File from "../../sound/new_contact.mp3";
import csMp3File from "../../sound/cs_notification.mp3";
import slowAlarmMp3File from "../../sound/buzzer.mp3";
import unfinishedChatMp3File from "../../sound/unfinished-chat.mp3";
import Toolbar from "../Toolbar";

const MY_USER_ID = ["6281231828249", "447860099299"];

// var socket;
var DataTransfer = require('fbjs/lib/DataTransfer');
class MessageListViewer extends Component {

  state = {
    messages: [],
    newMessage: "",
    open: false,
    files: [],
    fileType: "IMAGE",//or DOCUMENT
    origFileName: "",
    snackbarVisible: false,
    mustSendGreeting: false, 
    right: false,
    isLoading: false,
    audio: new Audio(mp3File),
    newContactAudio: new Audio(newContactMp3File),
    csAudio: new Audio(csMp3File),
    slowAlarmAudio: new Audio(slowAlarmMp3File),
    unfinishedChatAudio: new Audio(unfinishedChatMp3File),
    showSetLabelDialog: false,
    cancelCategory: "",
    showCancelCategory: false,
    errorMessage: "", 
    tmpLabel: "",
    selectedCity: "",
    selectedCityId: 0,
    deliveryDate: "",
    keterangan: "",
    emailRegister: "",
    listCity: [],
    muteSound: false,
    showLinkCreateEnquiries: false,
    isArchivedMessagesLoaded: false
  };


  chatContainer = React.createRef();
  constructor(){
      super();
  }

  getWaMessages = (isLoadArchived) => {

    if(this.props.currentConversation.fromNumber === ""){
      return;
    }
    
    this.setState({isLoading: true}, ()=>{
      const params = {
        from: this.props.currentConversation.fromNumber,
        useNest: false,
        loadArchived: isLoadArchived
      };
      console.log("PARAMSWAMESSAGE", params);
  
      axios
        .post(Api.messagesList(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("YOUR_MESSAGES",response.data);
          
          const responseData = response.data;

          let listMessages = responseData.data.filter((msg) => msg.from===this.props.currentConversation.fromNumber);
          let sendGreetingFirst = true;
          if(listMessages > 0){
            var now = moment(new Date()).tz("asia/jakarta"); //todays date
            var end = moment(listMessages[listMessages.length-1].receivedAt).tz("asia/jakarta"); // another date
            var oneDayInHours = 24; 
            
            var duration = moment.duration(now.diff(end));
            var delta = duration.asHours();
            console.log("CURRENTTIME ", now, " LAST MESSAGE TIME ", end, " DELTAINSECONDS ", delta, oneDayInHours);
            sendGreetingFirst = delta>24;
            
          }else{
            sendGreetingFirst = true;
            // this.setState({isLoading: false, mustSendGreeting: true});
          }

          if(isLoadArchived === true){

            let existingMessages = [...this.state.messages];
            let loadedArchivedMessages = response.data.data;

            loadedArchivedMessages = loadedArchivedMessages.concat(existingMessages);
            this.setState({ messages: loadedArchivedMessages, mustSendGreeting: false, isLoading: false, isArchivedMessagesLoaded: true }, () => this.scrollToMyRef());
          }else{
            this.setState({ messages: response.data.data, mustSendGreeting: false, isLoading: false, isArchivedMessagesLoaded: false }, () => this.scrollToMyRef());
          }          
          
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, isLoading: false });
          console.error("There was an error!", error);
        });
    });
    
  }

  scrollToMyRef = () => {
    const scroll =
      this.chatContainer.current.scrollHeight -
      this.chatContainer.current.clientHeight;
    this.chatContainer.current.scrollTo(0, scroll);
    // console.log("SCROLLING To", scroll);
  };

  componentDidMount() {
    this.getWaMessages(false);
  }

  showInputCustomerName = () => {

  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currentConversation.contactId !==
      this.props.currentConversation.contactId
    ) {
      this.getWaMessages(false);
    }
  }

  renderMessages = () => {
    let i = 0;
    let messageCount = this.state.messages.length;
    let tempMessages = [];

    const appProfile = Global.getAppProfile();
    
    while (i < messageCount) {
      let previous = this.state.messages[i - 1];
      let current = this.state.messages[i];
      let next = this.state.messages[i + 1];
      // let isMine = MY_USER_ID.includes(current.from);// current.from === MY_USER_ID;
      // let isMine =  appProfile.waNumber === current.from;// current.from === MY_USER_ID;
      let isMine =  this.props.currentConversation.fromNumber !== current.from;// current.from === MY_USER_ID;
      let currentMoment = moment(current.created_at);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
        // console.log(current.from," - ",MY_USER_ID);
      if (previous) {
        let previousMoment = moment(previous.created_at);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.from === current.from;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.created_at);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.from === current.from;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
          onDownloadDocument={this.handleDownloadDocument}
          onResendMessage={this.handleResendMessage}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    // setTimeout(() => {
    //   this.scrollToMyRef();
    // }, 3000);

    return tempMessages;
  };

  handleDownloadDocument = (fileName) => {
    console.log("DOWNLOADING ", fileName);
    var win = window.open(Api.getBaseDocsUrl() + fileName, '_blank');
    win.focus();
    // fetch(Api.getBaseDocsUrl() + fileName)
    // 	.then(response => {
        //         console.log("DOWNLOADED");
    // 		response.blob().then(blob => {
    // 			let url = window.URL.createObjectURL(blob);
    // 			let a = document.createElement('a');
    // 			a.href = url;
    // 			a.download = fileName;
    // 			a.click();
    // 		});
    // 		//window.location.href = response.url;
    // });
  }

  render() {
    return (
      <div className="message-list">

          <div ref={this.chatContainer} className="message-list-viewer-container">

          <Toolbar
            title={this.props.currentConversation.fromName}
            rightItems={[
              // Global.getAppProfile().type === "PURCHASING-APP" && this.props.currentConversation.assignToUser > 0 ? 
              // <div className="btn btn-danger" onClick={this.releaseSupplier}>
              //   <LockOpen />
              //   Selesai
              // </div> : <></>,
              // <FaInfoCircle
              //   key="msglistinfo"
              //   style={{ marginRight: 10, marginLeft: 10, color: "#FFFFFF" }}
              // />,
              // <FaVideo
              //   key="msglistvideo"
              //   style={{ marginRight: 10, marginLeft: 10, color: "#FFFFFF" }}
              // />,
              // <FaPhone
              //   key="msglistphone"
              //   style={{ marginRight: 10, marginLeft: 10, color: "#FFFFFF" }}
              // />,
              this.state.isArchivedMessagesLoaded ? <></> : <div className="badge badge-warning" style={{cursor: "pointer"}} onClick={() => this.getWaMessages(true)}>Open Archived</div>
            ]}
          />
            
            {
              this.state.isLoading ? (
                <div className="container">
                  <div className="row">
                      <div className="col-5"></div>
                      <div className="col-2">
                        <ReactLoading color="#FF0000" type="bubbles" width={80} height={10} />
                      </div>
                      <div className="col-5"></div>
                  </div>
                </div>
              ) : (<></>)
            }
            
            {this.state.isLoading ? "" : this.renderMessages()}
          </div>
          
        </div>
    );
  }

  getMediaContainerClasses = () => {
      const classes = "container bg-light";
      return this.state.files.length > 0 ? classes : classes + " d-none";
  }

}

export default MessageListViewer;
