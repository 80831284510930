import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import moment from "moment";
import Global from "../../customlib/Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

var dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class ExportCheckPointReport extends Component {

    getleadsReportData = () => {
        let leadsData = [];
        this.props.leadsReportData.forEach(element => {
            element.detail.forEach(lead => {
                lead.userName = element.userName;
                lead.checkPointCount = element.checkPointCount;
                lead.targetPercent = element.targetPercent;
                lead.appLabel = element.appLabel;
                lead.userType = element.userType;
                leadsData.push(lead);
            });
        });
        console.log("EXPORTDATA", leadsData);
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary"><ImportExport />Export ke Excel</button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getleadsReportData} name="Check Point Report">
                    <ExcelColumn label="User" value="userName"/>
                    <ExcelColumn label="Type" value="userType"/>
                    <ExcelColumn label="Aplikasi" value="appLabel"/>
                    <ExcelColumn label="Check Point Count" value="checkPointCount"/>
                    <ExcelColumn label="Target Percent" value="targetPercent"/>
                    <ExcelColumn label="Check In"
                                  value={(col) => col.checkPointSucceeded === true ? "Succeed" : "Failed"}/>
                    <ExcelColumn label="Date Time"
                                  value={(col) => Global.formatDateTimeToStrDateTime24(col.checkedAt)}/>
                    
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportCheckPointReport;