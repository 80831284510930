import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import moment from "moment";
import Global from "../../customlib/Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ExportInboundReferral extends Component {

    getleadsReportData = () => {
        return this.props.data;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary"><ImportExport />Export ke Excel</button>}>
                <ExcelSheet data={this.getleadsReportData} name="Inbound Referral IG">
                    <ExcelColumn label="No HP" value="from"/>
                    <ExcelColumn label="Nama" value="fromName"/>
                    <ExcelColumn label="Tanggal"
                                  value={(col) => Global.formatDateTimeToStrDateTime24(col.created_at)}/>
                    
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportInboundReferral;