import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, TableFooter } from '@mui/material';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Check, Close, QuestionAnswer, TrainRounded } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../MessageListViewer';

class ReportLeadsDataCancel extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null,
        resumeByKategori: [],
        years: [],
        selectedMonth: moment().format("M"),
        selectedYear: moment().format("YYYY"),
        listAppProfiles: [],
        filterAppNumber: "",
        totalWeekly: {
          totalW1: 0,
          totalW2: 0,
          totalW3: 0,
          totalW4: 0,
          totalW5: 0,
        }
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.loadAppProfile();
          this.getResumeWeekly(true);
        }
      );
    }

    handleChangeMonth = (event) => {
      this.setState({selectedMonth: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleChangeYear = (event) => {
      this.setState({selectedYear: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleAppTypeChanged = (event) => {
      this.setState({filterAppNumber: event.target.value}, ()=>{
        console.log(this.state.filterAppNumber);
        this.getResumeWeekly();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
  };

    getResumeWeekly = (replaceAll) => {

      this.setState({isLoading: true, resumeByKategori: [], totalWeekly: {
        totalW1: 0,
        totalW2: 0,
        totalW3: 0,
        totalW4: 0,
        totalW5: 0,
      }}, ()=>{
          var userData = Global.get();
          const params = {
              userId: userData.user.id,
              page: this.state.page,
              rowsPerPage: this.state.rowsPerPage,
              keywords: this.state.keyword,
              startDate: `${this.state.selectedYear}-${this.state.selectedMonth}-1`,
              endDate: `${this.state.selectedYear}-${this.state.selectedMonth}-1`,
              filterAppNumber: this.state.filterAppNumber
          }

          console.log("PARAMS", params, "replaceAll", replaceAll);

          axios
          .post(Api.allContactBerlabelCancel(), params, Api.getRequestHeader())
          .then((response) => {
              console.log("Weekly : ", response.data.data);

              let resumeByKategori = [];
              Global.getListKategoriProblem().forEach(kategori => {
                  resumeByKategori.push({kategoriName: kategori, w1: 0, w2: 0, w3: 0, w4: 0, w5: 0});
              });

              var resumeData = response.data.data;
              var totalWeekly = {
                totalW1: 0,
                totalW2: 0,
                totalW3: 0,
                totalW4: 0,
                totalW5: 0,
              };
              
              resumeByKategori.forEach(byKat => {
                resumeData.forEach((weekly, index) => {
                  if(index === 0){
                    byKat.w1 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                    totalWeekly.totalW1 = totalWeekly.totalW1 + byKat.w1;
                  }else if(index === 1){
                    byKat.w2 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                    totalWeekly.totalW2 = totalWeekly.totalW2 + byKat.w2;
                  }else if(index === 2){
                    byKat.w3 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                    totalWeekly.totalW3 = totalWeekly.totalW3 + byKat.w3;
                  }else if(index === 3){
                    byKat.w4 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                    totalWeekly.totalW4 = totalWeekly.totalW4 + byKat.w4;
                  }else if(index === 4){
                    byKat.w5 = weekly.contacts.filter(item => item.kategoriProblem === byKat.kategoriName).length;
                    totalWeekly.totalW5 = totalWeekly.totalW5 + byKat.w5;
                  }
                });
              });
              
              this.setState({resumeByKategori: resumeByKategori, totalWeekly: totalWeekly, isLoading: false}, () => {});
                          
          })
          .catch((error) => {
              this.setState({isLoading: false});
              console.error("There was an error!", userData.user.id, error);
          });
      });

  };

      handleShowMessages = (conversation) => {
        // console.log("CONVER", conversation);
        this.setState({currentConversation: conversation});
      }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah FU', minWidth: 100 },
        // { id: 'totalAdaResponLavender', label: 'Respon Lavender', minWidth: 100 },
        { id: 'totalAdaResponHsm', label: 'Respon HSM', minWidth: 100 },
        // { id: 'responRateLavender', label: 'Respon Rate Lavender', minWidth: 100 },
        { id: 'responRate', label: 'Respon Rate HSM', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        return ( 
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

            {
              this.state.currentConversation !== null ? (
                  <Dialog
                      open={this.state.currentConversation !== null}
                      // TransitionComponent={Transition}
                      // keepMounted
                      onClose={() => {this.setState({currentConversation: null})}}
                      aria-describedby=""
                  >
                      {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                      <DialogContent style={{height: "100%"}}>
                          <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} />
                      </DialogContent>
                      <DialogActions>
                      {/* <Button onClick={handleClose}>Disagree</Button>
                      <Button onClick={handleClose}>Agree</Button> */}
                      </DialogActions>
                  </Dialog>
              ) : (<></>)
            }

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row align-items-center">
                            <div className="col-4">
                              <h4>Kategori Cancel Order</h4>
                                {/* <strong>Total {this.state.contactLeads.length} Salesman</strong> */}
                            </div>
                            <div className="col-2">
                              {/* <MyDateRangePicker onDateChanged={this.handleDateChanged} /> */}
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Bulan</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={this.state.selectedMonth}
                                  label="Bulan"
                                  onChange={this.handleChangeMonth}
                                  size='small'
                                >
                                  <MenuItem value={1}>Januari</MenuItem>
                                  <MenuItem value={2}>Februari</MenuItem>
                                  <MenuItem value={3}>Maret</MenuItem>
                                  <MenuItem value={4}>April</MenuItem>
                                  <MenuItem value={5}>Mei</MenuItem>
                                  <MenuItem value={6}>Juni</MenuItem>
                                  <MenuItem value={7}>Juli</MenuItem>
                                  <MenuItem value={8}>Agustus</MenuItem>
                                  <MenuItem value={9}>September</MenuItem>
                                  <MenuItem value={10}>Oktober</MenuItem>
                                  <MenuItem value={11}>November</MenuItem>
                                  <MenuItem value={12}>Desember</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-1">
                              {/* <MyDateRangePicker onDateChanged={this.handleDateChanged} /> */}
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tahun</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={this.state.selectedYear}
                                  label="Bulan"
                                  onChange={this.handleChangeYear}
                                  size='small'
                                >
                                  {
                                    this.state.years.map(year => {
                                      return (<MenuItem value={year}>{year}</MenuItem>);
                                    })
                                  }
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-2">
                              <FormControl variant="outlined" style={{width: '100%'}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.filterAppNumber}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile.waNumber}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl>
                            </div>
                            <div className="col-2 text-center" style={{borderRadius: 10, padding: 5}}>
                              
                              {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={() => this.getResumeWeekly(true)}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                              
                            </div>
                        </div>
                        <br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                      <TableCell key={"head1"} align={"center"}>
                                        <strong>Resume Keterangan Cancel</strong>
                                      </TableCell>
                                      <TableCell key={"head2"} align={"center"}>
                                        <strong>Week 1</strong>
                                      </TableCell>
                                      <TableCell key={"head3"} align={"center"}>
                                        <strong>Week 2</strong>
                                      </TableCell>
                                      <TableCell key={"head4"} align={"center"}>
                                        <strong>Week 3</strong>
                                      </TableCell>
                                      <TableCell key={"head5"} align={"center"}>
                                        <strong>Week 4</strong>
                                      </TableCell>
                                      <TableCell key={"head1"} align={"center"}>
                                        <strong>Week 5</strong>
                                      </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.resumeByKategori.map((row) => {
                                    return (
                                        <TableRow>
                                          <TableCell>{row.kategoriName}</TableCell>
                                          <TableCell align='center'>{row.w1}</TableCell>
                                          <TableCell align='center'>{row.w2}</TableCell>
                                          <TableCell align='center'>{row.w3}</TableCell>
                                          <TableCell align='center'>{row.w4}</TableCell>
                                          <TableCell align='center'>{row.w5}</TableCell>
                                        </TableRow>
                                    );
                                    })}
                                    <TableRow>
                                      <TableCell align='center' style={{fontSize: 15}}><strong>Total Mingguan</strong></TableCell>
                                      <TableCell align='center' style={{fontSize: 15}}><strong>{this.state.totalWeekly.totalW1}</strong></TableCell>
                                      <TableCell align='center' style={{fontSize: 15}}><strong>{this.state.totalWeekly.totalW2}</strong></TableCell>
                                      <TableCell align='center' style={{fontSize: 15}}><strong>{this.state.totalWeekly.totalW3}</strong></TableCell>
                                      <TableCell align='center' style={{fontSize: 15}}><strong>{this.state.totalWeekly.totalW4}</strong></TableCell>
                                      <TableCell align='center' style={{fontSize: 15}}><strong>{this.state.totalWeekly.totalW5}</strong></TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableFooter>
                                  
                                </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>
            </Paper>
         );
    }
}
 
export default ReportLeadsDataCancel;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});


  function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }