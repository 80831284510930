import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, Autocomplete} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import {CircularProgress, LinearProgress} from "@mui/material";

class DailyClosingRateReport extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        appProfileId: "",
        dateRangeValue: [null,null],
        totalAllLeads: 0,
        totalUnpaidLeads: 0,
        closingRate: 0,
        isLoading: false
    }
    
    componentDidMount() {
        this.setState(
          {appProfileId: Global.getAppProfile()._id}, ()=>{
            this.getContactLeads();
          }
        );
    }

    getContactLeads = () => {

      this.setState({isLoading: true}, () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          appProfileId: this.state.appProfileId,
          includeNonClosingSales: false
        }

        axios
          .post(Api.dailyClosingRateReport(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var data = response.data.data;
            var tmpContactLeads = data.leadsReport;
            var totalAllLeads = data.totalLeads;
            var totalClosingLeads = 0;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

            const arrayLabels = [
              Global.labelPaid(),
              Global.labelComplaintKeterlambatan(),
              Global.labelComplaintProdukTidakSesuai(),
              Global.labelComplaintLayuRusak(),
              Global.labelComplaintReport(),
              Global.labelReport(),
              Global.labelKonfirmasiPesanan(),
              Global.labelComplaintPesananTidakDibuatkan(),
              Global.labelLainLain()
            ];

            tmpContactLeads.forEach(lead => {
                // lead.leadsCount = lead.leadsCount;
                lead.labelUnpaid = 0;
                lead.labelPaid = 0;
                lead.labelDp = 0;
                lead.labelCancel = 0;
                lead.labelPending = 0;
                totalClosingLeads += lead.leads.length;

                lead.leads.forEach(contact => {


                  if(arrayLabels.includes(contact.closingLabel)){
                    lead.labelPaid += 1;
                  }
    
                  if(contact.closingLabel === Global.labelUnpaid()){
                    lead.labelUnpaid += 1;
                  }
    
                  if(contact.closingLabel === Global.labelDp()){
                    lead.labelDp += 1;
                  }
    
                  if(contact.closingLabel === Global.labelCancel()){
                    lead.labelCancel += 1;
                  }

                  if(contact.closingLabel === Global.labelPending()){
                    lead.labelPending += 1;
                  }
    
                });
                lead.closingRate = (lead.labelUnpaid+lead.labelPaid)/lead.leadsCount*100;

            });

            this.setState({isLoading: false, contactLeads: tmpContactLeads, totalAllLeads:totalAllLeads, totalUnpaidLeads: totalClosingLeads, closingRate: Math.round(totalClosingLeads/totalAllLeads*100) });
          })
          .catch((error) => {
            this.setState({isLoading: false});
            console.error("There was an error!", error);
          });
      });

        
      };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate, endDate: formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah Leads', minWidth: 100 },
        { id: 'closingLeads', label: 'Closing Leads', minWidth: 100 },
        { id: 'closingRate', label: 'Closing rate', minWidth: 100 },
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        return ( 
            <Paper style={{padding:20, margin: 15}}>
              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-11">
                          <div className="panel-title">
                              <h1>Daily Closing Rate Report</h1>
                          </div>
                        </div>
                        <div className="col-1">
                          <Tooltip title="Close">
                            <Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/">
                              <div className="btn btn-danger btn-block">
                                <strong>X</strong>
                              </div>
                            </Link>
                          </Tooltip>
                        </div>
                      </div>
                        
                    </div>
                    <div className="panel-body">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <strong>Total {this.state.contactLeads.length} Salesman</strong>
                            </div>
                            {/* <div className="col-3"> */}
                            {
                                // Global.get().admin === true ? <ExportLeadsReport leadsReportData={this.state.contactLeads} /> : <></>
                            }
                            
                            {/* </div> */}
                            <div className="col-6 text-right" style={{borderRadius: 10, padding: 5}}>
                                {/* <span style={{marginTop: 5,}}><strong>Leads dari :</strong> &nbsp;</span> */}
                                {/* <MyDateRangePicker onDateChanged={this.handleDateChanged} /> */}
                                <TextField
                                    id="dateStart"
                                    // label="Tanggal"
                                    type="date"
                                    defaultValue={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {/* &nbsp; <strong style={{marginTop: 5,}}>Sampai</strong> &nbsp;
                                <TextField
                                    id="dateEnd"
                                    // label="Tanggal"
                                    type="date"
                                    defaultValue={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> */}
                            </div>
                        </div>
                        {/* <br /> */}
                        {
                          this.state.isLoading ? (<LinearProgress />) : (<></>)
                        }
                        <Paper className="" style={{marginTop: "10px"}}>
                                <div className="" style={{marginBottom: "20px", marginTop: "10px"}}>
                                    <div className="card-header bg-info">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                    <strong>Total Leads</strong>: {this.state.totalAllLeads}
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                <strong>Total Closing</strong> : {this.state.totalUnpaidLeads} Leads
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                    <strong>Closing Rate</strong> : {this.state.closingRate}%
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* <div className="card-body"> */}
                                        
                                        {/* <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                            
                                        </div> */}
                                        {/* <br /> */}
                                    {/* </div> */}
                                    {/* <div className="card-footer bg-info">
                                        
                                    </div> */}
                                </div>
                                
                            </Paper>
                        <br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                    {this.columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                        <strong>{column.label}</strong>
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.contactLeads.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                    return (
                                        <Row key={row.salesId} row={row} />
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                component="div"
                                count={this.state.contactLeads.length + this.state.rowsPerPage}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            /> */}
                        </Paper>
                        {/* <br />
                        <div className="row">
                            <div className="col-4">
                                Sales
                            </div>
                            <div className="col-2">
                                Jumlah Leads
                            </div>
                            <div className="col-2">
                                Action
                            </div>
                        </div>
                        <hr />
                        {
                            this.state.contactLeads.map(
                                (contact) => (
                                    <ContactLeadsItem contactLeads={contact} />
                                )
                            )
                        } */}
                    </div>
                    <div className="panel-footer">
                        {/* <Link to="/">
                            <div className="btn btn-primary">Close</div>
                        </Link> */}
                    </div>
                </div>
              </div>
            </Paper>
         );
    }
}
 
export default DailyClosingRateReport;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.salesName}
          </TableCell>
          <TableCell align="left">{row.leadsCount} leads</TableCell>
          <TableCell component="th" scope="row" align="center">{row.labelUnpaid + row.labelPaid}</TableCell>
          <TableCell component="th" scope="row" align="center">{Math.round(row.closingRate)}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Leads Detail
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nama</TableCell>
                      {
                          // Global.get().admin === true ? <TableCell>Nomor WhatsApp</TableCell> : <></>
                      }
                      <TableCell>Nomor WhatsApp</TableCell>
                      <TableCell>Label</TableCell>
                      <TableCell>Tanggal lead</TableCell>
                      <TableCell>Chat Terakhir</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {Global.getAppProfile().appVendor === "VONAGE" && Global.isSales() ? historyRow.fromName.slice(0, -5) + Array(5 + 1).join("x") : historyRow.fromName}
                        </TableCell>
                        {
                            // Global.get().admin === true ? <TableCell>{historyRow.from}</TableCell> : <></>
                        }
                        <TableCell>{Global.maskPhoneNumber(historyRow.from)}</TableCell>
                        <TableCell component="th" scope="row">
                          <div className={Global.getLabelClasses(historyRow.closingLabel)}>{historyRow.closingLabel}</div>
                        </TableCell>
                        <TableCell align="left">{Global.formatDateTimeToStrDateTime24(historyRow.createdAt)}</TableCell>
                        <TableCell align="left">
                          {Global.formatDateTimeToStrDateTime24(historyRow.lastMessageReceivedAt)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }