import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import React, { Component } from 'react';

class GeneralDialog extends Component {
    state = { } 

    render() { 
        
        return (
            <Dialog
                open={this.props.settings.isOpen}
                onClose={this.props.settings.onClose}
            >
                <DialogTitle>{this.props.settings.title}</DialogTitle>
                <Divider />
                <DialogContent>
                    {this.props.settings.content}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={this.props.settings.positiveButtonCallback}>{this.props.settings.positiveButtonText}</Button>
                    {this.props.settings.showNegativeButton && <Button variant="outlined" color='secondary' onClick={this.props.settings.onClose}>{this.props.settings.negativeButtonText}</Button>} 
                </DialogActions>
            </Dialog>
        );
    }
}
 
export default GeneralDialog;