import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, CircularProgress, ToggleButtonGroup, ToggleButton, TextField, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import ExportCheckPointReport from './ExportCheckPointReport';
import { ConnectWithoutContact, Lightbulb, Search } from '@mui/icons-material';

class HsmMonitoring extends Component {
    state = { 
        hsmLogs : [],
        sales : [],
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        appProfileId: "",
        isLoading: false,
        listAppProfiles: [],
        totalHsmMarketing: 0,
        totalAllHsm: 0
    }
    
    componentDidMount() {
        this.setState(
          {appProfileId: Global.getAppProfile()._id}, ()=>{
            this.loadAppProfile();
            this.loadHsmMonitoring();
          }
        );
    }

    loadHsmMonitoring = () => {
        const params = {
          date: this.state.startDate,
          appProfileId: this.state.appProfileId
        }

        this.setState({hsmLogs: [], isLoading: true}, () => {
            axios
            .post(Api.hsmMonitoringDaily(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("hsmLogs : ", response.data);
                
                var tmpLogs = response.data.data;
                tmpLogs = tmpLogs.filter(item => item.totalHsm && item.totalHsm > 0);
                let totalMarketing = 0;
                let totalAll = 0;
                tmpLogs.forEach(item => {
                  totalMarketing += item.counter ?? 0;
                  totalAll += item.totalHsm ?? 0;
                })
                this.setState({hsmLogs: tmpLogs, totalHsmMarketing: totalMarketing, totalAllHsm: totalAll, isLoading: false});
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
        });

      };
    
    loadAppProfile = () => {
      axios
        .post(
          Api.listAppProfile(),
          {},
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
        }
      );
  };

  handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        this.loadHsmMonitoring();
      });
  }

    render() { 
        return ( 
            // <Paper style={{padding:20}}>
              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <strong>Jumlah User : {this.state.hsmLogs.length}</strong>
                            </div>
                            <div className="col-3">
                              <FormControl variant="outlined" style={{width: '100%'}} size='small'>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.appProfileId}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl>
                            </div>
                            <div className="col-2 text-right" style={{borderRadius: 10, padding: 5}}>
                              <TextField
                                id="tanggal"
                                label="Tanggal"
                                value={this.state.startDate}
                                onChange={(event) => {
                                  console.log(event.target.value);
                                  let selectedDate = event.target.value;
                                  this.setState({startDate: selectedDate, endDate: selectedDate}, () => {
                                    this.loadHsmMonitoring();
                                  });
                                }}
                                type="date"
                                size='small'
                              />
                            </div>
                            <div className="col-1">
                            {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 5}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.loadHsmMonitoring()}} className="btn btn-primary" style={{padding: 7}}><Search /></div>)
                                }
                            </div>
                        </div>
                        <br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20px"><strong>No</strong></TableCell>
                                        <TableCell width={"70%"}><strong>User</strong></TableCell>
                                        <TableCell align='right'><strong>HSM Marketing</strong></TableCell>
                                        <TableCell align='right'><strong>All HSM</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.hsmLogs.map((row, index) => {

                                      var badgeClassname = "badge badge-pill badge-success";
                                      var textColor = "";
                                      if(row.counter <= 30){
                                        badgeClassname = "badge badge-pill badge-success";
                                        // textColor = "text-success";
                                      }else if(row.counter <= 40){
                                        badgeClassname = "badge badge-pill badge-warning";
                                        textColor = "text-warning";
                                      }else if(row.counter <= 50){
                                        badgeClassname = "badge badge-pill badge-danger";
                                        textColor = "text-danger";
                                      }

                                    return (
                                      <TableRow>
                                          {/* <TableCell width="20px" /> */}
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell className={textColor}>{row.userName}</TableCell>
                                          <TableCell className='text-right'><div className={badgeClassname} style={{minWidth: "30px",paddingTop: 3, paddingBottom: 3, paddingLeft: 10, paddingRight: 10}}>{row.counter}</div></TableCell>
                                          <TableCell className='text-right'>{row.totalHsm ?? 0}</TableCell>
                                      </TableRow>
                                      );
                                    })}
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell><strong>Total</strong></TableCell>
                                        <TableCell className='text-right'><strong>{this.state.totalHsmMarketing}</strong></TableCell>
                                        <TableCell className='text-right'><strong>{this.state.totalAllHsm}</strong></TableCell>
                                    </TableRow>
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="panel-footer">
                        
                    </div>
                </div>
              </div>
            // </Paper>
         );
    }
}
 
export default HsmMonitoring;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell width="20px">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.userName}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.rows.length}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.lastLoginState === "ONLINE" ? <span className='text-warning font-bold'><ConnectWithoutContact/> ONLINE</span> : <span className='text-secondary font-bold'><ConnectWithoutContact/> OFFLINE</span>}
          </TableCell> 
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Riwayat Login
                </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Login</strong></TableCell>
                      <TableCell><strong>Logout</strong></TableCell>
                      <TableCell><strong>Status</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.rows.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell align='left'>
                          {Global.formatDateTimeToStrDateTime24(historyRow.created_at)}
                        </TableCell>
                        <TableCell align="left">
                            {historyRow.logoutTime ? Global.formatDateTimeToStrDateTime24(historyRow.updated_at) : "--:--:--"}
                        </TableCell>
                        <TableCell align='left'>{historyRow.state}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }