import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import ExportLeadsReport from './ExportLeadsReport';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, CircularProgress, Tooltip} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { QuestionAnswer, Search } from '@mui/icons-material';
import DateRangePicker from '../../customlib/DateRangePicker';
import MessageList from '../MessageList';

class TeleFuReport extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        isTeleReport: true,
        additionalData: null,
        isLoading: false,
        currentConversation: null,
    }
    
    componentDidMount() {
        this.getContactLeads();
    }

    getContactLeads = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      var params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        reportMode: "tele-leads-report",
        appProfileId: Global.getAppProfile()._id
      };

      console.log("PARAMS", params);

      this.setState({
            contactLeads: [],
            additionalData: null,
            isLoading: true
          }, () => {});

      axios
        .post(Api.getContactLeads(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONTACTLEADS : ", response.data);
          console.log("USERDATA : ", "wkwkwk");
          
          var tmpContactLeads = response.data.data;
          
          if(userData.admin === false && Global.isTeleAdmin() === false && Global.isAdminTeleUser() === false){
            tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
          }

          tmpContactLeads.forEach(lead => {
            lead.leadsCount = lead.leads.length;
            lead.fromLavender = lead.leads.filter(element => element.isFuByHsm !== true);
            lead.fromHsm = lead.leads.filter(element => element.isFuByHsm === true);

            lead.leads.forEach(contact => {
              const newData =  {
                photo: "https://picsum.photos/seed/picsum/200",
                fromName: `${contact.fromName}`,
                text: contact.lastMessage,
                fromNumber: contact.from,
                contactId: contact.id,
                toNumber: contact.to,
                toName: contact.toName,
                selected: true,
                unreadCounter: contact.unreadCounter,
                assignToUserName: contact.assignToUserName,
                contactLabel: contact.label
              };
              contact.contactFormatted = newData;
            });
            
          });

          this.setState({
            contactLeads: [],
            additionalData: response.data.additional_data.length > 0 ? response.data.additional_data[0] : null,
            isLoading: false
          }, () => {
            this.setState({contactLeads: tmpContactLeads});
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    getWaMessages = (contactData) => {
      console.log("CONTACTDATA : ", contactData);
      if(contactData.fromNumber === ""){
        return;
      }
  
      this.setState({isLoading: true}, ()=>{
        const params = {
          from: contactData.fromNumber,
        };
        console.log("PARAMSWAMESSAGE", params);
    
        axios
          .post(Api.messagesList(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("YOUR_MESSAGES",response.data);
            
            const responseData = response.data;
              
            const clonedMyCust = [...this.state.listMycustomer];
            let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

            if(myCust.length > 0){
                myCust[0].messages = responseData.data;
            }

            this.setState({listMycustomer: clonedMyCust});
            
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, isLoading: false });
            console.error("There was an error!", error);
          });
      });
  
      
    };

    handleShowMessages = (conversation) => {
      this.setState({currentConversation: conversation});
  }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCountTotal', label: 'Total', minWidth: 100 },
        { id: 'leadsCountLavender', label: 'Fu Lavender', minWidth: 100 },
        { id: 'leadsCountHsm', label: 'Fu HSM', minWidth: 100 }
    ];

    render() { 
        return ( 
          <Paper style={{padding:20}}>
                <div className="container-fluid">
                  <div className="panel panel-default">
                      <div className="panel-heading">
                          <div className="panel-title">
                              {/* <h1>Setup Contact</h1> */}
                          </div>
                      </div>
                      <div className="panel-body">
                        <div className="row">
                        <div className={this.state.currentConversation !== null ? "col-8" : "col-12"}>
                        <div className="row align-items-center">
                              <div className="col-3">
                                  <strong>Total {this.state.contactLeads.length} Salesman</strong>
                              </div>
                              {/* <div className="col-3">
                              {
                                  Global.get().admin === true ? <ExportLeadsReport leadsReportData={this.state.contactLeads} /> : <></>
                              }
                              
                              </div> */}
                              <div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                                  {/* <span style={{marginTop: 5,}}><strong>Tele FU dari :</strong> &nbsp;</span>
                                  
                                  <TextField
                                      id="dateStart"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.startDate}
                                      onChange={this.handleStartDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  />
                                  &nbsp; <strong style={{marginTop: 5,}}>Sampai</strong> &nbsp;
                                  <TextField
                                      id="dateEnd"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.endDate}
                                      onChange={this.handleEndDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  /> */}
                              </div>
                              <div className="col-1">
                              {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                              </div>
                          </div>
                          <br />

                          {
                          this.state.additionalData !== null ? (
                            <>
                              <Paper>
                                <div className="container">
                                  Total FU bulan {moment(this.state.additionalData.end).format("MMMM")} : {this.state.additionalData.totalLeads} FU
                                </div>
                              </Paper>
                              <br />
                            </>
                          ): (<></>)
                        }

                          <Paper className="root">
                              <TableContainer>
                                  <Table aria-label="sticky table" size="small">
                                  <TableHead>
                                      <TableRow>
                                          <TableCell />
                                      {this.columns.map((column) => (
                                          <TableCell
                                              key={column.id}
                                              align={column.align}
                                              style={{ minWidth: column.minWidth }}
                                          >
                                          {column.label}
                                          </TableCell>
                                      ))}
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {this.state.contactLeads.map((row) => {
                                      return (
                                          <Row key={row.salesId} row={row} handleShowMessages={this.handleShowMessages} />
                                      );
                                      })}
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                              {/* <TablePagination
                                  rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                  component="div"
                                  count={this.state.contactLeads.length + this.state.rowsPerPage}
                                  rowsPerPage={this.state.rowsPerPage}
                                  page={this.state.page}
                                  onChangePage={this.handleChangePage}
                                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                              /> */}
                          </Paper>
                        </div>
                        <div className="col-4" style={{maxHeight: "70vh", overflowY: "none"}}>
                            {
                            this.state.currentConversation !== null ? (
                                <div className='text-right'>
                                  <div className="btn btn-block btn-danger" onClick={() => {this.handleShowMessages(null)}}>Close Chat</div>
                                </div>
                            ) : (<></>)
                            }
                            {
                            this.state.currentConversation !== null ? (
                                <Paper className='root'>
                                    <MessageList
                                        onUpdateReadCounter = {()=>{}}
                                        onNewIncomingMessage={()=>{}}
                                        onUpdateConversations={()=>{}}
                                        onUpdateCsHelpState={()=>{}}
                                        currentConversation={this.state.currentConversation}
                                        onUpdateContactLabelState={()=>{}}
                                        onClearCurrentConversation = {()=>{}}
                                        onUpdateSlow={()=>{}}
                                        onUpdateJustRead={()=>{}}
                                        onUpdateUnfinishedChat={()=>{}}
                                        onResetUnreadCounter={()=>{}}
                                        conversationList={[]}
                                        logoutHandler={()=>{}}
                                        onSaveCustomerName={()=>{}}
                                        style={{maxHeight: "70vh", overflowY: "none"}}
                                    />
                                </Paper>
                                
                            ) : (<></>)
                            }
                          </div>
                        </div>
                          
                          {/* <br />
                          <div className="row">
                              <div className="col-4">
                                  Sales
                              </div>
                              <div className="col-2">
                                  Jumlah Leads
                              </div>
                              <div className="col-2">
                                  Action
                              </div>
                          </div>
                          <hr />
                          {
                              this.state.contactLeads.map(
                                  (contact) => (
                                      <ContactLeadsItem contactLeads={contact} />
                                  )
                              )
                          } */}
                      </div>
                      <div className="panel-footer">
                          {/* <Link to="/">
                              <div className="btn btn-primary">Close</div>
                          </Link> */}
                      </div>
                  </div>
                </div>
              </Paper>
         );
    }
}
 
export default TeleFuReport;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.salesName}
          </TableCell>
          <TableCell align="left">{row.fromLavender.length + row.fromHsm.length}</TableCell>
          <TableCell align="left">{row.fromLavender.length} FU</TableCell>
          <TableCell align="left">{row.fromHsm.length} FU</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <div className="container border border-primary rounded">
                  <Typography variant="h6" gutterBottom component="div">
                    Follow Up HSM
                  </Typography>

                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nama</TableCell>
                        {
                            // Global.get().admin === true ? <TableCell>Nomor WhatsApp</TableCell> : <></>
                        }
                        <TableCell>Nomor WhatsApp</TableCell>
                        <TableCell>Tanggal FU</TableCell>
                        <TableCell>Chat Terakhir</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.fromHsm.map((historyRow) => (
                        <TableRow key={historyRow.id}>
                          <TableCell component="th" scope="row">
                            {historyRow.fromName}
                          </TableCell>
                          {
                              // Global.get().admin === true ? <TableCell>{historyRow.from}</TableCell> : <></>
                          }
                          <TableCell>{Global.maskPhoneNumber(historyRow.from)}</TableCell>
                          <TableCell align="left">{Global.formatDateTimeToStrDateTime24(historyRow.createdAt)}</TableCell>
                          <TableCell align="left">
                            {Global.formatDateTimeToStrDateTime24(historyRow.lastMessageReceivedAt)}
                          </TableCell>
                        <TableCell>
                          <Tooltip title="Lihat Chat">
                              <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {props.handleShowMessages(historyRow.contactFormatted)}}  />
                          </Tooltip>
                        </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                
                <br />
                <br />
                <div className="container border border-success rounded">
                  <Typography variant="h6" gutterBottom component="div">
                    Follow Up Lavender
                  </Typography>

                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nama</TableCell>
                        {
                            // Global.get().admin === true ? <TableCell>Nomor WhatsApp</TableCell> : <></>
                        }
                        <TableCell>Nomor WhatsApp</TableCell>
                        <TableCell>Tanggal FU</TableCell>
                        <TableCell>Chat Terakhir</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.fromLavender.map((historyRow) => (
                        <TableRow key={historyRow.id}>
                          <TableCell component="th" scope="row">
                            {historyRow.fromName}
                          </TableCell>
                          {
                              // Global.get().admin === true ? <TableCell>{historyRow.from}</TableCell> : <></>
                          }
                          <TableCell>{Global.maskPhoneNumber(historyRow.from)}</TableCell>
                          <TableCell align="left">{Global.formatDateTimeToStrDateTime24(historyRow.createdAt)}</TableCell>
                          <TableCell align="left">
                            {Global.formatDateTimeToStrDateTime24(historyRow.lastMessageReceivedAt)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }