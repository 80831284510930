import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { CloudDownload } from "@mui/icons-material";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


// const dataSet1 = [
//     {
//         name: "Johson",
//         amount: 30000,
//         sex: 'M',
//         is_married: true
//     },
//     {
//         name: "Monika",
//         amount: 355000,
//         sex: 'F',
//         is_married: false
//     },
//     {
//         name: "John",
//         amount: 250000,
//         sex: 'M',
//         is_married: false
//     },
//     {
//         name: "Josef",
//         amount: 450500,
//         sex: 'M',
//         is_married: true
//     }
// ];

// var dataSet2 = [
//     {
//         name: "Johnson",
//         total: 25,
//         remainig: 16
//     },
//     {
//         name: "Josef",
//         total: 25,
//         remainig: 7
//     }
// ];

class ExportConversation extends Component {

    state = {
        infobipNumber : "6281231828249"
    }

    getleadsReportData = () => {
        let leadsData = [];
        console.log("CONTACTDATA : ", this.props.contactData);
        this.props.messagesData.forEach(element => {
            console.log("PERBANDINGAN : ", element.from, this.state.infobipNumber);
            const data = {
                from: element.from,
                fromName: element.from === "6281231828249" ? this.props.contactData.assignToUserName : element.contact.name,
                to: element.to,
                type: element.message.type,
                message: element.message.caption !== undefined ? element.message.caption : element.message.text
            };
            if(data.message !== undefined){
                if(data.type !== "TEXT" && data.message.length <= 0){
                    if(data.type === "IMAGE"){
                        data.message = "Media Gambar";
                    }else if(data.type === "DOCUMENT"){
                        data.message = "Media DOKUMEN";
                    }else if(data.type === "STICKER"){
                        data.message = "Media Stiker";
                    }
                }
            }
            

            leadsData.push(data);

        });
        console.log("MESSAGES : ", leadsData);
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<CloudDownload style={{cursor: "pointer"}} className="text-success" />}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getleadsReportData} name="Conversation">
                    <ExcelColumn label="Dari" value="fromName"/>
                     <ExcelColumn label="Isi Pesan" value="message"/>
                    {/*<ExcelColumn label="WhatsApp Number" value="from"/>
                    <ExcelColumn label="Lead Date"
                                  value={(col) => moment(col.createdAt).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/>
                    <ExcelColumn label="Last Message Date"
                                  value={(col) => moment(col.lastMessageReceivedAt).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/> */}
                    
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportConversation;