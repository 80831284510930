import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import Global from "../../customlib/Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

var dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class ExportHistoryPerubahanLabel extends Component {

    getleadsReportData = () => {
        let leadsData = [];
        this.props.leadsReportData.forEach(element => {
            element.maskedFrom = Global.maskPhoneNumber(element.from);
            leadsData.push(element);
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary"><ImportExport />Export ke Excel</button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getleadsReportData} name="Riwayat Perubahan Label">
                    <ExcelColumn label="Nama" value="fromName"/>
                    <ExcelColumn label="No Customer" value="maskedFrom"/>
                    <ExcelColumn label="Label" value="currentLabel"/>
                    <ExcelColumn label="Tanggal Leads" value="createdAtFormatted"/>
                    <ExcelColumn label="Chat Terakhir" value="lastMessageReceivedAtFormatted"/>
                    <ExcelColumn label="Label Akhir" value="lastLabel"/>
                    <ExcelColumn label="Waktu Label Akhir" value="lastLabelAt"/>
                    <ExcelColumn label="Selang Waktu" value="delta"/>
                    
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportHistoryPerubahanLabel;