import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { CircularProgress, TextField } from '@mui/material';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Autocomplete} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Search } from '@mui/icons-material';
import DateRangePicker from '../../customlib/DateRangePicker';

class ByCitiByCategory extends Component {
    state = { 
        contactLeads : [],
        contactLeadsDisplay : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        isTeleReport: true,
        cityList: [],
        selectedCity: "",
        isLoading: false,
		jabodetabek: [],
		jabodetabekDisplay: [],
		nonJabodetabek: [],
		nonJabodetabekDisplay: [],
    byTimeJabodetabek: [],
    byTimeNonJabodetabek: [],
    summaryJabodetabek: {
      totalLeads: 0, totalClosing: 0
    },
    summaryNonJabodetabek: {
      totalLeads: 0, totalClosing: 0
    }

  }
    
    componentDidMount() {
        this.getContactLeads();
    }

    getContactLeads = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      var params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
        userId: userData.user.id,
        reportType: "TYPE_BYCITY_BYPRODUCTCATEGORY"
      };

      console.log("PARAMS", params);

      this.setState({contactLeads: [], contactLeadsDisplay: [], cityList: [], isLoading: true});

      axios
        .post(Api.getLeadsReportSummary(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONTACTLEADS : ", response.data);
          console.log("USERDATA : ", "wkwkwk");
          
          var tmpContactLeads = response.data.data;
          var tmpCityList = [];
          
          // if(userData.admin === false){
          //   tmpContactLeads = tmpContactLeads.filter(element => element.salesName === userData.user.name);
          // }

          let jabodetabek = tmpContactLeads.summary.filter(item => {
            let city = item.city.toLowerCase();
            return (city.includes("jakarta") || city.includes("bogor") || city.includes("depok") || city.includes("tangerang") || city.includes("bekasi"));
          });

          let nonJabodetabek = tmpContactLeads.summary.filter(item => {
            let city = item.city.toLocaleLowerCase();
            return (!city.includes("jakarta") && !city.includes("bogor") && !city.includes("depok") && !city.includes("tangerang") && !city.includes("bekasi"));
          });

          let leadsJabodetabek = tmpContactLeads.leads.filter(item => {
            let city = item.cityName === null || item.cityName === undefined ? "" : item.cityName.toLocaleLowerCase();
            return city && (city.includes("jakarta") || city.includes("bogor") || city.includes("depok") || city.includes("tangerang") || city.includes("bekasi"));
          });

          let leadsNonJabodetabek = tmpContactLeads.leads.filter(item => {
            let city = item.cityName === null || item.cityName === undefined ? "" : item.cityName && item.cityName.toLocaleLowerCase();
            return city && (!city.includes("jakarta") && !city.includes("bogor") && !city.includes("depok") && !city.includes("tangerang") && !city.includes("bekasi"));
          });


          let tmpSummaryJabodetabek = {...this.state.summaryJabodetabek};
          let tmpSummaryNonJabodetabek = {...this.state.summaryNonJabodetabek};
          jabodetabek.forEach(lead => {
            // lead.leadsCount = lead.leads.length;
            lead.leadsCount = 0;
            lead.leads.forEach(item => lead.leadsCount += item.count_category);
            lead.closingCount = leadsJabodetabek.filter(item => item.isClosing === true && item.cityName === lead.city).length;
            // lead.closingRate = lead.leadsCount > 0 ? (lead.closingCount/lead.leadsCount*100).toFixed(2) : 0;
            tmpCityList.push(lead.city);
            tmpSummaryJabodetabek.totalLeads += lead.leadsCount;
            tmpSummaryJabodetabek.totalClosing += lead.closingCount;
          });

          nonJabodetabek.forEach(lead => {
            // lead.leadsCount = lead.leads.length;
            lead.leadsCount = 0;
            lead.leads.forEach(item => lead.leadsCount += item.count_category);
            lead.closingCount = leadsNonJabodetabek.filter(item => item.isClosing === true && item.cityName === lead.city).length;
            // lead.closingRate = lead.leadsCount > 0 ? (lead.closingCount/lead.leadsCount*100).toFixed(2) : 0;
            tmpCityList.push(lead.city);
            tmpSummaryNonJabodetabek.totalLeads += lead.leadsCount;
            tmpSummaryNonJabodetabek.totalClosing += lead.closingCount;
          });

          let arrByTimeJabodetabek = [
            {
              id: "24000300",
              label: "24:00 - 03:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "03010600",
              label: "03:01 - 06:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "06010900",
              label: "06:01 - 09:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "09011200",
              label: "09:01 - 12:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "12011600",
              label: "12:01 - 16:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "16011800",
              label: "16:01 - 18:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "18012100",
              label: "18:01 - 21:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "21012359",
              label: "21:01 - 23:59",//4501 - 4759 pm
              count: 0
            },
          ];

          let arrByTimeNonJabodetabek = [
            {
              id: "24000300",
              label: "24:00 - 03:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "03010600",
              label: "03:01 - 06:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "06010900",
              label: "06:01 - 09:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "09011200",
              label: "09:01 - 12:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "12011600",
              label: "12:01 - 16:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "16011800",
              label: "16:01 - 18:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "18012100",
              label: "18:01 - 21:00",//4501 - 4759 pm
              count: 0
            },
            {
              id: "21012359",
              label: "21:01 - 23:59",//4501 - 4759 pm
              count: 0
            },
          ];

          leadsJabodetabek.forEach(item => {

            var originalTime = Global.formatDateTimeToStrFormat(item.created_at, "hh:mm A");
            var convertedTime = Global.convertTime12to24(originalTime);
            var timeValue = parseInt(convertedTime.replaceAll(":", ""));

            if(timeValue <= 2359){
              timeValue += 2400;
            }

            if(timeValue >= 2400 && timeValue <= 2700){//24:00 - 03:00
              arrByTimeJabodetabek[0].count += 1;
              arrByTimeJabodetabek[0].timeValue = timeValue;
            }else if(timeValue >= 2701 && timeValue <= 3000){//03:01 - 06:00
              arrByTimeJabodetabek[1].count += 1;
            }else if(timeValue >= 3001 && timeValue <= 3300){//06:01 - 09:00
              arrByTimeJabodetabek[2].count += 1;
            }else if(timeValue >= 3301 && timeValue <= 3600){//09:01 - 12:00
              arrByTimeJabodetabek[3].count += 1;
            }else if(timeValue >= 3601 && timeValue <= 4000){//12:01 - 16:00
              arrByTimeJabodetabek[4].count += 1;
            }else if(timeValue >= 4001 && timeValue <= 4300){//16:01 - 18:00
              arrByTimeJabodetabek[5].count += 1;
            }else if(timeValue >= 4301 && timeValue <= 4600){//18:01 - 21:00
              arrByTimeJabodetabek[6].count += 1;
            }else if(timeValue >= 4601 && timeValue <= 4899){
              arrByTimeJabodetabek[7].count += 1;
            }
          });

          leadsNonJabodetabek.forEach(item => {

            var originalTime = Global.formatDateTimeToStrFormat(item.created_at, "hh:mm A");
            var convertedTime = Global.convertTime12to24(originalTime);
            var timeValue = parseInt(convertedTime.replaceAll(":", ""));

            if(timeValue <= 2359){
              timeValue += 2400;
            }

            if(timeValue >= 2400 && timeValue <= 2700){//24:00 - 03:00
              arrByTimeNonJabodetabek[0].count += 1;
              arrByTimeNonJabodetabek[0].timeValue = timeValue;
            }else if(timeValue >= 2701 && timeValue <= 3000){//03:01 - 06:00
              arrByTimeNonJabodetabek[1].count += 1;
            }else if(timeValue >= 3001 && timeValue <= 3300){//06:01 - 09:00
              arrByTimeNonJabodetabek[2].count += 1;
            }else if(timeValue >= 3301 && timeValue <= 3600){//09:01 - 12:00
              arrByTimeNonJabodetabek[3].count += 1;
            }else if(timeValue >= 3601 && timeValue <= 4000){//12:01 - 16:00
              arrByTimeNonJabodetabek[4].count += 1;
            }else if(timeValue >= 4001 && timeValue <= 4300){//16:01 - 18:00
              arrByTimeNonJabodetabek[5].count += 1;
            }else if(timeValue >= 4301 && timeValue <= 4600){//18:01 - 21:00
              arrByTimeNonJabodetabek[6].count += 1;
            }else if(timeValue >= 4601 && timeValue <= 4899){
              arrByTimeNonJabodetabek[7].count += 1;
            }
          });

        //   this.setState({contactLeads: tmpContactLeads, contactLeadsDisplay: nonJabodetabek, cityList: tmpCityList, isLoading: false});
          this.setState({
            jabodetabek: jabodetabek, jabodetabekDisplay: jabodetabek, 
            nonJabodetabek: nonJabodetabek, nonJabodetabekDisplay: nonJabodetabek, 
            cityList: tmpCityList, isLoading: false,
            byTimeJabodetabek: arrByTimeJabodetabek,
            byTimeNonJabodetabek: arrByTimeNonJabodetabek,
            summaryJabodetabek: tmpSummaryJabodetabek,
            summaryNonJabodetabek: tmpSummaryNonJabodetabek
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

	handleTotalJabodetabek = () => {
		let total = 0;
		this.state.jabodetabekDisplay.forEach(item => {
			item.leads.forEach(lead => total += lead.count_category);
		});
		return total;
	}

	handleTotalNonJabodetabek = () => {
		let total = 0;
		this.state.nonJabodetabekDisplay.forEach(item => {
			item.leads.forEach(lead => total += lead.count_category);
		});
		return total;
	}

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleCityOnChange = (city) => {
      console.log("SELECTEDSALES", city);
      var tmpJabodetabek = [...this.state.jabodetabek];
      var tmpNonJabodetabek = [...this.state.nonJabodetabek];
      if(city !== null){
        tmpJabodetabek = tmpJabodetabek.filter(element => element.city === city);
        tmpNonJabodetabek = tmpNonJabodetabek.filter(element => element.city === city);
      }

      this.setState({selectedCity: city, jabodetabekDisplay: tmpJabodetabek, nonJabodetabekDisplay: tmpNonJabodetabek});
    }

    columns = [
        { id: 'salesName', label: 'Kota', minWidth: 170 },
        { id: 'leadsCounta', label: '', minWidth: 100 }
    ];

    render() { 
        return ( 
          <Paper style={{padding:20}}>
                <div className="container-fluid">
					<div className="panel panel-default">
						<div className="panel-heading">
							<div className="panel-title">
								{/* <h1>Setup Contact</h1> */}
							</div>
						</div>
						<div className="panel-body">
							<div className="row align-items-center">
								<div className="col-3">
									<strong>Total {this.state.contactLeadsDisplay.length} Kota</strong>
								</div>
								<div className="col-3">
								<Autocomplete
										id="free-solo-2-demo"
										// disableClearable
										freeSolo 
										options={this.state.cityList.map((option) => {
											// console.log("LABEL : ", option);
											return String(option);
										})}
										onChange={(event, newValue) => {
											// console.log("NEWVALUE : ", event);
											this.handleCityOnChange(newValue);
										}} 
										value={this.state.selectedCity}
										renderInput={(params) => (
										<TextField
											{...params}
											label="Pilih Kota"
											margin="0"
											variant="outlined"
											InputProps={{ ...params.InputProps, type: 'search' }}
										/>
										)}
                    size='small'
									/>
								</div>
								<div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
									<DateRangePicker onDateChanged={this.handleDateChanged} />
								</div>
								<div className="col-1">
								{
										this.state.isLoading === true ? 
											(<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
											(<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
									}
								</div>
							</div>
							<br />
							<Paper className="root" style={{padding: "10px"}}>
								<h4>JABODETABEK</h4>
								<TableContainer>
									<Table aria-label="sticky table" size='small'>
										<TableHead>
											<TableRow>
												<TableCell width={"20px"} />
												<TableCell>Jabodetabek</TableCell>
												<TableCell>Jumlah Leads</TableCell>
												<TableCell>Jumlah Closing</TableCell>
												<TableCell>Closing Rate (%)</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.jabodetabekDisplay.map((row) => {
												// return (
												// 	<TableRow>
												// 		<TableCell>{row.city}</TableCell>
												// 		<TableCell>{row.leadsCount}</TableCell>
												// 	</TableRow>
												// );
											return (
												<Row key={row.salesId} row={row} />
											);
											})}
											<TableRow>
												<TableCell width={"20px"}  />
												<TableCell><strong>Total</strong></TableCell>
												<TableCell><strong>{this.state.summaryJabodetabek.totalLeads}</strong></TableCell>
												<TableCell><strong>{this.state.summaryJabodetabek.totalClosing}</strong></TableCell>
												<TableCell><strong>{this.state.summaryJabodetabek.totalLeads > 0 ? (this.state.summaryJabodetabek.totalClosing/this.state.summaryJabodetabek.totalLeads*100).toFixed(2) : 0}%</strong></TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>

                <br />
                <Paper>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Jam</TableCell>
                        <TableCell>Jumlah</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.byTimeJabodetabek.map((historyRow) => (
                        <TableRow key={historyRow.id}>
                          <TableCell align="left">{historyRow.label}</TableCell>
                          <TableCell align="left">{historyRow.count}</TableCell>
                          <TableCell align="left">{historyRow.timevalue}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
							</Paper>
							<br />
							<Paper className="root" style={{padding: "10px"}}>
								<h4>NON JABODETABEK</h4>
								<TableContainer>
									<Table aria-label="sticky table" size='small'>
										<TableHead>
											<TableRow>
												<TableCell width={"20px"}  />
												<TableCell>Non Jabodetabek</TableCell>
												<TableCell>Jumlah Leads</TableCell>
												<TableCell>Jumlah Closing</TableCell>
												<TableCell>Closing Rate (%)</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.nonJabodetabekDisplay.map((row) => {
												// return (
												// 	<TableRow>
												// 		<TableCell>{row.city}</TableCell>
												// 		<TableCell>{row.leadsCount}</TableCell>
												// 	</TableRow>
												// );
											return (
												<Row key={row.salesId} row={row} />
											);
											})}
											<TableRow>
												<TableCell width={"30px"} />
												<TableCell><strong>Total</strong></TableCell>
												<TableCell><strong>{this.state.summaryNonJabodetabek.totalLeads}</strong></TableCell>
												<TableCell><strong>{this.state.summaryNonJabodetabek.totalClosing}</strong></TableCell>
												<TableCell><strong>{this.state.summaryNonJabodetabek.totalLeads > 0 ? (this.state.summaryNonJabodetabek.totalClosing/this.state.summaryNonJabodetabek.totalLeads*100).toFixed(2) : 0}%</strong></TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>

                <br />
                <Paper>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Jam</TableCell>
                        <TableCell>Jumlah</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.byTimeNonJabodetabek.map((historyRow) => (
                        <TableRow key={historyRow.id}>
                          <TableCell align="left">{historyRow.label}</TableCell>
                          <TableCell align="left">{historyRow.count}</TableCell>
                          <TableCell align="left">{historyRow.timevalue}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
							</Paper>
							
						</div>
						<div className="panel-footer">
										
						</div>
					</div>
                </div>
              </Paper>
         );
    }
}
 
export default ByCitiByCategory;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" align='left'>
            {row.city}
          </TableCell>
          <TableCell component="th" scope="row" align='left'>{row.leadsCount}</TableCell>
          <TableCell component="th" scope="row" align='left'>{row.closingCount}</TableCell>
          <TableCell component="th" scope="row" align='left'>{row.leadsCount > 0 ? (row.closingCount/row.leadsCount*100).toFixed(2) : 0}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Berdasarkan Kota dan Kategori Produk
                </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Kota</TableCell> */}
                      <TableCell>Kategori Produk</TableCell>
                      <TableCell>Jumlah</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        {/* <TableCell component="th" scope="row">
                          <div>{historyRow.city}</div>
                        </TableCell> */}
                        <TableCell align="left">{historyRow.product_category === "" ? "---" : historyRow.product_category}</TableCell>
                        <TableCell align="left">
                          {historyRow.count_category}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }