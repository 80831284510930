import React, { Component } from 'react';
import {TextField, Autocomplete, Tooltip as ToolTip} from '@mui/material';
import axios from "axios";
import Api from "../../customlib/Api";
import ReactLoading from 'react-loading';
import { Save, CancelRounded } from "@mui/icons-material";

class ContactEditChangeSales extends Component {
    state = { 
        selectedSales : {},//id = id, contactId, name
        isAssigned : false,
        isChecked : false,
        salesData: [],
        isLoading: false
    }

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.props.salesData];
        // console.log("SALESDATA : ", salesData);
        let selected = salesData.filter((sales) => sales.name===salesName);
        console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            newData.contactId = this.props.contactData.id;
            this.setState({selectedSales: newData}, ()=>{
                if(this.state.isChecked){
                    this.props.onChecked(newData);
                }
            });
        }

    }

    handleAssignContactToSales = () => {
        console.log("PARAMASSIGN : ", {contactId: this.props.contactData.id, userId: this.state.selectedSales.id});
        if(this.state.selectedSales.id === undefined | this.state.selectedSales.id <= 0 ){
            return;
        }
        this.setState({isLoading: true}, () => {
            axios
            .post(Api.assignContactToUser(), {contactId: this.props.contactData.id, userId: this.state.selectedSales.id}, Api.getRequestHeader())
            .then((response) => {
                // console.log("ASSIGNRESPONSE : ", response.data);
                let responseData = response.data;
                if(responseData.success){
                    this.setState({isAssigned: true, isLoading: false});
                    var contactData = this.props.contactData;
                    contactData.assignToUser = this.state.selectedSales.id;
                    contactData.assignToUserName = this.state.selectedSales.name;
                    this.props.onFinishEdit(contactData);
                }else{
                    this.setState({isLoading: false});
                }
                
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", error);
            });
        });
    }

    handleCheckBoxChange = (event) => {
        console.log("CHECKBOX_CHANGED");
        this.setState({isChecked: event.target.checked}, ()=>{
            if(event.target.checked){
                this.props.onChecked(this.state.selectedSales);
            }else{
                this.props.onUnchecked(this.state.selectedSales);
            }
        });
      }

    componentDidMount() {

        this.setState(
            {
                salesData : this.props.salesData
            },()=>{
                this.handleSelectionChanged(this.props.contactData.assignToUserName);
            }
        );

    }

    componentDidUpdate(prevProps, prevState) {
        
    }

    render() { 
        // console.log("SELECTEDSALES : ", this.state);
        const {assignToUserName} = this.props.contactData;
        return ( 
            <div>
                <div className="row">
                    <div className="col-10">
                    <Autocomplete
                        id="free-solo-2-demo"
                        // disableClearable
                        freeSolo 
                        options={this.props.salesData.map((option) => {
                            // console.log("LABEL : ", option);
                            return String(option.name);
                        })}
                        onChange={(event, newValue) => {
                            // console.log("NEWVALUE : ", event);
                            this.handleSelectionChanged(newValue);
                          }} 
                        value={assignToUserName}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="User"
                            margin="0"
                            variant="outlined"
                            InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                        )}
                    />
                    </div>
                    <div className="col-2 text-center">
                        
                        {
                            this.state.isLoading ? (<></>) : 
                            (
                                <div style={{marginTop: 3}}>
                                    <ToolTip title="Simpan">
                                        <Save style={{cursor: "pointer"}} className="text-primary" onClick={this.handleAssignContactToSales} /> 
                                    </ToolTip>
                                    <ToolTip title="Batal">
                                        <CancelRounded style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.props.onFinishEdit(this.props.contactData)}} /> 
                                    </ToolTip>
                                </div>
                            )
                        }
                        
                        <div style={{marginTop: 12}}>
                            {
                                this.state.isLoading ? 
                                <ReactLoading color="#FF0000" type="spin" width={30} height={10}/> : 
                                <span></span>
                            }
                        </div>

                        {/* <span className="btn btn-primary" onClick={this.handleAssignContactToSales}>
                            Apply
                        </span> */}
                        {/* &nbsp;
                            {
                            this.state.isLoading ? 
                                <ReactLoading color="#FF0000" type="bubbles" width={50} height={10}/> : 
                                <span></span>
                            } */}
                    </div>
                </div>
            </div>
         );
    }
}
 
export default ContactEditChangeSales;