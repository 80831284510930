import { TextField, CircularProgress, FormControl, InputLabel, Select, MenuItem, Snackbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip as ToolTip, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete, LinearProgress, FormControlLabel, Checkbox, Switch, TableFooter } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Search, QuestionAnswer, Edit, ErrorOutline, ArrowUpward, ArrowDownward, SwapHoriz, SwapVert} from '@mui/icons-material';
import ReactLoading from 'react-loading';
import moment from "moment";
import MessageListViewer from '../MessageListViewer';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import DateRangePicker from '../../customlib/DateRangePicker';
import { FaEquals } from 'react-icons/fa';

// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from 'chart.js';
// import { Bar } from 'react-chartjs-2';

class DataCancelDashboardNew extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        contactLeads: [],
        snackbarVisible: false,
        snackbarMessage: "",
        keyword: "",
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        isLoading: false,
        searchInField: "fromName",
        filterAppNumber: "",
        listAppProfiles: [],
        dataDisplay: [],
        dataDisplay2: [],
        summaryDataDisplay: {
            totalJumlahLeads: 0,
            totalClosing: 0,
            totalClosingRate: 0
        },
        summaryDataDisplay2: {
            totalJumlahLeads: 0,
            totalClosing: 0,
            totalClosingRate: 0
        }
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();

        const params = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          appProfileId: Global.getAppProfile()._id
        }

        this.setState({contactLeads: [], additionalData: null, isLoading: true}, () => {
          // console.log("ADDITIONAL_DATA : ", this.state.additionalData);
        });

        axios
          .post(Api.getContactLeads(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

            // tmpContactLeads.filter(element => [Global.labelCancel(), Global.labelPending(), Global.labelRandomLeads()].includes(element.label));

            let tmpDisplay1 = [];//{alasanUmum, jumlahLeads, closing, closingRate}
            let tmpDisplay2 = [];//{alasanUmum, jenisProduk, jumlahLeads, closing, closingRate}
            let totalDataDisplay1 = {
                totalJumlahLeads: 0,
                totalClosing: 0,
                totalClosingRate: 0
            };

            let totalDataDisplay2 = {
                totalJumlahLeads: 0,
                totalClosing: 0,
                totalClosingRate: 0
            };
            tmpContactLeads.forEach(lead => {
                
                lead.leads.forEach(contact => {
                    console.log("LOG__CEKLABEL", contact.label, contact.assignToUserName, contact.alasanUmum);
                    let findDisplay1 = tmpDisplay1.find((item) => item.alasanUmum === contact.alasanUmum);
                    let findDisplay2 = tmpDisplay1.find((item) => item.alasanUmum === contact.alasanUmum && item.jenisProduk===contact.productCategory);
                    
                    console.log("LOG__FINDDISPLAY", findDisplay1);
                    if(findDisplay1){
                        console.log("LOG__FOUND__1", findDisplay1);
                        findDisplay1.alasanUmum = contact.alasanUmum;
                        findDisplay1.jumlahLeads += 1;
                        findDisplay1.closing += contact.isClosing ? 1 : 0;
                        findDisplay1.closingRate = findDisplay1.jumlahleads <= 0 ? 0 : findDisplay1.closing/findDisplay1.jumlahLeads*100;
                    }else{
                        let jumlahLeads = 1;
                        let closing = contact.isClosing ? 1 : 0;
                        let closingRate = jumlahLeads <= 0 ? 0 : closing/jumlahLeads*100;
                        if(contact.alasanUmum === null || contact.alasanUmum === undefined || contact.alasanUmum === ""){
                            let findData = tmpDisplay1.find((item) => item.alasanUmum === "Belum di set");
                            if(findData){
                                console.log("LOG__FOUND__2", findDisplay1);
                                findData.alasanUmum = "Belum di set";
                                findData.jumlahLeads += 1;
                                findData.closing += contact.isClosing ? 1 : 0;
                                findData.closingRate = findData.jumlahleads <= 0 ? 0 : findData.closing/findData.jumlahLeads*100;
                            }else{
                                tmpDisplay1.push({
                                    alasanUmum: "Belum di set",
                                    jumlahLeads: jumlahLeads,
                                    closing: closing,
                                    closingRate: closingRate
                                });
                            }
                        }else{
                            let findData = tmpDisplay1.find((item) => item.alasanUmum === contact.alasanUmum);
                            if(findData){
                                console.log("LOG__FOUND__3", findDisplay1);
                                findData.alasanUmum = contact.alasanUmum;
                                findData.jumlahLeads += 1;
                                findData.closing += contact.isClosing ? 1 : 0;
                                findData.closingRate = findData.jumlahleads <= 0 ? 0 : findData.closing/findData.jumlahLeads*100;
                            }else{
                                tmpDisplay1.push({
                                    alasanUmum: contact.alasanUmum,
                                    jumlahLeads: jumlahLeads,
                                    closing: closing,
                                    closingRate: closingRate
                                });
                            }
                        }
                    }

                    if(findDisplay2){
                        if([Global.labelCancel(), Global.labelPending()].includes(contact.label)){
                            console.log("LOG__FOUND__1", findDisplay1);
                            findDisplay2.alasanUmum = contact.alasanUmum;
                            findDisplay2.jenisProduk = contact.productCategory;
                            findDisplay2.jumlahLeads += 1;
                            findDisplay2.closing += contact.isClosing ? 1 : 0;
                            findDisplay2.closingRate = findDisplay2.jumlahleads <= 0 ? 0 : findDisplay2.closing/findDisplay2.jumlahLeads*100;
                        }
                    }else{
                        if([Global.labelCancel(), Global.labelPending()].includes(contact.label)){
                            let jumlahLeads = 1;
                            let closing = contact.isClosing ? 1 : 0;
                            let closingRate = jumlahLeads <= 0 ? 0 : closing/jumlahLeads*100;
                            let findData = tmpDisplay2.find((item) => item.alasanUmum === contact.alasanUmum && item.jenisProduk === contact.productCategory);
                            if(findData){
                                console.log("LOG__FOUND__3", findDisplay1);
                                findData.alasanUmum = contact.alasanUmum;
                                findData.jumlahLeads += 1;
                                findData.closing += contact.isClosing ? 1 : 0;
                                findData.closingRate = findData.jumlahleads <= 0 ? 0 : findData.closing/findData.jumlahLeads*100;
                            }else{
                                tmpDisplay2.push({
                                    alasanUmum: contact.alasanUmum,
                                    jenisProduk: contact.productCategory,
                                    jumlahLeads: jumlahLeads,
                                    closing: closing,
                                    closingRate: closingRate
                                });
                            }
                        }
                    }

                    totalDataDisplay1.totalJumlahLeads += 1;
                    totalDataDisplay1.totalClosing += contact.isClosing ? 1 : 0;
                    totalDataDisplay1.totalClosingRate = totalDataDisplay1.totalJumlahLeads <= 0 ? 0 : (totalDataDisplay1.totalClosing/totalDataDisplay1.totalJumlahLeads*100).toFixed(1);
                    
                    totalDataDisplay2.totalJumlahLeads += 1;
                    totalDataDisplay2.totalClosing += contact.isClosing ? 1 : 0;
                    totalDataDisplay2.totalClosingRate = totalDataDisplay2.totalJumlahLeads <= 0 ? 0 : (totalDataDisplay2.totalClosing/totalDataDisplay2.totalJumlahLeads*100).toFixed(1);

                });

            });

            tmpDisplay1.sort((a,b) => b.closingRate - a.closingRate);
            tmpDisplay2.sort((a,b) => b.closingRate - a.closingRate);

            this.setState({isLoading: false, dataDisplay: tmpDisplay1, summaryDataDisplay: totalDataDisplay1, dataDisplay2: tmpDisplay2, summaryDataDisplay2: totalDataDisplay2});

          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

   

    handleShowSnackbar = () => {
        this.setState({snackbarVisible: true});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, errorMessage: ""});
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.handleReloadData()});
      }

    componentDidMount() {
        this.setState({filterAppNumber: Global.getAppProfile()._id}, () => {
            this.getContactLeads();
            this.loadAppProfile(); 
        })
        // this.getMyCustomerToday(true);
        // this.getResumeByKategoriProblemWeekly(true);
        // this.getResumeByPermasalahanWeekly(true);
        // this.getSalesTeleUsers();
        // this.getCityList();
    }

    columns = [
        { id: 'assignToUserName', label: 'Sales', minWidth: 170 },
        { id: 'fromName', label: 'Nama', minWidth: 170 },
        { id: 'from', label: 'No Customer', minWidth: 200 },
        { id: 'appLabel', label: 'Aplikasi', minWidth: 200 },
        { id: 'productCategory', label: 'Kategori', minWidth: 200 },
        { id: 'cityName', label: 'Kota', minWidth: 200 },
        { id: 'deliveryDateFormatted', label: 'Tanggal Kirim', minWidth: 200},
        { id: 'label', label: 'Label', minWidth: 200 },
        { id: 'keteranganCancel', label: 'Keterangan Cancel Sales', minWidth: 200 },
        { id: 'analisaCancel', label: 'Analisa', minWidth: 200 },
        // { id: 'why1', label: 'Why1', minWidth: 200 },
        // { id: 'why2', label: 'Why2', minWidth: 200 },
        // { id: 'why3', label: 'Why3', minWidth: 200 },
        // { id: 'why4', label: 'Why4', minWidth: 200 },
        // { id: 'why5', label: 'Why5', minWidth: 200 },
        // { id: 'saran', label: 'Saran', minWidth: 200 },
        { id: 'kategoriProblem', label: 'Keterangan Analisa', minWidth: 200 },
        { id: 'solusi', label: 'Solusi', minWidth: 200 },
        // {
        //     id: 'population',
        //     label: 'Population',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'size',
        //     label: 'Size\u00a0(km\u00b2)',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'assignToUserName',
        //     label: 'Salesman',
        //     minWidth: 170,
        //     align: 'left',
        // },
        {
            id: 'action',
            label: 'Action',
            minWidth: 130,
            align: 'center',
        },
    ];



    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.setState({page: 0}, () => {
                this.getContactLeads();
            });
        }
      };


    getFieldClassName = (fieldName) => {
        let className = "badge";
        if(this.state.searchInField === fieldName){
          className += " badge-primary";
        }else{
          className += " badge-secondary";
        }
    
        return className;
      }
    
    handleFieldSelectionChanged = (fieldName) => {
        this.setState({searchInField: fieldName}, () => {
            this.getMyCustomerToday(true);
        });
    }

    loadAppProfile = () => {
        const params = {
        };
        
        axios
          .post(
            Api.listAppProfile(),
            params,
            Api.getRequestHeaderNoAuth()
          )
          .then((response) => {
            console.log("App Profiles", response.data);
            
            let profiles = [];
            profiles.push({_id: "", waNumber: "", label: "---"});

            let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
            profilesFiltered.forEach(item => {
                profiles.push(item);
            });
            this.setState({listAppProfiles: profiles});
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, loginSuccess: false });
            console.error("There was an error!", error);
            // this.props.onLogin();
          }
        );
    };

    handleAppTypeChanged = (event) => {
        this.setState({filterAppNumber: event.target.value}, ()=>{
          console.log(this.state.filterAppNumber);
          this.getMyCustomerToday(true);
        //   this.getResumeWeekly();
        });
    }

    handleReloadData = () => {
        this.getContactLeads();
    }

    render() { 
        return Global.get().admin !== true ? (
            <div className='container text-center' style={{marginTop: 100}}>
                <ErrorOutline style={{width: 200, height: 200}} className="text-danger" />
                <h2>Lotus</h2>
                <h3>Permission denied</h3>
                <Link style={{textDecoration: "underline", width: '100%'}} to="/">
                    <div className="btn btn-warning">Back</div>
                </Link>
            </div>
        ) : ( 
            <Paper className="container-fluid" style={{padding: "20px"}}>
                {

                        <div className="container-fluid">

                            <div className="row"  style={{marginTop: 10, marginBottom: 0}}>
                                {/* <div className="col-4">
                                    <TextField
                                        onChange={this.handleKeyword}
                                        label="Ketika untuk mencari nama/nomor wa/kategori"
                                        type="text"
                                        className="form-control"
                                        id="namacustomer"
                                        variant="outlined"
                                        onKeyDown={this.onKeyDownHandler}
                                        onBlur={() => {
                                            
                                        }}
                                        size='small'
                                    />
                                </div> */}
                                
                                <div className="col-9 text-right" style={{paddingTop: "10px"}}>
                                    <span className='text-sm' style={{minWidth: "105px"}}>
                                        <small>Tanggal Leads</small>
                                    </span>
                                </div>
                                <div className=" col-2 text-left" style={{display: "flex", alignItems: "end",borderRadius: 10, padding: 0}}>
                                    <DateRangePicker onDateChanged={this.handleDateChanged} />
                                  
                              </div>
                              {/* <div className='col-2'>
                                <FormControl variant="outlined" style={{width: '100%'}} size='small'>
                                    <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.appTypeId}
                                        onChange={this.handleAppTypeChanged}
                                        label="Aplikasi"
                                    >
                                        {
                                        this.state.listAppProfiles?.map(
                                            (profile) => {
                                            return (<MenuItem key={profile._id} value={profile.waNumber}>{profile.label}</MenuItem>);
                                            } 
                                        )
                                        }
                                    </Select>
                                </FormControl>
                                    
                              </div> */}
                              <div className="col-1">
                                    {
                                        this.state.isLoading === true ? 
                                            (<CircularProgress size={30} color="secondary" />) : 
                                            (<div onClick={this.handleReloadData} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                    }
                                </div>
                            </div>
                            {/* <div className="container-fluid">
                                <small>Field Pencarian : </small>
                                    <div className={this.getFieldClassName("fromName")} onClick={() => this.handleFieldSelectionChanged("fromName")} style={{cursor: "pointer"}}>Nama</div>&nbsp;
                                    <div className={this.getFieldClassName("from")} onClick={() => this.handleFieldSelectionChanged("from")} style={{cursor: "pointer"}}>Nomor</div>&nbsp;
                                    <div className={this.getFieldClassName("productCategory")} onClick={() => this.handleFieldSelectionChanged("productCategory")} style={{cursor: "pointer"}}>Kategori</div>&nbsp;
                                    <div className={this.getFieldClassName("kategoriProblem")} onClick={() => this.handleFieldSelectionChanged("kategoriProblem")} style={{cursor: "pointer"}}>Keterangan Cancel</div>
                            </div> */}
                            <br />
                            
                            <Paper style={{backgroundColor: "#e7eef4", padding: "10px"}}>
                                <div className="row">
                                    <div className="col-12">
                                        {/* <h5><strong>Resume Keterangan Cancel Berlabel Cancel</strong></h5> */}
                                        {/* <div style={{height: "1px", backgroundColor: "#d3d3d3", marginBottom: "20px"}}></div> */}
                                        <Paper className="root">
                                            <TableContainer>
                                                <Table aria-label="sticky table" size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align='left'><strong>Keterangan Umum</strong></TableCell>
                                                            <TableCell align='right'><strong>Jumlah Leads</strong></TableCell>
                                                            <TableCell align='right'><strong>Closing</strong></TableCell>
                                                            <TableCell align='right'><strong>% Closing rate</strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                        this.state.dataDisplay.map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell align='left'>{row.alasanUmum}</TableCell>
                                                                <TableCell align='right'>{row.jumlahLeads}</TableCell>
                                                                <TableCell align='right'>{row.closing}</TableCell>
                                                                <TableCell align='right'>{Math.round(row.closingRate)}%</TableCell>
                                                            </TableRow>
                                                        );
                                                        })}
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align='center'><strong>Total</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.summaryDataDisplay.totalJumlahLeads}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.summaryDataDisplay.totalClosing}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.summaryDataDisplay.totalClosingRate}%</strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-12">
                                        {/* <h5><strong>Resume Keterangan Cancel Berlabel Cancel</strong></h5> */}
                                        {/* <div style={{height: "1px", backgroundColor: "#d3d3d3", marginBottom: "20px"}}></div> */}
                                        <Paper className="root">
                                            <TableContainer>
                                                <Table aria-label="sticky table" size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align='left'><strong>Keterangan Umum</strong></TableCell>
                                                            <TableCell align='left'><strong>Jenis Produk</strong></TableCell>
                                                            <TableCell align='right'><strong>Jumlah Leads</strong></TableCell>
                                                            {/* <TableCell align='right'><strong>Closing</strong></TableCell>
                                                            <TableCell align='right'><strong>% Closing rate</strong></TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                        this.state.dataDisplay2.map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell align='left'>{row.alasanUmum}</TableCell>
                                                                <TableCell align='left'>{row.jenisProduk}</TableCell>
                                                                <TableCell align='right'>{row.jumlahLeads}</TableCell>
                                                                {/* <TableCell align='right'>{row.closing}</TableCell>
                                                                <TableCell align='right'>{Math.round(row.closingRate)}%</TableCell> */}
                                                            </TableRow>
                                                        );
                                                        })}
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell colSpan={2} align='center'><strong>Total</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.summaryDataDisplay.totalJumlahLeads}</strong></TableCell>
                                                            {/* <TableCell align='right'><strong>{this.state.summaryDataDisplay.totalClosing}</strong></TableCell>
                                                            <TableCell align='right'><strong>{this.state.summaryDataDisplay.totalClosingRate}%</strong></TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </div>
                                </div>
                                
                            </Paper>
                        </div>
                    // )

                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />

                
            </Paper>
        );
    }
}
 
export default DataCancelDashboardNew;