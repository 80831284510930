import React, { Component } from 'react'
import moment from "moment-timezone";
import "./Message.css";
import Api from "../../customlib/Api";
import { FaFile } from "react-icons/fa";
import ImageZoom from 'react-medium-image-zoom';
import {Done, DoneAll, Replay, Error, ReportOff, QueryBuilder, Assignment, Help, MoreHoriz, MoreVert, Info, Visibility, Face, MarkChatRead, PersonPin, Person} from "@mui/icons-material";
import {Button, Menu, MenuItem, Tooltip as ToolTip, Tooltip}  from "@mui/material";
import ReactPlayer from "react-player";
import Global from "../../customlib/Global";

class Message extends Component {
    state = { 
        isMenuOpen: false,
        anchorEl: null
     }

    handleClick = (event) => {
        console.log(event);
        this.setState({anchorEl: event.target, isMenuOpen: true});
    };
    handleClose = (messageText) => {
        this.setState({anchorEl: null, isMenuOpen: false});
        // this.props.onOpenCreateOrder(messageText);
    };

    handleOpenCreateOrder = () => {
        // this.handleClose();
        this.props.onOpenCreateOrder();
    }

    componentDidMount() {
        // console.log("MESSAGE_DATA",this.props.data);
    }
    

    formatText = () => {
        if(this.props.data.message.type === "TEXT"){
            return this.props.data.message.text !== undefined ? this.replaceNewLine(this.props.data.message.text) : "";
        }else{
            return this.props.data.message.caption !== undefined ? this.replaceNewLine(this.props.data.message.caption) : "";
        }
    }

    formatReplyMessage = (replyData) => {
        if(replyData.message !== undefined){
            if(replyData.message.type === "TEXT"){
                return (
                    <div style={{backgroundColor: '#64b5f6', borderRadius: 10, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: "#000000", borderLeft: 'solid', borderLeftWidth: 5, borderLeftColor: "#ff7043", fontSize: 11}}>
                        {replyData.message.text}
                    </div>
                );
            }else if(replyData.message.type === "IMAGE"){
                return (
                <div style={{backgroundColor: '#64b5f6', borderRadius: 10, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: "#000000", borderLeft: 'solid', borderLeftWidth: 5, borderLeftColor: "#ff7043", fontSize: 11}}>
                    {replyData.message.caption}<br />
                    <ImageZoom
                        image={{
                        src: Api.getBaseImageUrl() + replyData.message.fileName,
                        alt: 'Golden Gate Bridge',
                        className: 'img-fluid img-thumbnail mx-auto d-block media-size'
                        }}
                        zoomImage={{
                        src: Api.getBaseImageUrl() + replyData.message.fileName,
                        alt: 'Golden Gate Bridge'
                        }}
                    />
                    <div className="btn btn-primary btn-sm btn-block" style={{marginBottom: "5px"}} onClick={() => this.downloadFile(Api.getBaseImageUrl() + replyData.message.fileName)}>
                        Download
                    </div>
                </div>
                );
            }else if(replyData.message.type === "DOCUMENT"){
                return (
                <div style={{backgroundColor: '#64b5f6', borderRadius: 10, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: "#000000", borderLeft: 'solid', borderLeftWidth: 5, borderLeftColor: "#ff7043", fontSize: 11}}>
                    {replyData.message.caption}<br />
                    <div className="btn btn-warning" onClick={() => this.props.onDownloadDocument(replyData.message.fileName)}>
                        <FaFile /> Buka dokumen
                    </div>
                    <div className="btn btn-primary btn-sm btn-block" style={{marginBottom: "5px"}} onClick={() => this.downloadFile(Api.getBaseDocsUrl() + replyData.message.fileName)}>
                        Download
                    </div>
                </div>
                );
            }
        }else{
            return (<div className="bg-info" style={{borderRadius: 10, padding: 5}}>Reply data missing</div>);
        }
        
    } 

    replaceNewLine = (text) => {
        var splitted = text.split("\n");

        if(splitted.length <= 1){
            return text;
        }

        var joined = splitted.map(str => <p>{str}</p>);
        return joined;
    } 

    downloadFile = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        aTag.setAttribute("target", "_blank");
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    render() { 

        const { data, isMine, startsSequence, endsSequence, showTimestamp} = this.props;
        const friendlyTimestamp = Global.formatDateTimeToStrFormat(data.created_at, "LLLL");
        const timeOnly = Global.formatDateTimeToStrTime24(data.created_at, true);
        const messageWidgetClasses = data.message.type === "TEXT" ? "d-none" : "";
        const messageText = data.message.type === "TEXT" ? data.message.text : data.message.caption;
        // const isFormPemesanan = messageText.includes("Untuk pemesanan, silahkan lengkapi form data berikut");
        const isFormPemesanan = messageText?.toLowerCase()?.includes(Global.FORM_ORDER_MESSAGE_MARK.toLowerCase()) && !isMine;
        const isImageMessage = data.message.type === "IMAGE" | data.message.type === "STICKER";
        const isDocumentMessage = data.message.type === "DOCUMENT";
        // const isFormPemesanan = false;

        return ( 
            <div
            className={[
                "message",
                `${isMine ? "mine" : ""}`,
                `${startsSequence ? "start" : ""}`,
                `${endsSequence ? "end" : ""}`,
            ].join(" ")}
            >
            {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

            <div className="bubble-container">
                
                <div className="bubble" 
                    // title={friendlyTimestamp}
                >
                    {/* {data.message.type !== "TEXT" ? data.message.caption.replace("\n", "<br />") ? data.message.caption.replace("\n", "<br />") : "" : data.message.text.replace("\n", "<br />")} */}
                    {
                        data.repliedMessage !== undefined && data.repliedMessage !== null ? 
                        this.formatReplyMessage(data.repliedMessage) : 
                        // (<div>{data.messageId}</div>)
                        (<></>)
                    }
                    {this.formatText()}
                    <div className={messageWidgetClasses}>
                    {
                        isImageMessage ? 
                        // (<img className="img-fluid img-thumbnail mx-auto d-block media-size" src={Api.getBaseImageUrl() + data.message.fileName} alt=""/>) : 
                        (
                            <ImageZoom
                                image={{
                                src: data.message.fileName !== undefined && data.message.fileName.startsWith("http") ? data.message.fileName : Api.getBaseImageUrl() + data.message.fileName,
                                alt: '',
                                className: 'img-fluid img-thumbnail mx-auto d-block media-size'
                                }}
                                zoomImage={{
                                src: data.message.fileName !== undefined && data.message.fileName.startsWith("http") ? data.message.fileName : Api.getBaseImageUrl() + data.message.fileName,
                                alt: ''
                                }}
                            />
                            // <></>
                        ) : (<></>)
                    }    
                    {
                        isImageMessage ? (
                            <div className="btn btn-primary btn-sm btn-block" style={{marginBottom: "5px"}} onClick={() => this.downloadFile(Api.getBaseImageUrl() + data.message.fileName)}>
                                Download
                            </div>
                        ) : (<></>)
                    }
                    {
                        data.message.type === "DOCUMENT" ? (
                            <>
                                <br />
                                <div className="btn btn-warning" onClick={() => this.props.onDownloadDocument(data.message.fileName)}>
                                    <FaFile /> Buka dokumen
                                </div>
                                &nbsp;
                                
                                {
                                    data.status === "FAILED" ? 
                                    (
                                        <div className="btn btn-danger" onClick={() => this.props.onResendMessage(data)}>
                                            <Replay />
                                        </div>
                                    ) : (<></>)
                                }
                                
                                
                            </>

                        ) : (<></>)
                    }
                    {
                        data.message.type === "DOCUMENT" ? (
                            <div className="btn btn-primary btn-sm btn-block" style={{marginBottom: "5px"}} onClick={() => this.downloadFile(Api.getBaseDocsUrl() + data.message.fileName)}>
                                Download
                            </div>
                        ) : (<></>)
                    }
                    {
                        data.message.type === "VIDEO" ? (<ReactPlayer width="100%" style={{maxHeight: "250px"}} controls={true} url={data.message.fileName !== undefined && data.message.fileName.startsWith("http") ? data.message.fileName : Api.getBaseVideosUrl() + data.message.fileName} />) : (<></>)
                    }
                    </div> 
                    <div className='text-right'>
                        {
                        data.markedAsRead && data.markedAsReadAt && 
                            <ToolTip title={"Dibaca oleh " + data.markedAsReadByName + " pada " + Global.formatDateTimeToStrDateTime24(data.markedAsReadAt)}>
                                <Face color='secondary' fontSize='15' style={{cursor: "pointer"}} />
                            </ToolTip>}
                        &nbsp;
                        <span className="text-right" style={{fontSize: 9}}>
                            
                            {isMine ? Global.maskPhoneNumber(data.to) : data.to} - {timeOnly}
                            {/* {!isMine ? data.messageId : ""} */}
                            {/* {data.message.type}{isImageMessage} */}
                        </span>
                    </div>
                    
                </div>
                
                {/* {
                    isFormPemesanan && <MoreHoriz />
                } */}

                <div className="indicator">
                {
                        data.status === "SEEN" && isMine ? 
                        (
                            <ToolTip title="Sudah dibaca">
                                <DoneAll style={{color: "#00c853", width: 18}} />
                            </ToolTip>
                        ) : 
                        (<></>)
                    }

                    {
                        data.status === "DELIVERED_TO_HANDSET" && isMine ? 
                        (
                            <ToolTip title="Sudah sampai ke handset pelanggan">
                                <DoneAll style={{width: 18}}/>
                            </ToolTip>
                        ) : 
                        (<></>)
                    }

                    {
                        data.status === "DELIVERED_TO_OPERATOR" && isMine ? 
                        (
                            <ToolTip title="Sudah dikirim ke server WA">
                                <Done style={{width: 18}}/>
                            </ToolTip>
                        ) : 
                        (<></>)
                    }
                    
                    {
                        data.status === "UNDELIVERABLE_NOT_DELIVERED" && isMine ? 
                        (
                            <ToolTip title={(<>Pesan tidak dapat dikirim ke pelanggan<br />Coba kirim pesan HSM terlebih dahulu</>)}>
                                <Error style={{color: "#FF0000", width: 18}} />
                            </ToolTip>
                        ) : 
                        (<></>)
                    }

                    {
                        data.status === "FAILED" && isMine ? 
                        (
                            <ToolTip title="Pesan gagal dikirim">
                                <Error style={{color: "#FF0000", width: 18}} />
                            </ToolTip>
                        ) : 
                        (<></>)
                    }

                    {
                        data.status === "FAILED_PERMANENTLY" && isMine ? 
                        (
                            <ToolTip title="Pesan gagal dikirim">
                                <ReportOff style={{color: "#FF0000", width: 18}} />
                            </ToolTip>
                        ) : 
                        (<></>)
                    }

                    {
                        data.status === "SENDING" && isMine ? 
                        (
                            <ToolTip title="Pesan sedang dikirim">
                                <QueryBuilder style={{width: 18}}/>
                            </ToolTip>
                        ) : 
                        (<></>)
                    }

                    {
                        data.isTemplate !== undefined && data.isTemplate === 1 && isMine ? 
                        (
                            <div>
                                <ToolTip title="Pesan template">
                                    <Assignment style={{width: 18, color: "#0000FF"}}/>
                                </ToolTip>
                            </div>
                            
                        ) : 
                        (<></>)
                    }

                    {
                        data.status === "NOSTATUS" && isMine ? 
                        (
                            <ToolTip title="Jenis pesan tidak diketahui">
                                <Help style={{width: 18, color: "#FF0000"}}/>
                            </ToolTip>
                        ) : 
                        (<></>)
                    }
                </div>
                    {
                        // (!isMine && this.props.onOpenCreateOrder && this.props.onOpenCustomerProfile) &&  
                        (!isMine && this.props.onOpenCreateOrder) &&  
                        // (!isMine && (isFormPemesanan || isImageMessage)) && 
                        // <Tooltip title="Pilih opsi">
                            <span>
                                
                                    <div onClick={this.handleClick} className="btn btn-sm btn-text p-0" style={{marginLeft: "0px", padding: "0px", borderRadius: "200px"}}>
                                        <MoreVert className='text-danger' />
                                    </div>
                                    <Menu
                                        id={"menu-" + data._id}
                                        anchorEl={this.state.anchorEl}
                                        open={this.state.isMenuOpen}
                                        onClose={this.handleClose}
                                        MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {/* {(isFormPemesanan && (Global.get().admin || Global.isSales())) && 
                                            <MenuItem onClick={this.props.onOpenCreateOrder(messageText)}>Create Order</MenuItem>
                                        }
                                        {isImageMessage === true ? <MenuItem onClick={() => this.downloadFile(Api.getBaseImageUrl() + data.message.fileName)}>Download Image</MenuItem> : <></>}
                                        {isDocumentMessage === true ? <MenuItem onClick={() => this.downloadFile(Api.getBaseDocsUrl() + data.message.fileName)}>Download Document</MenuItem> : <></>}
                                        {isDocumentMessage === true ? <MenuItem onClick={() => this.downloadFile(Api.getBaseDocsUrl() + data.message.fileName)}>Download Document</MenuItem> : <></>} */}
                                        
                                        <MenuItem disabled={!(isFormPemesanan && (Global.get().admin || Global.isSales()))} onClick={this.props.onOpenCreateOrder(messageText)}>Create Order</MenuItem>
                                        <MenuItem onClick={this.props.onOpenCustomerProfile(messageText)}>Customer Profile</MenuItem>
                                        <MenuItem disabled={!isImageMessage} onClick={() => this.downloadFile(Api.getBaseImageUrl() + data.message.fileName)}>Download Image</MenuItem>
                                        <MenuItem disabled={!isDocumentMessage} onClick={() => this.downloadFile(Api.getBaseDocsUrl() + data.message.fileName)}>Download Document</MenuItem>
                                        {/* <MenuItem onClick={() => this.handleClose(messageText)}>Create Order</MenuItem> */}
                                    </Menu>
                            </span>
                        // </Tooltip>
                    }
                </div>
            </div>
         );
    }
}
 
export default Message;

// export default function Message(props) {
  

//   return (
//     <div
//       className={[
//         "message",
//         `${isMine ? "mine" : ""}`,
//         `${startsSequence ? "start" : ""}`,
//         `${endsSequence ? "end" : ""}`,
//       ].join(" ")}
//     >
//       {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

//       <div className="bubble-container">
//         <div className="bubble" title={friendlyTimestamp}>
//             {data.message.type !== "TEXT" ? data.message.caption.replace("\n", "<br />") ? data.message.caption.replace("\n", "<br />") : "" : data.message.text.replace("\n", "<br />")}
//             <div className={messageWidgetClasses}>
//             {
//                 data.message.type === "IMAGE" ? 
//                 (<img className="img-fluid img-thumbnail mx-auto d-block media-size" src={Api.getBaseImageUrl() + data.message.fileName} alt=""/>) : 
//                 (
//                     <>
//                         <br />
//                         <div className="btn btn-warning" onClick={() => props.onDownloadDocument(data.message.fileName)}>
//                             <FaFile /> Download dokumen
//                         </div>
//                     </>

//                 )
//             }
//             </div>
//         </div>
//       </div>
//     </div>
//   );
// }
