import { Alert, CircularProgress, Divider, IconButton, LinearProgress, List, ListItem, ListItemText, Paper, TextField } from '@mui/material';
import React, { Component } from 'react';
import axios from "axios";
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import { Delete, Search, WhatsApp } from '@mui/icons-material';
import base64 from "base-64";
import utf8 from "utf8";

class DailyFollowUp extends Component {
    state = { 
        listCustomer: [],
        fuCode: null,
        decodedFuCode: null,
        mode: "FU_CODE",
        errorMessage: null,
        isValid: false,
        isLoading: false
     } 

     MODE_FU_CODE = "FU_CODE";
     MODE_SEARCH_CUSTOMER = "SEARCH_CUSTOMER";

     getListCustomerFu = () => {

        const params = {
          appProfileId: Global.getAppProfile()._id,
          userId: Global.get().user.id
        };
    
        console.log("PARAMWAJIBBAYAR", params);
    
        axios
          .post(Api.getListDailyFu(), params, Api.getRequestHeader())
          .then((response) => {
            // console.log("LISTWAJIBBAYAR : ", response.data);
            let data = response.data.data;
    
            this.setState({listCustomer: data});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    componentDidMount() {
        // this.getListCustomerFu();
    }

    handleChangeMode = (mode) => {
        this.setState({mode: mode}, () => {
            if(this.state.mode === this.MODE_SEARCH_CUSTOMER){
                this.getListCustomerFu();
            }
        });
    }

    handleFuCodeChange = (event) => {

        console.log("FU_CODE", event.target.value);
        this.initiateFuByFuCode(event.target.value);
    }

    initiateFuByFuCode = (base64Data) => {

        try {
            let refData = base64.decode(base64Data);
            refData = utf8.decode(refData);

            let parsedData = JSON.parse(refData);    
            let isValid = false;
            let message = null;
            let salesId = 0;

            console.log("LOG__TELEFU", parsedData);
            // return;

            if(parsedData.tipe === "view_chat"){
                isValid = true;
            }else if(parsedData.tipe === "tele_acquisition" || parsedData.tipe === "event_reminders_by_event"){
                // parsedData.customer_owner = 6;
                // if(parsedData.customer_owner === parsedData.tele_fu_owner){
                    salesId = parsedData.tele_fu_owner;
                    isValid = true;
                // }else{
                //     message = "Customer Tele tidak sinkron";
                // }
                
            }else if(parsedData.tipe === "ep"){
                // parsedData.customer_owner = 10;
                if(parsedData.customer_owner === null && parsedData.ep_fu_owner !== null && parsedData.ep_fu_owner > 0){
                    isValid = true;
                    salesId = parsedData.ep_fu_owner;
                }else{
                    message = "Customer ini sudah ada yang punya";
                }
            }else if(parsedData.tipe === "tele_fu"){
                // parsedData.customer_owner = 6;
                if(parsedData.customer_owner === parsedData.tele_fu_owner){
                    salesId = parsedData.tele_fu_owner;
                    isValid = true;
                }else{
                    message = "Customer Tele tidak sinkron";
                }
                
            }

            if(parsedData.tipe === "view_chat"){
                let payload = {
                    waNumber: parsedData.phone
                };
                this.openViewChat(payload);
            }else{
                this.setState({errorMessage: message, decodedFuCode: parsedData, isValid: isValid}, () => {
                    if(isValid){
                        let payload = {
                            salesId: salesId, 
                            customerId : parsedData.customer_id, 
                            waNumber: parsedData.phone
                        };
                        console.log("LOG__", payload);
                        // return;
                        // this.props.openChat(payload);
                        this.openTeleChat(payload);
                    }
                });
            }
            // this.props.openChat(parsedData);

        } catch (error) {
            console.log(error);
            // this.setState({errorMessage: error.message});
            this.setState({errorMessage: "Kode FU tidak valid"});
        }

    }

    openTeleChat = (parsedRefData) => {

        // let refData = localStorage.getItem("ref");
        // console.log("OPENTELECHAT_1", refData);
    
        // if(refData !== null && refData !== undefined && refData.length > 0){
          // let parsedRefData = JSON.parse(refData);
    
          // if(Global.get().user.id !== parsedRefData.salesId){
          //   this.handleShowSnackbar(
          //     <>
          //       {parsedRefData.waNumber} bukan customer anda!
          //     </>);
          //   return;
          // }
    
          console.log("OPENTELECHAT_2", parsedRefData);
    
          // let conversation = this.state.conversations.filter((conv) => conv.fromNumber===parsedRefData.waNumber);
    
          // if(conversation !== null && conversation.length > 0){
          //   //just open chat room
          //   this.handleConversationSelected(conversation[0]);
          // }else{
            //getcontact then import it into contact list and then open teh chat room
    
            const params = {
              salesId: parsedRefData.salesId, 
              waNumber: parsedRefData.waNumber, 
              customerId: parsedRefData.customerId,
              sendFrom: parsedRefData.sendFrom === undefined ? "" : parsedRefData.sendFrom,
              message: parsedRefData.message === undefined ? "" : parsedRefData.message,
              appProfileId: Global.getAppProfile()._id,
              isTesting: false
            };
    
            
            console.log("LOG__PAYLOAD", params);

            this.setState({isLoading: true}, () => {
                axios
                    .post(Api.getContactByNumber(), params, Api.getRequestHeader())
                    .then((response) => {
                        let data = response.data;
                        console.log("CONTACTINFOBYNUMBER", data);
                        if(data.success === true){
                            this.props.addToConversationList(data.data);
                        }else{
                            this.setState({errorMessage: data.message, decodedFuCode: null, isValid: false});
                        }
                        this.setState({isLoading: false});
                    })
                    .catch((error) => {
                        this.setState({errorMessage: error.message, isLoading: false, decodedFuCode: null, isValid: false});
                        console.error("There was an error!", error);
                    });
            });
    
      };

    openViewChat = (parsedRefData) => {
    
        const params = {
            waNumber: parsedRefData.waNumber, 
            appProfileId: Global.getAppProfile()._id,
        };
            
        console.log("PARAM_OPEN_CHAT", params);

        this.setState({isLoading: true}, () => {
            axios
                .post(Api.getContactInfo(), params, Api.getRequestHeader())
                .then((response) => {
                    let data = response.data;
                    console.log("CONTACTINFOBYNUMBER", data);
                    if(data.success === true){
                        this.props.addToConversationList(data.data);
                    }else{
                        this.setState({errorMessage: data.message, decodedFuCode: null, isValid: false});
                    }
                    this.setState({isLoading: false});
                })
                .catch((error) => {
                    this.setState({errorMessage: error.message, isLoading: false, decodedFuCode: null, isValid: false});
                    console.error("There was an error!", error);
                });
        });
    
      };
    
    render() { 
        return (
            // <Paper elevation={3} className="" style={{position: "fixed", bottom: 70, left: 16, height: "80vh", width: "30vw", padding: 10, zIndex: 1000000, overflowY: "scroll"}}>
            <Paper elevation={3} className="" style={{position: "fixed", bottom: 70, left: 16, width: "25vw", padding: 10, zIndex: 1000000, overflow: "hidden"}}>
                <div style={{position: "relative", top: 0, left: 0}}>
                    <div className="row">
                        <div className="col-10">
                        <h4>Follow Up</h4>
                        </div>
                        <div className="col-1 text-left" >
                            <div className="btn btn-danger" onClick={this.props.onClose}>X</div>
                        </div>
                    </div>
                    <Divider sx={{marginTop: "10px", marginBottom: "10px"}} />
                    {
                        this.state.mode === this.MODE_FU_CODE ? (
                            this.state.decodedFuCode !== null && this.state.isValid === true ? (
                                <div className="container">
                                    <Alert severity="success">
                                        Kode valid, Follow Up sedang diproses <br />
                                         <LinearProgress />
                                    </Alert>
                                </div>
                            ) : (
                                <div>
                                    <TextField id="outlined-basic" onKeyDown={() => {return false;}} onChange={this.handleFuCodeChange} label="Paste kode fu Lavender disini" variant="outlined" fullWidth />
                                </div>
                            )
                        ) : (
                            <div className="row">
                                <div className="col-12">
                                <TextField id="txtKodeFu" label="Nomor Customer" variant="outlined" fullWidth />aa
                                </div>
                                {/* <div className="col-1">
                                    <div className="btn">
                                        <Search />
                                    </div>
                                </div> */}
                            </div>
                        )
                    }
                </div>

                {
                    this.state.errorMessage !== null ? (
                        <div className="container">
                            <Alert severity="error">{this.state.errorMessage}</Alert>
                        </div>
                    ) : (<></>)
                }

                {/* <div className="container">
                    <List dense={true}>
                        {
                            this.state.listCustomer.map(item => {
                                let data = {
                                    salesId:item.owner, 
                                    customerId: item.customer_id,
                                    waNumber: item.phone, 
                                    sendFrom: "", 
                                    message: ""
                                };

                                return (
                                    <ListItem
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" color='primary' onClick={() => this.props.openChat(data)}>
                                                <WhatsApp />
                                            </IconButton>
                                        }
                                        >
                                        <ListItemText
                                            primary={item.nama_customer}
                                            secondary={false ? 'Secondary text' : null}
                                        />
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </div> */}

            </Paper>
        );
    }
}
 
export default DailyFollowUp;