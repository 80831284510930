import React, { Component } from "react";

import "./CsListItem.css";
import { RecordVoiceOver, NoteAdd, Note, Save, Cancel, ChatBubble, Chat, WhatsApp } from "@mui/icons-material"
import { Chip, Divider, TextField, Tooltip, CircularProgress, Paper, Button, ButtonGroup } from "@mui/material";
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import axios from "axios";
import moment from "moment";

class CsContactListItem extends Component {
    state = {
        isSavingNote: false,
        isEditingNote: false,
        note: ""
    };

  getColor = () => {
    var randomColor = Math.floor(Math.random()*16777215).toString(16);
    return "#"+randomColor;
  }

  handleNote = (event) => {
    this.setState({ note: event.target.value });
  };

  handleSetHelpState = (conversation, helpState) => {

    let params = {
      userId: Global.get().user.id,
      contactId: conversation.contactId,
      helpState: helpState,
      archived: false,
      csId: Global.get().user.id,
      csName: Global.get().user.name
    };
    
    console.log("PARAMS_SET_CS_HELP", params);
    console.log("HEADER", Api.getRequestHeader());
    axios
      .post(Api.setCsHelp(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("SETCSHELP : ", response.data);
        this.props.reloadCsHelpList();

        if(helpState === "SOLVING"){
            conversation.csHelpState = helpState;
            this.props.onCustomerStarted(conversation);
        }

      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  handleSaveNote = (conversation, note) => {

    this.setState({isSavingNote: true}, () => {
      let params = {
        userId: Global.get().user.id,
        userName: Global.get().user.name,
        contactId: conversation.contactId,
        note: note
      };
      
      axios
        .post(Api.csSaveNote(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("SAVECSNOTE : ", response.data);
          
          this.setState({isSavingNote: false, isEditingNote: false}, ()=>{
              this.props.onUpdateNote(conversation, note);
          });
          
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message });
          console.error("There was an error!", error);
        });
      });
  }

    handleToggleShowNoteInput = () => {
        let showState = this.state.isEditingNote;
        this.setState({isEditingNote: !showState});
    }

  componentDidMount() {
    this.setState({note: this.props.data.csNote});
    // shave(".conversation-snippet", 25);
  }

  render() {
    const { fromName, text, fromNumber, unreadCounter, assignToUserName, csHelpState, showButton, csNote, lastWaCsLog, contactLabel, firstContactAssignedToName, lastMessageTime, csCreatedAt} = this.props.data;

    return (
      <>
        <Paper elevation={0}
            className={this.getListItemClasses()}
            style={{cursor: "default"}}
            // onClick={() => {this.props.onSelect(this.props.data);}}
            >
            <div className="container-fluid" style={{padding: "5px 0 2px 0"}}>

                <div className="conversation-title">
                  {fromName} &nbsp;
                  <span className={this.getUreadCounterClasses(unreadCounter)}>({unreadCounter})</span>
                </div>
                
                <div>
                    <span className="badge badge-secondary" style={{fontSize: "8px"}}>{Global.maskPhoneNumber(fromNumber)}</span>&nbsp;
                        {/* {
                            csHelpState !== undefined && csHelpState !== "OFF" ? 
                            (
                                <div className="badge badge-warning">
                                CS : {csHelpState === "SOLVED" ? "DONE" : csHelpState}
                                </div>
                            ) : 
                            (
                                <></>
                            )
                        } */}

                    <div className="badge text-info" style={{fontSize: "9px"}}><RecordVoiceOver style={{width: 15}} /> {assignToUserName !== null && assignToUserName !== undefined && assignToUserName.length > 0 ? assignToUserName : firstContactAssignedToName}</div>&nbsp;
                    {   
                    contactLabel !== undefined && contactLabel !== null && contactLabel.length > 0 ? 
                    (
                        <><span className={Global.getLabelClasses(contactLabel)} style={{fontSize: "8px"}}>{contactLabel}</span>&nbsp;</>
                    ) : 
                    (<></>)
                    }
                    <p className="conversation-snippet" style={{margin: "0 0 5px 0"}}>{text}</p>
                    <div className="text-right">
                        <span style={{fontSize: "9px"}}>Masuk CS : { moment(csCreatedAt).tz("asia/jakarta").format("DD-MM-YYYY HH:mm:ss")}</span>
                    </div>
                    <div>
                        <ButtonGroup fullWidth size="small" aria-label="Small button group">
                            {
                                showButton === true && csHelpState==="OPEN" ? 
                                (
                                    <Tooltip title="Klik untuk memulai sesi bantuan">
                                        <Button color="error" size="small" variant="contained" onClick={() => {this.handleSetHelpState(this.props.data, "SOLVING")}}>
                                            Start
                                        </Button>
                                    </Tooltip>
                                    // </div>
                                ) : 
                                (
                                    <></>
                                )
                            }

                            {
                                showButton === true && csHelpState==="SOLVING" ? 
                                (
                                    <Tooltip title={"Akhiri sesi bantuan untuk customer ini, customer akan hilang dari list ini"}>
                                    {/* <div className="btn btn-primary btn-block btn-sm" onClick={() => {this.handleSetHelpState(this.props.data, "SOLVED")}}> */}
                                    <Button color="warning" size="small" variant="contained" onClick={() => {this.handleSetHelpState(this.props.data, "OFF")}}>
                                        Finish
                                    </Button>
                                    </Tooltip>
                                ) : 
                                (<></>)
                            }

                            {
                                showButton === true && csHelpState==="SOLVED" ? 
                                
                                Global.get().admin === true || Global.isCustomerServiceSpv() || Global.isCustomerService() ? 
                                
                                (
                                    <Tooltip title={"Akhiri sesi bantuan untuk customer ini, customer akan hilang dari list ini"}>
                                        <Button color="warning" size="small" variant="contained" onClick={() => {this.handleSetHelpState(this.props.data, "OFF")}}>
                                            Finish
                                        </Button>
                                    </Tooltip>
                                ) : 
                                (
                                    <Tooltip title={"Akhiri sesi bantuan untuk customer ini, customer akan hilang dari list ini"}>
                                        <Button color="warning" size="small" variant="contained" disabled>
                                            Finish
                                        </Button>
                                    </Tooltip>
                                ) 
                                : 
                                (
                                    <></>
                                )
                            }
                            {/* <Button color="primary" key="two">Add Note</Button> */}
                            <Tooltip title={csHelpState === "OPEN" ? "Klik tombol MULAI untuk terlebih dahulu" : "Buka halaman chat"}>
                                <Button color="primary" variant="contained" key="two" disabled={csHelpState === "OPEN"}
                                    onClick={() => {this.props.onOpenChat(this.props.data);}}>
                                    <WhatsApp style={{width: 17}} /> &nbsp;
                                    Open Chat
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </div>

                    {/* <div className="row">
                        <div className="col-6 text-left">
                        {
                            showButton === true && csHelpState==="OPEN" ? 
                            (
                                <Tooltip title="Klik untuk memulai sesi bantuan">
                                    <Button color="primary" size="small" variant="contained" style={{padding: "0px", fontSize: "10px"}} onClick={() => {this.handleSetHelpState(this.props.data, "SOLVING")}}>
                                        Start
                                    </Button>
                                </Tooltip>
                                // </div>
                            ) : 
                            (
                                <></>
                            )
                        }

                        {
                            showButton === true && csHelpState==="SOLVING" ? 
                            (
                                <Tooltip title={"Akhiri sesi bantuan untuk customer ini, customer akan hilang dari list ini"}>
                                <div className="btn btn-primary btn-block btn-sm" onClick={() => {this.handleSetHelpState(this.props.data, "SOLVED")}}>
                                <Button color="warning" size="small" variant="contained" style={{padding: "0px", fontSize: "10px"}} onClick={() => {this.handleSetHelpState(this.props.data, "OFF")}}>
                                    Finish
                                </Button>
                                </Tooltip>
                            ) : 
                            (<></>)
                        } */}

                        {/* {
                            showButton === true && csHelpState==="SOLVED" ? 
                            
                            Global.get().admin === true || Global.isCustomerServiceSpv() || Global.isCustomerService() ? 
                            
                            (
                                <Tooltip title={"Akhiri sesi bantuan untuk customer ini, customer akan hilang dari list ini"}>
                                    <Button color="warning" size="small" variant="contained" style={{padding: "0px", fontSize: "10px"}} onClick={() => {this.handleSetHelpState(this.props.data, "OFF")}}>
                                        Finish
                                    </Button>
                                </Tooltip>
                            ) : 
                            (
                                <Tooltip title={"Akhiri sesi bantuan untuk customer ini, customer akan hilang dari list ini"}>
                                    <Button color="warning" size="small" variant="contained" style={{padding: "0px", fontSize: "10px"}} disabled>
                                        Finish
                                    </Button>
                                </Tooltip>
                            ) 
                            : 
                            (
                                <></>
                            )
                        } */}

                            {/* <Tooltip title={this.state.isEditingNote ? "Tutup input catatan" : "Input catatan"}>
                                <div className="btn btn-success btn-sm" onClick={this.handleToggleShowNoteInput}>
                                    {
                                        this.state.isEditingNote ? 
                                        (
                                            <Cancel />
                                        ) : 
                                        (
                                            <NoteAdd />
                                        )
                                    }
                                </div>
                            </Tooltip> */}

                        {/* </div> */}
                        {/* <div className="col-6 text-right">
                            <Tooltip title={this.state.isEditingNote ? "Tutup input catatan" : "Input catatan"}>
                                <span style={{padding: "0px", cursor: "pointer"}} onClick={this.handleToggleShowNoteInput}>
                                    {
                                        this.state.isEditingNote ? 
                                        (
                                            <Cancel color="primary" />
                                        ) : 
                                        (
                                            <NoteAdd color="primary" />
                                        )
                                    }
                                </span>
                            </Tooltip>
                            
                        </div>
                    </div> */}                        
                </div>
                
                {
                    this.state.isEditingNote ? 
                    (
                        <div className="conversation-search">
                            <div className="row">
                                <div className="col-9">
                                    <TextField
                                        onChange={this.handleNote}
                                        multiline
                                        label="Catatan"
                                        type="text"
                                        value={lastWaCsLog !== undefined && lastWaCsLog !== null && csNote !== undefined ? this.state.note : ""}
                                        className="form-control"
                                        id={"note"+fromNumber}
                                        variant="outlined"
                                        // onKeyDown={this.onKeyDownHandler}
                                        onBlur={() => {
                                            
                                        }}

                                    />
                                </div>
                                <div className="col-3">

                                    {
                                        this.state.isSavingNote ? 
                                        (
                                            <CircularProgress size={36} />
                                        ) : 
                                        (
                                            <Tooltip title="Simpan note">
                                                <div className="btn btn-warning btn-block" onClick={() => {this.handleSaveNote(this.props.data, this.state.note)}}>
                                                    <Save />
                                                </div>
                                            </Tooltip>
                                        )
                                    }

                                    
                                </div>
                            </div>
                            <br />
                            
                        </div>
                    ) : 
                    
                    lastWaCsLog !== undefined && lastWaCsLog !== null && csNote !== undefined ? 
                    (
                        <Tooltip title="Catatan CS">
                            <Chip
                                icon={<Note />}
                                label={csNote}
                                variant="outlined"
                            />
                        </Tooltip>
                    ):
                    (
                        <></>
                    )
                    
                }

            </div>
            
        </Paper>
        {/* <Divider /> */}
      </>

        
      
    );
  }

  getListItemClasses() {
    let classes = "conversation-list-item border border-danger";
    classes += this.props.data.selected === true ? "selected" : "";
    return classes;
  }

  getUreadCounterClasses(unreadCounter){
      return unreadCounter > 0 ? "label label-danger text-danger" : "label label-danger text-danger d-none";
  }
}

export default CsContactListItem;
