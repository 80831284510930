import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport, CloudDownload, CloudDownloadOutlined } from "@mui/icons-material";
import moment from "moment";
import axios from "axios";
import Api from "../../customlib/Api";
import { Tooltip } from '@mui/material';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

var dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class ExportConversation extends Component {

    state = {
        leadsData: []
    }

    componentDidMount(){
        // const params = {
        //     from: this.props.contactData.fromNumber,
        //     };
        //     console.log("PARAMSWAMESSAGE", params);
        
        //     axios.post(Api.messagesList(), params, Api.getRequestHeader())
        //     .then((response) => {
        //       console.log("YOUR_MESSAGES",response.data);
                
        //       const responseData = response.data;
    
        //       this.responseData.data.forEach(element => {
        //         element.leads.forEach(lead => {
        //             this.leadsData.push(lead);
        //         });
        //         // return leadsData;
        //     });
                
        //     })
        //     .catch((error) => {
        //       this.setState({ errorMessage: error.message, isLoading: false });
        //       console.error("There was an error!", error);
        //     });
    }

    getleadsReportData = () => {
        // let leadsData = [];        
        return this.state.leadsData;
    }

    getWaMessages = () => {

        if(this.props.currentConversation.fromNumber === ""){
          return;
        }
    
        this.setState({isLoading: true}, ()=>{
          const params = {
            from: this.props.contactData.fromNumber,
          };
          console.log("PARAMSWAMESSAGE", params);
      
          axios
            .post(Api.messagesList(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_MESSAGES",response.data);
              
                
              
              
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoading: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    state = {  }
    render() { 
        return (
            // <ExcelFile element={<button className="btn btn-primary"><ImportExport /></button>}>
            <ExcelFile element={
                <Tooltip title="Download percakapan">
                    <CloudDownloadOutlined className="text-success" style={{cursor: "pointer"}} />
                </Tooltip>
            }>
            
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getleadsReportData} name="Leads Report">
                    <ExcelColumn label="Sales" value="contact.name"/>
                    {/* <ExcelColumn label="Customer" value="fromName"/>
                    <ExcelColumn label="WhatsApp Number" value="from"/>
                    <ExcelColumn label="Lead Date"
                                  value={(col) => moment(col.createdAt).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/>
                    <ExcelColumn label="Last Message Date"
                                  value={(col) => moment(col.lastMessageReceivedAt).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/> */}
                    
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportConversation;