import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, CircularProgress} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Search } from '@mui/icons-material';
import DateRangePicker from '../../customlib/DateRangePicker';

class BySalesByLabelByCategoryByCity extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        isTeleReport: true,
        isLoading: false
    }
    
    componentDidMount() {
        this.getContactLeads();
    }

    getContactLeads = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      var params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
        userId: userData.user.id,
        reportType: "TYPE_BYSALES_BYLABEL_BYPRODUCTCATEGORY_BYCITY"
      };

      this.setState({contactLeads: [], isLoading: true});

      console.log("PARAMS", params);

      axios
        .post(Api.getLeadsReportSummary(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONTACTLEADS : ", response.data);
          console.log("USERDATA : ", "wkwkwk");
          
          var tmpContactLeads = response.data.data;
          
          if(userData.admin === false && Global.isTeleAdmin() === false){
            tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
          }

          tmpContactLeads.forEach(lead => {
            lead.leadsCount = lead.leads.length;
          });

          this.setState({contactLeads: tmpContactLeads, isLoading: false});
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCounta', label: '', minWidth: 100 }
    ];

    render() { 
        return ( 
          <Paper style={{padding:20}}>
                <div className="container-fluid">
                  <div className="panel panel-default">
                      <div className="panel-heading">
                          <div className="panel-title">
                              {/* <h1>Setup Contact</h1> */}
                          </div>
                      </div>
                      <div className="panel-body">
                          <div className="row align-items-center">
                              <div className="col-3">
                                  <strong>Total {this.state.contactLeads.length} Salesman</strong>
                              </div>
                              <div className="col-3">
                              {
                                  // Global.get().admin === true ? <ExportLeadsReport leadsReportData={this.state.contactLeads} /> : <></>
                              }
                              
                              </div>
                              <div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                                  {/* <span style={{marginTop: 5,}}><strong>Tele FU dari :</strong> &nbsp;</span>
                                  
                                  <TextField
                                      id="dateStart"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.startDate}
                                      onChange={this.handleStartDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  />
                                  &nbsp; <strong style={{marginTop: 5,}}>Sampai</strong> &nbsp;
                                  <TextField
                                      id="dateEnd"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.endDate}
                                      onChange={this.handleEndDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  /> */}
                              </div>
                              <div className="col-1">
                              {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                            </div>
                          </div>
                          <br />
                          <Paper className="root">
                              <TableContainer>
                                  <Table aria-label="sticky table" size="small">
                                  <TableHead>
                                      <TableRow>
                                          <TableCell width={"20px"} />
                                      {this.columns.map((column) => (
                                          <TableCell
                                              key={column.id}
                                              align={column.align}
                                              style={{ minWidth: column.minWidth }}
                                          >
                                          {column.label}
                                          </TableCell>
                                      ))}
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {this.state.contactLeads.map((row) => {
                                      return (
                                          <Row key={row.salesId} row={row} />
                                      );
                                      })}
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                              {/* <TablePagination
                                  rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                  component="div"
                                  count={this.state.contactLeads.length + this.state.rowsPerPage}
                                  rowsPerPage={this.state.rowsPerPage}
                                  page={this.state.page}
                                  onChangePage={this.handleChangePage}
                                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                              /> */}
                          </Paper>
                          {/* <br />
                          <div className="row">
                              <div className="col-4">
                                  Sales
                              </div>
                              <div className="col-2">
                                  Jumlah Leads
                              </div>
                              <div className="col-2">
                                  Action
                              </div>
                          </div>
                          <hr />
                          {
                              this.state.contactLeads.map(
                                  (contact) => (
                                      <ContactLeadsItem contactLeads={contact} />
                                  )
                              )
                          } */}
                      </div>
                      <div className="panel-footer">
                          {/* <Link to="/">
                              <div className="btn btn-primary">Close</div>
                          </Link> */}
                      </div>
                  </div>
                </div>
              </Paper>
         );
    }
}
 
export default BySalesByLabelByCategoryByCity;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" align='left'>
            {row.salesName}
          </TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Berdasarkan Label dan Kategori Produk
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Label</TableCell>
                      {
                          // Global.get().admin === true ? <TableCell>Nomor WhatsApp</TableCell> : <></>
                      }
                      <TableCell>Kategori Produk</TableCell>
                      <TableCell>Kota</TableCell>
                      <TableCell>Jumlah</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          <div className={Global.getLabelClasses(historyRow.label)}>{historyRow.label === "" ? "---" : historyRow.label}</div>
                        </TableCell>
                        {
                            // Global.get().admin === true ? <TableCell>{historyRow.from}</TableCell> : <></>
                        }
                        <TableCell align="left">{historyRow.product_category === "" ? "---" : historyRow.product_category}</TableCell>
                        <TableCell align="left">{historyRow.city === "" ? "---" : historyRow.city}</TableCell>
                        <TableCell align="left">
                          {historyRow.count_category}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }