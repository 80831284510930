import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import {Paper, Tabs, Tab, Typography, Box} from '@mui/material';
import { Link } from 'react-router-dom';
import DataPendingLeads from './DataPendingLeads';
import LeadsBelumadaLabel from './LeadsbelumAdaLabel';
import DataPendingLeadsParcel from './DataPendingLeadsParcel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class DataPendingLeadsTabs extends Component {

    state = { 
        value : 0,
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    // classes = useStyles();
    // const [value, setValue] = React.useState(0);

    componentDidMount() {
        this.setState({value: 0});
    }
    

    handleChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };
    
    render() { 
        return ( 
            <>
            <Paper className={this.useStyles.root}>
                {/* <div className="container-fluid text-right bg-primary">
                    <Link to="/">
                        <div className="btn btn-primary">Close</div>
                    </Link>
                </div> */}
                <div className="container text-center">
                  <div className="row">
                      <div className="col-11" style={{paddingLeft: "30px"}}><h3>Data Pending leads</h3></div>
                      <div className="col-1 text-right">
                          <Link to="/">
                              <div className="btn btn-danger">X</div>
                          </Link>
                      </div>
                  </div>
                </div>
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab key="tab0" label="Data Pending Leads" />
                    <Tab key="tab1" label="Leads Parcel Event" />
                    <Tab key="tab2" label="Leads belum Ada Label" />
                </Tabs>
            </Paper>
            <TabPanel value={this.state.value} index={0}>
                <DataPendingLeads type={"ALL"} />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
                <DataPendingLeadsParcel type="PARCEL" />
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
              <LeadsBelumadaLabel />
            </TabPanel>

            {/* {
              Global.get().isDataPendingReportUser === true ? 
              (
                <TabPanel value={this.state.value} index={4}>
                  <DataCancelDashboard />
                </TabPanel>
              ) : 
              (<></>)
            } */}

            
            </>
         );
    }
}
 
export default DataPendingLeadsTabs;