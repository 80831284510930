import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, TableFooter } from '@mui/material';
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Check, Close, QuestionAnswer, TrainRounded } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageListViewer from '../MessageListViewer';

class WeeklyPersenCancel extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null,
        resumeByKategori: [],
        years: [],
        selectedMonth: moment().format("M"),
        selectedYear: moment().format("YYYY"),
        dataPerforma: [],
        contactLeadsHourly: [],
        listAppProfiles: [],
        filterAppNumber: "",
        performaTotalCancel: 0,
        performaTotalPengurangLeads: 0,
        performaTotalLeads: 0,
        summaryWeekly: {
          week1: 0,
          week: 2, 
          week3: 0,
          week4: 0
        },
        performaSalesByLabel: [],
        weeklyLeadsPercentage: []
    }
    
    componentDidMount() {
      let arrYears = [];
      for(let i=2020;i<=moment().format("YYYY");i++){
        arrYears.push(i);
      }
      this.setState(
        {appProfileId: Global.getAppProfile()._id, filterAppNumber: Global.getAppProfile().waNumber, years: arrYears}, ()=>{
          this.handleRefresh();
          // this.getResumeWeekly(true);
        }
      );
    }

    handleRefresh = () => {
        this.loadAppProfile();
        this.getWeeklyCancelLeadsPercentage();
    }

    handleChangeMonth = (event) => {
      this.setState({selectedMonth: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleChangeYear = (event) => {
      this.setState({selectedYear: event.target.value}, () => {
        this.getResumeWeekly(true);
      });
    }

    handleAppTypeChanged = (event) => {
      this.setState({appProfileId: event.target.value}, ()=>{
        console.log(this.state.appProfileId);
        // this.getContactLeads();
        this.handleRefresh();
      });
    }

    loadAppProfile = () => {
      const params = {
      };
      
      axios
        .post(
          Api.listAppProfile(),
          params,
          Api.getRequestHeaderNoAuth()
        )
        .then((response) => {
          console.log("App Profiles", response.data);
          
          let profiles = [];
          profiles.push({_id: "", waNumber: "", label: "---"});

          let profilesFiltered = response.data.data.filter((profile) => {return profile.enabled === true});
          profilesFiltered.forEach(item => {
              profiles.push(item);
          });
          this.setState({listAppProfiles: profiles});
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, loginSuccess: false });
          console.error("There was an error!", error);
          // this.props.onLogin();
        }
      );
  };

    

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    getWeeklyCancelLeadsPercentage = () => {

        this.setState({isLoading: true, resumeByKategori: [], summaryWeekly: {
          week1: 0,
          week2: 0,
          week3: 0,
          week4: 0
        }}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: `${this.state.startDate}`,
                endDate: `${this.state.endDate}`,
                filterAppNumber: this.state.filterAppNumber
            }
  
            console.log("PARAMS", params);
  
            axios
            .post(Api.weeklyCancelLeadsPercentage(), params, Api.getRequestHeader())
            .then((response) => {
                
                console.log(response.data);

                var summaryWeekly = {
                  week1: 0,
                  week2: 0,
                  week3: 0,
                  week4: 0
                };

                var responseData = response.data.data

                responseData.forEach(item => {
                  summaryWeekly.week1 += item.week1 <= 0 ? 0 : item.week1/item.week1AllLeads;
                  summaryWeekly.week2 += item.week2 <= 0 ? 0 : item.week2/item.week2AllLeads;
                  summaryWeekly.week3 += item.week3 <= 0 ? 0 : item.week3/item.week3AllLeads;
                  summaryWeekly.week4 += item.week4 <= 0 ? 0 : item.week4/item.week4AllLeads;
                });

                summaryWeekly.week1 = (summaryWeekly.week1/responseData.length*100).toFixed(2);
                summaryWeekly.week2 = (summaryWeekly.week2/responseData.length*100).toFixed(2);
                summaryWeekly.week3 = (summaryWeekly.week3/responseData.length*100).toFixed(2);
                summaryWeekly.week4 = (summaryWeekly.week4/responseData.length*100).toFixed(2);

                this.setState({weeklyLeadsPercentage: response.data.data, summaryWeekly: summaryWeekly, isLoading: false});
                // this.setState({resumeByKategori: resumeByKategori, totalWeekly: totalWeekly, isLoading: false}, () => {});
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });
  
    };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah FU', minWidth: 100 },
        // { id: 'totalAdaResponLavender', label: 'Respon Lavender', minWidth: 100 },
        { id: 'totalAdaResponHsm', label: 'Respon HSM', minWidth: 100 },
        // { id: 'responRateLavender', label: 'Respon Rate Lavender', minWidth: 100 },
        { id: 'responRate', label: 'Respon Rate HSM', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => this.handleRefresh());
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        return ( 
            <Paper style={{padding:20, backgroundColor: "#e6e9ed"}}>

            {
              this.state.currentConversation !== null ? (
                  <Dialog
                      open={this.state.currentConversation !== null}
                      // TransitionComponent={Transition}
                      // keepMounted
                      onClose={() => {this.setState({currentConversation: null})}}
                      aria-describedby=""
                  >
                      {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                      <DialogContent style={{height: "100%"}}>
                          <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} />
                      </DialogContent>
                      <DialogActions>
                      {/* <Button onClick={handleClose}>Disagree</Button>
                      <Button onClick={handleClose}>Agree</Button> */}
                      </DialogActions>
                  </Dialog>
              ) : (<></>)
            }

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-2" style={{padding: "5px"}}>
                            <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                          </div>
                          <div className="col-2">
                              <FormControl variant="outlined" style={{width: '100%'}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Aplikasi</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.appProfileId}
                                      onChange={this.handleAppTypeChanged}
                                      label="Aplikasi"
                                  >
                                      {
                                      this.state.listAppProfiles.map(
                                          (profile) => {
                                          return (<MenuItem key={profile._id} value={profile._id}>{profile.label}</MenuItem>);
                                          } 
                                      )
                                      }
                                  </Select>
                              </FormControl>
                            </div>
                          <div className="col-2 text-center" style={{borderRadius: 10, padding: 0}}>
                              
                              {
                                this.state.isLoading ? 
                                (
                                  <>
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.handleRefresh}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                              
                            </div>
                        </div>
                        
                        <h4>Weekly % Cancel</h4>
                        <small>Persentase leads berlabel cancel terhadap semua leads mingguan</small>
                        <br /><br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                      <TableCell key={"head1"} align={"left"} style={{fontWeight: "bold"}}>
                                        Sales
                                      </TableCell>
                                      <TableCell key={"head2"} align={"center"} style={{fontWeight: "bold"}}>
                                        1-7
                                      </TableCell>
                                      <TableCell key={"head3"} align={"center"} style={{fontWeight: "bold"}}>
                                        8-14
                                      </TableCell>
                                      <TableCell key={"head4"} align={"center"} style={{fontWeight: "bold"}}>
                                        15-22
                                      </TableCell>
                                      <TableCell key={"head5"} align={"center"} style={{fontWeight: "bold"}}>
                                        23-31
                                      </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.weeklyLeadsPercentage.map((row) => {
                                    return (
                                        <TableRow>
                                          <TableCell>{row.salesName}</TableCell>
                                          <TableCell align={"center"}>{(row.week1 <= 0 ? 0 : row.week1/row.week1AllLeads*100).toFixed(2)}%</TableCell>
                                          <TableCell align={"center"}>{(row.week2 <= 0 ? 0 : row.week2/row.week2AllLeads*100).toFixed(2)}%</TableCell>
                                          <TableCell align={"center"}>{(row.week3 <= 0 ? 0 : row.week3/row.week3AllLeads*100).toFixed(2)}%</TableCell>
                                          <TableCell align={"center"}>{(row.week4 <= 0 ? 0 : row.week4/row.week4AllLeads*100).toFixed(2)}%</TableCell>
                                        </TableRow>
                                    );
                                    })}
                                    <TableRow>
                                          <TableCell style={{fontWeight: "bold"}}>Summary</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.week1}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.week2}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.week3}%</TableCell>
                                          <TableCell align={"center"} style={{fontWeight: "bold"}}>{this.state.summaryWeekly.week4}%</TableCell>
                                        </TableRow>
                                </TableBody>
                                <TableFooter>
                                </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="panel-footer">
                    </div>
                </div>
              </div>
            </Paper>
         );
    }
}
 
export default WeeklyPersenCancel;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});


  function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }