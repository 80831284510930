import React, { Component } from "react";

import "./ConversationListItem.css";
import { RecordVoiceOver, SyncAlt } from "@mui/icons-material"
import { Button, Chip, Divider, Paper } from "@mui/material";
import Global from "../../customlib/Global";
import moment from "moment";
import { FaRecycle } from "react-icons/fa";

class ConversationListItem extends Component {
  state = {};

  getColor = () => {
    var randomColor = Math.floor(Math.random()*16777215).toString(16);
    return "#"+randomColor;
  }

  componentDidMount() {
    // shave(".conversation-snippet", 25);
  }

  render() {
    const { fromName, text, fromNumber, unreadCounter, assignToUserName, csHelpState, showButton, firstContactAssignedToName, contactLabel, deliveryDate, lastFuResult, crossedPending, isCustomerRecycle, customerRecycledAt, salesOnlineFu} = this.props.data;

    return (
      <>
        <Paper elevation={0}
            className={this.getListItemClasses()}
            onClick={() => {this.props.onSelect(this.props.data);}}>
            {/* <img className="conversation-photo" src={photo} alt="conversation" /> */}
            {/* <Avatar style={{backgroundColor: this.getColor()}}></Avatar> */}
            <div className="conversation-infao" style={{marginLeft:"2px"}}>
                {/* <h1 className="conversation-title">{fromName} <span className={this.getUreadCounterClasses(unreadCounter)}>({unreadCounter})</span></h1> */}
                <div className="conversation-title">
                  {fromName} &nbsp;
                  
                  <span className={this.getUreadCounterClasses(unreadCounter)}>({unreadCounter})</span>
                </div>
                
                <p className="conversation-snippet">{text}</p>
                {/* <span style={{ fontSize: 10, color: "grey" }}>{fromNumber}</span>
                <div></div> */}
                <span className="badge badge-secondary" style={{fontSize: "9px"}}>{Global.maskPhoneNumber(fromNumber)}</span>&nbsp;
                {
                  contactLabel !== undefined && contactLabel !== null && contactLabel.length > 0 ? 
                  (
                    <><span className={Global.getLabelClasses(contactLabel)} style={{fontSize: "10px"}}>{contactLabel}</span>&nbsp;<span className={Global.getLabelClasses(contactLabel)}>{lastFuResult}</span></>
                  ) : 
                  (<></>)
                }
                {
                  salesOnlineFu !== undefined && salesOnlineFu !== null && salesOnlineFu.length > 0 ? 
                  (
                    <><Button color={salesOnlineFu === "FU1" ? "error" : salesOnlineFu === "FU2" ? "warning" : "primary"} size="small" variant="outlined" style={{fontSize: "10px", padding: "0px"}}>{salesOnlineFu}</Button></>
                  ) : 
                  (<></>)
                }
                {
                  assignToUserName !== undefined && assignToUserName !== null && assignToUserName.length > 0 ? 
                  (
                    <div className="badge text-info" style={{fontSize: "10px"}}><RecordVoiceOver style={{width: 13}} /> {assignToUserName}</div>
                  ) : 
                  (<></>)
                }
                <div></div>
                {
                  this.props.showDeliveryDate === true ? (
                    <small>Pengiriman : {deliveryDate !== null && deliveryDate !== undefined ? Global.formatDateTimeToStrDateTime24(deliveryDate) : ""}</small>
                  ) : (<></>)
                }
                <div></div>
                
                {
                  Global.getAppProfile().type === "PURCHASING-APP" ? 
                  (<></>) : 
                  (<div style={{display:"flex", justifyContent: "space-between", justifyItems: "end"}}>
                    
                    <div className="badge text-info" style={{fontSize: "9px"}}>1'st Sales : {firstContactAssignedToName}</div>
                    {
                      // crossedPending && (<SyncAlt style={{}} color="secondary"/>)
                      // crossedPending && (<small style={{color: "red"}}>Crossed</small>)
                      crossedPending && (<Chip size="small" label="Crossed" color="error" variant="outlined" />)
                    }
                    {
                      isCustomerRecycle && (<Chip icon={<FaRecycle />} size="small" label="Recycle" color="warning" variant="outlined" />)
                    }
                  </div>)
                }
                
                {/* {
                  csHelpState !== undefined && csHelpState !== "OFF" ? 
                  (
                    <div className="badge badge-warning">
                      CS : {csHelpState === "SOLVED" ? "DONE" : csHelpState}
                    </div>
                  ) : 
                  (
                    <></>
                  )
                } */}
                
                {
                  showButton === true && csHelpState==="OPEN" ? 
                  (
                    <div style={{clear: 'both'}}>
                      <div className="btn btn-primary btn-block" onClick={() => {this.props.onUpdateHelpState("SOLVING")}}>
                        Bantu Sekarang
                      </div>
                    </div>
                  ) : 
                  (<></>)
                }

                {
                  showButton === true && csHelpState==="SOLVING" ? 
                  (
                    <div style={{clear: 'both'}}>
                      <div className="btn btn-primary btn-block" onClick={() => {this.props.onUpdateHelpState("SOLVED")}}>
                        Akhiri Sekarang
                      </div>
                    </div>
                  ) : 
                  (<></>)
                }

            </div>
            
        </Paper>
        {/* <Divider /> */}
      </>

        
      
    );
  }

  getListItemClasses() {
    let classes = "conversation-list-item ";
    classes += this.props.data.selected === true ? "selected" : "";
    return classes;
  }

  getUreadCounterClasses(unreadCounter){
      return unreadCounter > 0 ? "label label-danger text-danger" : "label label-danger text-danger d-none";
  }
}

export default ConversationListItem;
