import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import axios from 'axios';
import { Box, Button, Dialog, DialogContent, Divider, LinearProgress } from '@mui/material';
import checkInTimeMp3File from "../../sound/check_in_time.mp3";
import { AlarmOn } from '@mui/icons-material';

class CheckpointClient extends Component {
    state = { 
        showDialogCheckpoint : false,
        checkpointData: null,
        checkpointTimeOut: 5 * 60 * 1000,
        obTimeoutCheckpoint: null,
        checkInTimeAudio: new Audio(checkInTimeMp3File), 
        isCheckpointMissed: false       
    } 

    runCheckPoint = () => {
        this.setState({showDialogCheckpoint: true});
            
        let obTimeoutCheckpoint = setTimeout(()=>{
            this.setState({isCheckpointMissed: true});

            this.assignCheckPoint(false);

        }, this.state.checkpointTimeOut);
        
        this.setState({obTimeoutCheckpoint: obTimeoutCheckpoint});
        // console.log("Muncul setelah jeda waktu", this.state.runningCheckPointIndex, this.state.listRunningCheckPoint[this.state.runningCheckPointIndex]);
        try{
            this.state.checkInTimeAudio.play();
        }catch(err){
            console.log("PLAYERROR", err);
        }
      }

    assignCheckPoint = (isAssignedByUser) => {
        clearTimeout(this.state.obTimeoutCheckpoint);
    
        const params = {
          checkPointId: this.state.checkpointData?._id,
          isMissed: !isAssignedByUser
        };
        
        console.log("ASSIGN_CP_PARAMS", params);
    
        axios
        .post(Api.updateCheckpoint(), params, Api.getRequestHeader())
        .then((response) => {
    
          console.log("ASSIGN_CP_RESPONSE", response.data);
    
          var data = response.data;
    
          if(data.success === true){
            if(!this.state.isCheckpointMissed){
                this.setState({showDialogCheckpoint: false}, ()=>{
                //   this.getListRunningCheckpoint();
                });
            }
            
          }else{
    
          }
    
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    
    }

    componentDidMount() {
        Global.getSocket().on("notifyCheckPoint", data => {

            const appProfileId = Global.getAppProfile()._id;
            const userId = Global.get().user.id;

            const dataCheckpoint = data.checkpoints?.find(item => item.appProfileId === appProfileId && item.userId === userId);

            if(dataCheckpoint){
                this.setState({showDialogCheckpoint: true, checkpointData: dataCheckpoint, isCheckpointMissed: false}, () => {
                    this.runCheckPoint();
                });
            }

            console.log("LOG___notify_checkpoint", data);
        });
    }

    resetCheckpoint = () => {
        this.setState({isCheckpointMissed: false, checkpointData: null});
    }

    closeCheckpoint = () => {
        this.setState({showDialogCheckpoint: false});
    }

    render() { 
        return (<>
        
            {
                this.state.showDialogCheckpoint && (
                    <Dialog open={this.state.showDialogCheckpoint} >
                        <DialogContent>
                            {
                                !this.state.isCheckpointMissed && (
                                    <Box>
                                        <Box textAlign={"center"} fontWeight={"bold"}>
                                            <AlarmOn sx={{width: "70px", height: "70px"}} color='error' />
                                        </Box>
                                        <Box marginTop={"10px"} textAlign={"center"} fontWeight={"bold"}>
                                            Waktunya Check In!
                                        </Box>
                                        <Box marginTop={"10px"} textAlign={"center"} fontWeight={"normal"}>
                                            Jangan Sampai Terlambat
                                        </Box>
                                        <Box textAlign={"center"} fontWeight={"normal"}>
                                            Segera Klik Tombol Check In
                                        </Box>
                                        <Divider color='error' sx={{mt: "10px", mb: "10px"}} />
                                        <Box textAlign={"center"} fontWeight={"bold"}>
                                            <Button onClick={() => this.assignCheckPoint(true)} fullWidth variant='contained' color='warning'>Check In</Button>
                                        </Box>
                                    </Box>
                                )
                            }
                            {
                                this.state.isCheckpointMissed && (
                                    <Box>
                                        <Box textAlign={"center"} fontWeight={"bold"}>
                                            <AlarmOn sx={{width: "70px", height: "70px"}} color='error' />
                                        </Box>
                                        <Box marginTop={"10px"} textAlign={"center"} fontWeight={"bold"}>
                                            Anda melewatkan kesempatan check in ke 
                                        </Box>
                                        <Box marginTop={"10px"} fontWeight="bold" fontSize={"30px"} textAlign={"center"}>
                                            {
                                                this.state.checkpointData?.index
                                            }
                                        </Box>
                                        <Divider color='error' sx={{mt: "10px", mb: "10px"}} />
                                        <Box textAlign={"center"} fontWeight={"bold"}>
                                            <Button onClick={() => {this.resetCheckpoint(); this.closeCheckpoint();}} fullWidth variant='contained' color='warning'>Tutup</Button>
                                        </Box>
                                    </Box>
                                )
                            }
                        </DialogContent>
                    </Dialog>
                )
            }
        
        </>);
    }
}
 
export default CheckpointClient;