import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Divider, Drawer, Hidden, IconButton, List, Toolbar, ListItem, ListItemIcon, ListItemText, Typography, Box, Checkbox, FormControlLabel, Switch, } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { makeStyles, useTheme } from '@mui/styles';
import {ArrowBack, Inbox as InboxIcon, Menu as MenuIcon} from '@mui/icons-material'
import AssignContact from '../AssignContact';
import EditContact from '../EditContact';
import SettingManager from '../SettingManager';
import SalesManagement from '../SalesManagement';
import ContactLeadsReport from '../ContactLeadsReport';
import { Link } from 'react-router-dom';
import { CloseRounded } from "@mui/icons-material";
import SetupChatUsers from '../SetupChatUsers';
import SetupChatUser2 from '../SetupChatUsers/SetupChatUser2';
import SalesResponseTimeReport from '../SalesResponseTimeReport';
import AppProfile from '../AppProfile';
import CheckpointSettings from '../CheckpointSettings';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import { useHistory } from 'react-router-dom';
import LoginMonitoring from '../LoginMonitoring/LoginMonitoring';
import HsmFuSettings from '../HsmFuSettings';
import HsmMonitoring from '../HsmMonitoring/HsmMonitoring';
import HsmSettings from '../HsmSettings';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

function SettingsResponsive(props) {
  const { window: settingWindow } = props;
  // const theme = useTheme();
  // const classes = useStyles();

  let lotusConsoleEnabled = localStorage.getItem("lotus_console_enabled");

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [consoleEnabled, setConsoleEnabled] = React.useState(lotusConsoleEnabled !== null && lotusConsoleEnabled !== undefined ? (lotusConsoleEnabled === "false" ? false : true) : Api.isConsoleEnabled);
  const [currentPage, setCurrentPage] = React.useState({title: "App Profile", id: "APP_PROFILE"});

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleConsoleEnabled = () => {
    localStorage.setItem("lotus_console_enabled", !consoleEnabled);
    setConsoleEnabled(!consoleEnabled);
    console.log("HANDLECONSOLE");
    window.location.reload("/");
  }

  const drawer = (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <Toolbar />
      <Divider />
      <List>
            
            {
              Global.isSuperUser() ? (
                <ListItem button key="menuAppProfile" onClick={() => {setCurrentPage({title: "App Profile", id: "APP_PROFILE"})}}>
                  <ListItemIcon><InboxIcon /></ListItemIcon>
                  <ListItemText primary="App profile" />
              </ListItem>
              ) : (<></>)
            }
            {
              Global.isSuperUser() ? (
                <ListItem button key="menuPrivileges" onClick={() => {setCurrentPage({title: "Privileges", id: "PRIVILEGES"})}}>
                  <ListItemIcon><InboxIcon /></ListItemIcon>
                  <ListItemText primary="Privileges" />
                </ListItem>
              ) : (<></>)
            }

            <ListItem button key="menusales" onClick={() => {setCurrentPage({title: "Sales", id: "SALES"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Sales" />
            </ListItem>

            <ListItem button key="menucheckpoint" onClick={() => {setCurrentPage({title: "Checkpoint", id: "CHECKPOINT_SETTINGS"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Checkpoint" />
            </ListItem>
            
            <ListItem button key="menuhsmfu" onClick={() => {setCurrentPage({title: "HSM FU", id: "HSM_FU_SETTINGS"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="HSM Follow Up" />
            </ListItem>
            <ListItem button key="menuinputhsm" onClick={() => {setCurrentPage({title: "INPUT HSM", id: "HSM_INPUT_SETTINGS"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Input HSM" />
            </ListItem>
            
            <ListItem button key="menuhsmmonitoring" onClick={() => {setCurrentPage({title: "HSM Monitoring", id: "HSM_MONITORING"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="HSM Monitoring" />
            </ListItem>

            {/* <ListItem button key="menuEditContact" onClick={() => {setCurrentPage({title: "Edit Contact", id: "EDIT_CONTACT"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Edit Contact" />
            </ListItem> */}

            
            {/* <ListItem button key="menuContactBaru" onClick={() => {setCurrentPage({title: "Contact Baru", id: "CONTACT_BARU"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Contact Baru" />
            </ListItem> */}

            

            {/* <ListItem button key="setupchatusers" onClick={() => {setCurrentPage({title: "Setup Chat Users", id: "SETUP_CHAT_USERS"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Setup Chat Users" />
            </ListItem> */}

            {/* <ListItem button key="menuLeadsReport" onClick={() => {setCurrentPage({title: "Leads Report", id: "LEADS_REPORT"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Leads Report" />
            </ListItem> */}
            {/* <ListItem button key="menuSalesResponseTimeReport" onClick={() => {setCurrentPage({title: "Rerata Kecepatan Response Sales", id: "SALES_RESPONSE_TIME_REPORT"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Response Time" />
            </ListItem> */}
        </List>
      <Divider />
      {
        Global.isSuperUser ? 
        (
          <ListItem button key="menuloginmonitoring" onClick={() => {setCurrentPage({title: "Login Monitoring", id: "LOGIN_MONITORING"})}}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Login Monitoring" />
            </ListItem>
        ) : 
        (<></>)
      
      }
      {
        Global.isSuperUser ? 
        (
          <List>
            <ListItem>
              <FormControlLabel control={<Switch onChange={handleConsoleEnabled} checked={consoleEnabled} />} label="Console enabled" />
            </ListItem>
          </List>
        ) : 
        (<></>)
      }
      
      
    </div>
  );

  const container = settingWindow !== undefined ? () => settingWindow().document.body : undefined;

  return (
    // <div className={classes.root}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar position="fixed" className={classes.appBar}> */}
      <AppBar position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>
        <Toolbar>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          <Box>
            <div className="row">
            <div className="col-2">
                  <Link to="/">
                      {/* <div className="btn btn-danger"> */}
                        <ArrowBack style={{color: "#ffffff"}} />
                      {/* </div> */}
                  </Link>
                </div>
                <div className="col-md-10 col-lg-10">
                  <Typography variant="h6" noWrap>
                      {currentPage.title}
                  </Typography>
                </div>
                
            </div>
          </Box>
          
        </Toolbar>
      </AppBar>
      {/* <nav className={classes.drawer} aria-label="mailbox folders"> */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
            {drawer}
          </Drawer>
        </Hidden>
      </Box>
      {/* <main className={classes.content}> */}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {/* <div className={classes.toolbar} /> */}
        <Toolbar />
        {/* <div className="container"> */}
            {
                currentPage.id === "CONTACT_BARU" ? <AssignContact /> : <></>
            }

            {
                currentPage.id === "EDIT_CONTACT" ? <EditContact /> : <></>
            }

            {
                currentPage.id === "PRIVILEGES" ? <SettingManager /> : <></>
            }

            {
                currentPage.id === "SALES" ? <SalesManagement /> : <></>
            }

            {
                currentPage.id === "LEADS_REPORT" ? <ContactLeadsReport /> : <></>
            }

            {
                currentPage.id === "SETUP_CHAT_USERS" ? <SetupChatUser2 /> : <></>
            }

            {
                currentPage.id === "SALES_RESPONSE_TIME_REPORT" ? <SalesResponseTimeReport /> : <></>
            }

            {
                currentPage.id === "APP_PROFILE" ? <AppProfile /> : <></>
            }

            {
                currentPage.id === "CHECKPOINT_SETTINGS" ? <CheckpointSettings /> : <></>
            }

            {
                currentPage.id === "LOGIN_MONITORING" ? <LoginMonitoring /> : <></>
            }

            {
                currentPage.id === "HSM_FU_SETTINGS" ? <HsmFuSettings /> : <></>
            }

            {
                currentPage.id === "HSM_MONITORING" ? <HsmMonitoring /> : <></>
            }

            {
                currentPage.id === "HSM_INPUT_SETTINGS" ? <HsmSettings /> : <></>
            }
        {/* </div> */}
      </Box>
    </Box>
  );
}

export default SettingsResponsive;
