import { Add, BrowseGallery, Close, CloudUpload, Delete, DeleteForeverOutlined, FileUpload, Folder, Info, InfoTwoTone, Search, Upload } from '@mui/icons-material';
import { Autocomplete, Avatar, Box, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Snackbar, Switch, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react';
import "./CreateOrder.css";
import axios from 'axios';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import Dropzone from 'react-dropzone';
import { InputGroup } from 'react-bootstrap';
import base64 from "base-64";
import MessageList from '../MessageList';

class CreateOrder extends Component {
    state = { 
        dataOrder: {
            customerEmail: "",
            customerPhone: "",
            customerName: "",
            memberStatus: "",
            customerType: "",
            customerGender: "",
            product: null,
            productPrice: 0,
            productQuantity: 1,
            productShipping: "",
            productShippingCost: 0,
            isExpress: false,
            senderName: "",
            receiverName: "",
            receiverPhone: "",
            greetings: "",
            shippingAddress: "",
            notes: "",
            notesInternal: "",
            country: null,
            province: null,
            city: null,
            customLogoUrl: "",
            customDesignUrl: ""
        },
        listCountry: [],
        listProvince: [],
        listCity: [],
        keyword: "",
        page: 1,
        customLogoFile: null,
        customLogoBase64: null,
        customDesignFile: null,
        customDesignBase64: null,
        extractedDataOrder: null,
        showSnackbar: false,
        snackbarMessage: "",
        isLoadingCustomerInfo: false,
        value: 0,
        currentConversation: null
    } 

    loadListCountry = () => {

        axios
          .get(Api.listCountry(), Api.getRequestHeaderNoAuth())
          .then((response) => {
            console.log("LISTCOUNTRY : ", response.data);
            // let data = response.data.data;
            
            let data = response.data;
            let countries = [];
            data.forEach(element => {
                countries.push({
                    label: element.name,
                    id: element.id
                })
            });

            this.setState({listCountry: countries});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadListProvince = () => {

        axios
          .get(Api.listProvince(this.state.dataOrder.country), Api.getRequestHeaderNoAuth())
          .then((response) => {
            console.log("LISTPROVINCE : ", response.data);
            let data = response.data;
            let provinces = [];
            data.forEach(element => {
                provinces.push({
                    label: element.name,
                    id: element.id
                })
            });

            this.setState({listProvince: provinces});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadListCity = () => {

        axios
          .get(Api.listCity(this.state.dataOrder.province), Api.getRequestHeaderNoAuth())
          .then((response) => {
            console.log("LISTCITY : ", response.data);
            let data = response.data;
            let cities = [];
            data.forEach(element => {
                cities.push({
                    label: element.name,
                    id: element.id
                })
            });

            this.setState({listCity: cities});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadProducts = () => {
        this.setState({isLoadingSearch: true}, () => {
            axios
                .get(Api.listProduct(this.state.keyword, this.state.page), Api.getRequestHeaderNoAuth())
                .then((response) => {
                    console.log("LISTPRODUCT : ", response.data);
                    let data = response.data;

                    this.setState({listProduct: data, isLoadingSearch: false});
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        })
        
    };

    getWaMessages = (contact) => {
        console.log("CONTACTDATA : ", contact);
        if(contact.fromNumber === ""){
          return;
        }
    
        this.setState({isLoading: true}, ()=>{
          const params = {
            from: contact.fromNumber,
          };
          console.log("PARAMSWAMESSAGE", params);
      
          axios
            .post(Api.messagesList(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_MESSAGES",response.data);
              
              const responseData = response.data;
                
              const clonedMyCust = [...this.state.listMycustomer];
              let myCust = clonedMyCust.filter((cust) => cust.from===contact.fromNumber);
  
              if(myCust.length > 0){
                  myCust[0].messages = responseData.data;
              }
  
              this.setState({listMycustomer: clonedMyCust});
              
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoading: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    loadCustomerInfo = () => {
        this.setState({isLoadingCustomerInfo: true}, () => {
            axios
                .get(Api.lavenderGetCustomerInfo(this.state.dataOrder.customerEmail, this.state.dataOrder.customerPhone), Api.getRequestHeaderNoAuth())
                // .get(Api.lavenderGetCustomerInfo("kekeke", "wkwkw"), Api.getRequestHeaderNoAuth())
                .then((response) => {
                    console.log("CUSTOMER_INFO : ", response.data);
                    let data = response.data;

                    if(data){
                        let dataOrder = {...this.state.dataOrder};
                        dataOrder.customerEmail = data.email;
                        dataOrder.customerPhone = data.phone;
                        dataOrder.customerName = data.name;
                        dataOrder.memberStatus = data.is_member;
                        dataOrder.customerType = data.type;
                        dataOrder.customerGender = data.gender;
                        this.setState({dataOrder: dataOrder, isLoadingCustomerInfo: false});
                    }else{
                        this.handleShowSnackbar("Customer dengan \nemail: " + this.state.dataOrder.customerEmail + " dan \nphone: " + this.state.dataOrder.customerPhone + "\n tidak ditemukan");
                        this.setState({isLoadingCustomerInfo: false});
                    }

                    // this.setState({listProduct: data, isLoadingSearch: false});
                })
                .catch((error) => {
                    this.setState({isLoadingCustomerInfo: false});
                    console.error("There was an error!", error);
                });
        })
        
    };

    handleUploadImage = () => {
        this.setState({isUploading: true}, () => {

            const params = {
                logo: this.state.customLogoBase64,
                image: this.state.customDesignBase64
            }

            axios
                .post(Api.lavenderUploadImage(), params, Api.getRequestHeaderNoAuth())
                .then((response) => {
                    console.log("UPLOAD_RESULT : ", response.data);
                    let data = response.data;
                    let dataOrder = {...this.state.dataOrder};
                    dataOrder.customLogoUrl = data.logo;
                    dataOrder.customDesignUrl = data.image;
                    this.setState({dataOrder: dataOrder, isUploading: false}, () => {
                        this.handleOpenLavender();
                    });
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        })
        
    };

    handleProceed = () => {

        let customerEmail = this.state.dataOrder.customerEmail;
        let customerPhone = this.state.dataOrder.customerPhone;
        let customerName = this.state.dataOrder.customerName;
        let customerGender = this.state.dataOrder.customerGender;
        let shippingCost = this.state.dataOrder.shippingCost;
        let notesInternal = this.state.dataOrder.notesInternal;
        let city = this.state.dataOrder.city;
        let receiverName = this.state.dataOrder.receiverName;
        let receiverPhone = this.state.dataOrder.receiverPhone;

        if(customerEmail?.trim().length <= 0){
            this.handleShowSnackbar("Customer Email belum diisi");
            return;
        }

        if(!Global.isEmailValid(customerEmail)){
            this.handleShowSnackbar("Email tidak valid");
            return;
        }

        if(customerPhone?.trim().length <= 0){
            this.handleShowSnackbar("Customer Phone belum diisi");
            return;
        }

        if(!Global.isPhoneValid(customerPhone)){
            this.handleShowSnackbar("Customer phone tidak valid");
            return;
        }

        if(customerName?.trim().length <= 0){
            this.handleShowSnackbar("Customer Name belum diisi");
            return;
        }

        if(customerGender?.trim().length <= 0){
            this.handleShowSnackbar("Customer Gender belum diisi");
            return;
        }

        if(shippingCost?.trim().length <= 0){
            this.handleShowSnackbar("Shipping Cost belum diisi");
            return;
        }

        if(receiverName?.trim().length <= 0){
            this.handleShowSnackbar("Receiver name belum diisi");
            return;
        }

        if(receiverPhone?.trim().length <= 0){
            this.handleShowSnackbar("Receiver phone belum diisi");
            return;
        }

        if(!Global.isPhoneValid(receiverPhone)){
            this.handleShowSnackbar("Receiver phone tidak valid");
            return;
        }

        if(notesInternal.trim().length <= 0){
            this.handleShowSnackbar("Notes Internal belum diisi");
            return;
        }

        if(city === null || city <= 0){
            this.handleShowSnackbar("City belum diisi");
            return;
        }

        if(this.state.customLogoFile !== null || this.state.customDesignFile !== null){
            this.handleUploadImage();
        }else{
            this.handleOpenLavender();
        }

    }

    handleShowSnackbar = (message) => {
        this.setState({showSnackbar: true, snackbarMessage: message});
    }

    handleOpenLavender = () => {
        let payload = {
            "email": this.state.dataOrder.customerEmail,
            "phone": this.state.dataOrder.customerPhone,
            "name": this.state.dataOrder.customerName,
            "is_member": this.state.dataOrder.memberStatus,
            "type": this.state.dataOrder.customerType,
            "gender": this.state.dataOrder.customerGender,
            "product_id": this.state.dataOrder.product?.id,
            "product_price": this.state.dataOrder.productPrice,
            "product_quantity": this.state.dataOrder.productQuantity,
            "shipping_expedition": this.state.dataOrder.productShipping,
            "shipping_cost": this.state.dataOrder.productShippingCost,
            "shipping_express": this.state.dataOrder.isExpress,
            "sender_name": this.state.dataOrder.senderName,
            "receiver_name": this.state.dataOrder.receiverName,
            "receiver_phone": this.state.dataOrder.receiverPhone,
            "greetings": this.state.dataOrder.greetings,
            "shipping_address": this.state.dataOrder.shippingAddress,
            "notes": this.state.dataOrder.notes,
            "notes_internal": this.state.dataOrder.notesInternal,
            "country": this.state.dataOrder.country,
            "province": this.state.dataOrder.province,
            "city": this.state.dataOrder.city,
            "special_request_image": this.state.dataOrder.customDesignUrl,
            "special_request_logo": this.state.dataOrder.customLogoUrl
        }

        console.log("PAYLOAD", payload);
        let payloadBase64 = base64.encode(JSON.stringify(payload));
        console.log("PAYLOAD", payloadBase64);

        var url = Api.getLavenderUrl() + "#!/orders/create-lotus/" + payloadBase64;
        var win = window.open(url, '_blank');
        win.focus();

    }

    CUSTOMER_TYPES = [
        {
            id: 0,
            name: "Personal"
        },
        {
            id: 1,
            name: "Corporation"
        }
    ];

    MEMBER_STATUS = [
        {
            id: 0,
            name: "Bukan Member"
        },
        {
            id: 1,
            name: "Member"
        }
    ];

    GENDER = [
        {
            id: "Female",
            name: "Female"
        },
        {
            id: "Male",
            name: "Male"
        }
    ];

    handleCustomerEmailChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerEmail = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCustomerPhoneChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerPhone = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCustomerNameChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerName = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleMemberStatusChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.memberStatus = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCustomerTypesChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerType = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCustomerGenderChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerGender = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleProductPriceChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.productPrice = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleProductQuantityChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.productQuantity = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleProductShippingChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.productShipping = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleProductShippingCostChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.productShippingCost = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleIsExpressChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.isExpress = event.target.checked;
        this.setState({dataOrder: dataOrder});
    }

    handleSenderNameChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.senderName = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleReceiverNameChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.receiverName = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleReceiverPhoneChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.receiverPhone = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleGreetingsChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.greetings = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleShippingAddressChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.shippingAddress = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleNotesChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.notes = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleNotesInternalChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.notesInternal = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCountryChange = (event, newValue) => {
        console.log("COUNTRY", event, newValue);
        let data = {...this.state.dataOrder}
        data.country = newValue.id;
        data.province = 0;
        data.city = 0;
        this.setState({dataOrder: data}, () => {
            this.loadListProvince();
        })
    }

    handleProvinceChange = (event, newValue) => {
        console.log("PROVINCE", event, newValue);
        let data = {...this.state.dataOrder}
        data.province = newValue.id;
        data.city = 0;
        this.setState({dataOrder: data}, () => {
            this.loadListCity();
        })
    }

    handleCityChange = (event, newValue) => {
        console.log("CITY", event, newValue);
        let data = {...this.state.dataOrder}
        data.city = newValue.id;
        this.setState({dataOrder: data}, () => {})
    }

    handleKeywordChange = (event) => {
        console.log("KEYWORD", event);
        // let data = {...this.state.dataOrder}
        // data.city = newValue.id;
        this.setState({keyword: event.target.value}, () => {
            // this.loadProducts();
        })
    }

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          this.loadProducts();
        }
    };

    handleSelectProduct = (selectedProduct) => {
        let data = {...this.state.dataOrder};
        data.product = selectedProduct;
        data.productPrice = selectedProduct.price;
        
        this.setState({dataOrder: data});
    }

    handleCustomLogo = (acceptedFiles) => {
        if (
            acceptedFiles !== undefined &&
            acceptedFiles !== null &&
            acceptedFiles.length > 0
        ) {

            const reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);

            reader.onload = () => {
                this.setState({
                    customLogoFile: acceptedFiles[0],
                    customLogoBase64: reader.result
                });
            }

            reader.onerror = (error) => {
                console.log("ERROR", error)
            }

            // this.setState({
            //     customLogoFile: acceptedFiles[0]
            // });
        }
    };

    handleCustomDesign = (acceptedFiles) => {
        if (
            acceptedFiles !== undefined &&
            acceptedFiles !== null &&
            acceptedFiles.length > 0
        ) {

            const reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);

            reader.onload = () => {
                this.setState({
                    customDesignFile: acceptedFiles[0],
                    customDesignBase64: reader.result
                });
            }

            reader.onerror = (error) => {
                console.log("ERROR", error)
            }

            // this.setState({
            //     customDesignFile: acceptedFiles[0]
            // });
        }
    };

    handleDeleteCustomLogo = () => {
        this.setState({customLogoFile: null});
    }

    handleDeleteCustomDesign = () => {
        this.setState({customDesignFile: null});
    }

    extractFormOrderData = () => {
        let orderData = this.props.messageText ?? "";

        // let orderData = `
        // Untuk pemesanan, silahkan lengkapi data berikut

        // ##Tertera di Kartu Ucapan/Karangan Bunga Papan
        // # Ucapan : berduka cita
        // # Nama Pengirim : Kapus Mapim beserta jajaran
        
        // ##Tidak Tertera di Kartu Ucapan/Karangan Bunga
        // # Alamat Email (pemesan) : 
        // # Nama Penerima : andi
        // # Nomor Penerima : 081934190025
        // # Alamat Tujuan Pengiriman : jln. niih rt 006/007 no.18 b ragunan pasar minggu jakarta selatan
        // # Tanggal dan Jam Pengiriman : rabu, secepatnya
        // # Catatan Khusus Terkait Pesanan : 635.000
        
        // NB : 
        // *• Estimasi waktu untuk produksi dan pengiriman 4-5 Jam dan pesanan kami proses setelah pembayaran kami terima.*
        
        // *• Untuk pemesanan dan pengiriman yang dilakukan kurang dari waktu 24jam maka design akan menyesuaikan.*
        // `;

        let split_enter = orderData.split("\n");
        

        let arrConditions = [
            "silahkan lengkapi data",
            "tertera di kartu ucapan",
            "tidak tertera di kartu ucapan",
            "nb",
            "Estimasi waktu untuk produksi",
            "Untuk pemesanan dan pengiriman"
        ]

        let formatted = {
            ucapan: "",
            namaPengirim: "",
            email: "",
            namaPenerima: "",
            nomorPenerima: "",
            alamatTujuan: "",
            waktuPengiriman: "",
            catatanKhusus: ""
        }

        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerPhone = this.props.waNumber;
        let display = split_enter.map(line => <div><small>{line}</small></div>)

        split_enter.forEach(item => {
            let filter = arrConditions.filter(cond => item.toLocaleLowerCase().includes(cond.toLowerCase()))
            if(filter.length <= 0){
                
                let splitColon = item.split(":");
                let caption = splitColon.length > 0 ? splitColon[0] : item;
                let value = splitColon.length > 0 ? splitColon[1]?.trim() : item?.trim();
                console.log("EXTRACT_VALUE", caption, value);
                if(caption?.toLocaleLowerCase().includes("ucapan")){
                    formatted.ucapan = value;
                    dataOrder.greetings = value;
                }else if(caption?.toLocaleLowerCase().includes("nama pengirim")){
                    // formatted.namaPengirim = value;
                    // dataOrder.senderName = value;
                    // dataOrder.customerName = value;
                }else if(caption?.toLocaleLowerCase().includes("alamat email")){
                    formatted.email = value;
                    dataOrder.customerEmail = value;
                }else if(caption?.toLocaleLowerCase().includes("nama penerima")){
                    formatted.namaPenerima = value;
                    dataOrder.receiverName = value;
                }else if(caption?.toLocaleLowerCase().includes("nomor penerima")){
                    formatted.nomorPenerima = value;
                    dataOrder.receiverPhone = value;
                }else if(caption?.toLocaleLowerCase().includes("alamat tujuan")){
                    formatted.alamatTujuan = value;
                    dataOrder.shippingAddress = value;
                }else if(caption?.toLocaleLowerCase().includes("tanggal dan jam")){
                    formatted.waktuPengiriman = value;
                }else if(caption?.toLocaleLowerCase().includes("catatan khusus")){
                    formatted.catatanKhusus = value;
                    dataOrder.notes = value;
                }

            }
            
        })
        console.log("EXTRACT_VALUE", formatted);

        this.setState({dataOrder: dataOrder, extractedDataOrder: display}, () => {
            this.loadCustomerInfo();
        });
    }

    handleChange = (event, newValue) => {
        this.setState({value:newValue});
    };

    componentDidMount() {
        this.setState({currentConversation: this.props.conversation}, () => {
            this.loadListCountry();
            this.extractFormOrderData();
            this.getWaMessages(this.state.currentConversation);
        })
    }

    render() { 
        return (
            <Paper className='padding-5' style={{margin: "10px", backgroundColor: "#d5dae1"}} >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-10">
                            <h4>Autofill Create Order</h4>
                        </div>
                        <div className="col-2 text-right">
                            <div className="btn btn-sm btn-danger" onClick={this.props.onCloseDrawer}>
                                <Close />
                            </div>
                        </div>
                    </div>
                    <small>
                        Pastikan semua informasi customer dan produk sudah sesuai sebelum klik tombol <strong>Proceed</strong>
                    </small>
                    {/* <div className="btn btn-warning" onClick={this.extractFormOrderData}>Extract</div> */}
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}} />

                    <Paper className='padding-5 m-bottom-10'>
                        <h4>Customer Information</h4>
                        {
                            this.state.isLoadingCustomerInfo ? (<LinearProgress style={{marginTop: "10px", marginBottom: "10px"}} />) : (<Divider style={{marginTop: "10px", marginBottom: "10px"}} />)
                        }
                        
                        
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    error={false}
                                    id="txt-cust-email"
                                    label="Customer Email"
                                    defaultValue=""
                                    value={this.state.dataOrder.customerEmail}
                                    onChange={this.handleCustomerEmailChange}
                                    helperText=""
                                    fullWidth
                                    // variant="filled"
                                    required
                                    size='small'
                                    className='m-bottom-10'
                                    style={{marginBottom: "10px"}}
                                    type='email'
                                />
                                <TextField
                                    error={false}
                                    id="txt-cust-phone"
                                    label="Customer Phone"
                                    defaultValue=""
                                    value={Global.maskPhoneNumber(this.state.dataOrder.customerPhone, true)}
                                    // onChange={this.handleCustomerPhoneChange}
                                    helperText=""
                                    fullWidth
                                    // variant="filled"
                                    required
                                    size='small'
                                    className='m-bottom-10'
                                    style={{marginBottom: "10px"}}
                                    type='phone'
                                />
                                <TextField
                                    error={false}
                                    id="txt-cust-name"
                                    label="Customer Name"
                                    defaultValue=""
                                    value={this.state.dataOrder.customerName}
                                    onChange={this.handleCustomerNameChange}
                                    helperText=""
                                    fullWidth
                                    // variant="filled"
                                    required
                                    size='small'
                                    className='m-bottom-10'
                                    style={{marginBottom: "10px"}}
                                />
                                
                                <FormControl fullWidth size='small' className='m-bottom-10' 
                                    style={{marginBottom: "10px"}}>
                                    <InputLabel id="demo-simple-select-label">Member Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-member-status"
                                        value={this.state.dataOrder.memberStatus}
                                        label="Member Status"
                                        onChange={this.handleMemberStatusChange}
                                    >
                                        {
                                            this.MEMBER_STATUS.map((item) => {
                                                return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth size='small' className='m-bottom-10' 
                                    style={{marginBottom: "10px"}}>
                                    <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-customer-type"
                                        value={this.state.dataOrder.customerType}
                                        label="Customer Type"
                                        onChange={this.handleCustomerTypesChange}
                                    >
                                        {
                                            this.CUSTOMER_TYPES.map((item) => {
                                                return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth size='small' className='m-bottom-10' 
                                    style={{marginBottom: "10px"}} required>
                                    <InputLabel id="demo-simple-select-label">Customer Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-member-status"
                                        value={this.state.dataOrder.customerGender}
                                        label="Customer Gender"
                                        onChange={this.handleCustomerGenderChange}
                                    >
                                        {
                                            this.GENDER.map((item) => {
                                                return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-6">
                                <div className='border rounded padding-5' style={{backgroundColor: "#deecfc"}}>
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                    variant='scrollable'
                                >
                                    <Tab key="tab0" label="Order Message" wrapped />
                                    <Tab key="tab1" label="Chat History" wrapped />
                                </Tabs>
                                <TabPanel value={this.state.value} index={0}>
                                    {this.state.extractedDataOrder}
                                </TabPanel>
                                <TabPanel value={this.state.value} index={1}>
                                {
                                    this.state.currentConversation !== null ? (
                                        <Paper className='root'>
                                            <MessageList
                                                onUpdateReadCounter = {()=>{}}
                                                onNewIncomingMessage={()=>{}}
                                                onUpdateConversations={()=>{}}
                                                onUpdateCsHelpState={()=>{}}
                                                currentConversation={this.state.currentConversation}
                                                onUpdateContactLabelState={()=>{}}
                                                onClearCurrentConversation = {()=>{}}
                                                onUpdateSlow={()=>{}}
                                                onUpdateJustRead={()=>{}}
                                                onUpdateUnfinishedChat={()=>{}}
                                                onResetUnreadCounter={()=>{}}
                                                conversationList={[]}
                                                logoutHandler={()=>{}}
                                                onSaveCustomerName={()=>{}}
                                                style={{maxHeight: "70vh", overflowY: "none"}}
                                            />
                                        </Paper>
                                        
                                    ) : (<></>)
                                }
                                </TabPanel>
                                    {/* <strong>Order Message</strong>
                                    {this.state.extractedDataOrder} */}
                                </div>
                                {/* {this.props.messageText} */}
                            </div>
                        </div>
                    </Paper>

                    <Paper className='padding-5 m-bottom-10'>
                        <div className="row">
                            <div className="col-6">
                                <h4>Product Information</h4>
                                {
                                    this.state.dataOrder.product === null && (
                                        <Paper className='text-center' style={{paddingTop: "20vh", paddingBottom: "20vh", backgroundColor: "#d5dae1"}}>
                                            <InfoTwoTone color='primary' /><br />
                                            <div className="text-danger">
                                                Silakan cari dan pilih produk lebih dahulu
                                            </div>
                                        </Paper>
                                    )
                                }

                                {
                                    this.state.dataOrder.product !== null && (
                                        <div>
                                            <div className="row m-bottom-10" >
                                                <div className="col-4">
                                                    <div className="text-primary">Product Name</div>
                                                    <div className="text-capitalize"><small>{this.state.dataOrder.product ? this.state.dataOrder.product.category.name : "???"}</small></div>
                                                    <div className="text-capitalize"><small>{this.state.dataOrder.product ? this.state.dataOrder.product.name : "???"}</small></div>
                                                    <div className="text-warning"><small>[{this.state.dataOrder.product ? this.state.dataOrder.product.product_code : "???"}]</small></div>
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        error={false}
                                                        id="txt-price"
                                                        label="Price"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.productPrice}
                                                        onChange={this.handleProductPriceChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        // required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <TextField
                                                        error={false}
                                                        id="txt-quantity"
                                                        label="Quantity"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.productQuantity}
                                                        onChange={this.handleProductQuantityChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        // required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        error={false}
                                                        id="txt-shipping"
                                                        label="Shipping Expedition"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.productShipping}
                                                        onChange={this.handleProductShippingChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        // required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <TextField
                                                        error={false}
                                                        id="txt-shipping-cost"
                                                        label="Shipping Cost"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.productShippingCost}
                                                        onChange={this.handleProductShippingCostChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <FormControlLabel required control={<Switch size='small' checked={this.state.dataOrder.isExpress} onChange={this.handleIsExpressChange} />} label="Express" 
                                                        style={{marginBottom: "10px", marginLeft: "5px"}} />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-6">
                                                    <TextField
                                                        error={false}
                                                        id="txt-sender-name"
                                                        label="Sender Name (160)"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.senderName}
                                                        onChange={this.handleSenderNameChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        // required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <TextField
                                                        error={false}
                                                        id="txt-receiver-name"
                                                        label="Receiver Name"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.receiverName}
                                                        onChange={this.handleReceiverNameChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <TextField
                                                        error={false}
                                                        id="txt-receiver-phone"
                                                        label="Receiver Phone (62xxx...)"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.receiverPhone}
                                                        onChange={this.handleReceiverPhoneChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <TextField
                                                        error={false}
                                                        id="txt-greetings"
                                                        label="Greetings (100)"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.greetings}
                                                        onChange={this.handleGreetingsChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        // required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <TextField
                                                        error={false}
                                                        id="txt-shipping-address"
                                                        label="Shipping Address"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.shippingAddress}
                                                        onChange={this.handleShippingAddressChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        // required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        multiline
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <TextField
                                                        error={false}
                                                        id="txt-notes"
                                                        label="Notes"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.notes}
                                                        onChange={this.handleNotesChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        // required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        multiline
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                    <TextField
                                                        error={false}
                                                        id="txt-notes-internal"
                                                        label="Notes Internal"
                                                        defaultValue=""
                                                        value={this.state.dataOrder.notesInternal}
                                                        onChange={this.handleNotesInternalChange}
                                                        helperText=""
                                                        fullWidth
                                                        // variant="filled"
                                                        required
                                                        size='small'
                                                        className='m-bottom-10'
                                                        multiline
                                                        style={{marginBottom: "10px"}}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className='text-primary'>
                                                Shipping Location
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="auto-cuntry"
                                                        options={this.state.listCountry}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} label="Country" />}
                                                        size='small'
                                                        style={{marginBottom: "10px"}}
                                                        onChange={this.handleCountryChange}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="auto-province"
                                                        options={this.state.listProvince}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} label="Province" />}
                                                        size='small'
                                                        style={{marginBottom: "10px"}}
                                                        onChange={this.handleProvinceChange}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="auto-city"
                                                        options={this.state.listCity}
                                                        fullWidth
                                                        renderInput={(params) => <TextField required {...params} label="City" />}
                                                        size='small'
                                                        style={{marginBottom: "10px"}}
                                                        onChange={this.handleCityChange}
                                                        
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="text-primary m-bottom-10">Custom Logo</div>
                                                        
                                                        {
                                                            this.state.customLogoFile && (
                                                                <Paper className='border border-primary text-center padding-5' >
                                                                    <div className="btn btn-danger m-bottom-10" onClick={this.handleDeleteCustomLogo}>
                                                                        <DeleteForeverOutlined /> Delete
                                                                    </div>
                                                                    <br />
                                                                    <img className={"img img-responsive"} src={URL.createObjectURL(this.state.customLogoFile)} style={{maxWidth: 240, maxHeight: 240}} alt={this.state.customLogoFile.name} />
                                                                    
                                                                </Paper>
                                                            )
                                                        }

                                                        {
                                                            !this.state.customLogoFile && (
                                                                <Dropzone
                                                                    onDrop={(acceptedFiles) => {
                                                                        console.log(acceptedFiles);
                                                                        this.handleCustomLogo(acceptedFiles);
                                                                    }}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <Paper
                                                                            {...getRootProps()}
                                                                            className="text-center border border-secondary"
                                                                            style={{cursor: "pointer"}}
                                                                        >
                                                                            <CloudUpload color="secondary" />
                                                                            <input {...getInputProps()} />
                                                                            <div className="font-bold text-secondary">
                                                                                Choose File
                                                                            </div>
                                                                        </Paper>
                                                                    )}
                                                                </Dropzone>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                <div className="col-6">
                                                    <div className="text-primary m-bottom-10">Custom Design</div>
                                                        
                                                        {
                                                            this.state.customDesignFile && (
                                                                <Paper className='border border-primary text-center padding-5' >
                                                                    <div className="btn btn-danger m-bottom-10" onClick={this.handleDeleteCustomDesign}>
                                                                        <DeleteForeverOutlined /> Delete
                                                                    </div>
                                                                    <br />
                                                                    <img className={"img img-responsive"} src={URL.createObjectURL(this.state.customDesignFile)} style={{maxWidth: 240, maxHeight: 240}} alt={this.state.customDesignFile.name} />
                                                                </Paper>
                                                            )
                                                        }

                                                        {
                                                            !this.state.customDesignFile && (
                                                                <Dropzone
                                                                    onDrop={(acceptedFiles) => {
                                                                        console.log(acceptedFiles);
                                                                        this.handleCustomDesign(acceptedFiles);
                                                                    }}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <Paper
                                                                            {...getRootProps()}
                                                                            className="text-center border border-secondary"
                                                                            style={{cursor: "pointer"}}
                                                                        >
                                                                            <CloudUpload color="secondary" />
                                                                            <input {...getInputProps()} />
                                                                            <div className="font-bold text-secondary">
                                                                                Choose File
                                                                            </div>
                                                                        </Paper>
                                                                    )}
                                                                </Dropzone>
                                                            )
                                                        }
                                                </div>
                                            </div>
                                            <div className="" style={{paddingTop: "10px", paddingBottom: "10px"}}>
                                                <div className="btn btn-block btn-primary" onClick={this.handleProceed}>Proceed</div>
                                            </div>
                                        </div>
                                    )
                                }                                
                            </div>
                            <div className="col-6">
                                <h4>Product Search</h4>
                                <TextField
                                    error={false}
                                    id="txt-search-product"
                                    label="[product name] or [product code] or [product description]"
                                    value={this.state.keyword}
                                    onChange={this.handleKeywordChange}
                                    onKeyDown={this.onKeyDownHandler}
                                    defaultValue=""
                                    helperText=""
                                    fullWidth
                                    // variant="filled"
                                    required
                                    size='small'
                                    className='m-bottom-10'
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <Search />
                                          </InputAdornment>
                                        ),
                                      }}
                                    style={{marginBottom: "10px"}}
                                />

                                {
                                    this.state.isLoadingSearch && <div className="">
                                        <LinearProgress style={{marginTop: "10px", marginBottom: "10px"}} />
                                    </div>
                                }

                                {
                                    this.state.listProduct?.data?.length > 0 ? 
                                        <div className="">
                                            <List>
                                            {
                                                this.state.listProduct?.data?.map(item => {
                                                    return (
                                                        <ListItem className='border m-bottom-10'
                                                            onClick={() => this.handleSelectProduct(item)}
                                                            secondaryAction={
                                                                <Tooltip title="Add to form">
                                                                    <IconButton color='secondary' edge="end" aria-label="delete" onClick={() => this.handleSelectProduct(item)}>
                                                                        <Add />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                            style={{cursor: "pointer"}}
                                                            >
                                                            <ListItemAvatar>
                                                                {/* <Avatar> */}
                                                                    <img style={{margin: "5px"}} width={"100px"} className='img-responsive rounded' src={Api.getLavenderUrl() + "/" + item.image} alt="" />
                                                                {/* </Avatar> */}
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={
                                                                    <div>
                                                                        <div>{item.category.name}</div>
                                                                        <div>{item.name}</div>
                                                                    </div>
                                                                }
                                                                secondary={
                                                                    <div className="text-warning">
                                                                        [{item.product_code}]
                                                                    </div>
                                                                }
                                                            />
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                            </List>
                                        </div> : 
                                        <div className="">
                                            <Paper className='text-center' style={{paddingTop: "15vh", paddingBottom: "15vh", backgroundColor: "#d5dae1"}}>
                                                <InfoTwoTone color='primary' /><br />
                                                <div className="text-danger text center">
                                                    Ketikkan keyword kemudian tekan <strong>Enter</strong> 
                                                    <br />untuk melakukan pencarian produk
                                                </div>
                                            </Paper>
                                        </div>
                                }

                            </div>
                        </div>
                    </Paper>
                </div>
                <Snackbar
                    // anchorOrigin={{ "top", "center" }}
                    open={this.state.showSnackbar}
                    onClose={() => {this.setState({showSnackbar: false})}}
                    message={this.state.snackbarMessage}
                    autoHideDuration={6000}
                    // key={vertical + horizontal}
                />
            </Paper>
        );
    }
}
 
export default CreateOrder;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }