import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import { FaUserCheck, FaSave, FaTrash } from "react-icons/fa";
import ucwords from 'ucwords';
import { Button, Container, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
// import { Checkbox } from 'material-ui';



class HsmForm extends Component {
    state = { 
        hsmInfo: {
            name: "",
            status: "NOT APPROVED",
            category: "",
            headerFormat: "NONE",
            body: ""
        },
    }

    // handleSelectionChanged = (salesName) => {
    //     const salesData = [...this.props.salesData];
    //     // console.log("SALESDATA : ", salesData);
    //     let selected = salesData.filter((sales) => sales.name===salesName);
    //     console.log("FINDSELECTION : ", selected);

    //     if(selected.length > 0){
    //         let newData = JSON.parse(JSON.stringify(selected[0]));
    //         newData.lavenderSalesId = this.props.contactData.id;
    //         this.setState({selectedSales: newData});
    //     }

    // }

    handleHsmNameChange = (event) => {
        let hsm = {...this.state.hsmInfo};
        hsm.name = event.target.value;
        this.setState({hsmInfo: hsm});
    }
    
    handleHsmStatusChange = (event) => {
        let hsm = {...this.state.hsmInfo};
        hsm.status = event.target.value;
        this.setState({hsmInfo: hsm});
    }

    handleHsmCategoryChange = (event) => {
        let hsm = {...this.state.hsmInfo};
        hsm.category = event.target.value;
        this.setState({hsmInfo: hsm});
    }

    handleHsmHeaderFormatChange = (event) => {
        let hsm = {...this.state.hsmInfo};
        hsm.headerFormat = event.target.value;
        this.setState({hsmInfo: hsm});
    }

    handleHsmBodyChange = (event) => {
        let hsm = {...this.state.hsmInfo};
        hsm.body = event.target.value;
        this.setState({hsmInfo: hsm});
    }

    componentDidMount() {
        this.setState({hsmInfo: this.props.hsmInfo});
    }

    componentDidUpdate(prevProps, prevState) {
        
    }
    
    handleSubmit = () => {
        
        var params = this.state.hsmInfo;
    
        console.log("PARAMS", params);
        // return;
        axios
            .post(Api.saveHsmTemplate(), params, 
                Api.getRequestHeader())
            .then((response) => {
                console.log("doSaveHsmFu : ", response.data);
                let responseData = response.data;
                if(responseData.success){
                    console.log(responseData);
                    
                    this.setState({ ...this.state, 'bottom': false }, () => {
                        this.props.closeDrawer();
                        this.props.reload();
                    });
                }
                
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }


    render() { 
        // console.log("SALESNAME : ", this.state.lavenderSalesName);
        // const { from, fromName, lastMessage, assignToUserName} = this.props.contactData;
        return ( 
            <div>
                <Container>
                    <h1>Input HSM</h1>
                    <hr/>
                    <TextField onChange={this.handleHsmNameChange} value={this.state.hsmInfo?.name} id="hsmName" label="HSM Name" variant="outlined" size='small' fullWidth />
                    <br />
                    <br />
                    <TextField value={ucwords(this.state.hsmInfo?.name?.replaceAll("_", " "))} id="beautifiedHsmName" label="Beautified HSM Name" variant="outlined" size='small' fullWidth disabled />
                    <br /><br />
                    <FormControl variant="outlined" fullWidth size='small'>
                        <InputLabel id="demo-simple-select-outlined-label">Status Approval</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.hsmInfo?.status}
                            onChange={this.handleHsmStatusChange}
                            label="Status Approval">
                            <MenuItem value={"APPROVED"}>Approved</MenuItem>
                            <MenuItem value={"NOT APPROVED"}>Not Approved</MenuItem>
                        </Select>
                    </FormControl>
                    <br /><br />
                    <FormControl variant="outlined" fullWidth size='small'>
                        <InputLabel id="demo-simple-select-outlined-label">Kategori</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.hsmInfo?.category}
                            onChange={this.handleHsmCategoryChange}
                            label="Kategori">
                            <MenuItem value={"UTILITY"}>Utility</MenuItem>
                            <MenuItem value={"MARKETING"}>Marketing</MenuItem>
                        </Select>
                    </FormControl>
                    <br /><br />
                    <FormControl variant="outlined" fullWidth size='small'>
                        <InputLabel id="demo-simple-select-outlined-label">Header</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.hsmInfo?.headerFormat ?? "NONE"}
                            onChange={this.handleHsmHeaderFormatChange}
                            label="Header">
                            <MenuItem value={"NONE"}>Tanpa Header</MenuItem>
                            <MenuItem value={"IMAGE"}>Gambar</MenuItem>
                            <MenuItem value={"DOCUMENT"}>Dokumen</MenuItem>
                            <MenuItem value={"VIDEO"}>Video</MenuItem>
                            <MenuItem value={"TEXT"}>Teks</MenuItem>
                        </Select>
                    </FormControl>
                    <br /><br />
                    <TextField onChange={this.handleHsmBodyChange} value={this.state.hsmInfo?.body} id="hsmBody" label="HSM Body" variant="outlined" size='small' multiline fullWidth />
                    <br /><br />
                    <div className="btn btn-primary btn-block" onClick={this.handleSubmit}>Simpan</div>
                    {/* <Button className='btn btn-primary' onClick={this.handleSubmit}>Simpan</Button> */}
                </Container>
                {/* <div className="row">
                    <div className="col-8">
                    
                    </div>
                    <div className="col-2">
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Tipe Sales</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.salesType}
                            onChange={this.handleSalesTypeChange}
                            label="Tipe Sales">
                            <MenuItem value={"CS"}>Customer Service</MenuItem>
                            <MenuItem value={"SALES"}>Sales</MenuItem>
                            <MenuItem value={"PURCHASING"}>Purchasing</MenuItem>
                            <MenuItem value={"OTHER"}>Other</MenuItem>
                        </Select>
                    </FormControl>
                    </div>
                    
                    <div className="col-2 text-left" style={{paddingTop: 8,}}>
                        <span className="btn btn-primary" onClick={this.handleAssignContactToSales}>
                            <FaSave />
                        </span>
                        &nbsp;
                        <span className="btn btn-danger" onClick={this.handleAssignContactToSales}>
                            <FaTrash />
                        </span>
                        &nbsp;
                        {
                            this.state.isAssigned ? 
                                <FaUserCheck className="text-danger" style={{fontSize: 25}}/> : 
                                <span></span>
                            }
                    </div>
                </div> */}
                <br />
            </div>
         );
    }
}
 
export default HsmForm;