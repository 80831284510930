import React, { Component } from 'react';
import axios from "axios";
import {TextField, Autocomplete, Drawer, Paper} from '@mui/material';
import Api from "../../customlib/Api";
import Modal from "react-bootstrap/Modal";
import { Button, Container, Form, FormGroup, Label, ButtonGroup, Table} from 'reactstrap';
import { FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import './SettingContact.css'; 
import Global from '../../customlib/Global';

class SalesManagement extends Component {

    constructor(props) {
      super(props);
      this.state = {
        item: this.emptyCustomer,
        sales: []
      };
      // this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    state = { 
        contacts : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        salesData: [],
        selectedSales : {},//id = id, lavenderSalesId, lavenderSalesName, salesType
        id: 0,
        lavenderSalesId: 0,
        lavenderSalesName: "",
        salesType: "Acquisition",
        bottom: false,
        salesInfo: {}, //id = id, lavenderSalesId, lavenderSalesName, salesType
        showDeleteSalesLavenderDialog: false,
        idDelete:0,
        idSalesDelete:0,
        nameSalesDelete:"",
        allowReceiveLeads: 1,
        isNightShift: false
    }

   
    
    componentDidMount() {
        this.getSaleses();
        this.getSalesTeleUsers();
        this.setState({id: 0});
        
    }

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({salesData: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    getSaleses = () => {
      axios
        .post(Api.getSalesChatList(), {appProfileId: Global.getAppProfile()._id}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESCHATLIST : ", response.data.data);
            this.setState({sales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };


    handleAddButton = () => {
        let newData = 
        {
            id: 0,
            lavenderSalesId: 0,
            lavenderSalesName: "",
            salesType: "Acquisition"
        };

        const sales = [...this.state.contacts, newData];

        this.setState({contacts: []}, ()=>{
            this.setState({contacts: sales});
        });

    }

    handleSalesTypeChange = (event) => {
      this.setState({
          salesType: event.target.value

          
      });
      
    };

    handleSelectionChanged = (salesName) => {
      const salesData = [...this.state.salesData];
      // console.log("SALESDATA : ", salesData);
      let selected = salesData.filter((sales) => sales.name===salesName);
      console.log("FINDSELECTION : ", selected);

      if(selected.length > 0){
          let newData = JSON.parse(JSON.stringify(selected[0]));
          
          newData.lavenderSalesId = selected.id;
 
          this.setState({
            selectedSales: newData,
            lavenderSalesId: selected[0].id,
            lavenderSalesName: selected[0].name,
          });
          console.log(selected[0].id);
          console.log(selected[0].name);
          

          // console.log(this.state.lavenderSalesId );
          // console.log(this.state.lavenderSalesName);
      }

  }

  editSales = (id, salesId, salesName, salesType, allowReceiveLeads, isNightShift) => {
    // this.state.id = id;
    // this.state.lavenderSalesId = salesId;
    // this.state.lavenderSalesName = salesName;
    // this.state.salesType = salesType;
    // this.state.allowReceiveLeads = allowReceiveLeads;

    // console.log(this.state.id);
    // console.log(this.state.lavenderSalesId );
    // console.log(this.state.lavenderSalesName);
    // console.log(this.state.salesType);

    this.setState({ id: id, lavenderSalesId: salesId, lavenderSalesName: salesName, salesType: salesType, allowReceiveLeads: allowReceiveLeads, isNightShift: isNightShift, bottom: true });
    
  }

  addSales = () => {
    this.setState({ id: 0, lavenderSalesId: 0, lavenderSalesName: "", salesType: "Acquisition", allowReceiveLeads: 1, bottom: true });
  }


  cancel(){
    this.setState({ ...this.state, 'bottom': false });
  }

  async handleSubmit(event) {
    event.preventDefault();
      
    if(this.state.id === ''){
      this.state.id = '0';
    }else{
      // this.state.id = '0';
    }

    console.log("id--> "+this.state.id);
    console.log("selectedSales--> "+this.state.lavenderSalesId);
    console.log("selectedSalesname--> "+this.state.lavenderSalesName);
    console.log("salesType--> "+this.state.salesType);
    console.log("appProfileId--> ", Global.getAppProfile()._id);
    
    var params = [
      {
        id: this.state.id, 
        lavenderSalesId: this.state.lavenderSalesId, 
        lavenderSalesName: this.state.lavenderSalesName, 
        salesType: this.state.salesType, 
        allowReceiveLeads: this.state.allowReceiveLeads,
        appProfileId: Global.getAppProfile()._id,
        isNightShift: this.state.isNightShift
      }
    ];

    axios
      .post(Api.doSaveSales(), params, 
        Api.getRequestHeader())
      .then((response) => {
        console.log("doSaveSales : ", response.data);
        let responseData = response.data;
        if(responseData.success){
            console.log(responseData);
            // this.props.history.push('/setting');
            this.getSaleses();
            // return;
            
            this.setState({ ...this.state, 'bottom': false });
        }
        // this.setState({contacts: response.data.data});
        
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  handleRemoveSales = () => {
      console.log(this.state.idDelete);
      console.log(this.state.idSalesDelete);

    axios
    .post(Api.deleteSales(), {id: this.state.idDelete, lavenderSalesId: this.state.idSalesDelete}, Api.getRequestHeader())
    .then((response) => {
      console.log("doSaveSales : ", response.data);
      let responseData = response.data;
      if(responseData.success){
          console.log(responseData);
          this.getSaleses();
          this.handleCloseLogoutDialog();
      }
      
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
  }

  handleRemoveSalesDialog = (id, lavenderSalesId, lavenderSalesName) => {

    this.setState({ showDeleteSalesLavenderDialog: true,
                    idDelete: id,
                  idSalesDelete: lavenderSalesId,
                   nameSalesDelete: lavenderSalesName});


  }

  handleSwitchLeads = () => {
    let allowLeads = 0;
    if(this.state.allowReceiveLeads === 1){
      allowLeads = 0;
    }else{
      allowLeads = 1;
    }

    this.setState({allowReceiveLeads: allowLeads});

  }

  handleSwitchNightShift = () => {
    let isNightShift = false;
    if(this.state.isNightShift === true){
      isNightShift = false;
    }else{
      isNightShift = true;
    }

    this.setState({isNightShift: isNightShift});

  }

  handleCloseLogoutDialog = () => {
    this.setState({ showDeleteSalesLavenderDialog: false });
  };
    
    render() { 

      const { sales  } = this.state;

      const customerList = sales.map(saless => {
        return <tr key={saless.id}>
            {/* <td style={{whiteSpace: 'nowrap'}}>{saless.id}</td> 
            <td style={{whiteSpace: 'nowrap'}}>{saless.lavenderSalesId}</td>  */}
            <td style={{whiteSpace: 'nowrap'}}>{saless.lavenderSalesName}</td> 
            <td style={{whiteSpace: 'nowrap'}}>{saless.salesType}</td> 
            <td style={{whiteSpace: 'nowrap'}}>{saless.allowReceiveLeads === 1 ? "ON" : "OFF"}</td> 
            <td style={{whiteSpace: 'nowrap'}}>{saless.isNightShift === true ? "YES" : "NO"}</td> 
            <td>
            <ButtonGroup>
                <button onClick={ () => this.editSales(saless.id, saless.lavenderSalesId, saless.lavenderSalesName, saless.salesType, saless.allowReceiveLeads, saless.isNightShift)} className="btn btn-info">Edit </button>
                
                {/* <Button size="sm" color="danger" onClick={() => this.handleRemoveSales(saless.id, saless.lavenderSalesId)}>Delete</Button> */}
                <Button size="sm" color="danger" onClick={() => this.handleRemoveSalesDialog(saless.id, saless.lavenderSalesId, saless.lavenderSalesName)}>Delete</Button>
            </ButtonGroup>
            </td>
        </tr>
    });

      const toggleDrawer = (anchor, open) => (event) => {
        
        // console.log('this.state.id=> '+this.state.id);
        // if(this.state.id === 0){
        //   // this.setState({
        //     this.state.salesType = 'Acquisition';
        //     this.state.allowReceiveLeads = 1;
        //     // }); 
        // }

        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }

         
        // if(open === false){
          
        //   this.state.id = 0;
        //   this.state.lavenderSalesName = '';
        //   // if(this.state.salesType === ''){
        //     // this.state.salesType = 'Acquisition';
        //   // }else{
        //     this.state.salesType = '';
        //   // }

        //   // this.setState({ id: 0, lavenderSalesName: "", salesType: "", [anchor]: open });

        // }else{
        //   if(open === true && this.state.id === ''){
        //     // this.setState({
        //       this.state.salesType = 'Acquisition';
        //       // [anchor]: open
        //       // }); 
        //   }
        // }

      
        this.setState({[anchor]: open });
      };
 

        return (
          <Paper style={{padding: 20}}>
            <div>
              {/* <AppNavbar/> */}

              <div>

                <Modal
                  show={this.state.showDeleteSalesLavenderDialog}
                  onHide={this.handleCloseLogoutDialog}
                  backdrop="static"
                  keyboard={false}>
                  <Modal.Header closeButton>Konfirmasi</Modal.Header>
                  <Modal.Body>Yakin akan menghapus  {this.state.nameSalesDelete}?</Modal.Body>
                  <Modal.Footer>
                    <span onClick={this.handleRemoveSales} className="btn btn-danger">
                      Ya  
                    </span>
                    <span
                      onClick={this.handleCloseLogoutDialog}
                      className="btn btn-warning" >
                      Batalkan
                    </span>
                  </Modal.Footer>
                </Modal>
                
                  <React.Fragment key={'bottom'}>
                    
                    <Drawer width='40%' anchor={'right'} open={this.state['bottom']} onClose={toggleDrawer('bottom', false)}>
                      {/* {list(anchor)} */}
                      <div 
                        role="presentation"
                        // onClick={toggleDrawer('bottom', false)}
                        // onKeyDown={toggleDrawer('bottom', false)}
                      >
                        
                        <div className="col-12 text-left" style={{paddingTop: 8,}}>
                          <Container>
                              <h1>Input Salesman</h1>
                              <hr/>
                              <Form onSubmit={this.handleSubmit}>
                              
                              <FormGroup>
                                  <Label for="firstname">Nama Sales</Label>
                              {(this.state.salesData && this.state.salesData.length > 0) ? 
                                  <div class="col-12">
                                  <Autocomplete
                                      id="free-solo-2-demo"
                                      // disableClearable
                                      freeSolo 
                                      options={this.state.salesData.map((option) => {
                                          // console.log("LABEL : ", option);
                                          return String(option.name);
                                      })}
                                      
                                      onChange={(event, newValue) => {
                                          // console.log("NEWVALUE : ", event);
                                          this.handleSelectionChanged(newValue);
                                      }} 
                                      // value={this.state.contactData.lavenderSalesName}
                                      value={this.state.lavenderSalesName}
                                      renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          label="Sales"
                                          // margin="0"
                                          variant="outlined"
                                          InputProps={{ ...params.InputProps, type: 'search' }}
                                      />
                                      )}
                                  />
                              </div>
                              : 
                              <div></div>
                              }
                              </FormGroup>
                              
                              <FormGroup variant="outlined">
                                  <Label for="lastname">Sales tipe     </Label>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={this.state.salesType}
                                      onChange={this.handleSalesTypeChange}
                                      label="Tipe Sales">
                                      <MenuItem value={"Acquisition"}>Acquisition</MenuItem>
                                      <MenuItem value={"Retention"}>Retention</MenuItem>
                                  </Select>
                              </FormGroup> 
                              
                              <FormGroup variant="outlined">
                                  <FormControlLabel
                                    label= {this.state.allowReceiveLeads === 1 ? "Auto Leads (ON)" : "Auto Leads (OFF)"}
                                    control={
                                      <Switch
                                        checked={this.state.allowReceiveLeads === 1}
                                        onChange={this.handleSwitchLeads}
                                        name="switchLeads"
                                        // color="primary"
                                      />
                                    }
                                    
                                  />
                              </FormGroup> 
                                    
                              <FormGroup variant="outlined">
                                  <FormControlLabel
                                    label= {this.state.isNightShift === true ? "Night Shift (ON)" : "Night Shift (OFF)"}
                                    control={
                                      <Switch
                                        checked={this.state.isNightShift === true}
                                        onChange={this.handleSwitchNightShift}
                                        name="switchNightShift"
                                        // color="primary"
                                      />
                                    }
                                    
                                  />
                              </FormGroup> 
                                      
                              <br />
                              
                              <FormGroup>
                                  <div className="row">
                                      <div className="col-6">
                                          <Button className="btn-block" color="primary" type="submit">Save</Button>
                                      </div>
                                      <div className="col-6">
                                          <Button className="btn-block" color="secondary" onClick={() => this.cancel()} >Cancel</Button>
                                      </div>
                                  </div>
                              </FormGroup>
                              </Form>
                          </Container>
                            
                          </div>
                      </div>
                          
  

                    </Drawer>
                  </React.Fragment>
                
              </div>

              {/* <TemporaryDrawer/> */}
          
              <Container fluid> 
                <div className="float-right"> 
                  {/* <Button color="success" tag={Link} to="/addsales">Add Sales</Button> &nbsp; */}
                  <Button color="success" onClick={this.addSales}>Add Sales </Button>
                </div>
                <div>
                  <h3>Sales List</h3>
                </div>
                <Table className="mt-4">
                  <thead>
                    <tr>
                      {/* <th width="20%">Id</th>
                      <th width="20%">Sales Id</th>  */}
                      <th width="20%">Sales Name</th> 
                      <th width="20%">Sales Type</th> 
                      <th width="10%">Auto Leads</th> 
                      <th width="10%">Night Shift</th> 
                      <th width="10%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>

                  {(this.state.sales && this.state.sales.length > 0) ? 
                    customerList
                  : <div></div>}
                  </tbody>
                </Table>
              </Container>
            </div>
          </Paper>
        );

    }
}

export default SalesManagement;
