import { TextField } from '@mui/material';
import React, { Component } from 'react';

class TemplateParamsInput extends Component {
    state = { 
        paramValue: ""
     }

    handleParamValueChange = (event) => {
        this.setState({ paramValue: event.target.value }, ()=>{console.log(this.state.paramValue)});
    };



    render() { 
        return ( 
            <div style={{paddingTop: 15}}>
                <TextField
                    onChange={this.handleParamValueChange}
                    label={"Parameter " +  this.props.paramData.paramLabel}
                    type="text"
                    className="form-control"
                    id={this.props.templateData.name  + this.props.paramData.paramIndex}
                    variant="outlined"
                    onBlur={() => {
                        this.props.onUpdateData(
                            this.props.templateData.id,
                            this.props.paramData.paramIndex,
                            this.state.paramValue
                        )
                    }}
                    disabled={this.props.disabled}
                />
                <div className="form-group">
                    
                </div>
            </div>
         );
    }
}
 
export default TemplateParamsInput;