import { TextField, CircularProgress, Snackbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Search, ErrorOutline} from '@mui/icons-material';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import ExportHistoryPerubahanLabel from './ExportHistoryPerubahanLabel';

// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from 'chart.js';
// import { Bar } from 'react-chartjs-2';

class HistoryPerubahanLabel extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 0,
        rowsPerPage: 200,
        showAddForm: false,
        keyword: "",
        showEditAnalisa: false,
        obEditContact: null,
        savingAnalisa: false,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        // startDate: "2021-04-01",
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        isLoading: false,
        currentConversation: null,
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate, endDate: formatStartDate}, () => this.getMyCustomerToday(true));
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    getMyCustomerToday = (replaceAll) => {

        this.setState({isLoading: true, listMycustomer: []}, ()=>{
            var userData = Global.get();
            const params = {
                // startDate: "2024-08-01",
                // endDate: "2024-08-31",
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                appProfileId: Global.getAppProfile()._id,
                reportMode: "sales-leads-report"
            }

            console.log("PARAMS", params, "replaceAll", replaceAll);

            axios
            // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
            .post(Api.historyPerubahanLabelByLeads(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("RIWAYAT_LABEL : ", response.data.data);
                let data = response.data.data;

                data.forEach(element => {
                    element.createdAtFormatted = Global.formatDateTimeToStrDateTime24(element.created_at);
                    element.lastMessageReceivedAtFormatted = Global.formatUnixTimeStampsToStrDateTime24(element.lastMessageTime);
                    
                    let lastLabelTime = null;
                    let firstLabel = "";

                    if(element.logs.length <= 0){
                        element.lastLabel = element.currentLabel;
                        element.currentLabel = element.currentLabel;
                        lastLabelTime = moment(element.created_at);
                    }else if(element.logs.length === 1){
                        element.lastLabel = element.logs[0].label;
                        element.currentLabel = element.logs[0].label;
                        lastLabelTime = moment(element.logs[0].created_at);
                    }else if(element.logs.length === 2){
                        element.lastLabel = element.logs[1].label;
                        element.currentLabel = element.logs[0].label;
                        lastLabelTime = moment(element.logs[1].created_at);
                    }

                    element.lastLabelAt = lastLabelTime.tz("asia/jakarta").format("DD-MM-Y HH:mm:ss");;
                    var duration = moment.duration(lastLabelTime.diff(moment(element.created_at)));
                    let delta = duration.asSeconds();
                    element.delta = delta <= 0 ? "---" : this.formatTime(delta);
                });

                this.setState({listMycustomer: data, isLoading: false});
                
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    formatTime = (seconds) => {
        var duration = moment.duration(seconds, 'seconds');
        var formatted = duration.format("hh:mm:ss");
        return seconds < 60 ? "00:"+formatted : formatted;
    }

    handleShowSnackbar = () => {
        this.setState({snackbarVisible: true});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, errorMessage: ""});
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.getMyCustomerToday(true)});
      }

    componentDidMount() {
        console.log(Global.get());
        this.getMyCustomerToday(true);
    }

    columns = [
        { id: 'fromName', label: 'Nama', minWidth: 170 },
        { id: 'from', label: 'No Customer', minWidth: 200 },
        { id: 'currentLabel', label: 'Label', minWidth: 200 },
        { id: 'createdAtFormatted', label: 'Tanggal Leads', minWidth: 200 },
        { id: 'lastMessageReceivedAtFormatted', label: 'Chat Terakhir', minWidth: 200 },
        { id: 'lastLabel', label: 'Label Akhir', minWidth: 200 },
        { id: 'lastLabelAt', label: 'Waktu Label Akhir', minWidth: 200 },
        { id: 'delta', label: 'Selang Waktu', minWidth: 200 }
    ];

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.setState({page: 0}, () => {
                this.getMyCustomerToday(true);
            });
        }
      };

    handleShowMessages = (conversation) => {
        this.setState({currentConversation: conversation});
    }

    getFieldClassName = (fieldName) => {
        let className = "badge";
        if(this.state.searchInField === fieldName){
          className += " badge-primary";
        }else{
          className += " badge-secondary";
        }
    
        return className;
      }
    
    handleFieldSelectionChanged = (fieldName) => {
        this.setState({searchInField: fieldName}, () => {
            this.getMyCustomerToday(true);
        });
    }

    handleAppTypeChanged = (event) => {
        this.setState({filterAppNumber: event.target.value}, ()=>{
          this.getMyCustomerToday(true);
        });
      }

    render() { 
        return Global.get().admin !== true ? (
            <div className='container text-center' style={{marginTop: 100}}>
                <ErrorOutline style={{width: 200, height: 200}} className="text-danger" />
                <h2>Lotus</h2>
                <h3>Permission denied</h3>
                <Link style={{textDecoration: "underline", width: '100%'}} to="/">
                    <div className="btn btn-warning">Back</div>
                </Link>
            </div>
        ) : ( 
            <div className="containe-fluid" style={{padding: "0px 20px 20px 20px"}}>
                <div className="row">
                    <div className="col-6" style={{paddingTop: 25}}>
                        <h4>Riwayat Perubahan Label</h4>
                    </div>
                    <div className="col-6">
                        <div className="row"  style={{marginTop: 10, marginBottom: 0}}>
                            <div className="col-8 text-right" style={{paddingTop: 15}}>
                            {
                                Global.get().admin === true ? <ExportHistoryPerubahanLabel leadsReportData={this.state.listMycustomer} /> : <></>
                            }
                            </div>
                            <div className="col-3 text-right" style={{borderRadius: 10, padding: 5}}>
                                <TextField
                                    id="dateStart"
                                    label="Tanggal Leads"
                                    type="date"
                                    defaultValue={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            
                            <div className="col-1">
                                {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getMyCustomerToday(true)}} className="btn btn-primary" style={{padding: 15}}><Search /></div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Paper>
                    <TableContainer style={{maxHeight: 440}}>
                        <Table aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {this.columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.listMycustomer.map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                {this.columns.map((column) => {
                                    // const value = row[column.id];
                                    // const value = Global.isSales() && column.id === "from" ? row[column.id].slice(0, -5) + Array(5 + 1).join("x") : row[column.id];
                                    const value = column.id === "from" ? Global.maskPhoneNumber(row[column.id]) : row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.format && typeof value === 'number' ? column.format(value) : value}        
                                        </TableCell>
                                    )
                                    
                                })}
                                </TableRow>
                            );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />
            </div>
        );
    }
}
 
export default HistoryPerubahanLabel;

function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }