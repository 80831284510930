import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import {Paper, Tabs, Tab, Typography, Box} from '@mui/material';
import { Link } from 'react-router-dom';
import Global from "../../customlib/Global";
import DataCancelDashboard from '../DataPending/DataCancelDashboard';
import PerformaSalesByLabel from './PerformaSalesByLabel';
import PerformaCancelSales from './PerformaCancelSales';
import WeeklyPersenCancel from './WeeklyPersenCancel';
import Weekly from './Weekly';
import CancelByJam from './CancelByJam';
import ClosingAndAvgResponse from './ClosingAndAvgResponse/ClosingAndAvgResponse';
import SummaryDatabaseDataCancel from './SummaryDatabaseDataCancel';
import ContactLeadsReportHourly from '../ContactLeadsReport/ContactLeadsReportHourly';
import LeadsPerSections from './LadsPerSections';
import DataCancelDashboardNew from '../DataPending/DataCancelDashboardNew';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class DashboardPerformaSales extends Component {

    state = { 
        value : 0,
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1
        },
      });

    // classes = useStyles();
    // const [value, setValue] = React.useState(0);

    componentDidMount() {
        this.setState({value: 0});
    }
    

    handleChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };

    render() { 
        return ( 
            <>
            <Paper className={this.useStyles.root} style={{margin: "10px", padding: "5px", backgroundColor: "#e7eef4"}}>
                <div className="container-fluid text-center">
                  <div className="row">
                      <div className="col-11 text-left" style={{paddingLeft: "30px"}}><h1>Dashboard Performa Sales</h1></div>
                      <div className="col-1 text-right">
                          <Link to="/">
                              <div className="btn btn-danger">X</div>
                          </Link>
                      </div>
                  </div>
                </div>
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    variant='scrollable'
                >
                    <Tab key="tab0" label="Issue Leads" wrapped />
                    <Tab key="tab1" label="Closing &amp; Avg Response" wrapped />
                    <Tab key="tab2" label="Performa Sales By Label" wrapped />
                    {/* <Tab key="tab2" label="Performa Cancel By Sales" wrapped /> */}
                    <Tab key="tab3" label="Weekly % Cancel" wrapped />
                    <Tab key="tab4" label="Weekly" wrapped />
                    <Tab key="tab5" label="Data Cancel" wrapped />
                    <Tab key="tab6" label="Detail Leads" wrapped />
                    <Tab key="tab7" label="Leads Per Sections" wrapped />
                    {/* <Tab key="tab2" label="Cancel By Jam" wrapped /> */}
                </Tabs>
            </Paper>
            <TabPanel value={this.state.value} index={0}>
                <DataCancelDashboardNew />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
                <ClosingAndAvgResponse />
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
                <PerformaSalesByLabel />
            </TabPanel>
            {/* <TabPanel value={this.state.value} index={3}>
                <PerformaCancelSales />
            </TabPanel> */}
            <TabPanel value={this.state.value} index={3}>
                <WeeklyPersenCancel />
            </TabPanel>
            <TabPanel value={this.state.value} index={4}>
                <Weekly />
            </TabPanel>
            <TabPanel value={this.state.value} index={5}>
                <SummaryDatabaseDataCancel />
            </TabPanel>
            <TabPanel value={this.state.value} index={6}>
                <ContactLeadsReportHourly />
            </TabPanel>
            <TabPanel value={this.state.value} index={7}>
                <LeadsPerSections />
            </TabPanel>
            {/* <TabPanel value={this.state.value} index={6}>
                <CancelByJam />
            </TabPanel> */}
            </>
         );
    }
}
 
export default DashboardPerformaSales;