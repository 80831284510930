import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, TableFooter, Tooltip } from '@mui/material';
import ExportLeadsReport from './ExportLeadsReport';
import Global from "../../customlib/Global";
import DateRangePicker from "../../customlib/DateRangePicker";


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography,LinearProgress} from '@mui/material';

import 'moment-duration-format';
import { Refresh, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, QuestionAnswer } from '@mui/icons-material';
import MessageListViewer from '../MessageListViewer';

class SalesResponseTimeReportFirstTime extends Component {
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        isLoading: true,
        avgAll: 0, 
        appProfileId: "",
        dateRangeValue: [null,null],
        currentConversation: null
    }
    
    componentDidMount() {
      this.setState(
        {appProfileId: Global.getAppProfile()._id}, ()=>{
          this.getContactLeads();
        }
      );
    }

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();

        this.setState({isLoading: true}, () => {
          axios
          .post(Api.salesResponseTimeReport(), {
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              appProfileId: this.state.appProfileId
          }, Api.getRequestHeader())
          .then((response) => {
            console.log("RESPONSTIMEREPORT : ", response.data);
            
            var tmpContactLeads = response.data.data;

            if(userData.admin === false){
              tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

            var totalAvgResponseTime = 0;
            
            tmpContactLeads.forEach(lead => {
                

                let totalResponseTime = 0;
                let totalUnderOneMinute = 0;
                let totalHigherOneMinute = 0
                var cleanedUpLeads = [];
                lead.leads.forEach(item => {
                  var delta = 0;
                  if(item.firstResponse != null){
                    var firstMessageTime = moment(item.createdAt).tz("asia/jakarta"); //todays date
                    var firstResponseTime = moment(item.firstResponse).tz("asia/jakarta"); // another date
                    
                    var duration = moment.duration(firstResponseTime.diff(firstMessageTime));
                    delta = duration.asSeconds();
                    totalResponseTime += delta < 0 ? 0 : delta;
                  }else{
                    delta = 24 * 3600;
                  }
                  item.responseTime = delta;

                  const newData =  {
                    photo: "https://picsum.photos/seed/picsum/200",
                    fromName: `${item.fromName}`,
                    text: item.lastMessage,
                    fromNumber: item.from,
                    contactId: item.id,
                    toNumber: item.to,
                    toName: item.toName,
                    selected: true,
                    unreadCounter: item.unreadCounter,
                    assignToUserName: item.assignToUserName,
                    contactLabel: item.label
                  };
                  item.contactFormatted = newData;

                  if(delta <= 60){
                    totalUnderOneMinute += 1;
                  }else{
                    totalHigherOneMinute += 1;
                  }

                  // if(delta >= 0){
                    cleanedUpLeads.push(item);
                  // }

                });

                lead.leads = cleanedUpLeads;

                lead.leadsCount = lead.leads.length;
                let avgResponseTime = totalResponseTime/lead.leadsCount;
                lead.avgResponseTime = avgResponseTime;
                totalAvgResponseTime += avgResponseTime;
                lead.totalUnderOneMinute = totalUnderOneMinute;
                lead.totalHigherOneMinute = totalHigherOneMinute;

            });
            console.log("RESPONSETIMEREPORT", tmpContactLeads);

            var avgAll = totalAvgResponseTime/tmpContactLeads.length;

            this.setState({contactLeads: tmpContactLeads, avgAll: avgAll, isLoading: false});
          })
          .catch((error) => {
            this.setState({isLoading: false});
            console.error("There was an error!", error);
          });
        });

        
      };

    handleShowMessages = (conversation) => {
      // console.log("CONVER", conversation);
      this.setState({currentConversation: conversation});
    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    formatTime = (seconds) => {
      var duration = moment.duration(seconds, 'seconds');
      var formatted = duration.format("hh:mm:ss");
      return seconds < 60 ? formatted : "00:"+formatted;
    }

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah Leads', minWidth: 100 },
        { id: 'avgResponseTime', label: 'Avg Response Time', minWidth: 100 },
        { id: 'totalUnderOneMinute', label: '< 1 Menit', minWidth: 100 },
        { id: 'totalHigherOneMinute', label: '> 1 Menit', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        return ( 
            <Paper style={{padding:20}}>

            {
                this.state.currentConversation !== null ? (
                    <Dialog
                        open={this.state.currentConversation !== null}
                        // TransitionComponent={Transition}
                        // keepMounted
                        onClose={() => {this.setState({currentConversation: null})}}
                        aria-describedby=""
                    >
                        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                        <DialogContent style={{height: "100%"}}>
                            <MessageListViewer currentConversation={this.state.currentConversation} style={{width: 500}} />
                        </DialogContent>
                        <DialogActions>
                        {/* <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={handleClose}>Agree</Button> */}
                        </DialogActions>
                    </Dialog>
                ) : (<></>)
            }

              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row align-items-center">
                        <div className="col-3">
                                <strong>Total {this.state.contactLeads.length} Salesman</strong>
                            </div>
                            <div className="col-3">
                            {
                                Global.get().admin === true ? <ExportLeadsReport leadsReportData={this.state.contactLeads} /> : <></>
                            }
                            
                            </div>
                            <div className="col-3">
                              <DateRangePicker onDateChanged={this.handleDateChanged} />
                                {/* <span style={{marginTop: 5,}}><strong>Tanggal :</strong> &nbsp;</span>
                                
                                <TextField
                                    id="dateStart"
                                    // label="Tanggal"
                                    type="date"
                                    defaultValue={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> */}
                            </div>
                            <div className="col-2 text-center" style={{borderRadius: 10, padding: 5}}>
                              
                              {
                                this.state.isLoading ? 
                                (
                                  <>
                                    {/* <CircularProgress color="secondary" style={{width: 30, height: 30}} />  */}
                                    Sedang memuat
                                    <LinearProgress color="secondary" />
                                  </>
                                ) : 
                                (
                                  <div className="btn btn-primary" onClick={this.getContactLeads}>
                                    <Refresh /> Refresh
                                  </div>
                                )
                              }
                              
                              
                                
                                {/* &nbsp; <strong style={{marginTop: 5,}}>Sampai</strong> &nbsp;
                                <TextField
                                    id="dateEnd"
                                    // label="Tanggal"
                                    type="date"
                                    defaultValue={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> */}
                            </div>
                        </div>
                        <br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20px" />
                                    {this.columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.contactLeads.map((row) => {
                                    return (
                                        <Row handleShowMessages={this.handleShowMessages} key={row.salesId} row={row}  />
                                    );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                      {/* <TableCell style={{minWidth: 170}}></TableCell>
                                      <TableCell style={{minWidth: 100}}>Total Rerata : </TableCell> */}
                                      <TableCell style={{minWidth: 100}}>
                                        <strong>Total Rerata : {formatTime(this.state.avgAll)}</strong>
                                      </TableCell>
                                    </TableRow>
                                </TableFooter>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                component="div"
                                count={this.state.contactLeads.length + this.state.rowsPerPage}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            /> */}
                        </Paper>
                        {/* <br />
                        <div className="row">
                            <div className="col-4">
                                Sales
                            </div>
                            <div className="col-2">
                                Jumlah Leads
                            </div>
                            <div className="col-2">
                                Action
                            </div>
                        </div>
                        <hr />
                        {
                            this.state.contactLeads.map(
                                (contact) => (
                                    <ContactLeadsItem contactLeads={contact} />
                                )
                            )
                        } */}
                    </div>
                    <div className="panel-footer">
                        {/* <Link to="/">
                            <div className="btn btn-primary">Close</div>
                        </Link> */}
                    </div>
                </div>
              </div>
            </Paper>
         );
    }
}
 
export default SalesResponseTimeReportFirstTime;

const formatTime = (seconds) => {
  var duration = moment.duration(seconds, 'seconds');
  var formatted = duration.format("hh:mm:ss");
  return seconds < 60 ? "00:"+formatted : formatted;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell width="20px">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.salesName}
          </TableCell>
          <TableCell align="left">{row.leadsCount} leads</TableCell>
          <TableCell align="right">{formatTime(row.avgResponseTime)}</TableCell>
          <TableCell align="right">{row.totalUnderOneMinute}</TableCell>
          <TableCell align="right">{row.totalHigherOneMinute}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Leads Detail
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nama</TableCell>
                      {
                          // Global.get().admin === true ? <TableCell>Nomor WhatsApp</TableCell> : <></>
                      }
                      <TableCell>Nomor WhatsApp</TableCell>
                      <TableCell>Chat Pertama</TableCell>
                      <TableCell>Response Pertama</TableCell>
                      <TableCell>Delta</TableCell>
                      <TableCell>Lihat Chat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {Global.getAppProfile().appVendor === "VONAGE" && Global.isSales() ? historyRow.fromName.slice(0, -5) + Array(5 + 1).join("x") : historyRow.fromName}
                        </TableCell>
                        {
                            // Global.get().admin === true ? <TableCell>{historyRow.from}</TableCell> : <></>
                        }
                        <TableCell>{Global.maskPhoneNumber(historyRow.from)}</TableCell>
                        <TableCell align="left">{Global.formatDateTimeToStrDateTime24(historyRow.createdAt)}</TableCell>
                        <TableCell align="left">
                          {historyRow.firstResponse === null ? "Belum direspon" : Global.formatDateTimeToStrDateTime24(historyRow.firstResponse)}
                        </TableCell>
                        <TableCell align="right">
                          {formatTime(historyRow.responseTime < 0 ? 0 : historyRow.responseTime)}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Lihat Chat">
                              <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {props.handleShowMessages(historyRow.contactFormatted)}}  />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }