import { Card, CardContent, Typography, Snackbar, Tooltip as ToolTip, TextField, CircularProgress, FormControl, InputLabel, Select, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";

import { makeStyles } from '@mui/styles';
import {Search, QuestionAnswer, Edit} from '@mui/icons-material';
import ReactLoading from 'react-loading';
import moment from "moment-timezone";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from '../../customlib/DateRangePicker';

class FollowUpReport extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listMycustomer: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        page: 0,
        rowsPerPage: 40,
        showAddForm: false,
        keyword: "",
        showEditAnalisa: false,
        obEditHasilFu: null,
        savingAnalisa: false,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        // startDate: "2021-04-01",
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        isLoading: false,
        showFuConfirmationDialog: false,
        selectedContact: null,
        reportFuPerformance: [],
        reportPivotFu: [],
        isLoadingReport: false,
        selectedFu: null,
        showDetailFuDialog: false,
        showDetailOrderDialog: false
    }

    useStyles1 = makeStyles((theme) => (
            {
                root: {
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
                },
            }
        )
    );

    getSalesTeleUsers = () => {
        axios
        .post(Api.getSalesTeleUserList(), {}, Api.getRequestHeader())
        .then((response) => {
            console.log("SALESTELEUSER : ", response.data.data);
            this.setState({listSales: response.data.data});
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    handleEditOnClick = (contactData) => {
        const myCustomers = [...this.state.listMycustomer];

        var findCustomer = myCustomers.filter(customer => customer.id === contactData.id);

        if(findCustomer.length > 0){
            findCustomer[0].isEditing = !findCustomer[0].isEditing;
            findCustomer[0].assignToUser = contactData.assignToUser;
            findCustomer[0].assignToUserName = contactData.assignToUserName;
        }

        this.setState({listMycustomer: myCustomers});

    }

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getMyCustomerToday);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getMyCustomerToday);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    getMyCustomerToday = (replaceAll) => {

        this.setState({isLoading: true}, ()=>{
            var userData = Global.get();
            const params = {
                userId: userData.user.id,
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                keywords: this.state.keyword,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                appProfileId: userData.appProfile._id
            }

            console.log("PARAMS", params, "replaceAll", replaceAll);

            axios
            // .post(Api.getMyCustomerToday(), {userId: userData.user.id}, Api.getRequestHeader())
            .post(Api.getDataPendingFuNext(), params, Api.getRequestHeader())
            .then((response) => {
                console.log("MyCustomerToday : ", response.data.data);

                // let shuffledArray = response.data.data.sort((a, b) => 0.5 - Math.random());
                let shuffledArray = response.data.data;

                if(replaceAll === true){

                    var customers = shuffledArray;
                    customers.forEach(customer => {
                        const newData =  {
                            photo: "https://picsum.photos/seed/picsum/200",
                            fromName: `${customer.fromName}`,
                            text: customer.lastMessage,
                            fromNumber: customer.from,
                            contactId: customer.id,
                            toNumber: customer.to,
                            toName: customer.toName,
                            selected: true,
                            unreadCounter: customer.unreadCounter,
                            assignToUserName: customer.assignToUserName,
                            contactLabel: customer.label
                        };
                        
                        customer.isEditing = false;
                        customer.lastFuResultTmp = customer.lastFuResult;
                        customer.contactFormatted = newData;
                    });

                    this.setState({listMycustomer: customers, isLoading: false});
                }else{
                    let myCustomers = [...this.state.listMycustomer];

                    shuffledArray.forEach(customer => {
                        // console.log(element);
                        const newData =  {
                            photo: "https://picsum.photos/seed/picsum/200",
                            fromName: `${customer.fromName}`,
                            text: customer.lastMessage,
                            fromNumber: customer.from,
                            contactId: customer.id,
                            toNumber: customer.to,
                            toName: customer.toName,
                            selected: true,
                            unreadCounter: customer.unreadCounter,
                            assignToUserName: customer.assignToUserName,
                            contactLabel: customer.label
                        };
                        
                        customer.isEditing = false;
                        customer.contactFormatted = newData;
                        customer.lastFuResultTmp = customer.lastFuResult;
                        myCustomers.push(customer);
                    });

                    this.setState({listMycustomer: myCustomers, isLoading: false});
                }
                            
            })
            .catch((error) => {
                this.setState({isLoading: false});
                console.error("There was an error!", userData.user.id, error);
            });
        });

    };

    getWaMessages = (contactData) => {
        console.log("CONTACTDATA : ", contactData);
        if(contactData.fromNumber === ""){
          return;
        }
    
        this.setState({isLoading: true}, ()=>{
          const params = {
            from: contactData.fromNumber,
          };
          console.log("PARAMSWAMESSAGE", params);
      
          axios
            .post(Api.messagesList(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_MESSAGES",response.data);
              
              const responseData = response.data;
                
              const clonedMyCust = [...this.state.listMycustomer];
              let myCust = clonedMyCust.filter((cust) => cust.from===contactData.fromNumber);

              if(myCust.length > 0){
                  myCust[0].messages = responseData.data;
              }

              this.setState({listMycustomer: clonedMyCust});
              
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoading: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    getReportFu = () => {

        this.setState({isLoadingReport: true}, ()=>{
          const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            userId: Global.get().user.id
          };
          console.log("PARAMSREPORTFU", params);
      
          axios
            .post(Api.getDataPendingReportFu(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_REPORTFU",response.data);
              this.setState({reportFuPerformance: response.data.data, isLoadingReport: false}, () => {
                this.getReporPivotFu();
              });
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoadingReport: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    getReporPivotFu = () => {
        // return;
        this.setState({isLoadingReport: true}, ()=>{
          const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate, 
            appProfileId: Global.getAppProfile()._id,
            userId: Global.get().user.id
          };
          console.log("PARAMSREPORTPIVOTFU", params);
      
          axios
            .post(Api.datapendingPivotTable(), params, Api.getRequestHeader())
            .then((response) => {
              console.log("YOUR_REPORTFU_PIVOT",response.data);
              this.setState({reportPivotFu: response.data.data, isLoadingReport: false});
            })
            .catch((error) => {
              this.setState({ errorMessage: error.message, isLoadingReport: false });
              console.error("There was an error!", error);
            });
        });
    
        
      };

    handleSelectionChanged = (salesName) => {
        const salesData = [...this.state.listSales];
        let selected = salesData.filter((sales) => sales.name===salesName);
        // console.log("FINDSELECTION : ", selected);

        if(selected.length > 0){

            let newData = JSON.parse(JSON.stringify(selected[0]));

            this.setState({selectedSales: newData},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            });            
            
        }else{
            this.setState({selectedSales: {id: 0, name: ""}},()=>{
                console.log("SELECTEDSALES", this.state.selectedSales);
            }); 
        }

    }

    handleShowSnackbar = () => {
        this.setState({snackbarVisible: true});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, errorMessage: ""});
    }

    handleEditAnalisa = (contact) => {
        console.log("EDIT", contact);
        this.setState({obEditHasilFu: contact, showEditAnalisa: true});
    }

    saveAnalisa = () => {
        this.setState({savingAnalisa: true}, ()=>{
            const params = {
              contactId: this.state.obEditHasilFu.id,
              hasilFu: this.state.obEditHasilFu.lastFuResultTmp,
              source: this.state.obEditHasilFu.source,
              countFu: this.state.obEditHasilFu.countFuTmp,
              salesId: Global.get().user.id,
              totalOrder: Number(this.state.obEditHasilFu.totalOrder),
              salesName: Global.get().user.name,
              editLastFu: true
            };
            console.log("saveHasilFu", params);
            // return;
            axios
              .post(Api.saveHasilFu(), params, Api.getRequestHeader())
              .then((response) => {
                console.log("YOUR_MESSAGES",response.data);
                
                const responseData = response.data;
                
                if(responseData.success === true){
                    const myCustomers = [...this.state.listMycustomer];
                    var findCustomer = myCustomers.filter(customer => customer.id === responseData.data.id);

                    if(findCustomer.length > 0){
                        findCustomer[0].lastFuDate = responseData.data.lastFuDate;
                        findCustomer[0].lastFuResult = responseData.data.lastFuResult;
                        findCustomer[0].fuData = responseData.data.fuData;
                        findCustomer[0].lastFuResultTmp = null;
                    }

                    this.setState({listMycustomer: myCustomers, showEditAnalisa: false, savingAnalisa: false, obEditHasilFu: null, snackbarMessage: responseData.message}, () => {
                        this.handleShowSnackbar();
                    });

                }else{
                    this.setState({ snackbarMessage: responseData.message, savingAnalisa: false }, () => {
                        this.handleShowSnackbar();
                    });
                }
                
              })
              .catch((error) => {
                this.setState({ snackbarMessage: error.message, savingAnalisa: false }, () => {
                    this.handleShowSnackbar();
                });
                console.error("There was an error!", error);
              });
          });
    }

    handleDateChanged = (rangeDate) => {

        let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.getReportFu(); this.getReporPivotFu()});
      }

    componentDidMount() {
        // console.log(Global.get());
        this.getMyCustomerToday();
        this.getReportFu();
        // this.getReporPivotFu();
        this.getSalesTeleUsers();
    }

    columns = [
        // {
        //     id: 'action',
        //     label: 'Action',
        //     minWidth: 130,
        //     align: 'center',
        // },
        // { id: 'source', label: 'Dari', minWidth: 170 },
        { id: 'assignToUserName', label: 'Sales', minWidth: 60 },
        { id: 'fromName', label: 'Customer', minWidth: 60 },
        { id: 'from', label: 'No WA', minWidth: 60 },
        { id: 'productCategory', label: 'Kategori', minWidth: 60 },
        { id: 'lastFuDate', label: 'Last FU', minWidth: 60 },
        { id: 'nextFuDate', label: 'Next FU', minWidth: 60 },
        { id: 'fuData', label: 'Jumlah FU', minWidth: 60 },
        { id: 'lastFuResult', label: 'Hasil FU', minWidth: 60 },
        // {
        //     id: 'population',
        //     label: 'Population',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //     id: 'size',
        //     label: 'Size\u00a0(km\u00b2)',
        //     minWidth: 170,
        //     align: 'right',
        //     format: (value) => value.toLocaleString('en-US'),
        // },
    ];

    columnsReportFu = [
        { id: 'no', label: 'No', minWidth: 50 },
        { id: 'salesName', label: 'Nama User', minWidth: 170 },
        { id: 'totalFu', label: 'Total FU', minWidth: 170, align: "right" },
        { id: 'totalCustRegister', label: 'Total Register', minWidth: 170, align: "right" },
        { id: 'totalCustOrder', label: 'Total Cust Order', minWidth: 170, align: "right" },
        { id: 'totalOrder', label: 'Total Pembelian', minWidth: 170, align: "right" },
        { id: 'rataRata', label: 'Rata-rata', minWidth: 170, align: "right" },
        { id: 'closingDatabase', label: 'Closing Database', minWidth: 170, align: "right" }
    ];
    
    columnsPivotFu = [
        { id: 'sales_name', label: 'User', minWidth: 170},
        { id: 'jumlah_fu', label: 'Jumlah FU', minWidth: 60},
        { id: 'respon', label: 'Respon', minWidth: 60, align: "center"  },
        { id: 'respon_tertarik', label: 'Respon Tertarik', minWidth: 60, align: "center" },
        { id: 'register', label: 'Register', minWidth: 60, align: "center"  },
        { id: 'order', label: 'Order', minWidth: 60, align: "center"  },
        { id: 'menolak', label: 'Menolak', minWidth: 60, align: "center"  },
        { id: 'no_invalid', label: 'No Invalid', minWidth: 60, align: "center"  },
        { id: 'download_apps', label: 'Download Apps', minWidth: 60, align: "center"  }
    ];



    handleChangePage = (event, newPage) => {
        console.log("NEWPAGE : ", newPage);

        var isNavigateToNewPage = newPage >= this.state.listMycustomer.length/this.state.rowsPerPage;

        this.setState({page: newPage}, () => {if(isNavigateToNewPage){this.getMyCustomerToday();}});
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };

    handleCloseFormAddContact = () => {
        this.setState({showAddForm: false});
    }

    handleKeyword = (event) => {
        this.setState({ keyword: event.target.value });
    };

    handleCloseEditAnalisa = () => {
        this.setState({showEditAnalisa: false, obEditHasilFu: null, errorMessage: ""});
    }

    handleHasilFuChanged = (event) => {
        
        // const editingContact = JSON.parse(JSON.stringify(this.state.obEditHasilFu));
        const editingContact = this.state.obEditHasilFu;

        editingContact.lastFuResultTmp = event.target.value;
        console.log("lastFuResultTmp", editingContact.lastFuResultTmp);
        this.setState({ obEditHasilFu: editingContact });
    };

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.setState({page: 0}, () => {
                this.getMyCustomerToday(true);
            });
        }
      };

    handleCloseFuConfirmationDialog = () => {
        this.setState({showFuConfirmationDialog: false, selectedSales: null});
    }

    handleShowFuConfirmationDialog = (contact) => {
        console.log("CONTACT: ", contact);
        this.setState({showFuConfirmationDialog: true, selectedSales: contact}, () => {console.log(this.state.selectedSales)});
    }

    handleShowDetailFuDialog = (resumeFu) => {
        console.log("CONTACT: ", resumeFu);
        this.setState({showDetailFuDialog: true, selectedFu: resumeFu}, () => {console.log(this.state.selectedFu)});
    }

    handleCloseDetailFuDialog = () => {
        this.setState({showDetailFuDialog: false, selectedFu: null});
    }

    handleShowDetailOrderDialog = (resumeFu) => {
        console.log("CONTACT: ", resumeFu);
        this.setState({showDetailOrderDialog: true, selectedFu: resumeFu}, () => {console.log(this.state.selectedFu)});
    }

    handleCloseDetailOrderDialog = () => {
        this.setState({showDetailOrderDialog: false, selectedFu: null});
    }

    handleFu = () => {
        
    }

    handleOpenChat = () => {
        // console.log("DOWNLOADING ", contact);
        this.handleCloseFuConfirmationDialog();
        let data = {
            salesId: Global.get().user.id,
            waNumber: this.state.selectedSales.from
        }
        
        const encode = Buffer.from(JSON.stringify(data)).toString('base64')
        console.log("dataParams : ", data, encode);
        // var url = (this.state.selectedSales.source === "LOTUS" ? Api.getLotusUrl() : Api.getLavengerUrl()) + "?fu=" + encode;
        var url = Api.getLotusUrl() + "?fu=" + encode;
        var win = window.open(url, '_blank');
        win.focus();
    }

    render() { 
        return ( 
            <div className="containe-fluid" style={{padding: "20px"}}>
                <Paper style={{padding: "20px"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6">
                                <h3>Report Performance Tele Data Pending</h3>
                            </div>
                            <div className="col-6 text-right">
                                <div className="row">
                                    <div className="col-11 text-right">
                                        <DateRangePicker onDateChanged={this.handleDateChanged} />
                                    </div>
                                    <div className="col-1">
                                    {
                                        this.state.isLoadingReport === true ? 
                                            (<CircularProgress size={30} color="secondary" />) : 
                                            (<div onClick={() => {this.getReportFu()}} className="btn btn-primary" style={{padding: 5}}><Search /></div>)
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Paper className="root">
                            <TableContainer style={{maxHeight: 440}}>
                                <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                    {this.columnsReportFu.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.reportFuPerformance.map((row) => {
                                        var i = 1;
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {this.columnsReportFu.map((column) => {

                                            const value = row[column.id];

                                            if(column.id === "no"){
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                    </TableCell>    
                                                );
                                            }

                                            if(column.id === "from"){
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {Global.maskPhoneNumber(value)}        
                                                    </TableCell>    
                                                );
                                            }

                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                </TableCell>
                                            )
                                            
                                        })}
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        
                        <br />
                        <h3>Report By Hasil FU</h3>
                        <Paper className="root">
                            <TableContainer style={{maxHeight: 440}}>
                                <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                    {this.columnsPivotFu.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.reportPivotFu.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {this.columnsPivotFu.map((column) => {

                                            const value = row[column.id];

                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                </TableCell>
                                            )
                                            
                                        })}
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Paper>
                
                <br />
                <br />

                <Paper style={{padding:"20px"}}>
                    <div className="container-fluid">
                        <h3>Sudah di Follow Up</h3>
                        <div className="row"  style={{marginTop: 10, marginBottom: 10}}>
                            <div className="col-10">
                                <TextField
                                    onChange={this.handleKeyword}
                                    label="Ketik untuk mencari sales / nama customer / nomor wa / kategori / hasil fu terakhir"
                                    type="text"
                                    className="form-control"
                                    id="namacustomer"
                                    variant="outlined"
                                    onKeyDown={this.onKeyDownHandler}
                                    onBlur={() => {
                                        
                                    }}
                                    size='small'
                                />
                            </div>
                            
                            {/* <div className="col-5text-right" style={{borderRadius: 10, padding: 5}}>
                                <MyDateRangePicker onDateChanged={this.handleDateChanged} />
                            </div> */}
                            <div className="col-1">
                                {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getMyCustomerToday(true)}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                            </div>
                        </div>
                        <br />
                        {
                            this.state.showEditAnalisa === true ? 
                            (
                                <Paper className="" style={{marginTop: "10px"}}>
                                    <div className="" style={{marginBottom: "20px", marginTop: "10px"}}>
                                        <div className="card-header bg-info">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                        <strong>Nama</strong>: {this.state.obEditHasilFu.fromName}
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                    <strong>No WA</strong> : {Global.maskPhoneNumber(this.state.obEditHasilFu.from)}
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="bg-none rounded text-dark" style={{padding: "6px"}}>
                                                        <strong>Kategori</strong> : {this.state.obEditHasilFu.productCategory}
                                                    </div>
                                                </div>
                                                <div className="col-1">
                                                <button onClick={this.handleCloseEditAnalisa} type="button" class="close" aria-label="Close">
                                                    <span aria-hidden="true" className="text-white">&times;</span>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            
                                            <div className="row rounded" style={{backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px"}}>
                                                <div className="col-12 bg-white">
                                                    {/* <TextField
                                                        onChange={this.handleHasilFuChanged}
                                                        fullWidth
                                                        label="Hasil Follow Up"
                                                        // type="text"
                                                        id="hasilFu"
                                                        variant="outlined"
                                                        multiline={true}
                                                        // value={this.state.obEditHasilFu.lastFuResultTmp === undefined | this.state.obEditHasilFu.lastFuResultTmp === null ? "" : this.state.obEditHasilFu.lastFuResultTmp}
                                                    /> */}
                                                    <FormControl variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Hasil Follow Up</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={this.state.obEditHasilFu.lastFuResultTmp === undefined | this.state.obEditHasilFu.lastFuResultTmp === null ? "" : this.state.obEditHasilFu.lastFuResultTmp}
                                                            onChange={this.handleHasilFuChanged}
                                                            label="Hasil Follow Up"
                                                        >
                                                            {
                                                            Global.getListHasilFu().map(
                                                                (cat) => {
                                                                return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                                                } 
                                                            )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                
                                            </div>
                                            <br />
                                        </div>
                                        <div className="card-footer bg-info">
                                            <div className="row">
                                                <div className="col-9"></div>
                                                <div className="col-3 text-right">
                                                    {
                                                        this.state.savingAnalisa ? 
                                                        (
                                                            <div className="alert alert-danger">
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                        <ReactLoading color="#FF0000" width="30px" height="30px" type="spin"/>
                                                                    </div>
                                                                    <div className="col-10">
                                                                        loading ...
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : 
                                                        (
                                                            <div onClick={this.saveAnalisa} className="btn btn-warning btn-block">Simpan Hasil Follow Up</div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </Paper>
                            ) : 
                            (<></>)
                        }

                        <Paper className="root">
                            <TableContainer style={{maxHeight: 440}}>
                                <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                    {this.columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.listMycustomer.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {this.columns.map((column) => {
                                            // const value = row[column.id];
                                            // const value = Global.isSales() && column.id === "from" ? row[column.id].slice(0, -5) + Array(5 + 1).join("x") : row[column.id];
                                            const value = column.id === "from" ? Global.maskPhoneNumber(row[column.id]) : row[column.id];
                                            var isEditing = row.isEditing;
                                            var messagesDownloaded = row.messages !== undefined;
                                            // return 
                                            // var colValue = "";

                                            if(column.id === "assignToUserName"){
                                                return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                        </TableCell>
                                                    )
                                            }

                                            if(column.id === "lastFuDate"){
                                                if(value !== undefined && value !== null){
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {Global.formatDateTimeToStrDateTime24(value)}   
                                                        </TableCell>
                                                    );
                                                }else{
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            -
                                                        </TableCell>
                                                    );
                                                }
                                            }

                                            if(column.id === "nextFuDate"){
                                                if(value !== undefined && value !== null){
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {Global.formatDateTimeToStrDateTime24(value)}   
                                                        </TableCell>
                                                    );
                                                }else{
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            -
                                                        </TableCell>
                                                    );
                                                }
                                            }

                                            if(column.id === "fuData"){
                                                return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {Array.isArray(value) ? value.length : 0}   
                                                        </TableCell>
                                                    );
                                            }

                                            if(column.id === "lastFuResult"){
                                                if(value !== undefined && value !== null){
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {value} 
                                                            <Edit onClick={() => this.handleEditAnalisa(row)} />
                                                        </TableCell>
                                                    );
                                                }else{
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            -
                                                        </TableCell>
                                                    );
                                                }
                                            }
                                            
                                            if(column.id === "action"){
                                                return messagesDownloaded === true && column.id === "action" ? 
                                                    (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {/* {
                                                                Global.get().admin === true ? 
                                                                ( */}
                                                                    <ToolTip title="Input hasil follow up">
                                                                        <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditAnalisa(row);}}  />
                                                                    </ToolTip>
                                                                {/* ) : 
                                                                (<></>)
                                                            } */}
                                                            &nbsp;
                                                            <ToolTip title="Follow Up">
                                                                <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowFuConfirmationDialog(row)}}  />
                                                            </ToolTip>
                                                            {/* &nbsp;
                                                            {
                                                                Global.get().admin === true ? 
                                                                (
                                                                    <ToolTip title="Download percakapan" key={row.from}>
                                                                        <ExportConversation contactData={row.contactFormatted} messagesData={row.messages}  />
                                                                    </ToolTip>
                                                                ) : 
                                                                (<></>)
                                                            } */}
                                                        </TableCell>
                                                    ) : 
                                                    (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {/* {
                                                                Global.get().admin === true ? 
                                                                ( */}
                                                                    <ToolTip title="Input hasil follow up">
                                                                        <Edit style={{cursor: "pointer"}} className="text-primary" onClick={() => {this.handleEditAnalisa(row);}}  />
                                                                    </ToolTip>
                                                                {/* ) : 
                                                                (<></>)
                                                            } */}
                                                            &nbsp;
                                                            <ToolTip title="Follow Up">
                                                                <QuestionAnswer style={{cursor: "pointer"}} className="text-danger" onClick={() => {this.handleShowFuConfirmationDialog(row)}}  />
                                                            </ToolTip>
                                                            {/* &nbsp;
                                                            {
                                                                Global.get().admin === true ? 
                                                                (
                                                                    <ToolTip title="Download percakapan">
                                                                        <CloudDownloadOutlined style={{cursor: "pointer"}} className="text-success" onClick={() => {this.getWaMessages(row.contactFormatted);}}  />
                                                                    </ToolTip>
                                                                ) : 
                                                                (<></>)
                                                            } */}
                                                        </TableCell>
                                                    )
                                            }

                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}        
                                                </TableCell>
                                            )
                                            
                                        })}
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                component="div"
                                count={this.state.listMycustomer.length + this.state.rowsPerPage}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </Paper>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />

                <Modal
                    show={this.state.showDetailFuDialog}
                    onHide={this.handleCloseDetailFuDialog}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                >
                    <Modal.Header closeButton>Detail Follow Up ({this.state.selectedFu !== null && this.state.selectedFu !== undefined ? this.state.selectedFu.userName : ""})</Modal.Header>
                    <Modal.Body>
                        <div className="row" style={{padding: "10px"}}>
                            {
                                this.state.selectedFu !== null && this.state.selectedFu !== undefined ? 
                                    this.state.selectedFu.fuData.map((fuItem) => {
                                        return (
                                            <>
                                                <div className="col-4" key={fuItem._id}>
                                                    <Card>
                                                        <CardContent>
                                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                {fuItem.fromName}
                                                            </Typography>
                                                            <Typography variant="h5" component="div">
                                                                {Global.maskPhoneNumber(fuItem.from)}
                                                            </Typography>
                                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                                {Global.formatDateTimeToStrDateTime24(fuItem.created_at)}  
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                    <br />
                                                </div>
                                            </>
                                        );
                                    }) : 
                                (<></>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showDetailOrderDialog}
                    onHide={this.handleCloseDetailOrderDialog}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                >
                    <Modal.Header closeButton>Detail Order ({this.state.selectedFu !== null && this.state.selectedFu !== undefined ? this.state.selectedFu.userName : ""})</Modal.Header>
                    <Modal.Body>
                        <div className="row" style={{padding: "10px"}}>
                            {
                                this.state.selectedFu !== null && this.state.selectedFu !== undefined ? 
                                    this.state.selectedFu.orderData.map((fuItem) => {
                                        return (
                                            <>
                                                <div className="col-4" key={fuItem._id}>
                                                    <Card>
                                                        <CardContent>
                                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                {fuItem.fromName}
                                                            </Typography>
                                                            <Typography variant="h5" component="div">
                                                                {Global.maskPhoneNumber(fuItem.from)}
                                                            </Typography>
                                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                                {Global.formatDateTimeToStrDateTime24(fuItem.created_at)}  
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                    <br />
                                                </div>
                                            </>
                                        );
                                    }) : 
                                (<></>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        
                    </Modal.Footer>
                </Modal>
                
            </div>
        );
    }
}
 
export default FollowUpReport;